import crypto from 'crypto'
// import zlib from 'zlib'
import pako from 'pako'
import { LRUCache } from '@splunkdlt/cache'

export function md5(data: Object | string): string {
  const stringValue = typeof data === 'string' ? data : JSON.stringify(data)
  return crypto.createHash('md5').update(stringValue).digest('hex')
}

export class CompressedLRUCache<V = string | object> {
  private cache: LRUCache<string, Uint8Array>
  private parseObjects: boolean
  private hashKeys: boolean

  constructor({
    maxSize,
    parseObjects = true,
    hashKeys = true
  }: {
    maxSize: number
    parseObjects?: boolean
    hashKeys?: boolean
  }) {
    this.cache = new LRUCache<string, Uint8Array>({ maxSize })
    this.parseObjects = parseObjects
    this.hashKeys = hashKeys
  }

  private getStringValue(value: V): string {
    if (typeof value === 'string') {
      return value
    }
    if (typeof value === 'object') {
      return JSON.stringify(value)
    }
    return (value as any).toString()
  }

  set(key: any, value: V) {
    const hash = this.hashKeys ? md5(key) : key
    const stringValue = this.getStringValue(value)
    // const compressed = zlib.gzipSync(stringValue)
    const compressed = pako.deflate(stringValue)
    return this.cache.set(hash, compressed)
  }

  has(key: any) {
    const hash = this.hashKeys ? md5(key) : key
    return this.cache.has(hash)
  }

  get(key: any) {
    const hash = this.hashKeys ? md5(key) : key
    const compressed = this.cache.get(hash)
    if (compressed != null) {
      // const uncompressed = zlib.gunzipSync(compressed)
      const uncompressed = pako.inflate(compressed, { to:'string' })
      if (this.parseObjects) {
        return JSON.parse(uncompressed) as V
      }
      return (<unknown>uncompressed) as V
    }
    return undefined
  }
}
