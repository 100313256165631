import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

import { TiptapVuetifyPlugin } from 'tiptap-vuetify'

import 'tiptap-vuetify/dist/main.css'

import AMPL from '@/icon-components/AMPL.vue'
import AUDIO from '@/icon-components/AUDIO.vue'
import BAT from '@/icon-components/BAT.vue'
import BNB from '@/icon-components/BNB.vue'
import BTC from '@/icon-components/BTC.vue'
import BUSD from '@/icon-components/BUSD.vue'
import CDAI from '@/icon-components/CDAI.vue'
import CEL from '@/icon-components/CEL.vue'
import CETH from '@/icon-components/CETH.vue'
import CHZ from '@/icon-components/CHZ.vue'
import COMP from '@/icon-components/COMP.vue'
import CRO from '@/icon-components/CRO.vue'
import CRV from '@/icon-components/CRV.vue'
import CUSDC from '@/icon-components/CUSDC.vue'
import DAI from '@/icon-components/DAI.vue'
import ENJ from '@/icon-components/ENJ.vue'
import ENS from '@/icon-components/ENS.vue'
import ETH from '@/icon-components/ETH.vue'
import FORTH from '@/icon-components/FORTH.vue'
import FTM from '@/icon-components/FTM.vue'
import GRT from '@/icon-components/GRT.vue'
import GUSD from '@/icon-components/GUSD.vue'
import HEX from '@/icon-components/HEX.vue'
import HOT from '@/icon-components/HOT.vue'
import HT from '@/icon-components/HT.vue'
import KCS from '@/icon-components/KCS.vue'
import LEO from '@/icon-components/LEO.vue'
import LINK from '@/icon-components/LINK.vue'
import MATIC from '@/icon-components/MATIC.vue'
import MIM from '@/icon-components/MIM.vue'
import MKR from '@/icon-components/MKR.vue'
import NEXO from '@/icon-components/NEXO.vue'
import OKB from '@/icon-components/OKB.vue'
import OMG from '@/icon-components/OMG.vue'
import ONE from '@/icon-components/ONE.vue'
import OPEN from '@/icon-components/OPEN.vue'
import PAXG from '@/icon-components/PAXG.vue'
import QNT from '@/icon-components/QNT.vue'
import RARI from '@/icon-components/RARI.vue'
import SNX from '@/icon-components/SNX.vue'
import SUSD from '@/icon-components/SUSD.vue'
import SUSHI from '@/icon-components/SUSHI.vue'
import THETA from '@/icon-components/THETA.vue'
import TORN from '@/icon-components/TORN.vue'
import TRON from '@/icon-components/TRON.vue'
import TUSD from '@/icon-components/TUSD.vue'
import UNI from '@/icon-components/UNI.vue'
import USDC from '@/icon-components/USDC.vue'
import USDT from '@/icon-components/USDT.vue'
import VEN from '@/icon-components/VEN.vue'
import WBTC from '@/icon-components/WBTC.vue'
import WETH from '@/icon-components/WETH.vue'
import YFI from '@/icon-components/YFI.vue'
import ZIL from '@/icon-components/ZIL.vue'

const vuetify = new Vuetify()

Vue.use(Vuetify)

Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: 'mdi'
})

export default new Vuetify({
  // theme: {dark: true}
  icons: {
    iconfont: 'mdi',
    values: {
      // networks
      bitcoin: {
        component: BTC
      },
      ethereum: {
        component: ETH
      },
      tron: {
        component: TRON
      },
      // symbols
      AMPL: {
        component: AMPL
      },
      AUDIO: {
        component: AUDIO
      },
      BAT: {
        component: BAT
      },
      BNB: {
        component: BNB
      },
      BTC: {
        component: BTC
      },
      BUSD: {
        component: BUSD
      },
      CDAI: {
        component: CDAI
      },
      CEL: {
        component: CEL
      },
      CETH: {
        component: CETH
      },
      CHZ: {
        component: CHZ
      },
      COMP: {
        component: COMP
      },
      CRO: {
        component: CRO
      },
      CRV: {
        component: CRV
      },
      CUSD: {
        component: CUSDC
      },
      DAI: {
        component: DAI
      },
      ENJ: {
        component: ENJ
      },
      ENS: {
        component: ENS
      },
      ETH: {
        component: ETH
      },
      FORTH: {
        component: FORTH
      },
      FTM: {
        component: FTM
      },
      GRT: {
        component: GRT
      },
      GUSD: {
        component: GUSD
      },
      HEX: {
        component: HEX
      },
      HOT: {
        component: HOT
      },
      HT: {
        component: HT
      },
      KCS: {
        component: KCS
      },
      LEO: {
        component: LEO
      },
      LINK: {
        component: LINK
      },
      MATIC: {
        component: MATIC
      },
      MIM: {
        component: MIM
      },
      MKR: {
        component: MKR
      },
      NEXO: {
        component: NEXO
      },
      OKB: {
        component: OKB
      },
      OMG: {
        component: OMG
      },
      ONE: {
        component: ONE
      },
      OPEN: {
        component: OPEN
      },
      PAXG: {
        component: PAXG
      },
      QNT: {
        component: QNT
      },
      RARI: {
        component: RARI
      },
      SNX: {
        component: SNX
      },
      SUSD: {
        component: SUSD
      },
      SUSHI: {
        component: SUSHI
      },
      THETA: {
        component: THETA
      },
      TORN: {
        component: TORN
      },
      TRON: {
        component: TRON
      },
      TUSD: {
        component: TUSD
      },
      UNI: {
        component: UNI
      },
      USDC: {
        component: USDC
      },
      USDT: {
        component: USDT
      },
      VEN: {
        component: VEN
      },
      WBTC: {
        component: WBTC
      },
      WETH: {
        component: WETH
      },
      YFI: {
        component: YFI
      },
      ZIL: {
        component: ZIL
      },
    }
  }
})
