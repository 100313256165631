
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { BtcHerustics, OutputReference } from '@/store/chain'
import { ExtendedInput, ExtendedOutput, ExtendedTx, isExtendedCoinbase, TxHeader } from '@/types/bitcoin'
import { decodeBtcHex, isDust } from '@/utils/bitcoin'
import { copyToClipboard, cutMiddle } from '@/utils/general'
import { filter } from '@/utils/filters'
import { ClusterMetadata, Attribution, EntitySummary } from '@/utils/api'
import { mapState } from 'vuex'
import { LRUCache } from '@splunkdlt/cache'
import { prettyRoundedNumber } from '@/utils/general'

@Component({
  computed: mapState(['foundInputs', 'clusterSummaries', 'attributionSizes', 'clusterSummariesUpdated'])
})
export default class BtcOutputCard extends Vue {
  public cutMiddle = cutMiddle
  public isDust = isDust
  public show: boolean = false
  public clusterId: string = ''
  public attribution = ''
  public changeOutput = false
  public inputLink = ''
  public heuristics = {
    haveHeuristics: false,
    haveHeader: false,
    svb: 0,
    rbf: false,
    locktime: 0,
    version: 0,
    witness: false,
    multisig: ''
  }
  private nextInput = {
    txid: '',
    index: 0
  }
  public size: string = 'Loading'
  public transactionCount: string = 'Loading'

  @Prop() txid!: string
  @Prop() transaction!: ExtendedTx
  @Prop() item!: ExtendedOutput
  @Prop() network!: string
  @Prop() color!: string
  @Prop() selected!: boolean
  @Prop() update!: number

  public foundInputs!: LRUCache<string, ExtendedInput>
  public clusterSummaries!: LRUCache<string, EntitySummary>
  public attributionSizes!: LRUCache<string, number>
  public clusterSummariesUpdated!: number

  get unspentOutputs(): OutputReference[] {
    return this.$store.state.unspentOutputs
  }

  get shortenedMultisig() {
    const { type } = this.item.scriptPubKey
    if (type.startsWith('ms')) {
      const [numerator, denominator] = type.split('/')
      return `${numerator.slice(-1)}/${denominator.slice(0, 1)}`
    }
    return ''
  }

  created() {
    this.inputLink = ''
    this.updateAll()
  }

  @Watch('update')
  private updateAll() {
    this.setAttribution()
    this.setChange()
    this.updateNextHop()
    // this.heuristicsCacheUpdated()
    this.txnHeadersCacheUpdated()
  }

  public cardClasses(hover: boolean, selected: boolean): string {
    if (selected) {
      return 'hover selected'
    }
    if (hover) {
      return 'hover'
    }
    return ''
  }

  public getAttributions(address: string): Attribution[] {
    const has = this.$store.state.shared.attributionsCache.has(address)
    return has ? this.$store.state.shared.attributionsCache.get(address) : []
  }

  public getAttribution(address: string): string {
    const attributions = this.getAttributions(address)
    if (attributions.length > 0) {
      const valueSet = new Set(attributions.flatMap((a) => a.attributions))
      if (valueSet.size > 0) {
        return `${Array.from(valueSet).join(', ')}`
      }
    }
    return ''
  }

  public getCluster(address: string): ClusterMetadata | null {
    const has = this.$store.state.shared.clusterAddressCache.has(address)
    return has ? this.$store.state.shared.clusterAddressCache.get(address) : null
  }

  public getClusterAttribution(address: string): { attribution?: string; clusterId?: string; size?: number } {
    const cluster = this.getCluster(address)
    if (cluster != null) {
      const { id, topAttribution, size } = cluster
      if (topAttribution != null && topAttribution !== '') {
        return {
          attribution: topAttribution,
          clusterId: id,
          size
        }
      }
      return {
        clusterId: cluster.id,
        size
      }
    }
    this.clusterId = ''
    return {}
  }

  public getLabel(attribution: string, cluster: { attribution?: string; clusterId?: string }): string {
    if (cluster.attribution != null && cluster.attribution !== '') {
      return cluster.attribution
    }
    if (attribution !== '') {
      return attribution
    }
    if (cluster.clusterId != null && cluster.clusterId !== '') {
      return cutMiddle(cluster.clusterId, 9)
    }
    return ''
  }

  public setAttribution() {
    const { address } = this.item.scriptPubKey
    if (address != null && address !== '') {
      const { clusterId, attribution: clusterAttribution, size } = this.getClusterAttribution(address)
      this.clusterId = clusterId ?? ''
      this.size = size ? prettyRoundedNumber(size) : ''
      const attribution = this.getAttribution(address)
      this.attribution = this.getLabel(attribution, { clusterId, attribution: clusterAttribution })
    }
  }

  public setChange() {
    if (this.transaction != null) {
      const inputs = this.transaction.vin
      for (const input of inputs) {
        if (!isExtendedCoinbase(input)) {
          const { address } = input.spentOutput
          if (address != null && address !== '') {
            const clusterAttribution = this.getClusterAttribution(address)
            const attribution = this.getAttribution(address)
            if (
              (this.attribution !== '' && this.getLabel(attribution, clusterAttribution) === this.attribution) ||
              (address === this.item.scriptPubKey.address)
            ) {
              this.changeOutput = true
              return
            }
          }
        }
      }
    }
  }

  private findSpend(txid: string, n: number) {
    this.$store.dispatch('findInput', { network: this.network, previousOutput: txid, vout: n })
  }

  public toggleShow() {
    if (!this.show && this.clusterId) {
      this.$store.dispatch('getClusterSummary', { network: this.network, id: this.clusterId })
    }
    this.show = !this.show
  }

  @Watch('$store.state.foundInputsCount')
  private async foundSpend() {
    const id = `${this.txid}_${this.item.n}`
    const input: ExtendedInput | null = this.foundInputs.get(id)
    if (input != null) {
      this.nextInput.txid = input.txid
      this.nextInput.index = input.index
      this.inputLink = `/explorer/transaction/${this.network}/${this.nextInput.txid}?vin=${this.nextInput.index}`
    }
  }

  private updateNextHop() {
    this.findSpend(this.txid, this.item.n)
    this.foundSpend()
  }

  public isUnspent(txid: string, n: number) {
    return (
      filter(this.unspentOutputs, (u) => {
        return u.txid === txid && u.n === n
      }).length > 0
    )
  }

  public copy(text: string | undefined) {
    if (text != null) {
      copyToClipboard(text)
      this.$store.dispatch('updateSnackbar', { show: true, text: `Copied ${text} to clipboard.` })
    }
  }

  public decodeHex(hex: string): string {
    if (hex != null) {
      return decodeBtcHex(hex)
    }
    return ''
  }

  @Watch('$store.state.heuristicsCacheCount')
  private heuristicsCacheUpdated() {
    if (this.$store.state.heuristicsCache.has(this.nextInput.txid)) {
      const h: BtcHerustics = this.$store.state.heuristicsCache.get(this.nextInput.txid)
      this.heuristics.svb = h.svb
      this.heuristics.haveHeuristics = true
    }
  }

  @Watch('$store.state.txnHeadersCacheCount')
  private txnHeadersCacheUpdated() {
    if (this.$store.state.txnHeadersCache.has(this.nextInput.txid)) {
      const h: TxHeader = this.$store.state.txnHeadersCache.get(this.nextInput.txid)
      this.heuristics.locktime = h.locktime
      this.heuristics.version = h.version
      this.heuristics.witness = h.segwit
      this.heuristics.rbf = h.rbf
      this.heuristics.multisig = this.shortenedMultisig
      this.heuristics.haveHeader = true
    }
  }

  @Watch('clusterSummariesUpdated')
  private summariesAndSizesUpdated() {
    const summary = this.clusterSummaries.get(this.clusterId)
    if (summary != null) {
      this.transactionCount = prettyRoundedNumber(summary.symbols.bitcoin.currentTransactionCount)
    }
  }
}
