
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import { mapState } from 'vuex'
import * as d3 from 'd3'
import { Viewport } from 'pixi-viewport'
import { SimulationNodeDatum, SimulationLinkDatum, Simulation, DSVRowArray } from 'd3'
import {
  Application,
  Graphics,
  ObservablePoint,
  Polygon,
  Circle,
  Point,
  Texture,
  FederatedMouseEvent,
  Container
} from 'pixi.js'
import { cutEnd, getCsvOrExcelData, CustomFile, prettyRoundedNumber } from '@/utils/general'
import { filter } from '@/utils/filters'
import { formatTarget, classifyNodeId, nodeTypeToIdString } from '@/utils/viz'
import { attributionCategoryColor, clusterCategoryColor, COLOR_BLACK, COLOR_BLACK_STR, COLOR_BLACKISH, COLOR_LIGHT_GRAY, COLOR_ORANGE, COLOR_VERY_LIGHT_GRAY, COLOR_WHITE, convertColorToNumeric, LINK_COLOR_SWATCHES } from '@/utils/colors'
import { Investigation } from '@/store/investigations/investigations'
import {
  SettingsCollection,
  Location,
  Node,
  FormattedNode,
  FormattedLink,
  Target,
  TransactionFlowTarget,
  ForceControls,
  FlowTarget,
  Link,
  Ranges,
  Flow,
  Macrotized,
  ReportTransaction,
  IdType,
  SummaryLink,
  Edge,
  FormattedGraph,
  NoteNode,
  NoteLink,
  isNoteNode,
  isNoteLink,
  NoteNodeInfo,
  isNetworkDupesLink,
  GraphLink,
  NetworkDupesLink,
  isInteractiveLink,
  InteractiveLink
} from '@/store/investigations/viz'
import { FlowResponse, Attribution, ClusterMetadata } from '@/utils/api'
import Settings from './sidepanel-tabs/Settings.vue'
import { State } from '@/store'
import { LRUCache } from '@splunkdlt/cache'
import { SDFRenderer } from '@/utils/sdf-text'
import Menu from './Menu.vue'
import { combineBidirectionalLinks, drawLink, drawNetworkDupesLink, drawNoteLink, drawSingleBidirectionalLink, FormattedSummaryLink, getTotalThicknessForLink, isTransactionLevel, labelLink, LINK_MAX_TRANSACTIONS, LINK_MIN_TRANSACTIONS, MAX_THICKNESS, MIN_THICKNESS } from '@/utils/graph-links'
import { closestGridPoint, createNote, drawDashedPolygon, drawGrid, getBoxBoundsFromCorners, getGreatestValueFromFlow, Grid, GRID_SIZE, HALF_NODE_PLACEMENT_DISTANCE, NODE_PLACEMENT_DISTANCE, X_SCREEN_PADDING } from '@/utils/graph-helpers'
import { attachNodeIcon, drawNode, ENTITY_MAX_SIZE, ENTITY_MIN_SIZE, labelNode, NODE_MAX_SIZE, NODE_MIN_SIZE, ROTATED_OCTAGON_POINTS } from '@/utils/graph-nodes'
import Dialog, { DialogConfig, FormComponent } from '../Dialog.vue'
import FileUpload from '@/subcomponents/FileUpload.vue'
import Download from '@/subcomponents/Download.vue'
import ConfirmationForm from '@/subcomponents/ConfirmationForm.vue'
import { WebGLGraph } from '@/utils/graph'
import Legend from '@/subcomponents/Legend.vue'
import SaveInvestigation from '@/subcomponents/SaveInvestigation.vue'
import { Position } from 'vue-router/types/router'
import { FormattedLogEvent } from '@/types/eth'
import ColorPicker from '@/subcomponents/ColorPicker.vue'

@Component({
  components: {
    Settings,
    Menu,
    Dialog,
    ColorPicker
  },
  computed: {
    ...mapState([
      'categoryScores',
      'flowScaleTarget',
      'forces',
      'graphCreating',
      'graphLoading',
      'links',
      'newestLinks',
      'summaryLinks',
      'newestNodes',
      'nodes',
      'ranges',
      'selectedLink',
      'deletionConfirmations',
      'settings',
      'subnetworkFlow',
      'macrotized',
      'nodesToRemove',
      'linksToRemove',
      'subnetworkReceiver',
      'subnetworkSender',
      'target',
      'targetNetwork',
      'rawFlowFilterSelectMode',
      'graphRedraw',
      'snapshot',
      'report',
      'targetNodeRename',
      'formatDate',
      'deletedLinks',
      'highlightFlowKeys',
      'receiptsCache'
    ]),
    ...mapState({
      attributionsCache: (state) => (state as State).shared.attributionsCache,
      attributionsCacheUpdated: (state) => (state as State).shared.attributionsCacheUpdated,
      clusterAddressCache: (state) => (state as State).shared.clusterAddressCache,
      clusterAddressesUpdated: (state) => (state as State).shared.clusterAddressesUpdated,
      attributionClustersCache: (state) => (state as State).shared.attributionClustersCache,
      attributionClustersUpdated: (state) => (state as State).shared.attributionClustersUpdated,
      attributionSizeCache: (state) => (state as State).shared.attributionSizeCache,
      attributionSizesUpdated: (state) => (state as State).shared.attributionSizesUpdated
    })
  }
})
export default class Graph extends Vue {
  @Prop() containerWidth!: number
  @Prop() investigation!: Investigation
  @Prop() destroyCallback!: () => void

  public app!: Application
  public viewport!: Viewport

  private grid!: Grid
  private snapToGrid: boolean = false

  public dialogs: DialogConfig<typeof FormComponent>[] = [
    {
      button: {
        icon: 'mdi-file-upload',
        tooltip: 'Upload File'
      },
      dialog: {
        title: 'Upload',
        action: 'Upload',
        actionClick: this.graphFileContents
      },
      content: FileUpload,
      props: {
        maxSize: 5,
        accept: 'csv,xlsx'
      },
      onValueUpdate: this.handleFilesUploaded
    },
    {
      button: {
        icon: 'mdi-file-download',
        tooltip: 'Download Report'
      },
      dialog: {
        title: 'Download Report',
        action: 'Download'
      },
      content: Download,
      // set props here so that the component knows it's getting props, even though they'll be replaced later
      props: {
        investigation: this.investigation,
        app: this.app,
        viewport: this.viewport,
        grid: this.grid
      }
    },
    {
      button: {
        icon: 'mdi-cog',
        tooltip: 'Settings'
      },
      dialog: {
        title: 'Settings',
        action: 'Close'
      },
      content: Settings
    },
    {
      button: {
        icon: 'mdi-help',
        tooltip: 'Hotkeys Legend'
      },
      dialog: {
        title: 'Hotkeys',
        action: 'Close'
      },
      content: Legend
    }
  ]

  public deletionConfirmations!: boolean

  public settings!: SettingsCollection
  public graphCreating!: boolean
  public graphLoading!: boolean
  public nodes!: Node[]
  public newestNodes!: Set<string>
  public links!: Link[]
  public newestLinks!: Set<string>
  public ranges!: Ranges
  public summaryLinks!: SummaryLink[]
  public deletedLinks!: Edge[]
  public target!: Target | undefined
  public targetNetwork!: string
  public attributionsCache!: LRUCache<string, Attribution[]>
  public attributionsCacheUpdated!: number
  public clusterAddressCache!: LRUCache<string, ClusterMetadata>
  public clusterAddressesUpdated!: number
  public attributionClustersCache!: LRUCache<string, { [network: string]: ClusterMetadata }>
  public attributionClustersUpdated!: number
  public attributionSizeCache!: LRUCache<string, { [network: string]: number }>
  public attributionSizesUpdated!: number
  public categoryScores!: { [category: string]: number }
  public flowScaleTarget!: TransactionFlowTarget
  public forces!: ForceControls
  public subnetworkSender!: FlowTarget | undefined
  public subnetworkReceiver!: FlowTarget | undefined
  public subnetworkFlow!: FlowResponse | undefined
  public selectedLink!: InteractiveLink | undefined
  public macrotized!: Macrotized | undefined
  public nodesToRemove!: string[] | undefined
  public linksToRemove!: string[] | undefined
  public rawFlowFilterSelectMode!: boolean
  public graphRedraw!: boolean
  public snapshot!: Blob
  public report!: ReportTransaction[]
  public targetNodeRename!: string | undefined
  public formatDate!: (epoch: number, ms: boolean, shortness?: number) => string
  public highlightFlowKeys!: string[]
  public receiptsCache!: LRUCache<string, FormattedLogEvent>

  private priorSettings!: SettingsCollection
  private simulation!: Simulation<SimulationNodeDatum, SimulationLinkDatum<SimulationNodeDatum> | undefined>
  private drawing: boolean = true
  private shiftDown: boolean = false
  private ctrlDown: boolean = false
  private drawDeleted: boolean = false

  private nodesFormatted: FormattedNode[] = []
  private linksFormatted: FormattedLink[] = []
  private removedNodes: FormattedNode[] = []
  private removedLinks: FormattedLink[] = []
  private summaryLinksFormatted: FormattedSummaryLink[] = []
  private summaryLinksRemoved: FormattedSummaryLink[] = []
  private nodeSizeScale!: d3.ScaleLinear<number, number, never> | d3.ScaleSymLog<number, number, never>
  private linkScale!: d3.ScaleLinear<number, number, never> | d3.ScaleSymLog<number, number, never>
  private appearanceScale!: d3.ScaleLinear<number, number, never>
  private hopScale!: d3.ScaleLinear<number, number, never>

  private noteNodes: NoteNode[] = []
  private noteNodesRemoved: NoteNode[] = []
  private noteLinks: NoteLink[] = []
  private noteLinksRemoved: NoteLink[] = []
  private entityNetworks: { [entity: string]: string[] } = {}
  private networkDupesLinks: NetworkDupesLink[] = []
  private networkDupesLinksRemoved: NetworkDupesLink[] = []
  private finishedEditing?: NodeJS.Timeout

  private newestLinksCopy = new Set<string>()

  public decimalFormatter!: (n: number | string) => string

  private clicking: boolean = false
  private clickingOutside: boolean = false
  private overNodeOrLink: boolean = false
  private nodeDown?: FormattedNode | NoteNode
  private highlightedNode?: FormattedNode
  private highlightLink: string = ''

  private targetBorderGfx?: Graphics
  private targetBorderPoints: Location[] = [
    { x: -1, y: -1 },
    { x: -1, y: 1 },
    { x: 1, y: 1 },
    { x: 1, y: -1 }
  ]
  private targetBorderAnimation: number = 0

  private selectBorderGfx?: Graphics
  private selectBorderPoints: Location[] = []
  private selectBorderAdjusting: boolean = false
  private selectBorderMoving: boolean = false
  private selectBorderAnimation: number = 0

  private mouseStart?: Location
  private selectedNodes = new Set<FormattedNode>()
  public nodesAreSelected: boolean = false // need boolean for template reactivity
  private selectedLinks = new Set<InteractiveLink>()
  private movingLinks: GraphLink[] = []
  private movingLinksRemoved: GraphLink[] = []
  private deleteNodeListener = (e: KeyboardEvent) => {}
  private deleteLinkListener = (e: KeyboardEvent) => {}
  private deleteSelectionListener = (e: KeyboardEvent) => {}

  public deleteDialog: DialogConfig<typeof ConfirmationForm> = {
    button: {
      icon: ''
    },
    dialog: {
      title: 'Confirm Delete',
      action: 'Delete',
      actionClick: this.doRelevantDelete
    },
    content: ConfirmationForm,
    props: {
      actionMessageEnd: this.deletionMessageEnd,
      type: 'delete'
    },
    show: false
  }

  public nodeMenu = {
    show: false,
    node: '',
    x: 0,
    y: 0,
    localX: 0,
    localY: 0,
    label: '',
    editName: false
  }
  public linkMenu = {
    show: false,
    link: <InteractiveLink | undefined>undefined,
    x: 0,
    y: 0,
    localX: 0,
    localY: 0
  }
  public deleteNoteButton = {
    show: false,
    x: 0,
    y: 0,
    note: <NoteNode | undefined>undefined
  }

  private sdf!: SDFRenderer
  private textures: { [name: string]: Texture } = {}

  private rawFlowFilterNodes: Set<string> = new Set()

  public fileData?: DSVRowArray<string>[]

  public networksFilter: string[] = []

  public formatTarget = formatTarget
  public cutEnd = cutEnd
  public LINK_COLOR_SWATCHES = LINK_COLOR_SWATCHES
  public COLOR_BLACK_STR = COLOR_BLACK_STR

  public get networks() {
    const networks = new Set<string>()
    for (const node of this.nodesFormatted) {
      if (!isNoteNode(node)) {
        networks.add(classifyNodeId(node.id).network)
      }
    }
    for (const node of this.removedNodes) {
      if (!isNoteNode(node)) {
        networks.add(classifyNodeId(node.id).network)
      }
    }
    return Array.from(networks)
  }

  public get entityNodesFormatted() {
    return [
      ...filter(
        this.nodesFormatted,
        (n) => classifyNodeId(n.id).type !== 'transaction' || !!n.permanent
      ),
      ...this.noteNodes
    ]
  }

  public get entityNodesRemoved() {
    return [
      ...filter(
        this.removedNodes,
        (n) => classifyNodeId(n.id).type !== 'transaction' || !!n.permanent
      ),
      ...this.noteNodesRemoved
    ]
  }

  public get activeNodes() {
    if (this.settings.txnNodeSwitch) {
      const nodesFormatted: (FormattedNode | NoteNode)[] = Array.from(this.nodesFormatted)
      for (const node of this.noteNodes) {
        nodesFormatted.push(node)
      }
      return nodesFormatted
    }
    return this.entityNodesFormatted
  }

  public get activeNodesRemoved() {
    if (this.settings.txnNodeSwitch) {
      const removedNodes: (FormattedNode | NoteNode)[] = Array.from(this.removedNodes)
      for (const node of this.noteNodesRemoved) {
        removedNodes.push(node)
      }
      return removedNodes
    }
    return this.entityNodesRemoved
  }

  public get allLinks(): GraphLink[] {
    const links: GraphLink[] = []
    for (const link of this.linksFormatted) {
      links.push(link)
    }
    for (const link of this.removedLinks) {
      links.push(link)
    }
    for (const link of this.summaryLinksFormatted) {
      links.push(link)
    }
    for (const link of this.summaryLinksRemoved) {
      links.push(link)
    }
    for (const link of this.noteLinks) {
      links.push(link)
    }
    for (const link of this.noteLinksRemoved) {
      links.push(link)
    }
    for (const link of this.networkDupesLinks) {
      links.push(link)
    }
    for (const link of this.networkDupesLinksRemoved) {
      links.push(link)
    }
    return links
  }

  public get activeLinks(): GraphLink[] {
    const activeLinks: GraphLink[] = []
    // first grab transaction-level
    for (const link of this.linksFormatted) {
      if (this.settings.txnNodeSwitch || link.permanent) {
        activeLinks.push(link)
      }
    }
    // add summary links
    for (const link of this.summaryLinksFormatted) {
      activeLinks.push(link)
    }
    // add note links
    for (const link of this.noteLinks) {
      activeLinks.push(link)
    }
    // add network dupe links
    for (const link of this.networkDupesLinks) {
      activeLinks.push(link)
    }
    return activeLinks
  }

  public get activeLinksRemoved(): GraphLink[] {
    const activeLinksRemoved: GraphLink[] = []
    // first grab transaction-level
    for (const link of this.removedLinks) {
      if (this.settings.txnNodeSwitch || link.permanent) {
        activeLinksRemoved.push(link)
      }
    }
    // add summary links
    for (const link of this.summaryLinksRemoved) {
      activeLinksRemoved.push(link)
    }
    // add note links
    for (const link of this.noteLinksRemoved) {
      activeLinksRemoved.push(link)
    }
    // add network dupe links
    for (const link of this.networkDupesLinksRemoved) {
      activeLinksRemoved.push(link)
    }
    return activeLinksRemoved
  }

  public deletionMessageEnd() {
    let deletionTarget = ''
    if (this.selectedNodes.size > 0) {
      deletionTarget = 'nodes'
    }
    if (this.selectedLinks.size > 0) {
      deletionTarget = deletionTarget === '' ? 'links' : 'nodes and links'
    }
    if (deletionTarget === '') deletionTarget = this.target != null ? 'node' : 'link'
    return ` you want to delete the selected ${deletionTarget}`
  }

  deleteDialogShowChanged(value: boolean) {
    this.deleteDialog.show = value
  }

  addCanvas(container: HTMLElement) {
    const webGLGraph = new WebGLGraph({})
    const { graph: app, layers: viewports, width, height } = webGLGraph.createCanvas(container, 20)
    this.app = app
    this.viewport = viewports[0]
    container.appendChild(app.view as HTMLCanvasElement)
    app.stage.addChild(this.viewport)
    this.viewport
      .drag()
      .pinch()
      .wheel()
      .decelerate({ friction: 0.9 })
      .clampZoom({ minWidth: width / 3, minHeight: height / 3, maxWidth: width * 20, maxHeight: height * 20 })
      .on('pointerdown', (e: FederatedMouseEvent) => {
        let setupNodeMove = false
        const { x, y } = e.getLocalPosition(this.viewport)
        if (this.highlightedNode != null && this.selectedNodes.has(this.highlightedNode)) {
          setupNodeMove = true
        } else if (this.selectBorderPoints.length > 0) {
          const { lowerX, upperX, lowerY, upperY } = getBoxBoundsFromCorners(
            this.selectBorderPoints[0],
            this.selectBorderPoints[2]
          )
          if (x > lowerX && x < upperX && y > lowerY && y < upperY) {
            setupNodeMove = true
          } else if (!this.overNodeOrLink) {
            this.clickingOutside = true
            setTimeout(() => this.clickingOutside = false, 300)
          }
        } else if (!this.overNodeOrLink && this.selectedNodes.size > 0) {
          this.clickingOutside = true
          setTimeout(() => this.clickingOutside = false, 300)
        }

        if (setupNodeMove) {
          this.viewport.plugins.pause('drag')
          this.selectBorderMoving = true
          this.setMovingLinks()
          this.mouseStart = { x, y }

          this.simulation.alpha(0.2).alphaDecay(0).restart()
          for (const node of this.selectedNodes as Set<any>) {
            const { gfx } = node
            gfx.isDown = true
            gfx.alpha = 0.5
            gfx.dragging = true
          }
        } else if (e.shiftKey && !this.drawDeleted) {
          this.viewport.plugins.pause('drag')
          if (this.selectBorderGfx == null) {
            this.selectBorderGfx = new Graphics()
            this.selectBorderGfx.zIndex = 3
            this.viewport.addChild(this.selectBorderGfx)
          }
          this.selectBorderAdjusting = true
          this.selectBorderPoints = [
            { x, y },
            { x, y },
            { x, y },
            { x, y }
          ]
          this.animateSelectBorder()
        }
      })
      .on('rightdown', (e: FederatedMouseEvent) => {
        if (!this.overNodeOrLink) {
          this.clickingOutside = false // avoids clearing the selection on a right click in space
        }
      })
      .on('pointermove', (e: FederatedMouseEvent) => {
        if (this.selectBorderAdjusting && !this.overNodeOrLink) {
          const { x: newX, y: newY } = e.getLocalPosition(this.viewport)
          const { x: oldX, y: oldY } = this.selectBorderPoints[0]
          this.selectBorderPoints = [
            { x: oldX, y: oldY },
            { x: newX, y: oldY },
            { x: newX, y: newY },
            { x: oldX, y: newY }
          ]
        }
        if (this.selectBorderMoving) {
          const { x: oldX, y: oldY } = this.mouseStart!
          const { x: newX, y: newY } = e.getLocalPosition(this.viewport)
          const xDif = newX - oldX
          const yDif = newY - oldY
          for (const point of this.selectBorderPoints) {
            point.x += xDif
            point.y += yDif
          }
          for (const node of this.selectedNodes as Set<any>) {
            if (node.gfx.dragging) {
              node.x += xDif
              node.y += yDif
              node.freeze = { x: node.x, y: node.y }
            }
          }
          this.mouseStart = { x: newX, y: newY }
        } else if (this.nodeDown != null) {
          if (!this.rawFlowFilterSelectMode) {
            this.onDragMove(this.nodeDown, e)
          }
        }
      })
      .on('pointerup', (e: FederatedMouseEvent) => {
        if (this.clickingOutside) {
          this.clearSelection()
        } else if (this.selectBorderAdjusting) {
          this.findSelectedNodes()
        } else if (this.selectBorderMoving) {
          this.simulation.stop()
          for (const node of this.selectedNodes as Set<any>) {
            const { gfx } = node
            gfx.alpha = 1
            gfx.dragging = false
            gfx.isOver = false
            gfx.eventData = null
            if (this.snapToGrid) this.snapNodeToGrid(node)
          }
          if (this.snapToGrid) {
            this.ticked()
            this.handleTargetSelected()
          }
          this.updateGraphState()
        }
        this.selectBorderAdjusting = false
        this.selectBorderMoving = false
        this.viewport.plugins.resume('drag')
        this.mouseStart = undefined
      })
      .on('rightclick', (e: FederatedMouseEvent) => {
        if (!this.overNodeOrLink) {
          // show add note button
          const { x, y } = e
          const { x: localX, y: localY } = e.getLocalPosition(this.viewport)
          this.nodeMenu = {
            show: true,
            node: '',
            x,
            y,
            localX,
            localY,
            label: '',
            editName: false
          }
        }
      })
  }

  checkDeletionListeners() {
    if (this.selectedNodes.size > 0) {
      document.addEventListener('keyup', this.deleteSelectionListener)
    } else if (this.selectedLink != null) {
      document.addEventListener('keyup', this.deleteLinkListener)
    } else if (this.target != null) {
      document.addEventListener('keyup', this.deleteNodeListener)
    }
  }

  async doRelevantDelete() {
    if (this.selectedNodes.size > 0 || this.selectedLinks.size > 0) {
      this.deleteSelection()
    } else if (this.selectedLink != null) {
      await this.deleteLinks([this.selectedLink])
      this.handleTargetSelected()
      this.updateGraphState()
    } else if (this.target != null) {
      const nodeId = nodeTypeToIdString(this.target)
      const found = this.activeNodes.find((n) => n.id === nodeId)
      if (found) {
        await this.deleteNode(<FormattedNode>found)
        this.handleTargetSelected()
        this.updateGraphState()
      }
    }
    this.resetDeletionListeners()
  }

  resetDeletionListeners() {
    this.clearDeletionListeners()

    if (this.selectedNodes.size !== 0 || this.selectedLinks.size !== 0) {
      this.setSelectionDeletionListener()
    } else if (this.target != null) {
      this.setNodeDeletionListener(nodeTypeToIdString(this.target))
    } else if (this.selectedLink != null) {
      this.setLinkDeletionListener(this.selectedLink)
    }
  }

  clearDeletionListeners() {
    document.removeEventListener('keyup', this.deleteNodeListener)
    document.removeEventListener('keyup', this.deleteLinkListener)
    document.removeEventListener('keyup', this.deleteSelectionListener)
  }

  async clearSelectedNodesAndLinks() {
    for (const node of this.selectedNodes) {
      this.redrawNodeBorder(node, 1, COLOR_LIGHT_GRAY)
    }
    this.selectedNodes.clear()
    this.nodesAreSelected = false

    this.selectedLinks.clear()
    await this.ticked()

    this.resetDeletionListeners()
  }

  async keyupListener(e: KeyboardEvent) {
    if (e.key === 'Escape') { // clear focus
      if (this.selectedNodes.size > 0 || this.selectedLinks.size > 0) {
        this.clearSelection()
      } else {
        this.$store.dispatch('setTarget', { id: undefined })
        this.$store.dispatch('selectLink', { link: undefined })
      }
    } else if (e.key === 'Shift') {
      this.shiftDown = false
      await this.graphLinks(this.activeLinksRemoved)
      for (const node of this.activeNodesRemoved) {
        await this.graphNode(node, true)
      }
      await this.ticked()
    } else if (e.key === 'Control') {
      this.ctrlDown = false
    } else if (e.code === 'KeyT' && e.altKey) {
      this.$store.dispatch('toggleTxnNodes')
    }
    if (!(this.shiftDown && this.ctrlDown) && this.drawDeleted) {
      this.hideDeleted()
    }
  }

  keydownListener(e: KeyboardEvent) {
    if (e.key === 'Shift') {
      this.shiftDown = true
      for (const link of this.activeLinksRemoved) {
        if (link.gfx != null) {
          this.viewport.removeChild(link.gfx)
        }
      }
      for (const node of this.activeNodesRemoved) {
        if (!isNoteNode(node) && node.gfx != null) {
          this.viewport.removeChild(node.gfx)
        }
      }
    } else if (e.key === 'Control') {
      this.ctrlDown = true
    }
    if (this.shiftDown && this.ctrlDown && !this.drawDeleted) {
      this.showDeleted()
    }
  }

  async showDeleted() {
    this.drawDeleted = true
    await Promise.all(this.removedNodes.map(n => this.graphNode(n)))
    await this.ticked()
  }

  async hideDeleted() {
    this.drawDeleted = false
    await Promise.all(this.removedNodes.map(n => this.graphNode(n, true)))
    await this.ticked()
  }

  findSelectedNodes() {
    const { lowerX, upperX, lowerY, upperY } = getBoxBoundsFromCorners(this.selectBorderPoints[0], this.selectBorderPoints[2])

    for (const node of this.activeNodes) {
      const { x, y } = node
      if (x && y) {
        if (x > lowerX && x < upperX && y > lowerY && y < upperY) {
          this.selectedNodes.add(<FormattedNode>node)
          this.redrawNodeBorder(<FormattedNode>node, 4, COLOR_ORANGE)
          this.nodesAreSelected = true
        }
      }
    }
    if (this.nodesAreSelected) {
      this.resetDeletionListeners()
    }
  }

  setSelectionDeletionListener() {
    // add event listener for delete selection action
    this.deleteSelectionListener = (e: KeyboardEvent) => {
      if (e.key === 'Delete' || e.key === 'Backspace') {
        if (this.deletionConfirmations) {
          this.deleteDialog.show = true
        } else {
          this.deleteSelection()
        }
      } else if (e.key !== 'Shift') {
        this.clearSelection()
      }
      this.resetDeletionListeners()
    }
    document.addEventListener('keyup', this.deleteSelectionListener)
  }

  async deleteSelection() {
    for (const node of Array.from(this.selectedNodes).sort(
      (a, b) => classifyNodeId(a.id).type.localeCompare(classifyNodeId(b.id).type) // ensures addresses are done first
    )) {
      await this.deleteNode(node)
      this.selectedNodes.delete(node)
    }
    await this.deleteLinks(this.selectedLinks)
    await this.clearSelection()
    this.handleTargetSelected()
    this.updateGraphState()
  }

  async clearSelection() {
    if (this.selectBorderGfx != null) this.selectBorderGfx.clear()
    cancelAnimationFrame(this.selectBorderAnimation)
    this.selectBorderPoints = []
    await this.clearSelectedNodesAndLinks()
  }

  @Watch('$store.state.freezeCounter')
  freezeSelection() {
    if (this.nodesAreSelected) {
      for (const node of this.selectedNodes) {
        const { x, y } = node
        if (x && y) node.freeze = { x, y }
      }
    } else {
      this.freezeAll()
    }
  }

  @Watch('$store.state.unfreezeCounter')
  unfreezeSelection() {
    if (this.nodesAreSelected) {
      for (const node of this.selectedNodes) {
        node.freeze = undefined
      }
    } else {
      this.unfreezeAll()
    }
  }

  setMovingLinks() {
    this.movingLinks = filter(this.activeLinks, l => 
      l.source.freeze == null || l.target.freeze == null ||
      l.source === this.nodeDown || l.target === this.nodeDown ||
      this.selectedNodes.has(<FormattedNode>l.source) || this.selectedNodes.has(l.target)
    )
    this.movingLinksRemoved = filter(this.activeLinksRemoved, l => 
      l.source.freeze == null || l.target.freeze == null ||
      l.source === this.nodeDown || l.target === this.nodeDown ||
      this.selectedNodes.has(<FormattedNode>l.source) || this.selectedNodes.has(l.target)
    )
  }

  onDragStart(e: FederatedMouseEvent, node: Graphics | Container, note?: boolean): void {
    this.viewport.plugins.pause('drag')
    this.simulation.alpha(0.2).alphaDecay(0).restart();
    (node as any).isDown = true;
    (node as any).eventData = e
    if (!note) node.alpha = 0.5;
    (node as any).dragging = true
  }

  onDragEnd(e: FederatedMouseEvent, node: FormattedNode | NoteNode): void {
    e.stopPropagation()
    let pixiDisplay: Graphics | Container | undefined
    if (isNoteNode(node)) {
      pixiDisplay = node.input
    } else {
      pixiDisplay = node.gfx
    }
    if (pixiDisplay != null) {
      pixiDisplay.alpha = 1;
      (pixiDisplay as any).dragging = false;
      (pixiDisplay as any).isOver = false;
      (pixiDisplay as any).eventData = null
    }
    this.updateForces()
    this.viewport.plugins.resume('drag')
    if (this.snapToGrid && !isNoteNode(node)) {
      this.snapNodeToGrid(<FormattedNode & Position>node)
      this.handleTargetSelected()
      this.ticked()
    }
    this.updateGraphState()
  }

  onDragMove(node: FormattedNode | NoteNode, e: FederatedMouseEvent): void {
    let pixiDisplay: Graphics | Container | undefined
    if (isNoteNode(node)) {
      pixiDisplay = node.input
    } else {
      pixiDisplay = node.gfx
    }
    if (!this.selectBorderMoving && pixiDisplay != null && (pixiDisplay as any).dragging) {
      // const newPosition = (pixiDisplay as any).eventData.getLocalPosition(pixiDisplay.parent)
      const newPosition = e.getLocalPosition(this.viewport)
      let { x, y } = newPosition
      if (isNoteNode(node)) { // adjust x, y so that the middle of the note is where the mouse is, not the corner
        const { input } = node
        x -= input.width / 2
        y -= input.height / 2
        // set the input's coordinates to change location
        input.x = x
        input.y = y
      }
      node.x = x
      node.y = y
      node.freeze = { x, y }
    }
  }

  nodeGfxEvents(node: FormattedNode) {
    const { gfx, id, size } = node
    const { id: nodeId, type } = classifyNodeId(id)
    const scaledSize = this.nodeSizeScale(size)

    if (gfx != null) {
      gfx.eventMode = 'static'
      gfx.cursor = 'pointer'
      if (type === 'transaction') {
        const mid = scaledSize / 2
        gfx.hitArea = new Polygon(-mid, -mid, mid, -mid, mid, mid, -mid, mid)
      } else if (type === 'attribution' && nodeId.startsWith('ext-')) {
        gfx.hitArea = new Polygon(ROTATED_OCTAGON_POINTS.map(c => c * scaledSize))
      } else {
        gfx.hitArea = new Circle(0, 0, scaledSize)
      }

      gfx
        .on('click', async (e: FederatedMouseEvent) => {
          if (this.rawFlowFilterSelectMode) {
            // raw flow selection mode is on, so clicking only changes the selection
            if (type !== 'transaction') {
              // can't select transaction nodes
              if (this.rawFlowFilterNodes.has(id)) {
                // node is already selected
                this.rawFlowFilterNodes.delete(id) // unselect it
              } else {
                this.rawFlowFilterNodes.add(id) // node isn't selected, select it
              }
            }
          } else {
            // not in raw flow selection mode, so use normal click behavior
            if (this.removedNodes.includes(node)) {
              if (this.clicking) {
                await this.undeleteNodeAndLinks(node)
              }
              this.clicking = false
              // undraw connected deleted stuff if relevant
              const connectedLinksRemoved = filter(this.activeLinksRemoved, l => l.source === node || l.target === node)
              const bidirectional: (InteractiveLink)[] = []
              connectedLinksRemoved.map(l => {
                if (l.reversedLink == null) this.drawLink(l, !this.drawDeleted)
                else {
                  if (!bidirectional.includes(l)) {
                    bidirectional.push(l, l.reversedLink)
                  }
                }
                if (this.activeNodesRemoved.includes(l.source)) {
                  this.graphNode(l.source, !this.drawDeleted)
                }
                if (this.activeNodesRemoved.includes(l.target)) {
                  this.graphNode(l.target, !this.drawDeleted)
                }
              })
              this.drawBidirictionalLinks(bidirectional, !this.drawDeleted)
              this.updateGraphState()
            } else if (e.shiftKey) {
              // select/unselect it
              if (this.selectedNodes.has(node)) {
                this.selectedNodes.delete(node)
                if (this.selectedNodes.size === 0) {
                  this.nodesAreSelected = false
                }
              } else {
                this.selectedNodes.add(node)
                this.redrawNodeBorder(node, 4, COLOR_ORANGE)
                this.nodesAreSelected = true
              }
              this.resetDeletionListeners()
            }
            else if (e.ctrlKey) {
              // unfreeze node
              node.freeze = undefined
            }
          }
        })
        .on('mousedown', (e: FederatedMouseEvent) => {
          if (!this.rawFlowFilterSelectMode) {
            const { shiftKey, ctrlKey } = e
            if (!(shiftKey || ctrlKey)) {
              this.clicking = true
              setTimeout(() => this.clicking = false, 300)
              this.onDragStart(e, gfx)
              this.nodeDown = node
              this.setMovingLinks()
            }
          }
        })
        .on('mouseupoutside', (e: FederatedMouseEvent) => {
          if (!this.rawFlowFilterSelectMode) {
            this.onDragEnd(e, node)
            this.nodeDown = undefined
          }
        })
        .on('mouseup', (e: FederatedMouseEvent) => {
          if (!this.rawFlowFilterSelectMode) {
            this.onDragEnd(e, node)
            this.nodeDown = undefined
            const { shiftKey, ctrlKey } = e
            if (this.clicking && this.overNodeOrLink && !(shiftKey || ctrlKey) && !this.removedNodes.includes(node)) {
              this.setTarget(node) // only set as target if short click and not trying to do something else
            }
          }
        })
        .on('rightclick', (e: FederatedMouseEvent) => {
          // show options to rename node or create note
          const { x, y } = e
          const { x: localX, y: localY } = e.getLocalPosition(this.viewport)
          this.nodeMenu = {
            show: true,
            node: id,
            x,
            y,
            localX,
            localY,
            label: node.display ?? '',
            editName: false
          }
        })
        .on('mouseover', () => {
          if (this.nodeDown == null && !this.selectBorderMoving && !this.overNodeOrLink) {
            this.overNodeOrLink = true
            if (this.removedNodes.includes(node)) {
              this.graphNode(node)
              const nodeSet = new Set(this.activeNodes)
              const connectedLinksRemoved = filter(
                this.activeLinksRemoved,
                l => (l.source === node && nodeSet.has(l.target)) || (l.target === node && nodeSet.has(l.source))
              )
              const bidirectional: (InteractiveLink)[] = []
              connectedLinksRemoved.map(l => {
                if (l.reversedLink == null) this.drawLink(l)
                else {
                  if (!bidirectional.includes(l)) {
                    bidirectional.push(l, l.reversedLink)
                  }
                }
              })
              this.drawBidirictionalLinks(bidirectional)
            } else {
              this.$store.dispatch('selectNode', id)
            }
          }
        })
        .on('mouseout', () => {
          if (this.nodeDown == null && !this.selectBorderMoving) {
            this.overNodeOrLink = false
            if (this.removedNodes.includes(node)) {
              this.graphNode(node, !this.drawDeleted)
              const nodeSet = new Set(this.activeNodes)
              const connectedLinksRemoved = filter(
                this.activeLinksRemoved,
                l => (l.source === node && nodeSet.has(l.target)) || (l.target === node && nodeSet.has(l.source))
              )
              const bidirectional: (InteractiveLink)[] = []
              connectedLinksRemoved.map(l => {
                if (l.reversedLink == null) this.drawLink(l, !this.drawDeleted)
                else {
                  if (!bidirectional.includes(l)) {
                    bidirectional.push(l, l.reversedLink)
                  }
                }
              })
              this.drawBidirictionalLinks(bidirectional, !this.drawDeleted)
            } else {
              this.$store.dispatch('selectNode', '')
            }
          }
        })
    }
  }

  async deleteNode(node: FormattedNode) {
    // const { id: decodedId, type, network } = classifyNodeId(node.id)
    // // if it's an address node, check if its cluster is present in the graph
    // if (type === 'address') {
    //   const clusterMetadata = this.clusterAddressCache.get(decodedId)
    //   if (clusterMetadata != null) {
    //     const { topAttribution, id } = clusterMetadata
    //     const macroNode = nodeTypeToIdString(
    //        topAttribution ? { id: topAttribution, type: 'attribution', network }
    //        : { id, type: 'cluster', network }
    //     )
    //     if (
    //       this.nodesFormatted.findIndex(n => n.id === macroNode) !== -1 || 
    //       this.removedNodes.findIndex(n => n.id === macroNode) !== -1
    //     ) { // the cluster is graphed also, so macrotize the node instead of deleting it
    //       const toMacrotize = { id: decodedId, type, network }
    //       return await this.$store.dispatch('macrotize', toMacrotize)
    //     }
    //   }
    // }

    this.nodesFormatted = filter(this.nodesFormatted, (n) => n !== node)
    this.removedNodes.push(node)
    if (classifyNodeId(node.id).type !== 'transaction' || this.settings.txnNodeSwitch || node.permanent) {
      await this.graphNode(node, !this.drawDeleted)
    }

    let linksToRemove = new Set(filter(this.linksFormatted, (l) => l.source === node || l.target === node))
    this.removedLinks.push(...linksToRemove)
    this.linksFormatted = filter(this.linksFormatted, (l) => !linksToRemove.has(l))

    let summaryLinksToRemove = new Set(filter(this.summaryLinksFormatted, (l) => l.source === node || l.target === node))
    this.summaryLinksRemoved.push(...summaryLinksToRemove)
    this.summaryLinksFormatted = filter(this.summaryLinksFormatted, (l) => !summaryLinksToRemove.has(l))

    let dupeLinksToRemove = new Set(filter(this.networkDupesLinks, (l) => l.source === node || l.target === node))
    this.networkDupesLinksRemoved.push(...dupeLinksToRemove)
    this.networkDupesLinks = filter(this.networkDupesLinks, (l) => !dupeLinksToRemove.has(l))

    // remove connected notes
    const noteLinksToRemove = filter(this.noteLinks, link => link.target === node)
    const noteNodesToRemove = new Set<NoteNode>()
    for (const link of noteLinksToRemove) {
      const { gfx, source } = link
      this.noteLinksRemoved.push(link)
      this.viewport.removeChild(gfx)

      noteNodesToRemove.add(source)
      this.noteNodesRemoved.push(source)
      this.viewport.removeChild(source.input)
    }
    this.noteLinks = filter(this.noteLinks, link => link.target !== node)
    this.noteNodes = filter(this.noteNodes, node => !noteNodesToRemove.has(node))

    // if selected link is getting deleted, unselect it
    if (
      this.selectedLink != null &&
      (linksToRemove.has(<FormattedLink>this.selectedLink) || summaryLinksToRemove.has(<FormattedSummaryLink>this.selectedLink))
    ) {
      this.$store.dispatch('selectLink', { link: undefined })
    }

    // if txn links removed, check for any txn nodes that are only connected on one side and delete those
    if (linksToRemove.size > 0) {
      const txnNodeSidesMap: { [txnNode: string]: { in?: boolean; out?: boolean } } = {}
      for (const { source, target, permanent } of this.linksFormatted) {
        const { type: sourceType } = classifyNodeId(source.id)
        const present = permanent ? true : false
        if (sourceType === 'transaction') {
          const { id } = source
          if (id in txnNodeSidesMap) {
            txnNodeSidesMap[id].out = present
          } else {
            txnNodeSidesMap[id] = { out: present }
          }
        } else {
          const { id } = target
          if (id in txnNodeSidesMap) {
            txnNodeSidesMap[id].in = present
          } else {
            txnNodeSidesMap[id] = { in: present }
          }
        }
      }
      Promise.all(
        filter(
          this.nodesFormatted,
          n => n.id in txnNodeSidesMap && txnNodeSidesMap[n.id].in !== txnNodeSidesMap[n.id].out
        ).map(n => this.deleteNode(n))
      )
    }

    await this.ticked()
  }

  async graphNode(node: FormattedNode | NoteNode, hitBoxOnly?: boolean) {
    if (isNoteNode(node)) {
      this.viewport.removeChild(node.input)
      if (!hitBoxOnly) this.viewport.addChild(node.input)
    } else {
      if (!node.gfx) {
        node.gfx = new Graphics()
        node.gfx.zIndex = 2

        // attach event handling (supported by PixiJS)
        this.nodeGfxEvents(node)
      }

      node.gfx.clear()
      if (!hitBoxOnly) {
        await this.drawNode(node, 1, COLOR_LIGHT_GRAY)
        labelNode(
          node,
          this.receiptsCache,
          this.attributionsCache,
          this.clusterAddressCache,
          this.settings.fullAddressLabelSwitch,
          this.nodeSizeScale,
          this.sdf,
          this.settings.nodeLabelSwitch,
          this.settings.txnNodeLabelSwitch
        )
        attachNodeIcon(
          node,
          this.textures,
          this.nodeSizeScale,
          this.receiptsCache
        )
      } else {
        node.gfx.removeChildren()
      }
      this.viewport.removeChild(node.gfx)
      this.viewport.addChild(node.gfx)
    }
  }

  formatID(id: string) {
    return id ? cutEnd(classifyNodeId(id).id, 8) : ''
  }

  redrawNodeBorder(node: FormattedNode, borderThickness: number, borderColor: number) {
    if (!node.gfx) {
      this.graphNode(node)
    } else {
      node.gfx.clear()
      this.drawNode(node, borderThickness, borderColor)
    }
  }

  unhighlightNode() {
    if (this.highlightedNode && this.highlightedNode.gfx) {
      this.highlightedNode.gfx.clear()
      if (
        (this.rawFlowFilterSelectMode && this.rawFlowFilterNodes.has(this.highlightedNode.id)) ||
        this.selectedNodes.has(this.highlightedNode)
      ) {
        this.drawNode(this.highlightedNode, 4, COLOR_ORANGE)
      } else {
        this.drawNode(this.highlightedNode, 1, COLOR_LIGHT_GRAY)
      }

      this.highlightedNode = undefined
    }
  }

  async drawNode(node: FormattedNode, borderThickness: number, borderColor: number) {
    const { id: rawId, size: rawSize, gfx, unspent } = node
    const { id, type } = classifyNodeId(rawId)
    const size = this.nodeSizeScale(rawSize)

    if (gfx != null) {
      const color = this.nodeColor(node)
      drawNode(
        gfx,
        borderThickness,
        borderColor,
        color,
        type,
        id,
        size,
        unspent
      )
    }
  }

  nodeSize(nodeId: string): number {
    const { type, id, network } = classifyNodeId(nodeId)
    if (type === 'cluster') {
      const cluster = this.clusterAddressCache.get(id)
      if (cluster != null) {
        return cluster.size
      }
    } else if (type === 'attribution') {
      const networkSizeMap = this.attributionSizeCache.get(id)
      if (networkSizeMap != null) {
        return networkSizeMap[network] ?? 1
      }
    }
    return 1
  }

  nodeColor(node: FormattedNode): number {
    const { id: rawId, unspent } = node
    const { id, type, network } = classifyNodeId(rawId)

    if (this.networksFilter.length > 0 && !this.networksFilter.includes(network)) {
      return COLOR_VERY_LIGHT_GRAY
    }

    if (this.removedNodes.includes(node)) return COLOR_LIGHT_GRAY

    switch (type) {
      case 'transaction':
        return COLOR_BLACK
      case 'attribution':
        const networkClusterMap = this.attributionClustersCache.get(id)
        // category should be same for all networks
        const cluster = networkClusterMap ? Object.values(networkClusterMap)[0] : null
        return clusterCategoryColor(cluster, this.categoryScores)
      case 'address':
        if (unspent) {
          return COLOR_WHITE
        }
        if (this.attributionsCache.has(id)) {
          return attributionCategoryColor(this.attributionsCache.get(id), this.categoryScores)
        }
      default:
        return clusterCategoryColor(this.clusterAddressCache.get(id), this.categoryScores)
    }
  }

  async linkGfxEvents(link: InteractiveLink) {
    const { gfx } = link

    if (gfx != null) {
      gfx.eventMode = 'static'
      gfx.cursor = 'pointer'

      gfx
        .on('mouseover', () => {
          if (this.nodeDown == null && !this.selectBorderMoving && !this.overNodeOrLink) {
            this.overNodeOrLink = true
            if (this.activeLinksRemoved.includes(link)) {
              this.drawLink(link)
              this.graphNode(link.source)
              this.graphNode(link.target)
              // show sister links attached to showing nodes
              const nodeSet = new Set(this.activeNodes)
              const bidirectional = []
              if (!nodeSet.has(link.source)) {
                for (const removedLink of this.activeLinksRemoved) {
                  if (
                    (removedLink.source === link.source && nodeSet.has(removedLink.target)) ||
                    (removedLink.target === link.source && nodeSet.has(removedLink.source))
                  ) {
                    if (removedLink.reversedLink != null) {
                      bidirectional.push(removedLink, removedLink.reversedLink)
                    } else {
                      this.drawLink(removedLink)
                    }
                  }
                }
              }
              if (!nodeSet.has(link.target)) {
                for (const removedLink of this.activeLinksRemoved) {
                  if (
                    (removedLink.source === link.target && nodeSet.has(removedLink.target)) ||
                    (removedLink.target === link.target && nodeSet.has(removedLink.source))
                  ) {
                    if (removedLink.reversedLink != null) {
                      bidirectional.push(removedLink, removedLink.reversedLink)
                    } else {
                      this.drawLink(removedLink)
                    }
                  }
                }
              }
              if (bidirectional.length > 0) {
                this.drawBidirictionalLinks(bidirectional)
              }
            } else {
              this.$store.dispatch('highlightEdge', [link.source.id, link.target.id])
            }
          }
        })
        .on('mouseout', () => {
          if (this.nodeDown == null && !this.selectBorderMoving) {
            this.overNodeOrLink = false
            if (this.activeLinksRemoved.includes(link)) {
              this.drawLink(link, !this.drawDeleted)
              const nodeSet = new Set(this.activeNodes)
              const bidirectional = []
              if (!nodeSet.has(link.source)) {
                this.graphNode(link.source, !this.drawDeleted)
                for (const removedLink of this.activeLinksRemoved) {
                  if (
                    (removedLink.source === link.source && nodeSet.has(removedLink.target)) ||
                    (removedLink.target === link.source && nodeSet.has(removedLink.source))
                  ) {
                    if (removedLink.reversedLink != null) {
                      bidirectional.push(removedLink, removedLink.reversedLink)
                    } else {
                      this.drawLink(removedLink, !this.drawDeleted)
                    }
                  }
                }
              }
              if (!nodeSet.has(link.target)) {
                this.graphNode(link.target, !this.drawDeleted)
                for (const removedLink of this.activeLinksRemoved) {
                  if (
                    (removedLink.source === link.target && nodeSet.has(removedLink.target)) ||
                    (removedLink.target === link.target && nodeSet.has(removedLink.source))
                  ) {
                    if (removedLink.reversedLink != null) {
                      bidirectional.push(removedLink, removedLink.reversedLink)
                    } else {
                      this.drawLink(removedLink, !this.drawDeleted)
                    }
                  }
                }
              }
              if (bidirectional.length > 0) {
                this.drawBidirictionalLinks(bidirectional, !this.drawDeleted)
              }
            } else {
              this.$store.dispatch('highlightEdge', [])
            }
          }
        })
        .on('click', async (e: FederatedMouseEvent) => {
          if (this.clicking) {
            if (this.activeLinksRemoved.includes(link)) {
              await this.undeleteLinks([link])
              this.updateGraphState()
            } else if (e.shiftKey) {
              if (this.selectedLinks.has(link)) {
                this.selectedLinks.delete(link)
              } else {
                this.selectedLinks.add(link)
                await this.ticked()
              }
              this.resetDeletionListeners()
            } else if (this.selectedLink !== link) {
              this.$store.dispatch('setTarget', { id: undefined })
              await this.$store.dispatch('selectLink', { link })

              this.resetDeletionListeners()
            }
          }
        })
        .on('mousedown', (e: FederatedMouseEvent) => {
          this.clicking = true
          setTimeout(() => this.clicking = false, 300)
        })
        .on('rightclick', (e: FederatedMouseEvent) => {
          if (!this.activeLinksRemoved.includes(link)) {
            // show option to change link color
            const { x, y } = e
            const { x: localX, y: localY } = e.getLocalPosition(this.viewport)
            this.linkMenu = {
              show: true,
              link,
              x,
              y,
              localX,
              localY
            }
          }
        })
    }
  }

  bidirectionalLinkGfxEvents(link: InteractiveLink) {
    const { gfx, reversedLink } = link

    if (gfx != null) {
      // should never be false
      gfx.eventMode = 'static'
      gfx.cursor = 'pointer'

      gfx
        .on('mouseover', () => {
          if (this.nodeDown == null && !this.selectBorderMoving && !this.overNodeOrLink) {
            this.overNodeOrLink = true
            if (this.activeLinksRemoved.includes(link) && link.reversedLink != null) {
              this.drawBidirictionalLinks([link, link.reversedLink])
              this.graphNode(link.source)
              this.graphNode(link.target)
              // show sister links attached to showing nodes
              const nodeSet = new Set(this.activeNodes)
              const bidirectional = []
              if (!nodeSet.has(link.source)) {
                for (const removedLink of this.activeLinksRemoved) {
                  if (
                    (removedLink.source === link.source && nodeSet.has(removedLink.target)) ||
                    (removedLink.target === link.source && nodeSet.has(removedLink.source))
                  ) {
                    if (removedLink.reversedLink != null) {
                      bidirectional.push(removedLink, removedLink.reversedLink)
                    } else {
                      this.drawLink(removedLink)
                    }
                  }
                }
              }
              if (!nodeSet.has(link.target)) {
                for (const removedLink of this.activeLinksRemoved) {
                  if (
                    (removedLink.source === link.target && nodeSet.has(removedLink.target)) ||
                    (removedLink.target === link.target && nodeSet.has(removedLink.source))
                  ) {
                    if (removedLink.reversedLink != null) {
                      bidirectional.push(removedLink, removedLink.reversedLink)
                    } else {
                      this.drawLink(removedLink)
                    }
                  }
                }
              }
              if (bidirectional.length > 0) {
                this.drawBidirictionalLinks(bidirectional)
              }
            } else {
              this.$store.dispatch('highlightEdge', [link.source.id, link.target.id])
            }
          }
        })
        .on('mouseout', () => {
          if (this.nodeDown == null && !this.selectBorderMoving) {
            this.overNodeOrLink = false
            if (this.activeLinksRemoved.includes(link) && link.reversedLink != null) {
              this.drawBidirictionalLinks([link, link.reversedLink], !this.drawDeleted)
              const nodeSet = new Set(this.activeNodes)
              const bidirectional = []
              if (!nodeSet.has(link.source)) {
                this.graphNode(link.source, !this.drawDeleted)
                for (const removedLink of this.activeLinksRemoved) {
                  if (
                    (removedLink.source === link.source && nodeSet.has(removedLink.target)) ||
                    (removedLink.target === link.source && nodeSet.has(removedLink.source))
                  ) {
                    if (removedLink.reversedLink != null) {
                      bidirectional.push(removedLink, removedLink.reversedLink)
                    } else {
                      this.drawLink(removedLink, !this.drawDeleted)
                    }
                  }
                }
              }
              if (!nodeSet.has(link.target)) {
                this.graphNode(link.target, !this.drawDeleted)
                for (const removedLink of this.activeLinksRemoved) {
                  if (
                    (removedLink.source === link.target && nodeSet.has(removedLink.target)) ||
                    (removedLink.target === link.target && nodeSet.has(removedLink.source))
                  ) {
                    if (removedLink.reversedLink != null) {
                      bidirectional.push(removedLink, removedLink.reversedLink)
                    } else {
                      this.drawLink(removedLink, !this.drawDeleted)
                    }
                  }
                }
              }
              if (bidirectional.length > 0) {
                this.drawBidirictionalLinks(bidirectional, !this.drawDeleted)
              }
            } else {
              this.$store.dispatch('highlightEdge', [])
            }
          }
        })
        .on('click', async (e: FederatedMouseEvent) => {
          if (this.clicking) {
            if (this.activeLinksRemoved.includes(link)) {
              const linksToAdd = [link]
              if (reversedLink != null) linksToAdd.push(reversedLink)
              await this.undeleteLinks(linksToAdd)
              this.updateGraphState()
            } else if (e.shiftKey) {
              if (this.selectedLinks.has(link)) {
                this.selectedLinks.delete(link)
                if (reversedLink != null) this.selectedLinks.delete(reversedLink)
              } else {
                this.selectedLinks.add(link)
                if (reversedLink != null) this.selectedLinks.add(reversedLink)
                await this.ticked()
              }
              this.resetDeletionListeners()
            } else if (this.selectedLink !== link) {
              this.$store.dispatch('setTarget', { id: undefined })
              await this.$store.dispatch('selectLink', { link })

              this.resetDeletionListeners()
            }
          }
        })
        .on('mousedown', (e: FederatedMouseEvent) => {
          this.clicking = true
          setTimeout(() => this.clicking = false, 300)
        })
        .on('rightclick', (e: FederatedMouseEvent) => {
          if (!this.activeLinksRemoved.includes(link)) {
            // show option to change link color
            const { x, y } = e
            const { x: localX, y: localY } = e.getLocalPosition(this.viewport)
            this.linkMenu = {
              show: true,
              link,
              x,
              y,
              localX,
              localY
            }
          }
        })
    }
  }

  setLinkDeletionListener(link: InteractiveLink) {
    // add event listener for delete link action
    this.deleteLinkListener = (e: KeyboardEvent) => {
      if (e.key === 'Delete' || e.key === 'Backspace') {
        if (this.deletionConfirmations) {
          this.deleteDialog.show = true
        } else {
          this.deleteLinks([link])
          this.handleTargetSelected()
          this.updateGraphState()
          document.removeEventListener('keyup', this.deleteLinkListener)
        }
      }
    }
    document.addEventListener('keyup', this.deleteLinkListener)
  }

  async graphLinks(links: Array<GraphLink>) {
    const bidirectional: Array<InteractiveLink> = []
    const graphing = links.map((link) => {
      if (!link.reversedLink) return this.graphLink(link)
      else {
        if (!bidirectional.includes(link)) {
          bidirectional.push(link, link.reversedLink)
        }
      }
    })
    await Promise.all(graphing)
    if (bidirectional.length) await this.graphBidirictionalLinks(bidirectional)
  }

  async graphLink(link: GraphLink) {
    if (!link.gfx) {
      link.gfx = new Graphics()
      link.gfx.zIndex = 1

      if (isInteractiveLink(link)) {
        // attach event handling (supported by PixiJS)
        await this.linkGfxEvents(link)
      }
    }
    if (isInteractiveLink(link)) {
      await labelLink(
        link,
        this.decimalFormatter,
        this.formatDate,
        this.sdf,
        this.settings.linkLabelSwitch,
        this.settings.linkDateLabelSwitch
      )
    }
    this.viewport.removeChild(link.gfx)
    this.viewport.addChild(link.gfx)
  }

  async graphBidirictionalLinks(links: Array<InteractiveLink>) {
    for (let i = 0; i < links.length; i += 2) {
      const link = links[i]
      const reversed = links[i + 1]
      if (!link.gfx) {
        link.gfx = new Graphics()
        link.gfx.zIndex = 1

        // attach event handling (supported by PixiJS)
        this.bidirectionalLinkGfxEvents(link)
      }
      if (!reversed.gfx) {
        reversed.gfx = new Graphics()
        reversed.gfx.zIndex = 1

        // attach event handling (supported by PixiJS)
        this.bidirectionalLinkGfxEvents(reversed)
      }
      await labelLink(
        link,
        this.decimalFormatter,
        this.formatDate,
        this.sdf,
        this.settings.linkLabelSwitch,
        this.settings.linkDateLabelSwitch
      )
      await labelLink(
        reversed,
        this.decimalFormatter,
        this.formatDate,
        this.sdf,
        this.settings.linkLabelSwitch,
        this.settings.linkDateLabelSwitch
      )
      this.viewport.removeChild(link.gfx, reversed.gfx)
      this.viewport.addChild(link.gfx, reversed.gfx)
    }
  }

  scaleLinkThickness(link: InteractiveLink) {
    if (!isTransactionLevel(link)) {
      let relevantSummaries = link.linkSummaries
      if (this.targetNetwork !== '') {
        relevantSummaries = filter(relevantSummaries, s => s.network === this.targetNetwork)
      }
      const transactions = relevantSummaries.reduce((total, s) => total + s.transactions, 0)
      return this.linkScale(Math.min(transactions, LINK_MAX_TRANSACTIONS))
    }
    if (this.settings.linkThicknessRadio === 'amount' || !this.flowScaleTarget) {
      return this.linkScale(LINK_MIN_TRANSACTIONS)
    } else {
      const { targetTransaction } = this.flowScaleTarget
      const { id, index, isOutput } = targetTransaction
      const targetKey = `${id}|${index}|${isOutput}`
      const flows = link.amount.flows
      const greatestValue = getGreatestValueFromFlow(flows, targetKey)
      return this.linkScale(greatestValue)
    }
  }

  async drawLink(link: GraphLink, hitBoxOnly?: boolean) {
    if (link.gfx == null) {
      await this.graphLink(link) // sets up gfx
    }
    if (isNetworkDupesLink(link)) {
      if (hitBoxOnly) link.gfx.clear()
      else drawNetworkDupesLink(
        link,
        1,
        this.nodeSizeScale,
        this.networksFilter,
        this.activeLinksRemoved,
        this.newestLinksCopy,
        this.highlightFlowKeys,
        this.selectedLinks,
        this.highlightLink,
        this.selectedLink,
        this.subnetworkFlow,
        this.subnetworkSender,
        this.subnetworkReceiver
      )
    } else if (isNoteLink(link)) {
      if (hitBoxOnly) link.gfx.clear()
      else drawNoteLink(
        link,
        this.nodeSizeScale,
        this.networksFilter,
        this.activeLinksRemoved,
        this.newestLinksCopy,
        this.highlightFlowKeys,
        this.selectedLinks,
        this.highlightLink,
        this.selectedLink,
        this.subnetworkFlow,
        this.subnetworkSender,
        this.subnetworkReceiver
      )
    } else {
      const thickness = this.scaleLinkThickness(link)
      drawLink(
        link,
        thickness,
        this.nodeSizeScale,
        this.networksFilter,
        this.activeLinksRemoved,
        this.newestLinksCopy,
        this.highlightFlowKeys,
        this.selectedLinks,
        this.highlightLink,
        this.selectedLink,
        this.subnetworkFlow,
        this.subnetworkSender,
        this.subnetworkReceiver,
        hitBoxOnly
      )
    }
  }

  async drawBidirictionalLinks(links: (InteractiveLink)[], hitBoxOnly?: boolean) {
    await this.graphBidirictionalLinks(links) // ensures all have gfx
    await Promise.all(links.map((l) => getTotalThicknessForLink(l, this.scaleLinkThickness)))
    for (const link of links) {
      if (link.totalThickness && link.forwardThickness) {
        drawSingleBidirectionalLink(
          link,
          this.nodeSizeScale,
          this.networksFilter,
          this.activeLinksRemoved,
          this.newestLinksCopy,
          this.highlightFlowKeys,
          this.selectedLinks,
          this.highlightLink,
          this.selectedLink,
          this.subnetworkFlow,
          this.subnetworkSender,
          this.subnetworkReceiver,
          hitBoxOnly
        )
      }
    }
  }

  async deleteLinks(links: Iterable<GraphLink>) {
    const networkDupesLinksToDelete = new Set<NetworkDupesLink>()
    const noteLinksToDelete = new Set<NoteLink>()
    const linksToDelete = new Set<FormattedLink>()
    const summaryLinksToDelete = new Set<FormattedSummaryLink>()
    for (const link of links) {
      if (isNetworkDupesLink(link)) {
        networkDupesLinksToDelete.add(link)
      } else if (isNoteLink(link)) {
        noteLinksToDelete.add(link)
      } else if (isTransactionLevel(link)) {
        linksToDelete.add(link)
        if (link.reversedLink != null) {
          linksToDelete.add(link.reversedLink)
        }
      } else {
        summaryLinksToDelete.add(link)
        if (link.reversedLink != null) {
          summaryLinksToDelete.add(link.reversedLink)
        }
      }

      if (this.selectedLink === link) {
        this.$store.dispatch('selectLink', { link: undefined })
      }
    }

    let index = 0
    const txnLinksDeleted = linksToDelete.size > 0
    if (txnLinksDeleted) {
      index = this.linksFormatted.length - 1
      while (linksToDelete.size > 0 && index >= 0) {
        const link = this.linksFormatted[index]
        if (linksToDelete.has(link)) {
          this.removedLinks.push(this.linksFormatted.splice(index, 1)[0])
          linksToDelete.delete(link)
        }
        index--
      }
    }
    if (summaryLinksToDelete.size > 0) {
      index = this.summaryLinksFormatted.length - 1
      while (summaryLinksToDelete.size > 0 && index >= 0) {
        const link = this.summaryLinksFormatted[index]
        if (summaryLinksToDelete.has(link)) {
          this.summaryLinksRemoved.push(this.summaryLinksFormatted.splice(index, 1)[0])
          summaryLinksToDelete.delete(link)
        }
        index--
      }
    }
    if (noteLinksToDelete.size > 0) {
      index = this.noteLinks.length - 1
      while (noteLinksToDelete.size > 0 && index >= 0) {
        const link = this.noteLinks[index]
        if (noteLinksToDelete.has(link)) {
          this.noteLinksRemoved.push(this.noteLinks.splice(index, 1)[0])
          noteLinksToDelete.delete(link)
        }
        index--
      }
    }
    if (networkDupesLinksToDelete.size > 0) {
      index = this.networkDupesLinks.length - 1
      while (networkDupesLinksToDelete.size > 0 && index >= 0) {
        const link = this.networkDupesLinks[index]
        if (networkDupesLinksToDelete.has(link)) {
          this.networkDupesLinksRemoved.push(this.networkDupesLinks.splice(index, 1)[0])
          networkDupesLinksToDelete.delete(link)
        }
        index--
      }
    }

    // if txn links removed, check for any txn nodes that are only connected on one side and delete those
    if (txnLinksDeleted) {
      const txnNodeSidesMap: { [txnNode: string]: { in?: boolean; out?: boolean } } = {}
      for (const { source, target, permanent } of this.linksFormatted) {
        const { type: sourceType } = classifyNodeId(source.id)
        const present = permanent ? true : false
        if (sourceType === 'transaction') {
          const { id } = source
          if (id in txnNodeSidesMap) {
            txnNodeSidesMap[id].out = present
          } else {
            txnNodeSidesMap[id] = { out: present }
          }
        } else {
          const { id } = target
          if (id in txnNodeSidesMap) {
            txnNodeSidesMap[id].in = present
          } else {
            txnNodeSidesMap[id] = { in: present }
          }
        }
      }
      Promise.all(
        filter(
          this.nodesFormatted,
          n => n.id in txnNodeSidesMap && txnNodeSidesMap[n.id].in !== txnNodeSidesMap[n.id].out
        ).map(n => this.deleteNode(n))
      )
    }
  }

  async undeleteLinks(links: Iterable<InteractiveLink | NetworkDupesLink>) {
    const nodesToAdd = new Set<FormattedNode>()
    const dupes = new Set<NetworkDupesLink>()
    const transactionLevel = new Set<FormattedLink>()
    const summary = new Set<FormattedSummaryLink>()
    for (const link of links) {
      if (isNetworkDupesLink(link)) {
        dupes.add(link)
      } else if (isTransactionLevel(link)) {
        transactionLevel.add(link)
      } else {
        summary.add(link)
      }
      nodesToAdd.add(link.source)
      nodesToAdd.add(link.target)
    }
    await this.undeleteNodes(nodesToAdd)

    const indices: number[] = []
    // undelete network dupe links
    for (const [index, link] of this.networkDupesLinksRemoved.entries()) {
      if (dupes.has(link)) {
        indices.unshift(index) // add to beginning of indices list to remove in backwards order and avoid changing indices
        dupes.delete(link)
        if (dupes.size === 0) {
          break
        }
      }
    }
    for (const index of indices) {
      const link = this.networkDupesLinksRemoved.splice(index, 1)[0]
      this.networkDupesLinks.push(link)
    }
    indices.splice(0)
    // undelete transaction links
    for (const [index, link] of this.removedLinks.entries()) {
      if (transactionLevel.has(link)) {
        indices.unshift(index) // add to beginning of indices list to remove in backwards order and avoid changing indices
        transactionLevel.delete(link)
        if (transactionLevel.size === 0) {
          break
        }
      }
    }
    for (const index of indices) {
      const link = this.removedLinks.splice(index, 1)[0]
      this.linksFormatted.push(link)
    }
    indices.splice(0)
    // undelete summary links
    for (const [index, link] of this.summaryLinksRemoved.entries()) {
      if (summary.has(link)) {
        indices.unshift(index) // add to beginning of indices list to remove in backwards order and avoid changing indices
        summary.delete(link)
        if (summary.size === 0) {
          break
        }
      }
    }
    for (const index of indices) {
      const link = this.summaryLinksRemoved.splice(index, 1)[0]
      this.summaryLinksFormatted.push(link)
    }
    await this.ticked()
  }

  async undeleteNodeAndLinks(node: FormattedNode) {
    const nodeSet = new Set(this.nodesFormatted)
    const linksToAdd: (InteractiveLink | NetworkDupesLink)[] = []
    for (const link of this.removedLinks) {
      if ((link.source === node && nodeSet.has(link.target)) || (link.target === node && nodeSet.has(link.source))) {
        linksToAdd.push(link)
      }
    }
    for (const link of this.summaryLinksRemoved) {
      if ((link.source === node && nodeSet.has(link.target)) || (link.target === node && nodeSet.has(link.source))) {
        linksToAdd.push(link)
      }
    }
    for (const link of this.networkDupesLinksRemoved) {
      if ((link.source === node && nodeSet.has(link.target)) || (link.target === node && nodeSet.has(link.source))) {
        linksToAdd.push(link)
      }
    }
    if (linksToAdd.length === 0) {
      await this.undeleteNodes([node])
    } else {
      await this.undeleteLinks(linksToAdd) // will undelete the requested node as well as nodes on other end of all links
    }
  }

  async undeleteNodes(nodes: Iterable<FormattedNode>) {
    const nodesToAdd = new Set(nodes)

    // undelete connected notes
    const noteLinksToUndelete = filter(this.noteLinksRemoved, link => nodesToAdd.has(link.target))
    this.noteLinksRemoved = filter(this.noteLinksRemoved, link => !nodesToAdd.has(link.target))
    const noteNodesToUndelete = new Set<NoteNode>()
    for (const link of noteLinksToUndelete) {
      this.noteLinks.push(link)
      this.graphLink(link)
      noteNodesToUndelete.add(link.source)
    }
    this.noteNodesRemoved = filter(this.noteNodesRemoved, node => !noteNodesToUndelete.has(node))
    for (const node of noteNodesToUndelete) {
      this.noteNodes.push(node)
      await this.graphNode(node)
    }

    // remove nodes from removed, add to present, and graph
    const indices: number[] = []
    for (const [index, node] of this.removedNodes.entries()) {
      if (nodesToAdd.has(node)) {
        indices.unshift(index) // add to beginning of indices list to remove in backwards order and avoid changing indices
        nodesToAdd.delete(node)
        if (nodesToAdd.size === 0) {
          break
        }
      }
    }
    for (const index of indices) {
      const node = this.removedNodes.splice(index, 1)[0]
      this.nodesFormatted.push(node)
      if (this.settings.txnNodeSwitch || classifyNodeId(node.id).type !== 'transaction' || node.permanent) {
        await this.graphNode(node)
      }
    }
  }

  @Watch('targetNetwork')
  async ticked() {
    const targetID = this.target ? nodeTypeToIdString(this.target) : ''
    for (const n of this.nodesFormatted) {
      if (n.freeze != null) {
        n.x = n.freeze.x
        n.y = n.freeze.y
      }
      if (this.drawing) {
        const { gfx } = n
        if (gfx != null) {
          gfx.position = new Point(n.x, n.y) as ObservablePoint
          if (targetID) {
            if (n.id === targetID) {
              if (this.targetBorderGfx) {
                this.targetBorderGfx.position = gfx.position
              }
            }
          }
        }
      }
    }
    for (const n of this.removedNodes) {
      if (n.freeze != null) {
        n.x = n.freeze.x
        n.y = n.freeze.y
      }
      if (this.drawing) {
        const { gfx } = n
        if (gfx != null) {
          gfx.position = new Point(n.x, n.y) as ObservablePoint
        }
      }
    }
    for (const n of this.noteNodes) {
      if (n.freeze != null) {
        const { x, y } = n.freeze
        n.x = x
        n.y = y
      }
      if (this.drawing) {
        n.input.position = new Point(n.x, n.y)
      }
    }

    if (this.drawing) {
      const bidirectional: (InteractiveLink)[] = []
      let activeLinks, activeLinksRemoved
      if (this.nodeDown != null || this.selectBorderMoving) { // dragging nodes, only redraw links connected to moving nodes
        activeLinks = this.movingLinks
        activeLinksRemoved = this.movingLinksRemoved
      } else {
        activeLinks = this.activeLinks
        activeLinksRemoved = this.activeLinksRemoved
      }
      await Promise.all(activeLinks.map(l => {
        if (l.reversedLink == null) return this.drawLink(l)
        else {
          if (!bidirectional.includes(l)) {
            bidirectional.push(l, l.reversedLink)
          }
        }
      }))
      const bidirectionalHitBoxOnly: (InteractiveLink)[] = []
      if (!this.shiftDown || this.drawDeleted) { // don't draw ghost hit boxes if holding shift w/o control
        await Promise.all(activeLinksRemoved.map(l => {
          if (l.reversedLink == null) return this.drawLink(l, !this.drawDeleted)
          else {
            if (!bidirectional.includes(l)) {
              if (this.drawDeleted) {
                bidirectional.push(l, l.reversedLink)
              } else {
                bidirectionalHitBoxOnly.push(l, l.reversedLink)
              }
            }
          }
        }))
      }
      if (bidirectional.length > 0) await this.drawBidirictionalLinks(bidirectional)
      if (bidirectionalHitBoxOnly.length > 0) await this.drawBidirictionalLinks(bidirectionalHitBoxOnly, !this.drawDeleted)
    }
  }

  setUpGraph(container: HTMLElement) {
    // create canvas
    this.addCanvas(container)

    // set up grid
    this.grid = drawGrid(this.viewport, GRID_SIZE)
    this.toggleSnapToGrid()
    
    // set download dialog props here because app and viewport started as values, not refs
    for (const dialog of this.dialogs) {
      if (dialog.dialog.action === 'Download') {
        dialog.props = {
          investigation: this.investigation,
          app: this.app,
          viewport: this.viewport,
          grid: this.grid
        }
        break
      }
    }

    // set up force graph simulation
    this.simulation = d3.forceSimulation()

    this.updateForces()

    this.simulation.on('tick', () => {
      this.ticked()
    })
  }

  addEverythingToSimulation() {
    const nodes: (FormattedNode | NoteNode)[] = []
    for (const node of this.nodesFormatted) {
      nodes.push(node)
    }
    for (const node of this.removedNodes) {
      nodes.push(node)
    }
    for (const node of this.noteNodes) {
      nodes.push(node)
    }
    for (const node of this.noteNodesRemoved) {
      nodes.push(node)
    }
    this.simulation.nodes(nodes)

    // @ts-ignore
    this.simulation.force('link').links(this.allLinks)
  }

  updateScaling() {
    if (this.ranges != null) {
      this.changeLinkThicknesses()

      const { min: appearanceMin, max: appearanceMax } = this.ranges.appearance
      this.appearanceScale = d3
        .scaleLinear()
        .domain([appearanceMin, appearanceMax])
        .range([X_SCREEN_PADDING, this.viewport.screenWidth - X_SCREEN_PADDING])
    }
  }

  async setTarget(node: FormattedNode) {
    const { id: nodeId, display } = node
    const { id, type, network } = classifyNodeId(nodeId)
    this.$store.dispatch('selectLink', { link: undefined })
    this.$store.dispatch('setTarget', { id, name: display, type, network } as Target)

    this.resetDeletionListeners()
  }

  setNodeDeletionListener(nodeId: string) {
    // add event listener for delete node action
    this.deleteNodeListener = async (e: KeyboardEvent) => {
      if (e.key === 'Delete' || e.key === 'Backspace') {
        if (this.deletionConfirmations) {
          this.deleteDialog.show = true
        } else {
          const found = this.activeNodes.find((n) => n.id === nodeId)
          if (found != null) {
            await this.deleteNode(<FormattedNode>found)
            this.handleTargetSelected()
            this.updateGraphState()
          }
          document.removeEventListener('keyup', this.deleteNodeListener)
        }
      }
    }
    document.addEventListener('keyup', this.deleteNodeListener)
  }

  async toggleNodeLabels() {
    if (this.settings.nodeLabelSwitch) {
      for (const node of this.nodesFormatted) {
        labelNode(
          node,
          this.receiptsCache,
          this.attributionsCache,
          this.clusterAddressCache,
          this.settings.fullAddressLabelSwitch,
          this.nodeSizeScale,
          this.sdf,
          this.settings.nodeLabelSwitch,
          this.settings.txnNodeLabelSwitch
        )
      }
    } else {
      for (const node of this.nodesFormatted) {
        if (node.gfx != null && node.label != null) {
          node.gfx.removeChild(node.label)
        }
      }
    }
  }

  @Watch('$store.state.useLocalTime')
  toggleLinkLabels() {
    for (const link of this.activeLinks) {
      if (isInteractiveLink(link)) this.toggleLinkLabel(link)
    }
    for (const link of this.activeLinksRemoved) {
      if (isInteractiveLink(link)) this.toggleLinkLabel(link)
    }
    setTimeout(() => this.setAllLabelsCacheAsBitmap(true), 500)
    this.ticked()
  }

  toggleLinkLabel(link: InteractiveLink) {
    if (this.settings.linkLabelSwitch) {
      if (!this.settings.linkDateLabelSwitch && link.gfx != null && link.timeLabel != null) {
        link.gfx.removeChild(link.timeLabel)
      }
      labelLink(
        link,
        this.decimalFormatter,
        this.formatDate,
        this.sdf,
        this.settings.linkLabelSwitch,
        this.settings.linkDateLabelSwitch
      )
    } else {
      if (link.gfx != null) {
        if (link.amountLabel != null) link.gfx.removeChild(link.amountLabel)
        if (link.timeLabel != null) link.gfx.removeChild(link.timeLabel)
      }
    }
  }

  toggleRounding() {
    this.setDecimalFormat()
    this.toggleLinkLabels()
  }

  setDecimalFormat() {
    if (this.settings.roundDecimalsSwitch) {
      this.decimalFormatter = (n: number | string) => prettyRoundedNumber(n, 4)
    } else {
      this.decimalFormatter = (n: number | string) => prettyRoundedNumber(n)
    }
  }

  changeNodeLabels() {
    for (const node of this.nodesFormatted) {
      labelNode(
        node,
        this.receiptsCache,
        this.attributionsCache,
        this.clusterAddressCache,
        this.settings.fullAddressLabelSwitch,
        this.nodeSizeScale,
        this.sdf,
        this.settings.nodeLabelSwitch,
        this.settings.txnNodeLabelSwitch
      )
    }
  }

  changeNodeSizes() {
    if (this.settings.nodeSizeRadio === 'linear') {
      this.nodeSizeScale = d3.scaleLinear().domain([ENTITY_MIN_SIZE, ENTITY_MAX_SIZE]).range([NODE_MIN_SIZE, NODE_MAX_SIZE])
    } else {
      this.nodeSizeScale = d3.scaleSymlog().domain([ENTITY_MIN_SIZE, ENTITY_MAX_SIZE]).range([NODE_MIN_SIZE, NODE_MAX_SIZE])
    }
  }

  redrawNodes() {
    for (const n of this.nodesFormatted) {
      if (n.gfx) {
        n.gfx.clear()
        this.drawNode(n, 1, COLOR_LIGHT_GRAY)
      }
    }
  }

  async setLinkColor(color: string) {
    const { link } = this.linkMenu
    if (link !=  null) {
      link.color = color
      if (link.reversedLink != null) {
        link.reversedLink.color = color
        await this.drawBidirictionalLinks([link, link.reversedLink])
      } else {
        await this.drawLink(link)
      }
      this.updateGraphState()
    }
  }

  selectedLinksColor() {
    let allColor = ''
    for (const { color } of this.selectedLinks) {
      if (color != null && color !== allColor) {
        if (allColor === '') {
          allColor = color
        } else {
          return COLOR_BLACK_STR
        }
      }
    }
    return allColor ? allColor : COLOR_BLACK_STR
  }

  async colorSelectedLinks(color: string) {
    const bidirectional: InteractiveLink[] = []
    for (const link of this.selectedLinks) {
      link.color = color
      if (link.reversedLink != null) {
        bidirectional.push(link, link.reversedLink) // this is going to duplicate the links but ensures they're in sequence
      } else {
        await this.drawLink(link)
      }
    }
    await this.drawBidirictionalLinks(bidirectional)
    this.updateGraphState()
  }

  async addToGraph() {
    this.updateScaling()
    this.updateForces() // need this before plugging stuff into sim

    this.newestLinksCopy = new Set(this.newestLinks)

    // set up arrays to store the nodes/links that need to be undeleted based on the new graph data
    const nodesToUndelete: FormattedNode[] = []
    const linksToUndelete: (InteractiveLink)[] = []

    let initialGraphing = false
    const newNodes: FormattedNode[] = []
    let singleGraphedNode: FormattedNode | null = null
    const dupeEntities = new Set<string>()

    if (!(this.nodesFormatted.length > 0 || this.removedNodes.length > 0) || this.graphRedraw) {
      // generate the graph from state
      this.nodesFormatted = this.nodes.map((node) => ({
        ...Object.assign({}, node),
        size: this.nodeSize(node.id)
      }))
      this.linksFormatted = this.links.map((link: any) => Object.assign({}, link)) // cast as any because of sim type change
      this.summaryLinksFormatted = this.summaryLinks.map((link: any) => Object.assign({}, link))

      // set up links between same entities in different networks
      for (const { id: nodeId } of this.nodesFormatted) {
        const { id, type, network } = classifyNodeId(nodeId)
        if (type !== 'transaction') {
          const typedId = `${type}|${id}`
          if (typedId in this.entityNetworks) {
            this.entityNetworks[typedId].push(network)
            dupeEntities.add(typedId)
          } else {
            this.entityNetworks[typedId] = [network]
          }
        }
      }
      for (const typedId of dupeEntities) {
        const [type, id] = typedId.split('|')
        const allNetworks = this.entityNetworks[typedId]
        for (let i=0; i<allNetworks.length; i++) {
          const source = nodeTypeToIdString({
            type: type as IdType,
            id,
            network: allNetworks[i]
          })
          // pair with other new networks
          for (let j=i+1; j<allNetworks.length; j++) {
            const target = nodeTypeToIdString({
              type: type as IdType,
              id,
              network: allNetworks[j]
            })
            const dupeLink = {
              source,
              target,
              dupes: true
            } as any // can't type this because it will only have correct typing once graphed and simulated
            this.networkDupesLinks.push(dupeLink)
          }
        }
      }

      await this.generateNoteNodes()
      
      // plug everything into sim
      this.addEverythingToSimulation()

      combineBidirectionalLinks(this.linksFormatted)
      combineBidirectionalLinks(this.summaryLinksFormatted)

      if (this.investigation.state != null) {
        // regenerate graph
        initialGraphing = true
        // determine showing nodes and links
        const showingSet = new Set(filter(this.investigation.state.nodes, n => !n.deleted).map((n) => n.id))
        this.removedNodes = filter(this.nodesFormatted, (n) => !showingSet.has(n.id))
        this.nodesFormatted = filter(this.nodesFormatted, (n) => showingSet.has(n.id))

        const removedLinkSet = new Set(this.deletedLinks.map(l => `${l.source}|${l.target}`))

        this.removedLinks = filter(
          this.linksFormatted,
          (l) => removedLinkSet.has(`${l.source.id}|${l.target.id}`)
        )
        this.linksFormatted = filter(this.linksFormatted, (l) => !this.removedLinks.includes(l))

        this.summaryLinksRemoved = filter(
          this.summaryLinksFormatted,
          (l) => removedLinkSet.has(`${l.source.id}|${l.target.id}`)
        )
        this.summaryLinksFormatted = filter(this.summaryLinksFormatted, (l) => !this.summaryLinksRemoved.includes(l))

        // set node placements and labels
        const placementDict: { [node: string]: { x: number; y: number; name?: string } } = {}
        for (const n of this.investigation.state.nodes) {
          const { id, x, y, name } = n
          placementDict[id] = { x, y, name }
        }
        for (const n of this.nodesFormatted) {
          const { x, y, name } = placementDict[n.id]
          n.freeze = { x, y }
          n.display = name
        }
        for (const n of this.removedNodes) {
          if (n.id in placementDict) { // for backwards compatibility
            const { x, y, name } = placementDict[n.id]
            n.freeze = { x, y }
            n.display = name
          }
        }
      }
      if (this.nodesFormatted.length === 1) {
        singleGraphedNode = this.nodesFormatted[0]
        singleGraphedNode.freeze = {
          x: this.viewport.screenWidth / 2,
          y: this.viewport.screenHeight / 2
        }
      } else {
        for (const node of this.nodesFormatted) {
          newNodes.push(node)
        }
      }
      this.$store.dispatch('graphRedrawn')
    } else {
      const entityNewNetworks: { [id: string]: string[] } = {}
      
      // update formatted nodes and links based on new data
      const existingNodes: { [id: string]: FormattedNode } = {}
      for (const node of this.nodesFormatted) {
        existingNodes[node.id] = node
      }
      for (const node of this.removedNodes) {
        existingNodes[node.id] = node
      }
      for (const node of this.nodes) {
        if (!(node.id in existingNodes)) {
          // it's new, add it
          const newNode: FormattedNode = {
            ...Object.assign({}, node),
            size: this.nodeSize(node.id)
          }
          newNodes.push(newNode)
          this.nodesFormatted.push(newNode)
          const { id, type, network } = classifyNodeId(node.id)
          if (type !== 'transaction') {
            const typedId = `${type}|${id}`
            if (typedId in entityNewNetworks) {
              entityNewNetworks[typedId].push(network)
            } else {
              entityNewNetworks[typedId] = [network]
            }
            if (typedId in this.entityNetworks) {
              this.entityNetworks[typedId].push(network)
              dupeEntities.add(typedId)
            } else {
              this.entityNetworks[typedId] = [network]
            }
          }
        } else {
          // find the corresponding node and update relevant properties
          const nodeFormatted = existingNodes[node.id]
          const { appearance, flows, permanent } = node
          nodeFormatted.appearance = appearance
          nodeFormatted.flows = flows
          nodeFormatted.permanent = permanent
        }
      }

      const targetNode = this.target ? 
        this.activeNodes.find(
          n => this.target ? n.id === nodeTypeToIdString(this.target) : false
        ) as FormattedNode | undefined :
        this.selectedLink ? this.selectedLink.source : null // use source because only target would be extracted from link
      let targetX = 0, targetY = 0
      if (targetNode != null) {
        const { x, y } = targetNode.freeze ?? targetNode.gfx ?? targetNode
        targetX = x || 0
        targetY = y || 0
      }
      let maxLeftY = targetY, maxRightY = targetY

      const existingLinks: { [id: string]: FormattedLink } = {}
      for (const link of this.linksFormatted) {
        existingLinks[JSON.stringify([link.source.id, link.target.id])] = link
      }
      for (const link of this.removedLinks) {
        existingLinks[JSON.stringify([link.source.id, link.target.id])] = link
      }

      const newTransactionLinks: FormattedLink[] = []
      for (const link of this.links) {
        const key = JSON.stringify([link.source, link.target])
        if (!(key in existingLinks)) {
          // it's new, add it
          const linkToAdd = Object.assign({}, link as any)
          this.linksFormatted.push(linkToAdd)
          newTransactionLinks.push(linkToAdd)
        } else {
          // find the corresponding link and update relevant properties
          const linkFormatted = existingLinks[key]
          if (linkFormatted != null) {
            linkFormatted.amount = link.amount
            linkFormatted.permanent = link.permanent
          }

          if (targetNode != null) {
            if (linkFormatted.source === targetNode) {
              let { x, y } = linkFormatted.target.freeze ?? linkFormatted.target.gfx ?? linkFormatted.target
              x = x || 0
              y = y || 0
              if (x > targetX && y >= maxRightY) maxRightY = y + NODE_PLACEMENT_DISTANCE
            } else if (linkFormatted.target === targetNode) {
              let { x, y } = linkFormatted.source.freeze ?? linkFormatted.source.gfx ?? linkFormatted.source
              x = x || 0
              y = y || 0
              if (x < targetX && y >= maxLeftY) maxLeftY = y + NODE_PLACEMENT_DISTANCE
            }
          }
        }
      }

      const existingSummaryLinks: { [id: string]: FormattedSummaryLink } = {}
      for (const link of this.summaryLinksFormatted) {
        existingSummaryLinks[JSON.stringify([link.source.id, link.target.id])] = link
      }
      for (const link of this.summaryLinksRemoved) {
        existingSummaryLinks[JSON.stringify([link.source.id, link.target.id])] = link
      }

      for (const link of this.summaryLinks) {
        const key = JSON.stringify([link.source, link.target])
        if (!(key in existingSummaryLinks)) {
          // it's new, add it
          this.summaryLinksFormatted.push(Object.assign({}, link as any))
        } else {
          // find the corresponding link and update relevant properties
          const linkFormatted = existingSummaryLinks[key]
          linkFormatted.linkSummaries = link.linkSummaries
          linkFormatted.transactions = link.transactions

          if (targetNode != null) {
            if (linkFormatted.source === targetNode) {
              let { x, y } = linkFormatted.target.freeze ?? linkFormatted.target.gfx ?? linkFormatted.target
              x = x || 0
              y = y || 0
              if (x > targetX && y >= maxRightY) maxRightY = y + NODE_PLACEMENT_DISTANCE
            } else if (linkFormatted.target === targetNode) {
              let { x, y } = linkFormatted.source.freeze ?? linkFormatted.source.gfx ?? linkFormatted.source
              x = x || 0
              y = y || 0
              if (x < targetX && y >= maxLeftY) maxLeftY = y + NODE_PLACEMENT_DISTANCE
            }
          }
        }
      }

      for (const typedId of dupeEntities) {
        const [type, id] = typedId.split('|')
        const newNetworks = entityNewNetworks[typedId]
        const allNetworks = this.entityNetworks[typedId]
        for (let i=0; i<newNetworks.length; i++) {
          const source = nodeTypeToIdString({
            type: type as IdType,
            id,
            network: newNetworks[i]
          })
          // pair with other new networks
          for (let j=i+1; j<newNetworks.length; j++) {
            const target = nodeTypeToIdString({
              type: type as IdType,
              id,
              network: newNetworks[j]
            })
            const dupeLink = {
              source,
              target,
              dupes: true
            } as any // can't type this because it will only have correct typing once graphed and simulated
            this.networkDupesLinks.push(dupeLink)
          }
          // pair with non-new existing networks
          for (const network of allNetworks) {
            if (!newNetworks.includes(network)) {
              const target = nodeTypeToIdString({
                type: type as IdType,
                id,
                network
              })
              const dupeLink = {
                source,
                target,
                dupes: true
              } as any // can't type this because it will only have correct typing once graphed and simulated
              this.networkDupesLinks.push(dupeLink)
            }
          }
        }
      }

      // plug everything into sim to include things that were just added
      this.addEverythingToSimulation()

      // combine any links that haven't already been combined
      combineBidirectionalLinks(this.linksFormatted)
      combineBidirectionalLinks(this.summaryLinksFormatted)

      // place new nodes manually if relevant
      if (newTransactionLinks.length === 0 && newNodes.length === 1) {
        singleGraphedNode = newNodes[0]
        // put it in the center of the graph
        singleGraphedNode.freeze = {
          x: this.viewport.screenWidth / 2,
          y: this.viewport.screenHeight / 2
        }
      } else if (newTransactionLinks.length > 0 && targetX != null && targetY != null) {
        if (newTransactionLinks[0].amount.flows == null) {
          // new nodes were graphed on transaction level but not as flow, place them accordingly
          const newNodeSet = new Set(newNodes)
          for (const link of newTransactionLinks) {
            if (classifyNodeId(link.source.id).type === 'transaction' && newNodeSet.has(link.target)) {
              link.target.freeze = {
                x: targetX + NODE_PLACEMENT_DISTANCE,
                y: maxRightY
              }
              if (link.source.freeze == null) {
                link.source.freeze = {
                  x: targetX + HALF_NODE_PLACEMENT_DISTANCE,
                  y: maxRightY + NODE_PLACEMENT_DISTANCE
                }
              }
              maxRightY += NODE_PLACEMENT_DISTANCE
            } else if (classifyNodeId(link.target.id).type === 'transaction' && newNodeSet.has(link.source)) {
              link.source.freeze = {
                x: targetX - NODE_PLACEMENT_DISTANCE,
                y: maxLeftY
              }
              if (link.target.freeze == null) {
                link.target.freeze = {
                  x: targetX - HALF_NODE_PLACEMENT_DISTANCE,
                  y: maxLeftY + NODE_PLACEMENT_DISTANCE
                }
              }
              maxLeftY += NODE_PLACEMENT_DISTANCE
            }
          }
        } else if (this.forces.xEmbedTarget != null) {
          // flow was graphed, place nodes based on hops
          const { targetTransaction, sending } = this.forces.xEmbedTarget
          const { id, isOutput, index } = targetTransaction
          const key = `${id}|${isOutput}|${index}|${sending}`
          const directionMultiplier = sending ? 1 : -1
          const vertOffsetStart = sending ? maxRightY : maxLeftY
          const vertOffsetByHops: { [hops: number]: number } = {}
          for (const node of newNodes) {
            if (node.flows != null) {
              const flow = node.flows.find(f => {
                const { targetTransaction: fTxn, sending: fSending } = f
                const flowKey = `${fTxn.id}|${fTxn.isOutput}|${fTxn.index}|${fSending}`
                return key === flowKey
              })
              if (flow != null) {
                const { minHops } = flow
                const offset = minHops * directionMultiplier * NODE_PLACEMENT_DISTANCE
                let vertOffset = vertOffsetStart
                if (minHops in vertOffsetByHops) {
                  vertOffset += vertOffsetByHops[minHops]
                  vertOffsetByHops[minHops] += NODE_PLACEMENT_DISTANCE
                } else {
                  vertOffsetByHops[minHops] = NODE_PLACEMENT_DISTANCE
                }
                node.freeze = {
                  x: targetX + offset,
                  y: vertOffset
                }
              }
            }
          }
        }
      } else if (this.newestNodes.size === 1) { // just graphing a single node that's already graphed
        const singleNewNode = this.newestNodes.keys().next().value
        singleGraphedNode = this.nodesFormatted.find(n => n.id === singleNewNode) ?? 
          this.removedNodes.find(n => n.id === singleNewNode) ?? null
      }
      
      // undelete nodes and links in the most recently graphed set
      for (const link of this.removedLinks) {
        const linkKey = `${link.source.id}||${link.target.id}`
        if (this.newestLinksCopy.has(linkKey)) {
          linksToUndelete.push(link)
          if (link.reversedLink != null) {
            linksToUndelete.push(link.reversedLink)
          }
        }
      }
      for (const link of this.summaryLinksRemoved) {
        const linkKey = `${link.source.id}||${link.target.id}`
        if (this.newestLinksCopy.has(linkKey)) {
          linksToUndelete.push(link)
          if (link.reversedLink != null) {
            linksToUndelete.push(link.reversedLink)
          }
        }
      }
      if (linksToUndelete.length === 0) {
        for (const node of this.removedNodes) {
          if (this.newestNodes.has(node.id)) {
            nodesToUndelete.push(node)
          }
        }
      }
    }

    if (linksToUndelete.length > 0)
      await this.undeleteLinks(linksToUndelete)
    else for (const node of nodesToUndelete) {
      if (this.newestLinksCopy.size > 0) {
        await this.undeleteNodes([node])
      } else {
        await this.undeleteNodeAndLinks(node)
      }
    }

    // remove non-displayed links
    const removedNodeSet = new Set<FormattedNode | NoteNode>(this.removedNodes)
    for (const node of this.noteNodesRemoved) {
      removedNodeSet.add(node)
    }
    const linksToDelete: (GraphLink)[] = filter(
      this.linksFormatted,
      link => removedNodeSet.has(link.source) || removedNodeSet.has(link.target)
    )
    for (const link of this.noteLinks) {
      if (removedNodeSet.has(link.target)) {
        linksToDelete.push(link)
      }
    }
    const newAttributionNodeSet: Set<FormattedNode> = singleGraphedNode == null ? // only filter out attribution pair link if not single node graphed
      new Set(filter(newNodes, n => classifyNodeId(n.id).type === 'attribution')) :
      new Set()
    for (const link of this.summaryLinksFormatted) {
      const linkKey = `${link.source.id}||${link.target.id}`
      const reversedLinkKey = `${link.target.id}||${link.source.id}`
      const { id: sourceId, type: sourceType } = classifyNodeId(link.source.id)
      const { id: targetId, type: targetType } = classifyNodeId(link.target.id)
      if (
        removedNodeSet.has(link.source) || removedNodeSet.has(link.target) || // one side is removed
        // if not graphing from state, remove link if not graphing directly and
        // 1) both sides attributed, or 2) it's a matching address-attribution pair
        !initialGraphing && !this.newestLinksCopy.has(linkKey) && !this.newestLinksCopy.has(reversedLinkKey) && (
        (newAttributionNodeSet.has(link.source) && targetType === 'attribution') ||
        (newAttributionNodeSet.has(link.target) && sourceType === 'attribution') ||
        (sourceType === 'attribution' && targetType === 'address') ||
        (targetType === 'attribution' && sourceType === 'address')
        )
      ) {
        if (sourceType === 'attribution' && targetType === 'address') {
          const cluster = this.clusterAddressCache.get(targetId)
          if (cluster != null && cluster.topAttribution === sourceId) {
            linksToDelete.push(link)
          }
        } else if (targetType === 'attribution' && sourceType === 'address') {
          const cluster = this.clusterAddressCache.get(sourceId)
          if (cluster != null && cluster.topAttribution === targetId) {
            linksToDelete.push(link)
          }
        } else {
          linksToDelete.push(link)
        }
      }
    }
    await this.deleteLinks(linksToDelete)

    // graph stuff
    this.viewport.removeChildren()

    this.viewport.addChild(this.grid.gfx)

    await this.graphLinks(this.activeLinks)
    await this.graphLinks(this.activeLinksRemoved)

    await Promise.all(this.activeNodes.map(node => this.graphNode(node)))
    await Promise.all(this.activeNodesRemoved.map(node => this.graphNode(node, !this.drawDeleted)))

    if (!initialGraphing) { // only run sim if not everything is frozen, i.e. not graphing from state
      this.drawing = false
      this.simulation.tick(5000)
      this.drawing = true
    }
    await this.ticked()

    if (this.snapToGrid) this.snapAllToGrid()
    else this.freezeAll()

    setTimeout(() => this.setAllLabelsCacheAsBitmap(true), 500) // should leave enough time to render first while speeding up performance afterwards

    this.$store.dispatch('clearNewestNodes')
    this.$store.dispatch('clearNewestLinks')

    if (singleGraphedNode != null) { // if just graphing a single new node, set it as target and center graph
      this.setTarget(singleGraphedNode)
      const { x, y } = singleGraphedNode.freeze ?? singleGraphedNode.gfx ?? singleGraphedNode
      if (x != null && y != null) this.viewport.snap(x, y, { removeOnComplete: true })
    } else { // center graph on average of new entity nodes
      const newEntityNodes = filter(newNodes, n => classifyNodeId(n.id).type !== 'transaction')
      const newNodeCount = newEntityNodes.length
      if (newNodeCount === 1) {
        this.setTarget(newEntityNodes[0])
      }
      let avgX = 0, avgY = 0
      for (const node of newEntityNodes) {
        const { x, y } = node.freeze ?? node.gfx ?? node
        avgX += x ?? 0
        avgY += y ?? 0
      }
      if (avgX !== 0 && avgY !== 0) this.viewport.snap(avgX / newNodeCount, avgY / newNodeCount, { removeOnComplete: true })
    }
  }

  setAllLabelsCacheAsBitmap(value: boolean) {
    for (const node of this.activeNodes) {
      if (!isNoteNode(node)) {
        const { label, upperLabel } = node
        if (label != null) label.cacheAsBitmap = value
        if (upperLabel != null) upperLabel.cacheAsBitmap = value
      }
    }
    for (const node of this.activeNodesRemoved) {
      if (!isNoteNode(node)) {
        const { label, upperLabel } = node
        if (label != null) label.cacheAsBitmap = value
        if (upperLabel != null) upperLabel.cacheAsBitmap = value
      }
    }
    for (const link of this.activeLinks) {
      if (isInteractiveLink(link)) {
        const { amountLabel, timeLabel } = link
        if (amountLabel != null) amountLabel.cacheAsBitmap = value
        if (timeLabel != null) timeLabel.cacheAsBitmap = value
      }
    }
    for (const link of this.activeLinksRemoved) {
      if (isInteractiveLink(link)) {
        const { amountLabel, timeLabel } = link
        if (amountLabel != null) amountLabel.cacheAsBitmap = value
        if (timeLabel != null) timeLabel.cacheAsBitmap = value
      }
    }
  }

  updateGraphState() {
    this.$store.dispatch('updateGraphState', {
      // map just the properties needed to regraph
      nodes: this.nodesFormatted.map(({ id, size, appearance, flows, display, freeze, x, y, permanent }) => ({
        id,
        size,
        appearance,
        flows,
        display,
        freeze,
        x,
        y,
        permanent
      })),
      nodesRemoved: this.removedNodes.map(({ id, size, appearance, flows, display, freeze, x, y, permanent }) => ({
        id,
        size,
        appearance,
        flows,
        display,
        freeze,
        x,
        y,
        permanent
      })),
      notes: this.noteNodes.map(({ id, input, freeze, x, y }) => ({
        id,
        text: input.text,
        freeze,
        x,
        y
      })),
      notesRemoved: this.noteNodesRemoved.map(({ id, input, freeze, x, y }) => ({
        id,
        text: input.text,
        freeze,
        x,
        y
      })),
      links: this.linksFormatted.map(({ source, target, amount, permanent, color }) => ({
        source,
        target,
        amount,
        permanent,
        color
      })),
      linksRemoved: this.removedLinks.map(({ source, target, amount, permanent, color }) => ({
        source,
        target,
        amount,
        permanent,
        color
      })),
      summaryLinks: this.summaryLinksFormatted.map(({ source, target, linkSummaries, transactions, color }) => ({
        source,
        target,
        linkSummaries,
        transactions,
        color
      })),
      summaryLinksRemoved: this.summaryLinksRemoved.map(({ source, target, linkSummaries, transactions, color }) => ({
        source,
        target,
        linkSummaries,
        transactions,
        color
      })),
      app: this.app
    } as FormattedGraph)
  }

  freezeAll() {
    for (const node of this.nodesFormatted) {
      const { x, y } = node
      if (x && y) {
        node.freeze = { x, y }
      }
    }
  }

  unfreezeAll() {
    for (const node of this.nodesFormatted) {
      node.freeze = undefined
    }
  }

  snapNodeToGrid(node: FormattedNode & Position) {
    let { x, y } = closestGridPoint(this.grid, node)
    if (node.gfx != null) {
      node.gfx.x = x
      node.gfx.y = y
    }
    node.x = x
    node.y = y
    node.freeze = { x, y }
  }

  snapAllToGrid() {
    for (const node of this.nodesFormatted) {
      this.snapNodeToGrid(<FormattedNode & Position>node)
    }
    for (const node of this.removedNodes) {
      this.snapNodeToGrid(<FormattedNode & Position>node)
    }
    this.handleTargetSelected()
    this.ticked()
  }

  toggleSnapToGrid() {
    if (this.snapToGrid) {
      this.grid.gfx.visible = true
      this.snapAllToGrid()
    }
    else {
      this.grid.gfx.visible = false
    }
  }

  animateTargetBorder() {
    if (this.targetBorderGfx != null) {
      this.targetBorderGfx.rotation += 0.005
      this.targetBorderGfx.clear()
      this.targetBorderGfx.lineStyle(1, COLOR_BLACKISH, 0.7)
      var offsetInterval = 750
      drawDashedPolygon(
        this.targetBorderGfx,
        this.targetBorderPoints,
        0,
        0,
        0,
        10,
        5,
        ((Date.now() % offsetInterval) + 1) / offsetInterval
      )
      this.targetBorderAnimation = requestAnimationFrame(this.animateTargetBorder)
    }
  }

  animateSelectBorder() {
    if (this.selectBorderGfx != null) {
      this.selectBorderGfx.clear()
      this.selectBorderGfx.lineStyle(1, COLOR_BLACKISH, 0.7)
      var offsetInterval = 750
      drawDashedPolygon(
        this.selectBorderGfx,
        this.selectBorderPoints,
        0,
        0,
        0,
        10,
        5,
        ((Date.now() % offsetInterval) + 1) / offsetInterval
      )
    }
    this.selectBorderAnimation = requestAnimationFrame(this.animateSelectBorder)
  }

  setNodeDisplay(id: string, display: string) {
    const node = this.activeNodes.find(n => n.id === id) as FormattedNode | undefined // can't rename note node
    if (node != null) {
      node.display = display
      labelNode(
        node,
        this.receiptsCache,
        this.attributionsCache,
        this.clusterAddressCache,
        this.settings.fullAddressLabelSwitch,
        this.nodeSizeScale,
        this.sdf,
        this.settings.nodeLabelSwitch,
        this.settings.txnNodeLabelSwitch
      )
      this.updateGraphState()
      if (this.target) {
        const { id: targetId, type: targetType } = this.target
        const { id: nodeId, type: nodeType } = classifyNodeId(id)
        if (targetId === nodeId && targetType === nodeType) {
          this.$store.dispatch('renameTarget', { name: display })
        }
      }
    }
  }

  async generateNoteNodes() {
    await Promise.all(
      // there should be few enough notes for the use of spread here to be fine
      [...this.$store.state.noteNodes,
      ...this.$store.state.removedNotes]
      .map((note: NoteNodeInfo) => {
        const { id, x, y, text, deleted } = note
        // parse out the refId if relevant
        const [, refId] = id.split('::')
        return this.createNote(x || 0, y || 0, refId || '', id, text, deleted)
    }))
  }

  async addNote(id: string) {
    this.nodeMenu.show = false
    const { localX: x,localY: y } = this.nodeMenu
    await this.createNote(x, y, id)

    this.addEverythingToSimulation()
    if (id !== '') await this.ticked() // only need to tick if added a link
    this.updateGraphState()
  }

  async createNote(x: number, y: number, refId: string, noteId?: string, text: string = '', deleted?: boolean) {
    const noteNode = createNote(x, y, refId, noteId, text)
    const { input } = noteNode

    input.placeholder = `note${ refId ? ' on ' : '' }${this.formatID(refId)}`
    input.on(<any>'input', (text: string) => { // need casting because 'input' isn't registered as an event type
      clearTimeout(this.finishedEditing)
      this.finishedEditing = setTimeout(() => this.updateGraphState(), 5000)
    })
    this.viewport.addChild(input)

    if (!deleted) {
      this.noteNodes.push(noteNode)
    } else {
      this.noteNodesRemoved.push(noteNode)
    }
    this.addTextInputEvents(noteNode)

    if (refId !== '') {
      const noteLink = { // id's will be replaced with objects when plugged into sim
        source: noteNode.id,
        target: refId,
        note: true
      }
      await this.graphLink(noteLink as any)
      if (!deleted) {
        this.noteLinks.push(noteLink as any)
      } else {
        this.noteLinksRemoved.push(noteLink as any)
      }
    }
  }

  addTextInputEvents(node: NoteNode) {
    const { input } = node
    const graphics = input._surrogate_hitbox
    graphics.eventMode = 'static'
    graphics
      .on('mousedown', (e: FederatedMouseEvent) => {
        this.deleteNoteButton.show = false
        this.onDragStart(e, input, true)
        this.nodeDown = node
        this.setMovingLinks()
      })
      .on('mouseupoutside', (e: FederatedMouseEvent) => {
        this.onDragEnd(e, node)
        this.nodeDown = undefined
      })
      .on('mouseup', (e: FederatedMouseEvent) => {
        this.onDragEnd(e, node)
        this.nodeDown = undefined
      })
      .on('mouseover', () => {
        if (this.nodeDown == null && !this.selectBorderMoving && !this.overNodeOrLink) {
          this.overNodeOrLink = true
          let { x, y } = node.input.getGlobalPosition(undefined, true)
          y += (this.$refs.graphContainer as Element).getBoundingClientRect().top
          x += this.viewport.scale.x * (node.input.width - 15) // can make this more precise if use the button size instead of constant
          this.deleteNoteButton = {
            show: true,
            x,
            y,
            note: node
          }
        }
      })
      .on('mouseout', () => {
        if (this.nodeDown == null && !this.selectBorderMoving) {
          this.overNodeOrLink = false
          this.deleteNoteButton.show = false
        }
      })
  }

  deleteNote(node?: NoteNode) {
    if (node != null) {
      const index = this.noteNodes.indexOf(node)
      if (index !== -1) {
        this.noteNodes.splice(index, 1)
      }
      this.viewport.removeChild(node.input)

      // check for link to delete
      const linkIndex = this.noteLinks.findIndex(l => l.source === node)
      if (linkIndex !== -1) {
        const link = this.noteLinks.splice(linkIndex, 1)[0]
        this.viewport.removeChild(link.gfx)
      }

      this.updateGraphState()
    }
  }

  async toggleTxnNodes() {
    const txnNodes = filter(this.nodesFormatted, (n) => classifyNodeId(n.id).type === 'transaction' && !n.permanent)
    const removedTxnNodes = filter(this.removedNodes, (n) => classifyNodeId(n.id).type === 'transaction' && !n.permanent)
    if (this.settings.txnNodeSwitch) {
      // graph txn links and nodes
      await this.graphLinks(this.linksFormatted)
      await this.graphLinks(this.removedLinks)
      await Promise.all(txnNodes.map((node) => this.graphNode(node)))
      await Promise.all(removedTxnNodes.map((node) => this.graphNode(node, true)))
    } else {
      // remove txn links and nodes
      for (const link of this.linksFormatted) {
        if (!link.permanent && link.gfx != null) this.viewport.removeChild(link.gfx)
      }
      for (const link of this.removedLinks) {
        if (!link.permanent && link.gfx != null) this.viewport.removeChild(link.gfx)
      }
      for (const node of txnNodes) {
        if (node.gfx != null) this.viewport.removeChild(node.gfx)
      }
      for (const node of removedTxnNodes) {
        if (node.gfx != null) this.viewport.removeChild(node.gfx)
      }
    }

    const allActive = []
    for (const link of this.activeLinks) {
      allActive.push(link)
    }
    for (const link of this.activeLinksRemoved) {
      allActive.push(link)
    }
    // @ts-ignore
    this.simulation.force('link').links(allActive)

    this.changeLinkThicknesses()
    this.handleTargetSelected()
    this.ticked()
  }

  async handleFilesUploaded(files: CustomFile[]) {
    const fileData = await getCsvOrExcelData(files)
    if (fileData != null) {
      const { data } = fileData
      this.fileData = data
    }
  }

  async graphFileContents() {
    if (this.fileData != null) {
      await this.$store.dispatch('graphFileData', { data: this.fileData })
    }
  }

  filterNetworks() {
    const networks = new Set(this.networksFilter.length ? this.networksFilter : this.networks)
    // recolor and change interactivity of all nodes/links not in the selected networks
    for (const node of this.activeNodes) {
      if (!isNoteNode(node) && node.gfx != null) {
        node.gfx.clear()
        this.drawNode(node, 1, COLOR_LIGHT_GRAY)
        node.gfx.eventMode = networks.has(classifyNodeId(node.id).network) ? 'static' : 'none'
      }
    }
    for (const node of this.activeNodesRemoved) {
      if (!isNoteNode(node) && node.gfx != null) {
        node.gfx.eventMode = networks.has(classifyNodeId(node.id).network) ? 'static' : 'none'
      }
    }
    for (const link of this.activeLinks) {
      if (link.gfx != null) {
        link.gfx.eventMode = networks.has(classifyNodeId(link.target.id).network) ? 'static' : 'none'
      }
    }
    for (const link of this.activeLinksRemoved) {
      if (link.gfx != null) {
        link.gfx.eventMode = networks.has(classifyNodeId(link.target.id).network) ? 'static' : 'none'
      }
    }
    this.ticked()
  }

  @Watch('settings')
  async updateSettings() {
    if (this.priorSettings.nodeLabelSwitch !== this.settings.nodeLabelSwitch) this.toggleNodeLabels()
    if (this.priorSettings.fullAddressLabelSwitch !== this.settings.fullAddressLabelSwitch) this.changeNodeLabels()
    if (this.priorSettings.txnNodeSwitch !== this.settings.txnNodeSwitch) await this.toggleTxnNodes()
    if (this.priorSettings.txnNodeLabelSwitch !== this.settings.txnNodeLabelSwitch) this.toggleNodeLabels()
    if (
      this.priorSettings.linkLabelSwitch !== this.settings.linkLabelSwitch ||
      this.priorSettings.linkDateLabelSwitch !== this.settings.linkDateLabelSwitch
    ) this.toggleLinkLabels()
    if (this.priorSettings.roundDecimalsSwitch !== this.settings.roundDecimalsSwitch) this.toggleRounding()
    if (this.priorSettings.nodeSizeRadio !== this.settings.nodeSizeRadio) {
      this.changeNodeSizes()
      this.redrawNodes()
    }
    if (this.priorSettings.linkThicknessRadio !== this.settings.linkThicknessRadio) this.changeLinkThicknesses()

    this.priorSettings = { ...this.settings }
    this.ticked()
  }

  @Watch('flowScaleTarget')
  handleFlowScaleTargetChange() {
    this.changeLinkThicknesses()
    this.ticked()
  }

  changeLinkThicknesses() {
    if (this.settings.linkThicknessRadio === 'amount' || this.flowScaleTarget == null) {
      this.linkScale = d3.scaleLinear()
        .domain([LINK_MIN_TRANSACTIONS, LINK_MAX_TRANSACTIONS])
        .range([MIN_THICKNESS, MAX_THICKNESS])
    } else {
      const { targetTransaction, sending } = this.flowScaleTarget
      const { id, isOutput, index } = targetTransaction
      const { max } = this.ranges.flows[`${id}|${isOutput}|${index}|${sending}`].flows
      this.linkScale = d3.scaleSymlog().domain([0, max]).range([MIN_THICKNESS, MAX_THICKNESS])
    }
  }

  @Watch('forces')
  updateForces() {
    let x, y
    if (this.target != null) {
      const targetId = nodeTypeToIdString(this.target)
      const targetNode = this.nodesFormatted.find(n => n.id === targetId)
      if (targetNode != null) {
        ({ x, y } = targetNode.freeze ?? targetNode.gfx ?? targetNode)
      }
    }
    // use middle of screen if target loc doesn't work
    x = x || this.viewport.screenWidth / 2
    y = y || this.viewport.screenHeight / 2
    if (this.forces.xEmbedRadio === 'hops' && this.forces.xEmbedTarget) {
      const { targetTransaction, sending } = this.forces.xEmbedTarget
      const { id, isOutput, index } = targetTransaction
      const key = `${id}|${isOutput}|${index}|${sending}`
      if (key in this.ranges.flows) {
        const { min, max } = this.ranges.flows[key].hops
        if (sending) {
          this.hopScale = d3
          .scaleLinear()
          .domain([min, max])
          .range([x - max * NODE_PLACEMENT_DISTANCE, x - NODE_PLACEMENT_DISTANCE])
        } else {
          this.hopScale = d3
          .scaleLinear()
          .domain([min, max])
          .range([x + NODE_PLACEMENT_DISTANCE, x + max * NODE_PLACEMENT_DISTANCE])
        }
      }
    }
    this.simulation
      .force(
        'link',
        d3
          //@ts-ignore
          .forceLink(this.allLinks)
          .id((d: any) => d.id)
          .distance(this.forces.linkDistance)
          .strength(this.forces.linkStrength)
      )
      .force(
        'charge',
        d3
          .forceManyBody()
          .strength((d: any) => this.nodeSizeScale(d.size) * this.forces.chargeStrengthMult)
          .theta(this.forces.chargeTheta)
          .distanceMin(this.forces.chargeDistance.min)
          .distanceMax(this.forces.chargeDistance.max)
      )
      .force(
        'x',
        d3
          .forceX()
          .x((d: any) => {
            if (this.forces.xEmbedRadio === 'hops' && this.forces.xEmbedTarget && d.flows != null && d.flows.length > 0) {
              const { targetTransaction, sending } = this.forces.xEmbedTarget
              const { id, isOutput, index } = targetTransaction
              const key = `${id}|${isOutput}|${index}`
              for (const flow of d.flows as Flow[]) {
                const { id, isOutput, index } = flow.targetTransaction
                const flowKey = `${id}|${isOutput}|${index}`
                if (key === flowKey && sending === flow.sending) {
                  return this.hopScale(flow.minHops)
                }
              }
              return this.hopScale(0)
            }
            let usableAppearance = d.appearance
            if (usableAppearance > this.ranges.appearance.max) usableAppearance = this.ranges.appearance.max
            else if (usableAppearance < this.ranges.appearance.min) usableAppearance = this.ranges.appearance.min
            return this.appearanceScale(usableAppearance)
          })
          .strength(this.forces.xEmbedStrength)
      )
      .force('y', d3.forceY(y).strength(this.forces.yEmbedStrength))
      .force(
        'collision',
        d3.forceCollide().radius((d: any) => this.nodeSizeScale(d.size) + 1)
      )
      .force(
        'center',
        d3.forceCenter(this.viewport.screenWidth / this.forces.xCenterRatio, this.viewport.screenHeight / 2)
      )
      .alpha(this.forces.alpha)
      .velocityDecay(this.forces.velocityDecay)
      .alphaDecay(1 - Math.pow(this.forces.alpha, 1 / 300))
    if (!this.forces.playing) {
      this.simulation.stop()
    } else {
      this.simulation.restart()
    }
  }

  @Watch('graphLoading')
  async graphDataChanged() {
    if (!this.graphLoading) {
      this.$store.dispatch('showLoadingDialog', { show: true, text: 'Rendering graph...' })
      await this.addToGraph()
      this.toggleRounding() // update edge labels
      this.adjustViewport() // watcher doesn't catch the event when originally sized
      this.$store.dispatch('showLoadingDialog', { show: false })
      this.updateGraphState()
      this.handleTargetSelected()
    }
  }

  @Watch('attributionSizesUpdated')
  setAttributionNodeSizes() {
    for (const node of this.nodesFormatted) {
      const { id, size: oldSize } = node
      const { type } = classifyNodeId(id)
      if (type === 'attribution') {
        const size = this.nodeSize(id)
        if (size !== oldSize) {
          node.size = size
          // reset hit area
          const { gfx } = node
          const scaledSize = this.nodeSizeScale(size)
          if (gfx != null) {
            gfx.hitArea = new Circle(0, 0, scaledSize)
          }
          // redraw
          this.graphNode(node)
          // resize target border if relevant
          if (this.target && this.target.id === classifyNodeId(node.id).id) {
            this.handleTargetSelected()
          }
        }
      }
    }
    this.ticked()
    for (const node of this.removedNodes) {
      const { id, size: oldSize } = node
      const { type } = classifyNodeId(id)
      if (type === 'attribution') {
        const size = this.nodeSize(id)
        if (size !== oldSize) {
          node.size = size
          // reset hit area
          const { gfx } = node
          const scaledSize = this.nodeSizeScale(size)
          if (gfx != null) {
            gfx.hitArea = new Circle(0, 0, scaledSize)
          }
        }
      }
    }
  }

  @Watch('$store.state.selectedNode')
  nodeSelected() {
    this.unhighlightNode()
    let selectedNode = this.$store.state.selectedNode
    if (selectedNode) {
      const highlightNode = this.nodesFormatted.find((n) => n.id === selectedNode)
      if (highlightNode != null) {
        this.redrawNodeBorder(highlightNode, 5, COLOR_ORANGE)
        this.highlightedNode = highlightNode
      }
    }
  }

  @Watch('$store.state.highlightedEdge')
  async edgeHighlighted() {
    // undo previous highlight if relevant
    if (this.highlightLink !== '') {
      for (const link of this.activeLinks) {
        const linkString = JSON.stringify([link.source.id, link.target.id])
        const reversedLinkString = JSON.stringify([link.target.id, link.source.id])
        if (this.highlightLink === linkString || this.highlightLink === reversedLinkString) {
          // redraw it
          this.highlightLink = ''
          if (link.reversedLink != null) {
            await this.drawBidirictionalLinks([link, link.reversedLink])
          } else {
            await this.drawLink(link)
          }
          break
        }
      }
    }
    const highlightedEdge = this.$store.state.highlightedEdge
    if (highlightedEdge.length > 0) {
      this.highlightLink = JSON.stringify(highlightedEdge)
      for (const link of this.activeLinks) {
        const linkString = JSON.stringify([link.source.id, link.target.id])
        const reversedLinkString = JSON.stringify([link.target.id, link.source.id])
        if (this.highlightLink === linkString || this.highlightLink === reversedLinkString) {
          // redraw it
          if (link.reversedLink != null) {
            await this.drawBidirictionalLinks([link, link.reversedLink])
          } else {
            await this.drawLink(link)
          }
          break
        }
      }
    }
  }

  @Watch('containerWidth')
  adjustViewport() {
    if (this.app != null) {
      // this.$refs.graphContainer is a step behind, even with $nextTick for some reason
      this.app.renderer.resize(
        this.containerWidth,
        (this.$refs.graphContainer as Element).clientHeight
      )
      const { worldWidth, worldHeight } = this.viewport
      this.viewport.resize(
        this.containerWidth,
        (this.$refs.graphContainer as Element).clientHeight,
        worldWidth,
        worldHeight
      )
    }
  }

  @Watch('attributionClustersUpdated')
  @Watch('clusterAddressesUpdated')
  @Watch('attributionsCacheUpdated')
  handleAttributions() {
    this.changeNodeLabels()
    this.redrawNodes()
  }

  @Watch('target')
  handleTargetSelected() {
    if (this.targetBorderGfx != null) {
      this.viewport.removeChild(this.targetBorderGfx)
      cancelAnimationFrame(this.targetBorderAnimation)
    }
    if (this.target !== undefined) {
      this.targetBorderPoints = [
        { x: -1, y: -1 },
        { x: -1, y: 1 },
        { x: 1, y: 1 },
        { x: 1, y: -1 }
      ]
      const targetID = nodeTypeToIdString(this.target)
      const found = this.activeNodes.find((n) => {
        return targetID === n.id
      }) as FormattedNode | undefined
      if (found != null) {
        const expandedRadius = this.nodeSizeScale(found.size) + 15
        for (const point of this.targetBorderPoints) {
          point.x *= expandedRadius
          point.y *= expandedRadius
        }
        if (this.targetBorderGfx == null) {
          this.targetBorderGfx = new Graphics()
          this.targetBorderGfx.zIndex = 3
        }
        this.animateTargetBorder()
        this.viewport.addChild(this.targetBorderGfx)
        const { x, y } = found
        this.targetBorderGfx.position = new Point(x, y) as ObservablePoint
      } else {
        if (this.targetBorderGfx) {
          this.targetBorderGfx = undefined
        }
        this.$store.dispatch('setTarget', { id: undefined }) // unset target if couldn't find it in the graph
      }
    } else {
      if (this.targetBorderGfx) {
        this.targetBorderGfx = undefined
      }
    }
  }

  @Watch('selectedLink')
  @Watch('highlightFlowKeys')
  handleLinksChange() {
    this.ticked()
  }

  @Watch('macrotized')
  async handleMacrotized() {
    if (this.macrotized) {
      const { oldEntity, deleted, macro, macroIndex, changedLinks, newLinksStart } = this.macrotized

      // edit nodes
      let searchToReplace: string
      if (deleted) {
        // there was already a macro node
        // delete micro
        const microIndex = this.nodesFormatted.findIndex((n) => n.id === oldEntity)
        const toRemove = this.nodesFormatted.splice(microIndex, 1)[0]
        await this.graphNode(toRemove, !this.drawDeleted)
        searchToReplace = macro
      } else {
        // the micro needs to be converted to the macro
        searchToReplace = oldEntity
      }
      // adjust appropriate node
      let formattedIndex = this.nodesFormatted.findIndex((n) => n.id === searchToReplace)
      let old: FormattedNode
      if (formattedIndex !== -1) {
        old = this.nodesFormatted[formattedIndex]
      } else { // must have been removed
        formattedIndex = this.removedNodes.findIndex((n) => n.id === searchToReplace)
        old = this.removedNodes.splice(formattedIndex, 1)[0]
        this.nodesFormatted.push(old)
      }
      const { appearance, flows, unspent } = this.nodes[macroIndex]
      old.size = this.nodeSize(old.id)
      old.appearance = appearance
      old.flows = flows
      old.unspent = unspent
      
      await this.graphNode(old)
      this.simulation.nodes(this.nodesFormatted) // in case node id got changed

      //edit links
      for (const link of changedLinks) {
        const { index: linkIndex, deleted: linkDeleted, side, macro: sideIsMacro, counterparty } = link
        let relevantLinks
        let source: string, target: string
        if (side === 'source') {
          source = sideIsMacro ? macro : oldEntity
          target = counterparty
        } else {
          source = counterparty
          target = sideIsMacro ? macro : oldEntity
        }
        let foundIndex = this.linksFormatted.findIndex(
          (l) => l.source.id === source && l.target.id === target
        )
        if (foundIndex !== -1) {
          relevantLinks = this.linksFormatted
        } else {
          // must be in removed links
          foundIndex = this.removedLinks.findIndex(
            (l) => l.source.id === source && l.target.id === target
          )
          relevantLinks = this.removedLinks
        }
        if (linkDeleted) {
          const toRemove = relevantLinks.splice(foundIndex, 1)[0]
          if (toRemove.reversedLink) {
            toRemove.reversedLink.reversedLink = undefined
          }
        } else if (linkIndex) {
          relevantLinks[foundIndex] = Object.assign({}, this.links[linkIndex] as any) // link index must exist if not deleted
          // plug links into sim to get the node references to be present for the adjusted link
          // @ts-ignore
          this.simulation.force('link').links(this.linksFormatted)
        } else {
          console.warn('something has gone wrong while changing macrotized links')
        }
      }

      // add new links, if relevant
      const newLinks = []
      if (newLinksStart && newLinksStart < this.links.length) {
        for (const link of this.links.slice(newLinksStart)) {
          const newLink = Object.assign({}, link as any)
          this.linksFormatted.push(newLink)
          newLinks.push(newLink)
        }
      }

      this.addEverythingToSimulation()
      combineBidirectionalLinks(this.linksFormatted)

      this.updateScaling()
      // graph stuff where relevant
      this.redrawNodes()
      await this.graphLinks(this.activeLinks)
      await this.graphLinks(this.activeLinksRemoved)

      await this.ticked()

      this.updateGraphState()
      this.handleTargetSelected()

      this.$store.dispatch('macrotizedHandled')
    }
  }

  @Watch('nodesToRemove')
  async handleNodesToRemove() {
    if (this.nodesToRemove != null) {
      for (const id of this.nodesToRemove) {
        const found = this.activeNodes.find((n) => n.id === id)
        if (found) {
          await this.deleteNode(<FormattedNode>found)
        }
      }
      this.$store.dispatch('removeNodes', { ids: [] })
      this.handleTargetSelected()
      this.updateGraphState()
    }
  }

  @Watch('linksToRemove')
  async handleLinksToRemove() {
    if (this.linksToRemove != null) {
      const removeSet = new Set(this.linksToRemove)
      await this.deleteLinks(filter(this.activeLinks, l => {
        const key = `${l.source.id}||${l.target.id}`
        return removeSet.has(key)
      }))
      await this.ticked()
      this.handleTargetSelected()
      this.updateGraphState()
      this.$store.dispatch('removeLinks', { links: [] })
    }
  }

  @Watch('rawFlowFilterSelectMode')
  handleRawFlowFilterSelectMode() {
    if (this.rawFlowFilterSelectMode) {
      // notify user of select mode
      this.$store.dispatch('updateSnackbar', {
        show: true,
        text: 'Select mode started; click nodes to select, hit Enter to confirm selection and any other key to exit.'
      })
      // set listener to end select mode:
      // pressing Enter will confirm the node selections made and filter raw flows,
      // and pressing any key including Enter will end select mode and redraw all nodes
      // to get rid of selection styling
      const listener = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
          this.$store.dispatch('filterRawFlows', { nodes: Array.from(this.rawFlowFilterNodes) })
        }
        this.$store.dispatch('endRawFlowFilterSelectMode')
        document.removeEventListener('keypress', listener)
        this.rawFlowFilterNodes.clear()
        this.redrawNodes()
      }
      document.addEventListener('keypress', listener)
    }
  }

  @Watch('targetNodeRename')
  handleNodeRename() {
    if (this.targetNodeRename != null && this.target != null) {
      const nodeId = nodeTypeToIdString(this.target)
      this.setNodeDisplay(nodeId, this.targetNodeRename)
      this.$store.dispatch('renameTargetNode', { name: undefined })
    }
  }

  async created() {
    // if it's a temp investigation, add a save button
    if (this.investigation._id == null) {
      this.dialogs.unshift({
        button: {
          icon: 'mdi-content-save',
          tooltip: 'Save Investigation'
        },
        dialog: {
          title: 'Save Investigation',
          action: 'Save',
          actionClick: () => this.dialogs.shift()
        },
        content: SaveInvestigation
      })
    }

    document.addEventListener('focusin', this.clearDeletionListeners)
    document.addEventListener('keyup', this.keyupListener)
    document.addEventListener('keydown', this.keydownListener)

    this.setDecimalFormat()
    this.changeNodeSizes()
    this.priorSettings = { ...this.$store.state.settings }
    this.textures['address'] = Texture.from('assets/account.svg')
    this.textures['addressDark'] = Texture.from('assets/account-black.svg')
    this.textures['cluster'] = Texture.from('assets/account-group.svg')
    this.textures['clusterDark'] = Texture.from('assets/account-group-black.svg')
    this.textures['coinbase'] = Texture.from('assets/coinbase-logo.svg')
    this.textures['contract'] = Texture.from('assets/file-document.svg')
    this.textures['bitcoin'] = Texture.from('assets/bitcoin.svg')
    this.textures['ethereum'] = Texture.from('assets/ethereum.svg')
    this.textures['tron'] = Texture.from('assets/tron.svg')
  }

  async mounted() {
    if (!this.sdf) this.sdf = await SDFRenderer.createInstance()

    const graphContainer = this.$refs.graphContainer as HTMLElement
    this.removeChildren(graphContainer)
    this.setUpGraph(this.$refs.graphContainer as HTMLElement)
  }

  beforeDestroy() {
    document.removeEventListener('focusin', this.clearDeletionListeners)
    document.removeEventListener('keyup', this.keyupListener)
    document.removeEventListener('keydown', this.keydownListener)
  }

  private removeChildren(container: HTMLElement) {
    let child = container.firstChild
    while (child != null) {
      container.removeChild(child)
      child = container.firstChild
    }
  }

  destroy() {
    try {
      this.targetBorderGfx = undefined
      this.app.destroy(true, true)
    } catch (e) {}
    if (this.destroyCallback != null) {
      this.destroyCallback()
    }
  }
}
