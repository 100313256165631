// import zlib from 'zlib'
import pako from 'pako'

export class CompressedMap<V = object> {
  private map: Map<string, Uint8Array> = new Map<string, Uint8Array>()

  public size: number = this.map.size

  private getStringValue(value: V): string {
    if (typeof value === 'string') {
      return value
    }
    if (typeof value === 'object') {
      return JSON.stringify(value)
    }
    return (value as any).toString()
  }

  private uncompressAndParse(value: Uint8Array): V {
    // const uncompressed = zlib.gunzipSync(value)
    const uncompressed = pako.inflate(value, { to: 'string' })
    // const uncompressedString = uncompressed.toString('utf8')
    return JSON.parse(uncompressed) as V
  }

  set(key: any, value: V) {
    const stringValue = this.getStringValue(value)
    // const compressed = zlib.gzipSync(stringValue)
    const compressed = pako.deflate(stringValue)
    this.map.set(key, compressed)
  }

  has(key: any) {
    return this.map.has(key)
  }

  get(key: any) {
    const compressed = this.map.get(key)
    if (compressed != null) {
      return this.uncompressAndParse(compressed)
    }
    return undefined
  }

  values() {
    const compressedValues = this.map.values()
    const values: Array<V> = []
    for (const compressed of compressedValues) {
      values.push(this.uncompressAndParse(compressed))
    }
    return values
  }

  entries() {
    const compressedEntries = this.map.entries()
    const entries: Array<[string, V]> = []
    for (const [key, compressed] of compressedEntries) {
      entries.push([key, this.uncompressAndParse(compressed)])
    }
    return entries
  }

  keys() {
    return this.map.keys()
  }

  clear() {
    this.map.clear()
  }
}
