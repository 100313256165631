import { Api, StringMap } from '@/utils/api'
import { Commit, Dispatch } from 'vuex'
import { State } from './index'

export interface SignupCode {
  code: string
  used: boolean
  date: string
}

interface ApiKey {
  key: string
  level: number
  username: string
  account: string
  active: boolean
  dateAdded: string
  lastActive: string
}

interface User {
  username: string
  email: string
  account: string
  active: boolean
  lastSeen: string
  scopes?: string[]
  isAdmin?: boolean
}

export interface UserLocation {
  [key: string]: UserSession[]
}

export interface UserSession {
  type: string
  value: string
  time: number
}

export interface ActiveUsers {
  count: number
  users: UserLocation
}

export interface RateLimits {
  [key: string]: {
    limit: number
    remaining: number
    reset: number
  }
}

export const accountState = {
  signupCodes: <SignupCode[]>[],
  apiKeys: <ApiKey[]>[],
  users: <User[]>[],
  activeUsers: <ActiveUsers>{},
  rateLimits: <RateLimits>{},
  useExternalApi: <boolean>false,
  externalApiRequest: <ExternalAPIRequest | undefined>undefined
}

export interface ExternalAPIRequest {
  url: string
  urlInterpolations?: StringMap
  headers?: StringMap
  params?: ObjectMap
  payload?: any
  dataPath: string[] | string // max 2, first is for current context, second is for next
  method: 'get' | 'post' | 'head'
  splitMultiple?: boolean
  next?: {
    condition: 'ifnull'
    request: ExternalAPIRequest
  }
}

export type ObjectMap = { [key: string]: any } & Object

export const accountMutations = {
  // admin
  SET_SIGNUP_CODES(state: State, { codes }: { codes: SignupCode[] }) {
    state.signupCodes = codes
  },
  SET_API_KEYS(state: State, { keys }: { keys: ApiKey[] }) {
    state.apiKeys = keys
  },
  SET_USERS(state: State, { users }: { users: User[] }) {
    state.users = users
  },
  SET_ACTIVE_USERS(state: State, { activeUsers }: { activeUsers: ActiveUsers }) {
    state.activeUsers = activeUsers
  },
  SET_RATE_LIMITS(state: State, { rateLimits }: { rateLimits: RateLimits }) {
    state.rateLimits = rateLimits
  },
  // external API
  SET_USE_EXTERNAL_API(state: State, { use }: { use: boolean }) {
    state.useExternalApi = use
  },
  SET_EXTERNAL_API_REQUEST(state: State, { data }: { data: ExternalAPIRequest }) {
    state.externalApiRequest = data
  }
}

export const accountActions = (state: State, api: Api, dispatch: Dispatch) => ({
  // admin
  async getUsers({ commit }: { commit: Commit }) {
    const response = await api.getUsers()
    if (response != null) {
      commit('SET_USERS', { users: response.data })
    }
  },
  async getSignupCodes({ commit }: { commit: Commit }) {
    const response = await api.getSignupCodes()
    if (response != null) {
      commit('SET_SIGNUP_CODES', { codes: response.data })
    }
  },
  async genSignupCodes({ commit }: { commit: Commit }) {
    await api.genSignupCodes()
  },
  async getApiKeys({ commit }: { commit: Commit }) {
    const response = await api.getApiKeys()
    if (response.success) {
      commit('SET_API_KEYS', { keys: response.data })
    } else {
      dispatch('updateSnackbar', {
        show: true,
        text: `${response.message} ${JSON.stringify(response)}`,
        timeout: -1
      })
    }
  },
  async genApiKey({ commit }: { commit: Commit }, { username }: { username: string }) {
    const response = await api.genApiKey(username)
    if (!response.success) {
      dispatch('updateSnackbar', {
        show: true,
        text: `${response.message} ${JSON.stringify(response)}`,
        timeout: -1
      })
    }
  },
  async toggleApiKey({ commit }: { commit: Commit }, { username, key }: { username: string; key: string }) {
    const response = await api.toggleApiKey(username, key)
    if (!response.success) {
      dispatch('updateSnackbar', {
        show: true,
        text: `${response.message} ${JSON.stringify(response)}`,
        timeout: -1
      })
    }
  },
  async editApiKeyScope({ commit }: { commit: Commit }, { key, scopes }: { key: string; scopes: string[] }) {
    const response = await api.updateApiKeyScopes(key, scopes)
    if (!response.success) {
      dispatch('updateSnackbar', {
        show: true,
        text: `${response.message} ${JSON.stringify(response)}`,
        timeout: -1
      })
    }
  },
  async deleteApiKey({ commit }: { commit: Commit }, { username, key }: { username: string; key: string }) {
    const response = await api.deleteApiKey(username, key)
    if (!response.success) {
      dispatch('updateSnackbar', {
        show: true,
        text: `${response.message} ${JSON.stringify(response)}`,
        timeout: -1
      })
    }
  },
  async toggleUser({ commit }: { commit: Commit }, { username }: { username: string }) {
    const response = await api.toggleUser(username)
    if (!response.success) {
      dispatch('updateSnackbar', {
        show: true,
        text: `${response.message} ${JSON.stringify(response)}`,
        timeout: -1
      })
    }
  },
  async deleteUser({ commit }: { commit: Commit }, { username }: { username: string }) {
    const response = await api.deleteUser(username)
    if (!response.success) {
      dispatch('updateSnackbar', {
        show: true,
        text: `${response.message} ${JSON.stringify(response)}`,
        timeout: -1
      })
    }
  },
  async getActiveUsers({ commit }: { commit: Commit }) {
    const response = await api.getActiveUsers()
    if (!response.success) {
      dispatch('updateSnackbar', {
        show: true,
        text: `${response.message} ${JSON.stringify(response)}`,
        timeout: -1
      })
      return
    }
    commit('SET_ACTIVE_USERS', { activeUsers: response.data })
  },
  async getRateLimits({ commit }: { commit: Commit }, { ids }: { ids: string[] }) {
    const response = await api.getRateLimits(ids)
    if (!response.success) {
      console.warn('couldnt get rate limits')
      return
    }
    commit('SET_RATE_LIMITS', { rateLimits: response.data })
  },
  // external API
  setUseExternalApi({ commit }: { commit: Commit }, { use }: { use: boolean }) {
    commit('SET_USE_EXTERNAL_API', { use })
  },
  setExternalApiRequest({ commit }: { commit: Commit }, { data }: { data: ExternalAPIRequest }) {
    commit('SET_EXTERNAL_API_REQUEST', { data })
  }
})
