
interface DOMEvent<T extends EventTarget> extends Event {
  readonly target: T
}

import { FormComponent } from '@/components/Dialog.vue'
import { CustomFile } from '@/utils/general'
import { Prop, Component, Watch, Emit } from 'vue-property-decorator'

@Component
export default class FileUpload extends FormComponent {
  @Prop() maxSize!: number
  @Prop() accept!: string

  public isLoading: boolean = false
  public errors: any[] = []
  public uploadReady: boolean = true
  public files: CustomFile[] = []

  getImgSrc(src: string | ArrayBuffer): string | undefined {
    if (typeof src ==='string') {
      return src
    }
    return undefined
  }

  async handleFileChange(e: DOMEvent<HTMLInputElement>) {
    this.errors = []
    // Check if file is selected
    if (e.target.files && e.target.files.length > 0) {
      // Get uploaded file and properties
      const file = e.target.files[0]
      const size = Math.round((file.size / 1024 / 1024) * 100) / 100
      const splitName = file.name.split('.')
      const fileExtension = splitName.pop()
      const name = splitName.shift()
      // Check if file is valid
      if (name && fileExtension && this.isFileValid(size, fileExtension)) {
        const isImage = ['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension)
        const reader = new FileReader()
        reader.onload = () => {
          this.files.push({
            name,
            size,
            type: file.type,
            fileExtension,
            isImage: isImage,
            url: reader.result,
            isUploaded: true
          })
        }
        reader.readAsDataURL(file)
      } else {
        console.warn('invalid file')
      }
    }
  }

  isFileSizeValid(fileSize: number) {
    if (fileSize > this.maxSize) {
      this.errors.push(`File size should be less than ${this.maxSize} MB`)
    }
  }

  isFileTypeValid(fileExtension: string) {
    if (!this.accept.split(',').includes(fileExtension)) {
      this.errors.push(`File type should be ${this.accept}`)
    }
  }

  isFileValid(size: number, fileExtension: string) {
    this.isFileSizeValid(size)
    this.isFileTypeValid(fileExtension)
    if (this.errors.length === 0) {
      return true
    } else {
      return false
    }
  }

  @Watch('files', { deep: true })
  @Emit('value-updated')
  filesChanged() {
    return this.files
  }
}
