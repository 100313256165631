import { IViewportOptions, Viewport } from 'pixi-viewport'
import { Application } from 'pixi.js'

export interface WebGLContext {
  graph: Application
  layers: Viewport[]
  height: number
  width: number
}

export class WebGLGraph {
  public minResolution: number
  public layerCount: number
  public graph: Application | undefined
  public viewport: Viewport | undefined
  public layers: Viewport[] = []

  constructor({ minResolution = 2, layers = 1 }: { minResolution?: number; layers?: number }) {
    this.minResolution = minResolution
    this.layerCount = layers
  }

  createCanvas(container: HTMLElement, expandWorldFactor?: number): WebGLContext {
    const height = container.clientHeight
    const width = container.clientWidth

    const baseOptions = {
      screenWidth: width,
      screenHeight: height,
      worldWidth: width * (expandWorldFactor ?? 1),
      worldHeight: height * (expandWorldFactor ?? 1),
      passiveWheel: false
    }

    const windowDPR = window.devicePixelRatio
    const resolution = windowDPR == null || windowDPR < this.minResolution ? this.minResolution : windowDPR

    this.graph = new Application({
      width,
      height,
      resizeTo: container,
      antialias: true,
      resolution,
      autoDensity: true,
      sharedTicker: true,
      autoStart: true,
      backgroundAlpha: 0
    })

    const options: IViewportOptions = {
      ...baseOptions,
      events: this.graph.renderer.events
    }

    for (let i = 0; i < this.layerCount; i++) {
      const layer = new Viewport(options)
      if (i > 0) {
        layer.visible = false
      }
      layer.sortableChildren = true
      this.graph.stage.addChild(layer)
      this.layers.push(layer)
    }

    const { graph, layers } = this

    return {
      graph,
      layers,
      height,
      width
    }
  }
}
