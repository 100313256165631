
import { Component, Prop, Vue } from 'vue-property-decorator'
import { titleCase } from '@/utils/general'

export interface NullNumberMap {
  [key: string]: null | number
}

export interface SortResponse {
  sortBy: string[]
  sortDesc: number[]
}

export interface SortItem {
  key: string
  label: string
}

@Component
export default class SortBar extends Vue {
  public titleCase = titleCase
  @Prop() single!: boolean
  @Prop() small!: boolean
  @Prop() fields!: Array<string | SortItem>
  @Prop() allowNoSort!: boolean
  @Prop() onChange!: (selected: SortResponse) => void
  @Prop() disabled!: boolean
  public btnStates: NullNumberMap = {}

  public selected: string[] = []
  public lastClicked: string = ''
  public lastIndex: number = 0

  get isDisabled() {
    if (!!this.disabled) {
      return true
    }
    return false
  }

  get singleField() {
    return !!this.single
  }

  get props(): string[] {
    if (this.fields == null) {
      return []
    }
    return this.fields.map((item: string | SortItem) => (typeof item === 'string' ? item : item.key))
  }

  get allowNull() {
    return !!this.allowNoSort
  }

  public key(item: string | SortItem): string {
    if (typeof item === 'string') {
      return item
    }
    return item.key
  }

  public label(item: string | SortItem): string {
    if (typeof item === 'string') {
      return item
    }
    return item.label
  }

  created() {
    this.btnStates = this.props.reduce(
      (merged, field) => ({
        ...merged,
        [`${field}`]: null
      }),
      <NullNumberMap>{}
    )
    if (!this.allowNull) {
      const keys = Object.keys(this.btnStates)
      this.btnStates[keys[0]] = 1
    }
  }

  public clicked(event: any) {
    if (this.btnStates[`${this.lastClicked}`] !== null) {
      if (!this.selected.includes(this.lastClicked) && !this.singleField) {
        this.selected.push(this.lastClicked)
      } else if (this.singleField) {
        this.selected = [this.lastClicked]
      }
    } else {
      if (this.selected.includes(this.lastClicked)) {
        const index = this.selected.indexOf(this.lastClicked)
        this.selected.splice(index, 1)
      }
    }
    const sortBy = this.selected
    const sortDesc = this.selected.map((i) => this.btnStates[i] as number)
    this.onChange({ sortBy, sortDesc })
  }

  public updateBtnState(item: string, index: number) {
    this.lastClicked = item
    this.lastIndex = index
    if (this.singleField) {
      const states = Object.keys(this.btnStates)
      for (const state of states) {
        if (state !== item) {
          this.btnStates[state] = null
        }
      }
    }
    if (this.allowNull) {
      switch (this.btnStates[item]) {
        case null:
          this.btnStates[item] = 1
          break
        case 0:
          this.btnStates[item] = null
          break
        case 1:
          this.btnStates[item] = 0
          break
      }
    } else {
      switch (this.btnStates[item]) {
        case null:
          this.btnStates[item] = 1
          break
        case 0:
          this.btnStates[item] = 1
          break
        case 1:
          this.btnStates[item] = 0
          break
      }
    }
  }

  public removeChip(item: string) {
    this.btnStates[item] = null
    if (this.selected.includes(item)) {
      const index = this.selected.indexOf(item)
      this.selected.splice(index, 1)
    }
  }
}
