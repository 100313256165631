import { BigNumber } from 'bignumber.js'
import {
  AddressInfo,
  AttributedTransaction,
  ContractInfo,
  EventData,
  FormattedBlock,
  FormattedLogEvent,
  FormattedTransaction,
  FunctionCall,
  hasStandards,
  isContractInfo,
  isContractType,
  isTokenProps,
} from '../types/eth'
import { formatNumber, trimZeros } from './bignum'
import { cutMiddle } from './general'
import { filter } from './filters'

export interface EthAttribution {
  transactionsCount?: number
  timestamp: number
  version: number
  rank: number
  isContract: boolean
  address: string
  name: string | null
}

export interface NftInfo {
  name: string
  tokenId: string | number
  owner: string
  recipient: string
}

const ETHER_DECIMALS = 18

BigNumber.config({ DECIMAL_PLACES: ETHER_DECIMALS })

export function formatEther(value: string): string {
  return new BigNumber(value).div(10 ** ETHER_DECIMALS).toFixed()
}

export function formatERC20(value: string, decimals: number | string): string {
  const DECIMAL_PLACES = typeof decimals === 'string' ? parseInt(decimals, 10) : decimals
  const bn = BigNumber.clone({ DECIMAL_PLACES })
  return new bn(value).div(10 ** DECIMAL_PLACES).toString()
}

export function isEthBlock(block: any): block is FormattedBlock {
  if (block == null || typeof block === 'string') {
    return false
  }
  return block.transactionsRoot !== undefined
}

export function isEthTx(tx: any): tx is AttributedTransaction {
  if (tx == null || typeof tx === 'string') {
    return false
  }
  return tx.hash !== undefined
}

export function isEthAttr(attr: any): attr is EthAttribution {
  if (attr == null || typeof attr === 'string') {
    return false
  }
  return attr.address !== undefined
}

export function reduceEthValue(sum: string | number, value: string | number): string {
  return new BigNumber(sum).plus(new BigNumber(value).div(10 ** ETHER_DECIMALS)).toFixed(3)
}

export function reduceGasValue(sum: string | number, value: string | number): string {
  return new BigNumber(sum).plus(new BigNumber(value)).toString()
}

export function gasToEth(gas: string, gasPrice: string, gasUsed: string): string {
  return trimZeros(
    new BigNumber(gasUsed)
      .times(new BigNumber(gasPrice))
      .div(10 ** ETHER_DECIMALS)
      .toString()
  )
}

export function formatEth(value: string | number): string {
  return `${trimZeros(formatNumber(value, 18))} ETH`
}

export function formatGas(gas: string, gasPrice: string, gasUsed: string): string {
  return `${trimZeros(gasToEth(gas, gasPrice, gasUsed))} ETH`
}

interface DecodedWyvernMatch {
  initiatorIsBuyer: boolean
  initiatorIsSeller: boolean
  nftFrom: string
  nftTo: string
  payment: string
}

export function decodeWyvernAtomicMatch(
  initiator: string,
  call: FunctionCall | undefined,
  receipts: FormattedLogEvent[],
  ethValue: string | number
): DecodedWyvernMatch | undefined {
  if (call == null) {
    return undefined
  }
  /**
   * call.params[0].value - 14 addresses
   * buy side
   *  0: exchange
   *  1: maker
   *  2: taker
   *  3: fee recipient
   *  4: target
   *  5: static target
   *  6: ERC20 address
   * sell side
   *  7: exchange
   *  8: maker
   *  9: taker
   *  10: fee recipient
   *  11: target
   *  12: static target
   *  13: ERC20 address
   *
   * call.params[1].value - 18 uints (uint256)
   * buy side
   *  0: makerRelayerFee
   *  1: takerRelayerFee
   *  2: makerProtocolFee
   *  3: takerProtocolFee
   *  4: base price of order (in payment tokens)
   *  5: extra - minimum bid increment for English auctions, starting/ending price difference
   *  6: listing timestamp
   *  7: expiration timestamp (0 = no expiry)
   *  8: salt - to prevent duplicate hashes
   * sell side
   *  9: makerRelayerFee
   *  10: takerRelayerFee
   *  11: makerProtocolFee
   *  12: takerProtocolFee
   *  13: base price of order (in payment tokens)
   *  14: extra - minimum bid increment for English auctions, starting/ending price difference
   *  15: listing timestamp
   *  16: expiration timestamp (0 = no expiry)
   *  17: salt - to prevent duplicate hashes
   *
   * call.params[2].value - 8 uints (uint8)
   * buy side
   *  0: fee method - 0 for protocol token, 1 for split fee
   *  1: sale side - 0 for buy, 1 for sell
   *  2: sale kind - 0 for fixed price, 1 for dutch auction
   *  3: how to call - 0 for call, 1 for delegate call (delegate call is via proxy)
   * sell side
   *  4: fee method - 0 for protocol token, 1 for split fee
   *  5: sale side - 0 for buy, 1 for sell
   *  6: sale kind - 0 for fixed price, 1 for dutch auction
   *  7: how to call - 0 for call, 1 for delegate call (delegate call is via proxy)
   *
   * call.params[3].value - call data buy (bytes)
   * call.params[4].value - call data sell (bytes)
   * call.params[5].value - replacement pattern buy (bytes)
   * call.params[6].value - replacement pattern sell (bytes)
   * call.params[7].value - static call extra data buy (bytes)
   * call.params[8].value - static call extra data sell (bytes)
   */
  const zeroAddress = '0x0000000000000000000000000000000000000000'
  const addresses = call.params[0].value as string[]
  const uints = call.params[1].value as Array<string | number>
  const feesSidesKindsCalls = call.params[2].value as number[]
  // buy side
  const buyExchange = addresses[0]
  const buyMaker = addresses[1]
  const buyMakerRelayerFee = uints[0]
  const buyMakerProtocolFee = uints[2]
  const buyTaker = addresses[2]
  const buyTakerRelayerFee = uints[1]
  const buyTakerProtocolFee = uints[3]
  const buyFeeRecipient = addresses[3]
  const buyTarget = addresses[4]
  const buyStaticTarget = addresses[5]
  const buyTokenAddress = addresses[6]

  const buyOrderBasePrice = uints[4]
  const buyExtra = uints[5] // minimum bid increment for English aunctions
  const buyOrderTimestamp = uints[6]
  const buyOrderExpiration = uints[7]

  const buyFeeMethod = feesSidesKindsCalls[0]
  const buySide = feesSidesKindsCalls[1]
  const buyAuctionKind = feesSidesKindsCalls[2]
  const buyCall = feesSidesKindsCalls[3]

  // sell side
  const sellExchange = addresses[7]
  const sellMaker = addresses[8]
  const sellMakerRelayerFee = uints[9]
  const sellMakerProtocolFee = uints[11]
  const sellTaker = addresses[9]
  const sellTakerRelayerFee = uints[10]
  const sellTakerProtocolFee = uints[12]
  const sellFeeRecipient = addresses[10]
  const sellTarget = addresses[11]
  const sellStaticTarget = addresses[12]
  const sellTokenAddress = addresses[13]

  const sellOrderBasePrice = uints[13]
  const sellExtra = uints[14]
  const sellOrderTimestamp = uints[15]
  const sellOrderExpiration = uints[16]

  const sellFeeMethod = feesSidesKindsCalls[4]
  const sellSide = feesSidesKindsCalls[5]
  const sellAuctionKind = feesSidesKindsCalls[6]
  const sellCall = feesSidesKindsCalls[7]
  // this means it was up for sale
  const initiatorIsBuyer = initiator === buyMaker && buyTaker === sellMaker
  // this means there was a bid that the seller accepted
  const initiatorIsSeller = initiator === sellMaker && buyMaker === sellTaker
  // sell maker is always where the nft comes from
  const nftFrom = sellMaker
  // buy maker is always where the nft goes to
  const nftTo = buyMaker

  const buyWithToken = buyTokenAddress !== zeroAddress
  const sellForToken = sellTokenAddress !== zeroAddress
  const saleInTokens = buyWithToken
  const erc20Transfers = filter(
    receipts,
    (r) =>
      r.event != null &&
      r.event.signature === 'Transfer(address,address,uint256)' &&
      r.event.params[2].name !== '_tokenId' &&
      r.event.params[2].name !== 'id'
  )
  if (saleInTokens && erc20Transfers.length === 0) {
    console.log('sale in tokens, no erc20 transfers')
    console.log(receipts)
    return undefined
  }

  const isFixedPriceAuction = buyAuctionKind === 0 || sellAuctionKind === 0
  const isDutchAuction = buyAuctionKind === 1 || sellAuctionKind === 1

  // const tokenDecimals = erc20Transfers[0].addressInfo
  const formattedPayment = (): string => {
    if (saleInTokens) {
      const firstErc20Transfer = erc20Transfers[0]
      if (isContractInfo(firstErc20Transfer.addressInfo) && isTokenProps(firstErc20Transfer.addressInfo.properties)) {
        const decimals = firstErc20Transfer.addressInfo.properties.decimals!
        const symbol = firstErc20Transfer.addressInfo.properties.symbol
        return `${trimZeros(formatERC20(buyOrderBasePrice as string, decimals))} ${symbol}`
      }
      // this might mean we're not catching an erc20 during data ingest
      return ''
    }
    return trimZeros(formatEth(ethValue))
  }

  return {
    initiatorIsBuyer,
    initiatorIsSeller,
    nftFrom,
    nftTo,
    payment: formattedPayment()
  }
}

export function isNftTransfer(call: FunctionCall | EventData | undefined): boolean {
  if (call == null) {
    return false
  }
  return (
    call.signature === 'safeTransferFrom(address,address,uint256,bytes)' ||
    call.signature === 'safeTransferFrom(address,address,uint256)' ||
    call.signature === 'transferFrom(address,address,uint256)' ||
    (call.signature === 'Transfer(address,address,uint256)' && call.params[2].name === '_tokenId') ||
    (call.signature === 'Transfer(address,address,uint256)' && call.params[2].name === 'id')
  )
}

export function isNftTransferSingle(call: FunctionCall | EventData | undefined): boolean {
  if (call == null) {
    return false
  }
  return call.signature === 'TransferSingle(address,address,address,uint256,uint256)'
}

export function isNftOrderMatch(call: FunctionCall | EventData | undefined): boolean {
  if (call == null) {
    return false
  }
  return call.signature === 'OrdersMatched(bytes32,bytes32,address,address,uint256,bytes32)'
}

export function isAtomicMatch(call: FunctionCall | EventData | undefined): boolean {
  if (call == null) {
    return false
  }
  return (
    call.signature ===
    'atomicMatch_(address[14],uint256[18],uint8[8],bytes,bytes,bytes,bytes,bytes,bytes,uint8[2],bytes32[5])'
  )
}

export function isErc20Transfer(
  call: FunctionCall | EventData | undefined,
  info: ContractInfo | AddressInfo | undefined
): boolean {
  if (call == null || info == null) {
    return false
  }
  if (
    (call.signature === 'transfer(address,uint256)' ||
      (call.signature === 'Transfer(address,address,uint256)' && call.params[2].name === 'wad')) &&
    isContractInfo(info) &&
    isTokenProps(info.properties) &&
    hasStandards(info.contractType)
  ) {
    return !!info.contractType?.standards?.includes('ERC20')
  }
  return false
}

export function isErc20To(call: FunctionCall | EventData): boolean {
  return call.signature === 'transfer(address,uint256)'
}

export function isErc20FromTo(call: FunctionCall | EventData): boolean {
  return call.signature === 'Transfer(address,address,uint256)'
}

export function getNftInfo(
  call: FunctionCall | EventData | undefined,
  to: ContractInfo | AddressInfo | undefined
): NftInfo | undefined {
  if (to == null || call == null) {
    return undefined
  }
  if (isNftTransfer(call) && isContractInfo(to) && isTokenProps(to.properties)) {
    const tokenId = call.params[2].value
    const recipient = call.params[1].value
    const owner = call.params[0].value
    return {
      name: to.properties.name,
      tokenId: <string | number>tokenId,
      owner: <string>owner,
      recipient: <string>recipient
    }
  }
  if (
    call.signature === 'TransferSingle(address,address,address,uint256,uint256)' &&
    isContractInfo(to) &&
    isTokenProps(to.properties)
  ) {
    const tokenId = call.params[3].value
    const recipient = call.params[2].value
    const owner = call.params[1].value
    return {
      name: to.properties.name,
      tokenId: <string | number>tokenId,
      owner: <string>owner,
      recipient: <string>recipient
    }
  }
  return undefined
}

export function displayName(a: string, p: ContractInfo, c?: string | null, full?: boolean) {
  if (!a) {
    if (c) {
      return '(contract created)'
    }
    return '(no address)'
  }
  if (!p) {
    return full ? a : cutMiddle(a, 8)
  }
  const contractName = p.isContract && isTokenProps(p.properties) ? p.properties.name : undefined
  const contractType = p.isContract && isContractType(p.contractType) ? p.contractType.name : undefined
  const contractStandards = hasStandards(p.contractType) ? p.contractType?.standards!.join(', ') : undefined

  if (contractName && contractType && contractStandards) {
    return `${contractName} - ${contractType} (${contractStandards})`
  }
  if (contractName && contractType) {
    return `${contractName} - ${contractType}`
  }
  if (contractName) {
    return contractName
  }
  if (contractStandards) {
    return `(${contractStandards})`
  }
  if (p.isContract) {
    return `${full ? a : cutMiddle(a)} (contract)`
  }
  return full ? a : cutMiddle(a, 8)
}

export function filterDisplayName(tx: FormattedTransaction) {
  const p = tx.toInfo as ContractInfo
  if (p) {
    const contractName = p.isContract && isTokenProps(p.properties) ? p.properties.name : undefined
    const contractType = p.isContract && isContractType(p.contractType) ? p.contractType.name : undefined
    const contractStandards = hasStandards(p.contractType) ? p.contractType?.standards!.join(', ') : undefined

    if (contractName && contractType && contractStandards) {
      return `${contractName} - ${contractType} (${contractStandards})`
    }
    if (contractName && contractType) {
      return `${contractName} - ${contractType}`
    }
    if (contractName) {
      return contractName
    }
    if (contractStandards) {
      return `(${contractStandards})`
    }
  }
  return tx.to ?? '(new contract)'
}

export function wyvernAtomicMatchDisplay(txn: FormattedTransaction, receipts: FormattedLogEvent[]): string | undefined {
  const { call } = txn
  if (isAtomicMatch(call)) {
    const filtered = filter(receipts, (r) => r.transactionHash === txn.hash)
    const nftTransfer = filter(filtered, (r) => isNftTransfer(r.event))
    const nftTransferSingle = filter(filtered, (r) => isNftTransferSingle(r.event))
    const nftOrderMatch = filter(filtered, (r) => isNftOrderMatch(r.event))
    const decoded = decodeWyvernAtomicMatch(txn.from, call, filtered, txn.value)
    console.log(decoded)
    if (nftTransfer.length > 0 && nftTransfer[0].event && decoded) {
      const nftInfo = getNftInfo(nftTransfer[0].event, nftTransfer[0].addressInfo)
      const nft = nftInfo ? `${nftInfo.name} NFT ID ${nftInfo.tokenId}` : 'a NFT'
      if (decoded.initiatorIsBuyer) {
        return `${cutMiddle(decoded.nftTo)} bought ${nft} from ${cutMiddle(decoded.nftFrom)} for ${decoded.payment}`
      }
      return `${cutMiddle(decoded.nftFrom)} sold ${nft} to ${cutMiddle(decoded.nftTo)} for ${decoded.payment}`
    }
    if (nftTransferSingle.length > 0 && nftTransferSingle[0].event && decoded) {
      const nftInfo = getNftInfo(nftTransferSingle[0].event, nftTransferSingle[0].addressInfo!)
      const nft = nftInfo ? `${nftInfo.name} NFT ID ${nftInfo.tokenId}` : 'a NFT'
      if (decoded.initiatorIsBuyer) {
        return `${cutMiddle(decoded.nftTo)} bought ${nft} from ${cutMiddle(decoded.nftFrom)} for ${decoded.payment}`
      }
      return `${cutMiddle(decoded.nftFrom)} sold ${nft} to ${cutMiddle(decoded.nftTo)} for ${decoded.payment}`
    }
  }
  return undefined
}
