import { ClusterMetadata, Attribution } from './api'

export const COLOR_BLACK = 0x000000
export const COLOR_BLACKISH = 0x0f0f0f
export const COLOR_WHITE = 0xffffff
export const COLOR_LIGHT_GRAY = 0xd3d3d3
export const COLOR_VERY_LIGHT_GRAY = 0xe6e6e6
export const COLOR_NEON_RED = 0xff3131
export const COLOR_ORANGE = 0xffa500
export const COLOR_GOLD = 0xd3af37
export const COLOR_GREEN = 0x00E676//0x00ff00
export const COLOR_BLUE = 0x42A5F5//0x0000ff
export const COLOR_YELLOW = 0xFFD600//0xffff00
export const COLOR_RED = 0xC62828//0xff0000
export const COLOR_POSTIT = 0xE6F2A2

export const DEFAULT_RISK_COLOR = COLOR_BLUE
export const NO_RISK_COLOR = COLOR_GREEN
export const LOW_RISK_COLOR = COLOR_YELLOW
export const HIGH_RISK_COLOR = COLOR_RED
export const COLOR_BLACK_STR = '#000000'
export const DEFAULT_RISK_COLOR_STR = '#42A5F5'//'#0000FF'
export const NO_RISK_COLOR_STR = '#00E676'//'#00FF00'
export const LOW_RISK_COLOR_STR = '#FFD600'//'#FFFF00'
export const HIGH_RISK_COLOR_STR = '#C62828'//'#FF0000'

const DEFAULT_RISK_COLOR_CLASS = 'blue lighten-1 white--text rounded-xl px-2'
const NO_RISK_COLOR_CLASS = 'green accent-3 rounded-xl px-2'
const LOW_RISK_COLOR_CLASS = 'yellow accent-4 rounded-xl px-2'
const HIGH_RISK_COLOR_CLASS = 'red darken-3 white--text rounded-xl px-2'

export const MAP_MARKER_COLOR = '#1976d2'

export const MAP_NODE_COLOR_BTC = '#f9931a'
export const MAP_ELECTRUM_SERVER_COLOR_BTC = '#9638ff'
export const MAP_ELECTRUM_USER_COLOR_BTC = '#0056c0'
export const MAP_ELECTRUM_TXN_COLOR_BTC = '#18ff00'
export const MAP_ELECTRUM_WALLET_COLOR_BTC = '#ff0000'
export const MAP_ELECTRUM_WATCHLIST_COLOR_BTC = '#000000'

export const MAP_NODE_COLOR_LTC = '#c66000'
export const MAP_ELECTRUM_SERVER_COLOR_LTC = '#6305cc'
export const MAP_ELECTRUM_USER_COLOR_LTC = '#0056c0'
export const MAP_ELECTRUM_TXN_COLOR_LTC = '#00cc00'
export const MAP_ELECTRUM_WALLET_COLOR_LTC = '#ff0000'
export const MAP_ELECTRUM_WATCHLIST_COLOR_LTC = '#000000'

export const LINK_COLOR_SWATCHES = [
  [
    COLOR_BLACK_STR,
    DEFAULT_RISK_COLOR_STR,
    NO_RISK_COLOR_STR,
    LOW_RISK_COLOR_STR,
    HIGH_RISK_COLOR_STR
  ],
  [
    '#D0B8AC', // pale dogwood
    '#F3D8C7', // champagne pink
    '#EFE5DC', // linen
    '#157A6E', // pine green
    '#499F68', // shamrock green
  ],
  [
    '#AD343E', // cardinal
    '#A379C9', // lavender
    '#F2AF29', // xanthous (dark yellow)
    '#7D8CC4', // glacous (light lavender)
    '#A0D2DB', // non photo blue (light teal)
  ],
  [
    '#A8763E', // copper
    '#D17A22', // ochre
    '#9046CF', // blue violet
    '#88A0A8', // cadet gray
    '#FDF5BF', // lemon chiffon
  ]
]

export function attributionCategoryColor(
  attributions: Attribution[] | null,
  categoryScores: { [category: string]: number }
) {
  let risk = -1
  if (attributions != null && attributions.length > 0) {
    for (const attribution of attributions) {
      for (const category of attribution.categories) {
        const categoryRisk = categoryScores[category]
        risk = risk < categoryRisk ? categoryRisk : risk
      }
    }
  }
  return riskColor(risk)
}

export function clusterCategoryColor(cluster: ClusterMetadata | null, categoryScores: { [category: string]: number }) {
  let risk = -1
  if (cluster != null) {
    const { topCategory } = cluster
    if (topCategory) {
      const categoryRisk = categoryScores[topCategory]
      risk = risk < categoryRisk ? categoryRisk : risk
    }
  }
  return riskColor(risk)
}

export function clusterCategoryColorString(
  cluster: ClusterMetadata | null,
  categoryScores: { [category: string]: number }
) {
  let risk = -1
  if (cluster) {
    const { topCategory } = cluster
    if (topCategory) {
      const categoryRisk = categoryScores[topCategory]
      risk = risk < categoryRisk ? categoryRisk : risk
    }
  }
  return riskColorString(risk)
}

export function categoryColor(
  category: string,
  categoryScores: { [category: string]: number },
  string: boolean = false,
  templateClass: boolean = false
) {
  let risk = -1
  const categoryRisk = categoryScores[category]
  risk = risk < categoryRisk ? categoryRisk : risk
  if (templateClass) {
    return riskColorClass(risk)
  }
  return string ? riskColorString(risk) : riskColor(risk)
}

function riskColorClass(risk: number): string {
  switch (risk) {
    case -1:
      return DEFAULT_RISK_COLOR_CLASS
    case 0:
      return NO_RISK_COLOR_CLASS
    case 1:
      return LOW_RISK_COLOR_CLASS
    default:
      return HIGH_RISK_COLOR_CLASS
  }
}

function riskColor(risk: number): number {
  switch (risk) {
    case -1:
      return DEFAULT_RISK_COLOR
    case 0:
      return NO_RISK_COLOR
    case 1:
      return LOW_RISK_COLOR
    default:
      return HIGH_RISK_COLOR
  }
}

function riskColorString(risk: number): string {
  switch (risk) {
    case -1:
      return DEFAULT_RISK_COLOR_STR
    case 0:
      return NO_RISK_COLOR_STR
    case 1:
      return LOW_RISK_COLOR_STR
    default:
      return HIGH_RISK_COLOR_STR
  }
}

export function convertColorToNumeric(color: string | number): number {
  if (typeof color === 'number') {
    return color
  }
  if (color.includes('#')) {
    return parseInt(color.replace('#', '0'), 16)
  }
  if (color.includes('rgb(')) {
    const rgb = color.replace('rgb(', '').replace(')', '').split(',')
    const hex = rgb
      .map((c) => {
        const value = parseInt(c, 10).toString(16)
        return value.length === 1 ? '0' + value : value
      })
      .join('')
    return parseInt(`0x${hex}`, 16)
  }
  // else is rgba - ignore the a(lpha) value
  const rgb = color.replace('rgba(', '').replace(')', '').split(',')
  const hex = rgb
    .map((c) => {
      const value = parseInt(c, 10).toString(16)
      return value.length === 1 ? '0' + value : value
    })
    .join('')
  return parseInt(`0x${hex}`, 16)
}
