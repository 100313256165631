
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { OutputReference } from '@/store/chain'
import { ExtendedInput, ExtendedTx, isExtendedCoinbase, Output, TxHeader } from '@/types/bitcoin'
import { isDust } from '@/utils/bitcoin'
import { copyToClipboard, cutMiddle } from '@/utils/general'
import { ClusterMetadata, Attribution } from '@/utils/api'
import { mapState } from 'vuex'
import { LRUCache } from '@splunkdlt/cache'
import { FormattedLink, SettingsCollection } from '@/store/investigations/viz'
import { nodeTypeToIdString } from '@/utils/viz'
import { filter } from '@/utils/filters'

@Component({
  computed: mapState(['foundInputs', 'transaction'])
})
export default class BtcCounterpartyOutputCard extends Vue {
  public cutMiddle = cutMiddle
  public isDust = isDust
  public clusterId: string = ''
  public attribution = ''
  public changeOutput = false
  public heuristics = {
    haveHeader: false,
    rbf: false,
    locktime: 0,
    version: 0,
    witness: false,
    multisig: ''
  }
  private nextInput = ''

  private addressNode = ''
  private clusterNode = ''
  private graphAddressButton = {
    color: '',
    icon: '',
    text: ''
  }
  private graphClusterButton = {
    color: '',
    icon: '',
    text: ''
  }

  @Prop() item!: Output
  @Prop() txid!: string
  @Prop() decimalFormatter!: (n: number | string) => string

  public foundInputs!: LRUCache<string, ExtendedInput>
  public transaction!: ExtendedTx

  get unspentOutputs(): OutputReference[] {
    return this.$store.state.unspentOutputs
  }

  get shortenedMultisig() {
    const { type } = this.item.scriptPubKey
    if (type.startsWith('ms')) {
      const [numerator, denominator] = type.split('/')
      return `${numerator.slice(-1)}/${denominator.slice(0, 1)}`
    }
    return ''
  }

  created() {
    this.setAttribution()
    this.setChange()
    this.updateNextHop()
    this.txnHeadersCacheUpdated()
    this.updateGraphed()
  }

  public cardClasses(hover: boolean): string {
    if (hover) {
      return 'hover'
    }
    return ''
  }

  public getAttributions(address: string): Attribution[] {
    const has = this.$store.state.shared.attributionsCache.has(address)
    return has ? this.$store.state.shared.attributionsCache.get(address) : []
  }

  public getAttribution(address: string): string {
    const attributions = this.getAttributions(address)
    if (attributions.length > 0) {
      const valueSet = new Set(attributions.flatMap((a) => a.attributions))
      if (valueSet.size > 0) {
        return `${Array.from(valueSet).join(', ')}`
      }
    }
    return ''
  }

  public getCluster(address: string): ClusterMetadata | null {
    const has = this.$store.state.shared.clusterAddressCache.has(address)
    return has ? this.$store.state.shared.clusterAddressCache.get(address) : null
  }

  public getClusterAttribution(address: string): { attribution?: string; clusterId?: string } {
    const cluster = this.getCluster(address)
    if (cluster != null) {
      const { id, topAttribution } = cluster
      if (topAttribution != null && topAttribution !== '') {
        return {
          attribution: topAttribution,
          clusterId: id
        }
      }
      return {
        clusterId: cluster.id
      }
    }
    this.clusterId = ''
    return {}
  }

  public getLabel(attribution: string, cluster: { attribution?: string; clusterId?: string }): string {
    if (cluster.attribution != null && cluster.attribution !== '') {
      return cluster.attribution
    }
    if (attribution !== '') {
      return attribution
    }
    if (cluster.clusterId != null && cluster.clusterId !== '') {
      return cutMiddle(cluster.clusterId, 9)
    }
    return ''
  }

  public setAttribution() {
    const { address } = this.item.scriptPubKey
    if (address != null && address !== '') {
      this.addressNode = nodeTypeToIdString({ id: address, type: 'address', network: 'bitcoin' })
      const { clusterId, attribution: clusterAttribution } = this.getClusterAttribution(address)
      this.clusterId = clusterId ?? ''
      this.clusterNode = clusterId ? nodeTypeToIdString(
        clusterAttribution ? { id: clusterAttribution, type: 'attribution', network: 'bitcoin' }
        : { id: clusterId, type: 'cluster', network: 'bitcoin' }
      ) : ''
      const attribution = this.getAttribution(address)
      this.attribution = this.getLabel(attribution, { clusterId, attribution: clusterAttribution })
    }
  }

  public setChange() {
    if (this.transaction != null) {
      const inputs = this.transaction.vin
      for (const input of inputs) {
        if (!isExtendedCoinbase(input)) {
          const { address } = input.spentOutput
          if (address != null && address !== '') {
            const clusterAttribution = this.getClusterAttribution(address)
            const attribution = this.getAttribution(address)
            if (
              (this.attribution !== '' && this.getLabel(attribution, clusterAttribution) === this.attribution) ||
              (address === this.item.scriptPubKey.address)
            ) {
              this.changeOutput = true
              return
            }
          }
        }
      }
    }
  }

  private findSpend(txid: string, n: number) {
    this.$store.dispatch('findInput', { network: 'bitcoin', previousOutput: txid, vout: n })
  }

  private updateNextHop() {
    this.findSpend(this.txid, this.item.n)
    this.foundSpend()
  }

  public copy(text: string | undefined) {
    if (text != null) {
      copyToClipboard(text)
      this.$store.dispatch('updateSnackbar', { show: true, text: `Copied ${text} to clipboard.` })
    }
  }

  private async toggleClusterGraphed() {
    const cp = this.clusterNode
    if (this.graphClusterButton.text.startsWith('Graph')) {
      this.$store.dispatch('graphCounterparty', { network: 'bitcoin', cp, cpIsOutput: true })
    } else {
      this.removeCp(cp)
    }
  }

  private toggleAddressGraphed() {
    const { value } = this.item
    const cp = this.addressNode
    if (this.graphAddressButton.text.startsWith('Graph')) {
      this.$store.dispatch('graphCounterparty', { network: 'bitcoin', cp, value, cpIsOutput: true })
    } else {
      this.removeCp(cp)
    }
  }

  private removeCp(cp: string) {
    const linkSelected = this.$store.state.selectedLink != null || this.$store.state.target.type === 'transaction'
    const txnNode = nodeTypeToIdString({ type: 'transaction', id: this.txid, network: 'bitcoin' })

    if (!linkSelected) this.$store.dispatch('removeNodes', { ids: [cp] })
    else this.$store.dispatch('removeLinks', { links: [`${txnNode}||${cp}`] })
  }

  @Watch('$store.state.foundInputsCount')
  private async foundSpend() {
    const id = `${this.txid}_${this.item.n}`
    const input: ExtendedInput | null = this.foundInputs.get(id)
    if (input != null) {
      this.nextInput = input.txid
    }
  }

  @Watch('$store.state.txnHeadersCacheCount')
  private txnHeadersCacheUpdated() {
    if (this.$store.state.txnHeadersCache.has(this.nextInput)) {
      const h: TxHeader = this.$store.state.txnHeadersCache.get(this.nextInput)
      this.heuristics.locktime = h.locktime
      this.heuristics.version = h.version
      this.heuristics.witness = h.segwit
      this.heuristics.rbf = h.rbf
      this.heuristics.multisig = this.shortenedMultisig
      this.heuristics.haveHeader = true
    }
  }

  @Watch('$store.state.formattedLinks')
  @Watch('$store.state.formattedSummaryLinks')
  @Watch('$store.state.settings', { deep: true })
  private updateGraphed() {
    const { txnNodeSwitch } = this.$store.state.settings as SettingsCollection
    const linkSelected = this.$store.state.selectedLink != null || this.$store.state.target.type === 'transaction'
    const links = linkSelected ?
      new Set(
        filter(this.$store.state.formattedLinks, (l: FormattedLink) => !!l.permanent)
        .map((l: FormattedLink) => `${l.source.id}${l.target.id}`)
      ) : txnNodeSwitch ?
      new Set(this.$store.state.formattedLinks.map((l: FormattedLink) => `${l.source.id}${l.target.id}`)) :
      new Set(this.$store.state.formattedSummaryLinks.map((l: FormattedLink) => `${l.source.id}${l.target.id}`))
    const opposingNode = (txnNodeSwitch || linkSelected) ?
      nodeTypeToIdString({ type: 'transaction', id: this.txid, network: 'bitcoin' }) :
      this.$store.state.target ? nodeTypeToIdString(this.$store.state.target) : ''

    if (this.clusterNode !== '' && (this.clusterNode === opposingNode || links.has(`${opposingNode}${this.clusterNode}`))) {
      this.graphClusterButton = {
        color: 'warning',
        icon: linkSelected ? 'mdi-vector-line' : 'mdi-vector-polyline-minus',
        text: `Ungraph Cluster${linkSelected ? ' Output' : ''}`
      }
    } else {
      this.graphClusterButton = {
        color: 'primary',
        icon: linkSelected ? 'mdi-vector-line' : 'mdi-vector-polyline-plus',
        text: `Graph Cluster${linkSelected ? ' Output' : ''}`
      }
    }

    if (this.addressNode !== '' && (this.addressNode === opposingNode || links.has(`${opposingNode}${this.addressNode}`))) {
      this.graphAddressButton = {
        color: 'warning',
        icon: linkSelected ? 'mdi-vector-line' : 'mdi-vector-polyline-minus',
        text: `Ungraph Address${linkSelected ? ' Output' : ''}`
      }
    } else {
      this.graphAddressButton = {
        color: 'gray darken-3',
        icon: linkSelected ? 'mdi-vector-line' : 'mdi-vector-polyline-plus',
        text: `Graph Address${linkSelected ? ' Output' : ''}`
      }
    }
  }
}
