
import { Component, Prop, Vue } from 'vue-property-decorator'
import BtcInputCard from '@/subcomponents/BtcInputCard.vue'
import BtcOutputCard from '@/subcomponents/BtcOutputCard.vue'
import DataIteratorSearch from '@/subcomponents/DataIteratorSearch.vue'
import {
  ExtendedCoinbase,
  ExtendedInput,
  ExtendedOutput,
  ExtendedTx,
  isExtendedCoinbase,
  Output
} from '@/types/bitcoin'
import { customSearch, NestedSearchKeys, NestedStringNumberMap } from '@/utils/general'
import SortBar, { SortResponse } from '@/subcomponents/SortBar.vue'
import { IOItem } from '@/store/clusters'

@Component({
  components: {
    DataIteratorSearch,
    BtcInputCard,
    BtcOutputCard,
    SortBar
  }
})
export default class BtcIOList extends Vue {
  @Prop() mode!: 'input' | 'output' | 'mixed'
  @Prop() sortFields!: string[]

  @Prop() network!: string
  @Prop() transaction!: ExtendedTx
  @Prop() data!: Array<ExtendedInput | ExtendedOutput | Output | IOItem | ExtendedCoinbase>
  @Prop() color!: string
  @Prop() searchIndex!: string
  public search: string = ''
  public searchInput: string = ''
  public updateCount: number = 0
  private userInput: boolean = false
  private searchIndexInternal: string = ''
  private searchKeys: Array<string | NestedSearchKeys> = [
    'previousOutput',
    { spentOutput: ['address', 'value', 'height', 'type', 'hex', 'asm'] }
  ]

  private sortBy: Array<string> = []
  private sortDesc: Array<number> = []
  private allActive = false

  get label() {
    if (this.mode === 'input') {
      return 'Inputs'
    }
    if (this.mode === 'output') {
      return 'Outputs'
    }
    if (this.mode === 'mixed') {
      return 'Ledger'
    }
  }

  get items() {
    if (this.mode === 'input') {
      return this.inputs
    }
    if (this.mode === 'output') {
      return this.outputs
    }
    if (this.mode === 'mixed') {
      return this.data
    }
    return []
  }

  get inputs() {
    if (this.data != null && this.mode === 'input') {
      return (<Array<ExtendedInput | ExtendedCoinbase>>this.data).map((i: ExtendedInput | ExtendedCoinbase) => ({
        ...i,
        // for lazy load. override when sort changes otherwise cards are hidden
        isActive: this.allActive,
        // for sorts
        value: isExtendedCoinbase(i) ? i.value : i.spentOutput.value,
        age: isExtendedCoinbase(i) ? -this.transaction.time : -i.spentOutput.time,
        address: isExtendedCoinbase(i) ? 'block reward' : i.spentOutput.address
      }))
    }
    return []
  }

  get outputs() {
    if (this.data != null && this.mode === 'output') {
      return (<Output[]>this.data).map((o: ExtendedOutput | Output) => ({
        ...o,
        // for lazy load. override when sort changes otherwise cards are hidden
        isActive: this.allActive,
        // for sorts
        address: o.scriptPubKey.address
      }))
    }
    return []
  }

  get selectedIndex() {
    if (this.searchIndexInternal != null && this.searchIndexInternal.startsWith('#')) {
      try {
        const index = parseInt(this.searchIndexInternal.replace('#', ''), 10)
        return index
      } catch (e) {
        console.warn('could not parse output # to select', this.searchIndexInternal)
      }
    }
    return -1
  }

  created() {
    if (this.searchIndex != null && this.searchIndex.startsWith('#')) {
      this.searchInput = this.searchIndex
    }
  }

  txid(transaction: ExtendedTx | undefined, item: IOItem | undefined): string {
    if (transaction != null) {
      return transaction.txid
    }
    if (item != null) {
      return item.id
    }
    return ''
  }

  mounted() {
    this.scrollToCard()
  }

  public onUserInput() {
    this.userInput = true
  }

  public scrollToCard() {
    if (this.selectedIndex >= 0) {
      this.$vuetify.goTo(this.selectedIndex * 105, {
        container: this.$refs.list as Vue,
        duration: 500,
        offset: 0,
        easing: 'easeInOutCubic'
      })
    }
  }

  public customFilter<T, K extends NestedStringNumberMap & T>(items: K[], search: string): T[] {
    return customSearch(items, search, this.searchKeys, 'inputs')
  }

  public updateSort(sort: SortResponse) {
    const { sortBy, sortDesc } = sort
    this.sortBy = sortBy
    this.sortDesc = sortDesc
    this.allActive = true
    this.updateCount++
  }

  public updateSearchModel(str: string) {
    if (str.startsWith('#') && str.length > 1) {
      this.searchIndexInternal = str
      if (this.userInput) {
        this.scrollToCard()
      }
    } else if (str.startsWith('#') && str.length === 1) {
      this.searchIndexInternal = ''
    } else {
      this.searchIndexInternal = ''
      this.search = str
    }
  }
}
