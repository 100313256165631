import { Commit, Dispatch } from 'vuex'
import { LRUCache } from '@splunkdlt/cache'
import { State } from './index'
import { ClusterMetadata, Api, Attribution, AttributionMap } from '@/utils/api'
import { filter } from '@/utils/filters'
import { ServerEvent } from '@/utils/general'

export const sharedState = {
  attributionsCache: new LRUCache<string, Attribution[]>({ maxSize: 2000 }),
  attributionsCacheUpdated: <number>0,
  clusterAddressCache: new LRUCache<string, ClusterMetadata>({ maxSize: 5000 }),
  clusterIdCache: new LRUCache<string, ClusterMetadata>({ maxSize: 1000 }),
  clusterAddressesUpdated: <number>0,
  attributionClustersCache: new LRUCache<string, { [network: string]: ClusterMetadata }>({ maxSize: 200 }),
  attributionClustersUpdated: <number>0,
  attributionSizeCache: new LRUCache<string, { [network: string]: number }>({ maxSize: 200 }),
  attributionSizesUpdated: <number>0,
  attributionSizesNotLoaded: new Set<string>()
}

export const sharedMutations = {
  ATTRIBUTIONS_CACHE_ADD(state: State, { attributions }: { attributions: AttributionMap }) {
    const addresses = Object.keys(attributions)
    for (const address of addresses) {
      state.shared.attributionsCache.set(address, attributions[address])
    }
    state.shared.attributionsCacheUpdated++
  },
  CLUSTER_CACHE_ADD(state: State, { address, cluster }: { address: string; cluster: ClusterMetadata }) {
    state.shared.clusterAddressCache.set(address, cluster)
    state.shared.clusterAddressesUpdated++
  },
  ATTRIBUTION_CLUSTER_CACHE_ADD(
    state: State,
    { attribution, network, cluster }: { attribution: string; network: string; cluster: ClusterMetadata }
  ) {
    const networkMap = state.shared.attributionClustersCache.get(attribution)
    if (networkMap != null) {
      networkMap[network] = cluster
    } else {
      state.shared.attributionClustersCache.set(attribution, { [network]: cluster })
    }
    state.shared.attributionClustersUpdated++
  },
  ATTRIBUTION_SIZE_CACHE_ADD(
    state: State,
    { attribution, network, size }: { attribution: string; network: string; size: number }
  ) {
    const networkMap = state.shared.attributionSizeCache.get(attribution)
    if (networkMap != null) {
      networkMap[network] = size
    } else {
      state.shared.attributionSizeCache.set(attribution, { [network]: size })
    }
    state.shared.attributionSizesUpdated++
  },
  ATTRIBUTION_SIZE_NOT_LOADED(state: State, { attribution, network }: { attribution: string; network: string }) {
    state.shared.attributionSizesNotLoaded.add(`${attribution}||${network}`)
  },
  ATTRIBUTION_SIZE_LOADED(state: State, { attribution, network }: { attribution: string; network: string }) {
    state.shared.attributionSizesNotLoaded.delete(`${attribution}||${network}`)
  }
}

export const sharedActions = (state: State, api: Api, dispatch: Dispatch) => ({
  async getAttributions({ commit }: { commit: Commit }, { addresses }: { addresses: string[] }) {
    const unattributed = filter(addresses, (a) => state.shared.attributionsCache.get(a) == null)
    const attributions = await api.getAttributions(unattributed)
    if (attributions) {
      commit('ATTRIBUTIONS_CACHE_ADD', { attributions })
    }
  },
  setAttributionSizeFromEvent({ commit }: { commit: Commit }, { event }: { event: ServerEvent }) {
    const { data: size, requestedUrl } = event
    const [,,,network,,attribution] = requestedUrl.split('/')
    if (attribution != null && attribution !== '' && size > 0) {
      commit('ATTRIBUTION_SIZE_CACHE_ADD', { attribution, network, size })
      commit('ATTRIBUTION_SIZE_LOADED', { attribution, network })
    }
  }
})
