const loadFont = require('load-bmfont')
const createLayout = require('layout-bmfont-text')
const createIndices = require('quad-indices')

import { filter } from './filters'
import { Geometry, Shader, Texture, Mesh } from 'pixi.js'

interface Font {
  pages: string[]
  chars: FontChar[]
  kernings: Kerning[]
  info: FontInfo
  common: FontCommon
}

interface FontChar {
  id: number
  x: number
  y: number
  width: number
  height: number
  xoffset: number
  yoffset: number
  xadvance: number
  page: number
  chnl: number
}

interface Kerning {
  first: number
  second: number
  amount: number
}

interface FontInfo {
  face: string
  size: number
  bold: number
  italic: number
  charset: string
  unicode: number
  stretchH: number
  smooth: number
  aa: number
  padding: number[]
  spacing: number[]
}

interface FontCommon {
  lineHeight: number
  base: number
  scaleW: number
  scaleH: number
  pages: number
  packed: number
  alphaChnl: number
  redChnl: number
  greenChnl: number
  blueChnl: number
}

interface CreateLayoutInputs {
  font: Font
  text: string
  width?: number
  mode?: 'pre' | 'nowrap' //default normal word wrap
  align?: 'left' | 'center' | 'right' //default left
  letterSpacing?: number //default 0
  lineHeight?: number //default font.common.lineHeight
  tabSize?: number //default 4
  start?: number //default 0
  end?: number //default text.length
}

interface Layout {
  glyphs: Glyph[]
  width: number
  height: number
  baseline: number
  xHeight: number
  descender: number
  ascender: number
  capHeight: number
  lineHeight: number
}

interface Glyph {
  index: number
  data: FontChar
  position: number[]
  line: number
}

async function loadFNT(): Promise<Font> {
  return new Promise((resolve) => {
    loadFont('helvetica_neue_en.fnt', (error: Error, font: Font) => {
      if (error) throw error
      resolve(font)
    })
  })
}

function getPositions(glyphs: Glyph[]): Float32Array {
  const positions = new Float32Array(glyphs.length * 8)
  let i = 0
  glyphs.forEach(function (glyph) {
    const { xoffset, yoffset, width, height } = glyph.data

    // bottom left position
    var x = glyph.position[0] + xoffset
    var y = glyph.position[1] + yoffset

    // quad size
    var w = width
    var h = height

    // BL
    positions[i++] = x
    positions[i++] = y
    // TL
    positions[i++] = x
    positions[i++] = y + h
    // TR
    positions[i++] = x + w
    positions[i++] = y + h
    // BR
    positions[i++] = x + w
    positions[i++] = y
  })
  return positions
}

function getUvs(glyphs: Glyph[], textWidth: number, textHeight: number, flipY: boolean): Float32Array {
  const uvs = new Float32Array(glyphs.length * 8)
  let i = 0
  glyphs.forEach(function (glyph) {
    const { x, y, width, height } = glyph.data
    const bw = x + width
    const bh = y + height

    // top left position
    const u0 = x / textWidth
    const v1 = flipY ? (textHeight - y) / textHeight : y / textHeight
    const u1 = bw / textWidth
    const v0 = flipY ? (textHeight - bh) / textHeight : bh / textHeight

    // BL
    uvs[i++] = u0
    uvs[i++] = v1
    // TL
    uvs[i++] = u0
    uvs[i++] = v0
    // TR
    uvs[i++] = u1
    uvs[i++] = v0
    // BR
    uvs[i++] = u1
    uvs[i++] = v1
  })
  return uvs
}

export class SDFRenderer {
  private font!: Font

  async init() {
    this.font = await loadFNT()
  }

  static async createInstance() {
    const renderer = new SDFRenderer()
    await renderer.init()
    return renderer
  }

  createMesh(text: string, textLines: number, maxTextLineWidth: number) {
    const layout: Layout = createLayout({
      font: this.font,
      text,
      letterSpacing: 1,
      align: 'left'
    } as CreateLayoutInputs)

    const textWidth = this.font.common.scaleW
    const textHeight = this.font.common.scaleH

    // get visible glyphs
    const glyphs = filter(layout.glyphs, (glyph) => {
      const { width, height } = glyph.data
      return width * height > 0
    })

    // get common vertex data
    const positions = getPositions(glyphs)
    let uvs = getUvs(glyphs, textWidth, textHeight, false)

    const indices = createIndices({
      clockwise: true,
      type: 'uint16',
      count: glyphs.length
    })

    const geometry = new Geometry()

    geometry.addAttribute('position', positions, 2)
    geometry.addAttribute('uv', uvs, 2)
    geometry.addIndex(indices)

    const fragShader = `
            precision mediump float;
            varying vec2 vUv;
            
            uniform sampler2D tSDF;
            uniform vec3 textColor;
            uniform vec3 outlineColor;
            uniform float outlineSize;
            uniform float smoothing;
            uniform float buffer;
            uniform float opacity;

            
            void main() {
                float distance = texture2D(tSDF, vUv).a;
                float alpha = smoothstep(buffer - smoothing, buffer + smoothing, distance);
                float border = smoothstep(buffer + outlineSize - smoothing, buffer + outlineSize + smoothing, distance);
                gl_FragColor = vec4(mix(outlineColor, textColor, border), 1.) * alpha * opacity; // for border
                //gl_FragColor = vec4(textColor, 1) * alpha * opacity; // for no border
            }`

    const vertShader = `
            precision mediump float;
            attribute vec2 position;
            attribute vec2 uv;
            
            varying vec2 vUv;
            
            uniform mat3 translationMatrix;
            uniform mat3 projectionMatrix;
            
            void main() {
                vUv = uv;
                gl_Position = vec4((projectionMatrix * translationMatrix * vec3(position, 1.0)).xy, 0.0, 1.0);
            }`

    const material = Shader.from(vertShader, fragShader, {
      tSDF: Texture.from('helvetica_neue_en.png'),
      textColor: [0.1, 0.1, 0.1],
      outlineColor: [0.1, 0.1, 0.1],
      smoothing: 0.1,
      buffer: 0.5,
      outlineSize: 0,
      opacity: 1,
      drawUV: false,
      drawDistance: false
    })

    const mesh = new Mesh(geometry, material)
    mesh.roundPixels = true
    mesh.cullable = true

    return mesh
  }
}
