import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Network from '@/views/Network.vue'
import Investigations from '@/views/Investigations.vue'
import Accounts from '@/components/accounts/Accounts.vue'
import UserAccount from '@/components/accounts/UserAccount.vue'
import Explorer from '@/views/Explorer.vue'
import ExplorerHome from '@/components/explorer/ExplorerHome.vue'
import Block from '@/components/explorer/Block.vue'
import ExplorerNetwork from '@/components/explorer/Network.vue'
import Transactions from '@/components/explorer/Transactions.vue'
import Transaction from '@/components/explorer/Transaction.vue'
import Address from '@/components/explorer/Address.vue'
import Cluster from '@/components/explorer/Cluster.vue'
import Attribution from '@/components/explorer/Attribution.vue'
import IP from '@/components/explorer/IP.vue'
import Wallet from '@/components/explorer/Wallet.vue'
import NetworkHome from '@/components/network/Network.vue'
import Stats from '@/components/network/Stats.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: '/investigations'
  },
  {
    path: '/investigations',
    name: 'investigations',
    component: Investigations,
    meta: {
      title: 'Heights Labs - Investigations'
    },
    children: [
      {
        path: ':flow',
        name: 'flowInvestigation',
        component: Investigations
      }
    ]
  },
  {
    path: '/network',
    component: Network,
    meta: {
      title: 'Heights Labs - Network'
    },
    children: [
      {
        path: '/',
        name: 'networkhome',
        component: NetworkHome
      },
      {
        path: 'stats',
        name: 'stats',
        component: Stats
      }
    ]
  },
  {
    path: '/explorer',
    component: Explorer,
    meta: {
      title: 'Heights Labs - Explorer'
    },
    children: [
      {
        path: '/',
        name: 'explorerhome',
        component: ExplorerHome
      },
      {
        path: ':network',
        name: 'explorernetwork',
        component: ExplorerNetwork
      },
      {
        path: 'block/:network/:blockId',
        name: 'block',
        component: Block
      },
      {
        path: 'transactions/:network/:blockId',
        redirect: (to) => {
          let perPage = 10
          try {
            const txnsPerPage = window.localStorage.get('txnsPerPage')
            if (txnsPerPage != null) {
              const parsed = parseInt(txnsPerPage, 10)
              if (parsed.toString() === txnsPerPage) {
                perPage = parsed
              }
            }
          } catch (e) {
            console.warn("couldn't parse page count")
          }

          return { path: `transactions/${to.params.network}/${to.params.blockId}/${perPage}/1` }
        }
      },
      {
        path: 'transactions/:network/:blockId/:count/:page',
        name: 'transactions',
        component: Transactions
      },
      {
        path: 'transaction/:network/:txnId',
        name: 'transaction',
        component: Transaction
      },
      {
        path: 'address/:network/:address',
        name: 'address',
        component: Address
      },
      {
        path: 'cluster/:network/:id',
        name: 'cluster',
        component: Cluster
      },
      {
        path: 'attribution/:network/:attribution',
        name: 'attribution',
        component: Attribution
      },
      {
        path: 'ip/:network/:ip',
        name: 'IP Address',
        component: IP
      },
      {
        path: 'wallet/:network/:id',
        name: 'Wallet',
        component: Wallet
      }
    ]
  },
  {
    path: '/accounts',
    name: 'acconts',
    component: Accounts
  },
  {
    path: '/account-settings',
    name: 'Account Settings',
    component: UserAccount
  },
  {
    path: '/register',
    name: 'Register',
    // route level code-splitting
    // this generates a separate chunk (register.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "register" */ '../views/Register.vue'),
    meta: {
      title: 'Heights Labs - Register'
    }
  },
  {
    path: '/securityalert',
    name: 'securityalert',
    // route level code-splitting
    // this generates a separate chunk (securityalert.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "securityalert" */ '../views/SecurityAlert.vue'),
    meta: {
      title: 'Heights Labs - Security Alert'
    }
  },
  {
    path: '/forgotpassword',
    name: 'forgotpassword',
    // route level code-splitting
    // this generates a separate chunk (forgotpassword.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "forgotpassword" */ '../views/ForgotPassword.vue'),
    meta: {
      title: 'Heights Labs - Forgot Password'
    }
  },
  {
    path: '/resetpassword',
    name: 'resetpassword',
    // route level code-splitting
    // this generates a separate chunk (resetpassword.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "resetpassword" */ '../views/ResetPassword.vue'),
    meta: {
      title: 'Heights Labs - Reset Password'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title)

  // Find the nearest route element with meta tags.
  // const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)
  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags)

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  // Array.from(document.querySelectorAll('[data-vue-router-controlled]'))
  //   .map(el => (el.parentNode) ? el.parentNode.removeChild(el) : null)

  // // Skip rendering meta tags if there are none.
  // if(!nearestWithMeta) return next()

  // // Turn the meta tag definitions into actual elements in the head.
  // nearestWithMeta.meta.metaTags.map(tagDef => {
  //   const tag = document.createElement('meta')

  //   Object.keys(tagDef).forEach(key => {
  //     tag.setAttribute(key, tagDef[key])
  //   })

  //   // We use this to track which meta tags we create so we don't interfere with other ones.
  //   tag.setAttribute('data-vue-router-controlled', '')
  //   return tag
  // })
  // // Add the meta tags to the document head.
  // .forEach(tag => document.head.appendChild(tag))

  next()
})

export default router
