
import { Component, Prop, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'
import {
  Map as MapBox,
  MapboxOptions,
  Marker,
  NavigationControl
} from 'mapbox-gl'
import { MAP_MARKER_COLOR } from '@/utils/colors'
@Component({
  components: {},
  computed: {
    ...mapState(['mapBoxToken'])
  }
})
export default class MapCard extends Vue {
  private mapBoxToken!: string
  // private mapElement!: HTMLElement
  private map!: MapBox
  private mapOptions = {
    accessToken: '',
    style: "mapbox://styles/mapbox/light-v11",
    center: { lng: 0, lat: 0 },
    zoom: 3,
    projection: {
      name: 'equirectangular'
    }
  }
  @Prop() coords!: { lat: number, lng: number }[]

  async mounted() {
    // this.mapElement = this.$refs.map as HTMLElement
    this.mapOptions.accessToken = this.mapBoxToken
    this.mapOptions.center = { lng: this.coords[0].lng, lat: this.coords[0].lat }
    const options = { container: this.$refs.map as HTMLElement, ...this.mapOptions } as MapboxOptions
    this.map = new MapBox(options)
    this.map.addControl(new NavigationControl())
    this.map.on('load', this.addMarker)
  }

  addMarker() {
    for (const { lat, lng } of this.coords) {
      const marker = new Marker({ color: MAP_MARKER_COLOR })
        .setLngLat([ lng, lat ])
        .addTo(this.map)
    }
  }
}
