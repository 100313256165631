import { FormattedNode, IdType } from "@/store/investigations/viz"
import { classifyNodeId, txnContractName } from "./viz"
import { cutEnd } from "./general"
import { LRUCache } from "@splunkdlt/cache"
import { FormattedLogEvent } from "@/types/eth"
import { Attribution, ClusterMetadata } from "./api"
import { HALF_PI } from "./graph-links"
import { Graphics, Mesh, Shader, Sprite, Texture } from "pixi.js"
import { SDFRenderer } from "./sdf-text"

export const NODE_LABEL_SIZE = 12

export const ENTITY_MIN_SIZE = 1
export const ENTITY_MAX_SIZE = 100000000

export const NODE_MIN_SIZE = 12
export const NODE_MAX_SIZE = 40

export const SQRT_2_OVER_2 = Math.sqrt(2) / 2
export const EIGHTH_PI = HALF_PI / 4

export const OCTAGON_POINTS = [
  1, 0,
  SQRT_2_OVER_2, SQRT_2_OVER_2,
  0, 1,
  -SQRT_2_OVER_2, SQRT_2_OVER_2,
  -1, 0,
  -SQRT_2_OVER_2, -SQRT_2_OVER_2,
  0, -1,
  SQRT_2_OVER_2, -SQRT_2_OVER_2
]

export const ROTATED_OCTAGON_POINTS = OCTAGON_POINTS.map((c, index) => {
  if (index % 2 === 0) { // even, x coord
    return c * Math.cos(EIGHTH_PI) - OCTAGON_POINTS[index + 1] * Math.sin(EIGHTH_PI)
  } else { // odd, y coord
    return OCTAGON_POINTS[index - 1] * Math.sin(EIGHTH_PI) + c * Math.cos(EIGHTH_PI)
  }
})

interface NodeLabels {
  lower: string
  upper?: string
}

export function labelNode(
  node: FormattedNode,
  receiptsCache: LRUCache<string, FormattedLogEvent>,
  attributionsCache: LRUCache<string, Attribution[]>,
  clusterAddressCache: LRUCache<string, ClusterMetadata>,
  fullAddressLabelSwitch: boolean,
  nodeSizeScale: d3.ScaleLinear<number, number, never> | d3.ScaleSymLog<number, number, never>,
  sdf: SDFRenderer,
  nodeLabelSwitch: boolean,
  txnNodeLabelSwitch: boolean
) {
  if (node.gfx != null) {
    if (node.label != null) {
      node.gfx.removeChild(node.label)
      if (node.upperLabel != null) {
        node.gfx.removeChild(node.upperLabel)
      }
    }
    setUpSDFNodeLabel(
      node,
      receiptsCache,
      attributionsCache,
      clusterAddressCache,
      fullAddressLabelSwitch,
      nodeSizeScale,
      sdf
    )
    const { type, id } = classifyNodeId(node.id)
    if (
      node.label != null && nodeLabelSwitch &&
      (
      type !== 'transaction' || 
      txnNodeLabelSwitch ||
      txnContractName(id, receiptsCache) != null
      )
    ) {
      node.gfx.addChild(node.label)
      if (node.upperLabel != null) {
        node.gfx.addChild(node.upperLabel)
      }
    }
  }
}

function setUpSDFNodeLabel(
  node: FormattedNode,
  receiptsCache: LRUCache<string, FormattedLogEvent>,
  attributionsCache: LRUCache<string, Attribution[]>,
  clusterAddressCache: LRUCache<string, ClusterMetadata>,
  fullAddressLabelSwitch: boolean,
  nodeSizeScale: d3.ScaleLinear<number, number, never> | d3.ScaleSymLog<number, number, never>,
  sdf: SDFRenderer
) {
  const { lower, upper } = getNodeLabelText(
    node,
    receiptsCache,
    attributionsCache,
    clusterAddressCache,
    fullAddressLabelSwitch
  )
  const yDisplacement = nodeSizeScale(node.size) + NODE_LABEL_SIZE
  const xDisplacement = 0.25 * NODE_LABEL_SIZE
  // lower label
  const width = lower.length
  const label: Mesh<Shader> = sdf.createMesh(lower, 1, width)
  label.position.set(-width * xDisplacement, yDisplacement)
  label.width = (width * NODE_LABEL_SIZE) / 2
  label.height = NODE_LABEL_SIZE
  node.label = label
  // upper label
  if (upper) {
    const upperWidth = upper.length
    const upperLabel: Mesh<Shader> = sdf.createMesh(upper, 1, upperWidth)
    upperLabel.position.set(-upperWidth * xDisplacement, -yDisplacement)
    upperLabel.width = (upperWidth * NODE_LABEL_SIZE) / 2
    upperLabel.height = NODE_LABEL_SIZE
    node.upperLabel = upperLabel
  } else {
    node.upperLabel = undefined
  }
}

function getNodeLabelText(
  node: FormattedNode,
  receiptsCache: LRUCache<string, FormattedLogEvent>,
  attributionsCache: LRUCache<string, Attribution[]>,
  clusterAddressCache: LRUCache<string, ClusterMetadata>,
  fullAddressLabelSwitch: boolean
): NodeLabels {
  const { id, type, network } = classifyNodeId(node.id)
  if (node.display && type !== 'address') {
    return { lower: node.display }
  }
  if (type === 'attribution') return { lower: id }
  if (type === 'transaction') {
    if (network === 'ethereum') {
      const contractName = txnContractName(id, receiptsCache)
      if (contractName != null) return { lower: contractName }
    }
    if (fullAddressLabelSwitch) {
      return { lower: id }
    } else {
      return { lower: cutEnd(id) }
    }
  }
  if (type) {
    let lower = id
    if (!fullAddressLabelSwitch) {
      lower = cutEnd(id)
    }
    const labels: NodeLabels = { lower }
    if (node.display) {
      labels.upper = node.display
      return labels
    }
    if (type === 'address') {
      const attribution = attributionsCache.get(id)
      if (attribution != null && attribution.length > 0) {
        labels.upper = attribution[0].attributions[0]
        return labels
      }
    }
    const clusterMetadata = clusterAddressCache.get(id)
    if (!clusterMetadata) return labels
    const { topAttribution } = clusterMetadata
    if (topAttribution) {
      labels.upper = topAttribution
    }
    return labels
  }
  return { lower: id }
}

export function attachNodeIcon(
  node: FormattedNode,
  textures: { [name: string]: Texture },
  nodeSizeScale: d3.ScaleLinear<number, number, never> | d3.ScaleSymLog<number, number, never>,
  receiptsCache: LRUCache<string, FormattedLogEvent>
) {
  const { gfx, icon, networkIcon, id: rawId, unspent, size: nodeSize } = node
  if (gfx != null) {
    if (icon != null) {
      gfx.removeChild(icon)
      delete node.icon
    }
    if (networkIcon != null) {
      gfx.removeChild(networkIcon)
      delete node.networkIcon
    }
    const { id, type, network } = classifyNodeId(rawId)
    if (type && type !== 'transaction') {
      // main node icon
      let textureType: string = type
      if (type === 'attribution' && id.startsWith('ext-')) {
        textureType = 'coinbase'
      } else {
        if (type === 'attribution') {
          textureType = 'cluster'
        }
        textureType += 'Dark'
        // const color = nodeColor(id, type, unspent)
        // switch (color) {
        //   case COLOR_WHITE:
        //   case NO_RISK_COLOR:
        //   case LOW_RISK_COLOR:
        //   case HIGH_RISK_COLOR:
        //     textureType += 'Dark'
        // }
      }
      const sprite = Sprite.from(textures[textureType])
      sprite.zIndex = 3
      let size = nodeSizeScale(nodeSize)
      sprite.width = size
      sprite.height = size
      let position = -size / 2
      sprite.position.set(position, position)
      gfx.addChild(sprite)
      node.icon = sprite

      // network icon
      const networkSprite = Sprite.from(textures[network])
      networkSprite.zIndex = 3
      position += size - 75 / size
      // size /= 2 // size proportional to node
      size = 15 // constant size
      const origHeight = networkSprite.width
      const scale = size/origHeight
      networkSprite.height = size
      networkSprite.width *= scale
      networkSprite.position.set(position, position)
      gfx.addChild(networkSprite)
      node.networkIcon = networkSprite
    } else if (type === 'transaction' && network === 'ethereum') {
      if (txnContractName(id, receiptsCache) != null) {
        const sprite = Sprite.from(textures['contract'])
        sprite.zIndex = 3
        const size = nodeSizeScale(nodeSize)
        sprite.width = size
        sprite.height = size
        const position = -size / 2
        sprite.position.set(position, position)
        gfx.addChild(sprite)
        node.icon = sprite
      }
    }
  }
}

export function drawNode(
  gfx: Graphics,
  borderThickness: number,
  borderColor: number,
  color: number,
  type: IdType,
  id: string,
  size: number,
  unspent?: boolean
) {
  gfx.lineStyle(borderThickness, borderColor)
  gfx.beginFill(color)
  if (type === 'transaction') {
    const mid = size / 2
    gfx.drawRect(-mid, -mid, size, size)
  } else if (unspent) {
    gfx.arc(0, 0, size, HALF_PI, HALF_PI * 3)
  } else if (type === 'attribution' && id.startsWith('ext-')) {
    gfx.drawPolygon(ROTATED_OCTAGON_POINTS.map(c => c * size))
  } else {
    gfx.drawCircle(0, 0, size)
  }
  gfx.endFill()
}