export function filter<T>(array: T[], fn: (i: T) => boolean): T[] {
  const final = []
  for (let i = 0; i < array.length; i++) {
    if (fn(array[i])) {
      final.push(array[i])
    }
  }
  return final
}

export type Filter<T> = {
  [K in keyof T]?: T[K] | T[K][] | Filter<any>
}

function recursiveFilter<T>(item: T, filter: Filter<T>): boolean {
  // if the filter is an array, check if any filter element matches the item or any item element.
  if (Array.isArray(filter)) {
    if (Array.isArray(item)) {
      return item.some((i) => filter.some((f) => recursiveFilter(i, f)))
    } else {
      return filter.some((f) => recursiveFilter(item, f))
    }
  }

  // iterate over each key-value pair in the filter.
  for (const key in filter) {
    if (filter.hasOwnProperty(key)) {
      const filterValue = filter[key as keyof T]
      const itemValue = item[key as keyof T]

      // if the current item value is null or undefined, it doesn't match the filter.
      if (itemValue === null || itemValue === undefined) {
        return false
      }

      // if the filter value is an object (but not an array), recursively check nested properties.
      if (typeof filterValue === 'object' && !(filterValue instanceof Array)) {
        // if the item value is an array, check if any of its elements match the filter.
        if (Array.isArray(itemValue)) {
          if (!itemValue.some((iv) => recursiveFilter(iv, filterValue as Filter<any>))) {
            return false
          }
        } else if (!recursiveFilter(itemValue as any, filterValue as Filter<any>)) {
          return false
        }
      }
      // if the filter value is an array, check if the item value is included in the filter array.
      else if (Array.isArray(filterValue)) {
        if (!filterValue.map((v) => String(v).toLowerCase()).includes(String(itemValue).toLowerCase())) {
          return false
        }
      }
      // direct value comparison.
      else {
        if (typeof itemValue === 'string' && typeof filterValue === 'string') {
          if (itemValue.toLowerCase() !== filterValue.toLowerCase()) {
            return false
          }
        } else if (itemValue !== filterValue) {
          return false
        }
      }
    }
  }
  // if all filter criteria are met, return true.
  return true
}

export function filterObjects<T>(data: T[], filters: (Filter<Partial<T>> | Filter<Partial<T>>[])[]): T[] {
  return data.filter((item) => {
    for (const filter of filters) {
      if (Array.isArray(filter)) {
        if (!filter.some((subFilter) => recursiveFilter(item, subFilter))) {
          return false
        }
      } else {
        if (!recursiveFilter(item, filter)) {
          return false
        }
      }
    }
    return true
  })
}