
import { Component, ProvideReactive, Vue, Watch } from 'vue-property-decorator'
import { ChartData } from '@/subcomponents/types/charts'
import AreaChart from '@/subcomponents/AreaChart.vue'
import MultiChart from '@/subcomponents/MultiChart.vue'

import {
  AssetAndName,
  AssetChartData,
  AssetPriceChartData,
  Chart,
  Color,
  EthStats,
  EthTokenStats,
  Interval,
  Multichart,
  PriceStats
} from './types/explorer'

import { Api } from '@/utils/api'
import { titleCase } from '@/utils/general'
import { filter } from '@/utils/filters'
import { format } from 'd3'
import { NetworkSupportedAsset } from '@/store/chain'

function chartFilter(c: Chart, n: AssetAndName) {
  return c.assetAndName!.name === n.name && c.assetAndName!.asset === n.asset
}

@Component({
  components: {
    AreaChart,
    MultiChart
  }
})
export default class ExplorerHome extends Vue {
  private ethNetworks: string[] = []
  public selectedAssets: string[] = []
  public ethAssets: NetworkSupportedAsset[] = []
  private ethCharts: Chart[] = []
  public ethChartsFiltered: Chart[] = this.ethCharts
  private assetChartData: AssetChartData = {}
  private assetPriceChartData: AssetPriceChartData = {}
  private api!: Api
  public titleCase = titleCase

  public selectedChartTypes: string[] = []
  public selectedCharts: AssetAndName[] = []
  public charts: Chart[] = [
    { name: 'valueTransferred', title: 'Value Transferred', data: [], lastUpdate: 0, unit: 'ether' },
    { name: 'uniqueSenders', title: 'Unique Senders', data: [], lastUpdate: 0, unit: '' },
    { name: 'uniqueReceivers', title: 'Unique Receivers', data: [], lastUpdate: 0, unit: '' },
    { name: 'transactionCount', title: 'Transaction Count', data: [], lastUpdate: 0, unit: '' },
    { name: 'gasUsed', title: 'Gas Used', data: [], lastUpdate: 0, unit: '' },
    { name: 'avgGasUsed', title: 'Avg Gas Used', data: [], lastUpdate: 0, unit: '' },
    { name: 'cumulativeGasUsed', title: 'Cumulative Gas Used', data: [], lastUpdate: 0, unit: '' },
    { name: 'avgCumulativeGasUsed', title: 'Avg Cumulative Gas Used', data: [], lastUpdate: 0, unit: '' },
    { name: 'avgGasMax', title: 'Avg Gas Max', data: [], lastUpdate: 0, unit: '' },
    { name: 'avgGasPrice', title: 'Avg Gas Price', data: [], lastUpdate: 0, unit: 'gwei' },
    { name: 'nativeFeeUsed', title: 'Native Fee Used', data: [], lastUpdate: 0, unit: 'ether' },
    { name: 'nativeFeeCumulativeUsed', title: 'Cumulative Native Fee Used', data: [], lastUpdate: 0, unit: 'ether' }
  ]
  public tokenOnlyCharts: Chart[] = [
    { name: 'methodsCalled', title: 'Methods Called', data: [], lastUpdate: 0, unit: '' }, // only tokens
    { name: 'abiTransfers', title: 'ABI Transfers', data: [], lastUpdate: 0, unit: '' } // only tokens
  ]
  public ethOnlyCharts: Chart[] = [
    { name: 'contractCalls', title: 'Contract Calls', data: [], lastUpdate: 0, unit: '' }, // only eth
    { name: 'contractsCreated', title: 'Contracts Created', data: [], lastUpdate: 0, unit: '' }, // only eth
    { name: 'nativeFeeMax', title: 'Max Native Fee', data: [], lastUpdate: 0, unit: 'ether' }, // only eth
    { name: 'volume', title: 'Volume', data: [], lastUpdate: 0, unit: 'ether' }, // only eth
    { name: 'uncles', title: 'Uncles', data: [], lastUpdate: 0, unit: '' } // only eth
    // {name: 'size', title: 'Block Size', data: [], lastUpdate: 0}
  ]
  public priceCharts: Chart[] = [
    { name: 'open', title: 'Avg Market Open', data: [], lastUpdate: 0, unit: 'USD' },
    { name: 'close', title: 'Avg Market Close', data: [], lastUpdate: 0, unit: 'USD' },
    { name: 'high', title: 'Market High', data: [], lastUpdate: 0, unit: 'USD' },
    { name: 'low', title: 'Market Low', data: [], lastUpdate: 0, unit: 'USD' },
    { name: 'volumeFrom', title: 'Volume From (to USD)', data: [], lastUpdate: 0, unit: 'ether' },
    { name: 'volumeTo', title: 'Volume To (from USD)', data: [], lastUpdate: 0, unit: 'USD' }
  ]
  public intervals: Interval[] = [
    { value: '1 min', label: 'minute' },
    { value: '1 hour', label: 'hour' },
    { value: '1 day', label: 'day' },
    { value: '1 week', label: 'week' },
    { value: '30 day', label: 'month' },
    { value: '365 day', label: 'year' }
  ]
  public selectedInterval: string = this.intervals[this.intervals.length - 2].value
  public startDateMenu: boolean = false
  public startDate: string = ''
  get formattedStartDate() {
    return this.formatDate(this.startDate)
  }
  public endDateMenu: boolean = false
  public endDate: string = ''
  get formattedEndDate() {
    return this.formatDate(this.endDate)
  }
  public startTimeMenu: boolean = false
  public startTime: string = ''
  public endTimeMenu: boolean = false
  public endTime: string = ''
  public loadingData: boolean = false
  private colors: Color[] = [
    { text: 'blue', value: 0x0000ff },
    { text: 'green', value: 0x00ff00 },
    { text: 'red', value: 0xff0000 }
  ]
  public dialog: boolean = false
  public multichartSelections: AssetAndName[] = []
  public multichartColors: number[] = []
  public chartsToDisplay: Chart[] = []
  public multichartsToDisplay: Multichart[] = []
  @ProvideReactive() chartWidth: number = 350
  @ProvideReactive() chartHeight: number = 200

  async mounted() {
    this.ethNetworks = this.$store.state.ethereumNetworks

    this.api = new Api()

    for (const network of this.ethNetworks) {
      this.$store.dispatch('getSupportedStats', { network })
    }

    // generate default charts
    // await Promise.any(this.ethAssets.map(a => this.getChartData(a)))

    // // store attributions for later
    // await Promise.all(this.ethNetworks.map(n => this.api.getAllAttributions(n)))
  }

  @Watch('$store.state.statsSupportedNetworks')
  private updateSupportedStats() {
    this.ethAssets = this.$store.state.statsSupportedNetworks as NetworkSupportedAsset[]
    for (const asset of this.ethAssets) {
      this.addAssetCharts(asset)
    }
    this.filterCharts()
  }

  async getChartData(asset: NetworkSupportedAsset) {
    await Promise.all([
      this.getAssetData(asset, this.selectedInterval, new Date(0), new Date(Date.now())),
      this.getPriceData(asset, this.selectedInterval, new Date(0), new Date(Date.now()))
    ])

    this.addAssetCharts(asset)
  }

  async getAssetData({ network, asset, symbol }: NetworkSupportedAsset, interval: string, start: Date, end: Date) {
    const assetData = await this.api.getStats({
      network,
      asset,
      interval,
      prices: false,
      start: Math.floor(start.getTime() / 1000.0),
      end: Math.floor(end.getTime() / 1000.0)
    })
    // const assetData = <any>[]

    if (symbol.toLowerCase() === 'eth') {
      this.assetChartData[asset] = assetData
        .map((data: any): EthStats => {
          return {
            bucket: new Date(data.bucket),
            transactionCount: parseInt(data.sum_transactioncount, 10),
            gasUsed: parseInt(data.sum_gasused, 10),
            avgGasUsed: parseFloat(data.avg_gasused),
            avgGasPrice: parseFloat(data.avg_gasprice),
            cumulativeGasUsed: parseInt(data.sum_cumulativegasused, 10),
            avgCumulativeGasUsed: parseFloat(data.avg_cumulativegasused),
            avgGasMax: parseFloat(data.avg_gasmax),
            nativeFeeUsed: parseFloat(data.sum_nativefeeused),
            nativeFeeCumulativeUsed: parseFloat(data.sum_nativefeecumulativeused),
            nativeFeeMax: parseFloat(data.sum_nativefeemax),
            valueTransferred: parseFloat(data.sum_valuetransferred),
            uniqueSenders: parseInt(data.sum_uniquesenders, 10),
            uniqueReceivers: parseInt(data.sum_uniquereceivers, 10),
            contractCalls: parseInt(data.sum_contractcalls, 10),
            contractsCreated: parseInt(data.sum_contracts_created, 10),
            volume: parseFloat(data.sum_volume),
            uncles: parseInt(data.sum_unclescount, 10)
          }
        })
        .reverse()
    } else {
      this.assetChartData[asset] = assetData
        .map((data: any): EthTokenStats => {
          return {
            bucket: new Date(data.bucket),
            transactionCount: parseInt(data.sum_transactioncount, 10),
            gasUsed: parseInt(data.sum_gasused, 10),
            avgGasUsed: parseFloat(data.avg_gasused),
            avgGasPrice: parseFloat(data.avg_gasprice),
            cumulativeGasUsed: parseInt(data.sum_cumulativegasused, 10),
            avgCumulativeGasUsed: parseFloat(data.avg_cumulativegasused),
            avgGasMax: parseFloat(data.avg_gasmax),
            nativeFeeUsed: parseFloat(data.sum_nativefeeused),
            nativeFeeCumulativeUsed: parseFloat(data.sum_nativefeecumulativeused),
            valueTransferred: parseFloat(data.sum_valuetransferred),
            uniqueSenders: parseInt(data.sum_uniquesenders, 10),
            uniqueReceivers: parseInt(data.sum_uniquereceivers, 10),
            methodsCalled: parseInt(data.sum_methodscalled, 10),
            abiTransfers: parseInt(data.sum_abi_transfer, 10)
          }
        })
        .reverse()
    }
  }

  async getPriceData({ network, asset, prices }: NetworkSupportedAsset, interval: string, start: Date, end: Date) {
    if (prices) {
      // const assetData = await this.api.getStats({
      //   network,
      //   asset,
      //   interval,
      //   prices: true
      //   start: Math.floor(start.getTime()/1000.0),
      //   end: Math.floor(end.getTime()/1000.0)
      // })
      const priceData = <any>[]

      this.assetPriceChartData[asset] = priceData
        .map((data: any): PriceStats => {
          return {
            bucket: new Date(data.bucket),
            open: parseFloat(data.avg_open),
            close: parseFloat(data.avg_close),
            high: parseFloat(data.max_high),
            low: parseFloat(data.min_low),
            volumeFrom: parseFloat(data.sum_from),
            volumeTo: parseFloat(data.sum_to)
          }
        })
        .reverse()
    }
  }

  addAssetCharts({ network, asset, symbol, prices }: NetworkSupportedAsset) {
    this.ethCharts.push(
      ...this.charts.map((c: any): Chart => {
        const { name, title, data, lastUpdate, unit } = c
        return {
          name,
          title: `${symbol} ${title}`,
          data,
          lastUpdate,
          unit,
          assetAndName: {
            name: name!,
            asset: asset
          }
        }
      })
    )

    if (symbol.toLowerCase() === 'eth') {
      this.ethCharts.push(
        ...this.ethOnlyCharts.map((c: any): Chart => {
          const { name, title, data, lastUpdate, unit } = c
          return {
            name,
            title: `${symbol} ${title}`,
            data,
            lastUpdate,
            unit,
            assetAndName: {
              name: name!,
              asset: asset
            }
          }
        })
      )
    } else {
      this.ethCharts.push(
        ...this.tokenOnlyCharts.map((c: any): Chart => {
          const { name, title, data, lastUpdate, unit } = c
          return {
            name,
            title: `${symbol} ${title}`,
            data,
            lastUpdate,
            unit,
            assetAndName: {
              name: name!,
              asset: asset
            }
          }
        })
      )
    }

    if (prices) {
      this.ethCharts.push(
        ...this.priceCharts.map((c: any): Chart => {
          const { name, title, data, lastUpdate, unit } = c
          return {
            name,
            title: `${symbol} ${title}`,
            data,
            lastUpdate,
            unit,
            assetAndName: {
              name: name!,
              asset: asset
            }
          }
        })
      )
    }
  }

  filterCharts() {
    const selectedAssets = this.selectedAssets.length ? this.selectedAssets : this.ethAssets.map((a) => a.asset)
    const selectedChartTypes = this.selectedChartTypes.length
      ? this.selectedChartTypes
      : [...this.charts, ...this.ethOnlyCharts, ...this.tokenOnlyCharts, ...this.priceCharts].map((c) => c.name)

    this.ethChartsFiltered = this.ethCharts.filter(
      (c: Chart) =>
        selectedAssets.includes(c.assetAndName?.asset!) && selectedChartTypes.includes(c.assetAndName?.name!)
    )
    this.chartsToDisplay = this.chartsToDisplay.filter(
      (c: Chart) =>
        selectedAssets.includes(c.assetAndName?.asset!) && selectedChartTypes.includes(c.assetAndName?.name!)
    )
  }

  async updateSelectedCharts() {}

  async updateCharts() {
    // await this.updateData()
    this.chartsToDisplay = this.selectedCharts.map((a) => {
      const { name, title, lastUpdate, unit, assetAndName } = this.ethCharts.find((c) => chartFilter(c, a))!
      const update = lastUpdate > 0 ? lastUpdate : Date.now()
      return {
        name,
        title,
        assetAndName,
        lastUpdate: update,
        unit,
        data: this.mapData(a, unit)
      }
    })
  }

  mapData(assetAndName: AssetAndName, unit: string): ChartData[] {
    const { asset, name } = assetAndName
    const formatValue = format('0,.4f')
    let chartData: any[] = this.assetChartData[asset]
    if (this.isPriceStat(name)) chartData = this.assetPriceChartData[asset]
    return chartData.map((stats: any): ChartData => {
      const value = isNaN(stats[name]) ? 0 : stats[name]
      return {
        date: stats.bucket,
        value,
        tooltip: {
          items: [
            { label: 'Date', value: stats.bucket.toLocaleString() },
            { label: 'Value', value: `${formatValue(value)} ${unit}` }
          ]
        }
      }
    })
  }

  isPriceStat(name: string): boolean {
    const priceStats = this.priceCharts.map((c) => c.name)
    return priceStats.includes(name)
  }

  chartKey(c: Chart): string {
    const { assetAndName, lastUpdate } = c
    const { name, asset } = assetAndName!
    return `${asset}-${name}-${this.chartHeight}-${this.chartWidth}-${lastUpdate}}`
  }

  deleteChart(chart: Chart) {
    const chartSelectComponent = this.$refs.selectChart as any
    chartSelectComponent.selectItem(chart)
  }

  async updateData() {
    const startDate = this.startDate ? this.startDate : new Date(0).toISOString().substring(0, 10)
    const startTime = this.startTime ? this.startTime : '00:00'
    const currentDateTime = new Date(Date.now()).toISOString()
    const endDate = this.endDate ? this.endDate : currentDateTime.substring(0, 10)
    const endTime = this.endTime ? this.endTime : currentDateTime.substr(11, 5)
    console.log(this.selectedCharts)
    this.chartsToDisplay = []
    if (this.selectedCharts.length) this.loadingData = true
    const selected = filter(this.ethAssets, (a) => this.selectedCharts.map((s) => s.asset).includes(a.asset))
    console.log(selected)
    await Promise.all([
      ...selected.map((asset) =>
        this.getAssetData(
          asset,
          this.selectedInterval,
          this.getDateFromStrings(startDate, startTime),
          this.getDateFromStrings(endDate, endTime)
        )
      )
      // ...this.ethAssets.map(asset =>
      //   this.getPriceData(
      //     asset,
      //     this.selectedInterval,
      //     this.getDateFromStrings(startDate, startTime),
      //     this.getDateFromStrings(endDate, endTime)
      //   )
      // )
    ])
    this.loadingData = false
    this.updateCharts()
  }

  availableCharts(index: number) {
    return [
      ...this.ethChartsFiltered.filter((c) => !this.multichartSelections.slice(0, index).includes(c.assetAndName!))
    ]
  }

  availableColors(index: number) {
    return [...this.colors.filter((c) => !this.multichartColors.slice(0, index).includes(c.value))]
  }

  initializeMultichartOpts() {
    if (!this.multichartSelections.length) this.addToMultichart(0)
  }

  addToMultichart(index: number) {
    this.multichartSelections.push(this.availableCharts(index)[0].assetAndName!)
    this.multichartColors.push(this.availableColors(index)[0].value)
  }

  displayMultichart() {
    this.dialog = false

    this.loadingData = true

    const startDate = this.startDate ? this.startDate : new Date(0).toISOString().substring(0, 10)
    const startTime = this.startTime ? this.startTime : '00:00'
    const currentDateTime = new Date(Date.now()).toISOString()
    const endDate = this.endDate ? this.endDate : currentDateTime.substring(0, 10)
    const endTime = this.endTime ? this.endTime : currentDateTime.substr(11, 5)

    const names: string[] = []
    const titles: string[] = []
    const assetAndNames: AssetAndName[] = []
    let aggLastUpdate = 0
    const units: string[] = []
    const aggData: ChartData[][] = []
    const lineColors: number[] = []
    const fillColors: number[] = []

    const selected = filter(this.ethAssets, (a) => this.selectedCharts.map((s) => s.asset).includes(a.asset))

    this.multichartSelections.forEach(async (a, index) => {
      const networkSupportedAsset = this.ethAssets.find((asset) => asset.asset === a.asset)
      await this.getAssetData(
        networkSupportedAsset!,
        this.selectedInterval,
        this.getDateFromStrings(startDate, startTime),
        this.getDateFromStrings(endDate, endTime)
      )
      const { name, title, lastUpdate, unit, assetAndName } = this.ethCharts.find((c) => chartFilter(c, a))!
      const update = lastUpdate > 0 ? lastUpdate : Date.now()
      names.push(name!)
      titles.push(title)
      assetAndNames.push(assetAndName!)
      aggLastUpdate = update
      units.push(unit)
      aggData.push(this.mapData(a, unit))
      lineColors.push(this.multichartColors[index])
      fillColors.push(0xe9eff5)
    })

    this.loadingData = false

    this.multichartsToDisplay.push({
      names,
      titles,
      assetAndNames,
      lastUpdate: aggLastUpdate,
      units,
      data: aggData,
      lineColors,
      fillColors
    })
  }

  multichartKey(m: Multichart): string {
    const { assetAndNames, lastUpdate } = m
    const names = assetAndNames.map((a) => a.name)
    const assets = assetAndNames.map((a) => a.asset)
    return `${assets.join()}-${names.join()}-${this.chartHeight}-${this.chartWidth}-${lastUpdate}`
  }

  deleteMultichart(index: number) {
    this.multichartsToDisplay.splice(index, 1)
  }

  formatDate(date: string) {
    if (!date) return ''
    const [year, month, day] = date.split('-')
    return `${month}/${day}/${year}`
  }

  getDateFromStrings(dateString: string, timeString: string): Date {
    return new Date(`${dateString}T${timeString}:00.000Z`)
  }

  handleMenuInput(opened: boolean) {
    if (!opened) {
      this.updateData()
    }
  }

  startTimePickerSave() {
    // TODO: figure out how to type this
    // https://github.com/vuetifyjs/vuetify/blob/master/packages/vuetify/src/mixins/returnable/index.ts
    const startTimeMenu = this.$refs.startTimeMenu as any
    startTimeMenu.save(this.startTime)
  }

  endTimePickerSave() {
    const endTimeMenu = this.$refs.endTimeMenu as any
    endTimeMenu.save(this.endTime)
  }
}
