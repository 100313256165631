
import { Component, Prop, ProvideReactive, Vue } from 'vue-property-decorator'
import { DirectiveOptions } from 'vue'
import * as d3 from 'd3'
import * as PIXI from 'pixi.js'
import { Viewport } from 'pixi-viewport'

import { TooltipItems, ChartData, Point } from './types/charts'

const xAxisDirective: DirectiveOptions = {
  inserted(el, binding) {
    const axis = binding.value
    // @ts-ignore
    d3.select(el).call(axis).selectAll('text').attr('transform', 'rotate(315)').style('text-anchor', 'end')
  }
}

const yAxisDirective: DirectiveOptions = {
  inserted(el, binding) {
    const axis = binding.value
    // @ts-ignore
    d3.select(el).call(axis)
  },
  componentUpdated(el, binding) {
    const axis = binding.value
    // @ts-ignore
    d3.select(el).transition().call(axis)
  }
}

@Component({
  directives: {
    xAxisDirective,
    yAxisDirective
  }
})
export default class MultiChart extends Vue {
  @Prop() graphData!: ChartData[][]
  @Prop() chartTitles!: string
  @Prop() height!: number
  @Prop() width!: number
  @Prop() axisPadding!: number
  @Prop() fillColors!: number[]
  @Prop() lineColors!: number[]
  @Prop() yScaleType!: string
  private topPadding: number = 35
  private chartHeight: number = 0
  private chartWidth: number = 0
  private scaleToggle: string = ''
  private viewport!: any
  private xScale!: d3.ScaleTime<number, number, never>
  private yScaleLinear!: d3.ScaleLinear<number, number, never>
  private yScaleLog!: d3.ScaleLogarithmic<number, number, never>
  private yScale!: d3.ScaleLinear<number, number, never> | d3.ScaleLogarithmic<number, number, never>
  private yDomain: any
  private nearestPoints: Point[] = this.graphData.map(() => {
    return { x: 0, y: 0 }
  })
  @ProvideReactive() private axisLeft: any = null
  private axisBottom: any = null
  private areas: any[] = []
  private lines: any[] = []
  private areaGraphics: PIXI.Graphics[] = []
  private lineGraphics: PIXI.Graphics[] = []
  @ProvideReactive() tooltipTitles: string[] = []
  @ProvideReactive() tooltipItems: TooltipItems[][] = []
  private mouseInChart: boolean = false

  mounted() {
    this.chartHeight = this.height - this.axisPadding
    this.chartWidth = this.width - this.axisPadding
    const graphContainer = this.$refs.graphContainer as Element

    const xDomain: any = d3.extent(this.graphData.flat(), (d) => d.date)
    this.xScale = d3.scaleTime().range([0, this.chartWidth]).domain(xDomain)

    this.yDomain = d3.extent(this.graphData.flat(), (d) => d.value)
    if (this.yScaleType === 'log') {
      this.scaleToggle = this.yScaleType
      this.yScale = d3.scaleLog().range([this.chartHeight, 0]).domain(this.yDomain)
    } else {
      // linear is default
      this.scaleToggle = 'linear'
      this.yScale = d3.scaleLinear().range([this.chartHeight, 0]).domain(this.yDomain)
    }

    this.axisLeft = d3.axisLeft(this.yScale).ticks(5, '~s')

    const timeRangeDays = (xDomain[1] - xDomain[0]) / 86400000.0

    var formatDatetime: (date: Date) => string
    if (timeRangeDays < 1.5) formatDatetime = d3.timeFormat('%-I:%M %p')
    else if (timeRangeDays < 5) formatDatetime = d3.timeFormat('%-m/%-d %-I %p')
    else formatDatetime = d3.timeFormat('%x')

    this.axisBottom = d3
      .axisBottom(this.xScale)
      // @ts-ignore
      .tickFormat(formatDatetime)
      .ticks(Math.min(this.graphData.flat().length, 10))

    this.addCanvas(graphContainer)
    for (let index = 0; index < this.graphData.length; index++) {
      this.drawChart(index)
    }
    this.initSvg()
  }

  scaleToggleChange(value: string) {
    if (value === 'log') {
      this.yScale = d3.scaleLog().range([this.chartHeight, 0]).domain(this.yDomain)
    } else {
      // linear is default
      this.yScale = d3.scaleLinear().range([this.chartHeight, 0]).domain(this.yDomain)
    }
    this.axisLeft = d3.axisLeft(this.yScale).ticks(5, '~s')

    for (let index = 0; index < this.graphData.length; index++) {
      this.updateChart(index)
    }
  }

  initSvg() {
    const svg = this.$refs.graphSVG as Element

    svg.addEventListener('mousemove', (e: any) => {
      const mousePoint = d3.pointer(e)
      const lastMouseX = mousePoint[0]
      this.adjustTooltips(lastMouseX)
    })
  }

  adjustTooltips(lastMouseX: number) {
    const closestItems = this.getClosestItems(lastMouseX)
    this.nearestPoints = this.getItemsCoords(closestItems)
    this.tooltipTitles = closestItems.map((i) => i.tooltip.title ?? '')
    this.tooltipItems = closestItems.map((i) => i.tooltip.items)
  }

  getItemsCoords(input: ChartData[]): Point[] {
    return input.map((d) => {
      return { x: this.xScale(d.date), y: this.yScale(d.value) }
    })
  }

  getClosestItems(lastMouseX: number): ChartData[] {
    const bisectDate = d3.bisector((d: ChartData) => d.date).center
    const mouseDate = this.xScale.invert(lastMouseX) //need separate scales?
    let indices = this.graphData.map((data) => bisectDate(data, mouseDate))
    return this.graphData.map((data, index) => data[indices[index]])
  }

  mousevlineStyle(nearestX: number) {
    return `translate(${nearestX},10)`
  }

  addCanvas(container: Element): Viewport {
    const app = new PIXI.Application({
      width: this.chartWidth,
      height: this.chartHeight,
      antialias: true,
      backgroundAlpha: 0,
      resolution: 1,
      view: this.$refs.chartCanvas as HTMLCanvasElement
      // forceCanvas: true
    })
    container.appendChild(app.view as HTMLCanvasElement)

    this.viewport = new Viewport({
      screenWidth: this.chartWidth,
      screenHeight: this.chartHeight,
      events: app.renderer.events
    })
    app.stage.addChild(this.viewport)

    const ticker = PIXI.Ticker.shared
    ticker.autoStart = false
    ticker.stop()
    // console.log(PIXI.utils.isWebGLSupported())
    return this.viewport
  }

  setLine(index: number) {
    this.lines[index] = d3
      .line()
      .x((d: any) => {
        return this.xScale(d.date)
      })
      .y((d: any) => {
        return this.yScale(d.value)
      })
      .curve(d3.curveMonotoneX)
  }

  setArea(index: number) {
    this.areas[index] = d3
      .area()
      .x((d: any) => {
        return this.xScale(d.date)
      })
      .y1((d: any) => {
        return this.yScale(d.value)
      })
      .y0(this.chartHeight)
      .curve(d3.curveMonotoneX)
  }

  drawChart(index: number) {
    // fill area
    this.setArea(index)
    this.addArea(index)
    // draw line on top
    this.setLine(index)
    this.addLine(index)
  }

  addArea(index: number) {
    const areaShape = new PIXI.Graphics()
    this.areaGraphics[index] = areaShape
    this.viewport.addChild(areaShape)
    areaShape.beginFill(this.fillColors[index])
    areaShape.alpha = 0.5
    this.areas[index].context(areaShape)(this.graphData[index])
    areaShape.endFill()
  }

  addLine(index: number) {
    const line = new PIXI.Graphics()
    this.lineGraphics[index] = line
    this.viewport.addChild(line)
    line.lineStyle(2, this.lineColors[index])
    this.lines[index].context(line)(this.graphData[index])
  }

  updateArea(index: number) {
    const areaShape = this.areaGraphics[index]
    areaShape.clear()
    areaShape.beginFill(this.fillColors[index])
    this.areas[index].context(areaShape)(this.graphData[index])
    areaShape.endFill()
  }

  updateLine(index: number) {
    const line = this.lineGraphics[index]
    line.clear()
    line.lineStyle(2, this.lineColors[index])
    this.lines[index].context(line)(this.graphData[index])
  }

  updateChart(index: number) {
    this.setArea(index)
    this.updateArea(index)

    this.setLine(index)
    this.updateLine(index)
  }

  toHexString(value: number): string {
    const unpadded = value.toString(16)
    const padded = unpadded.padStart(6, '0')
    return `#${padded}`
  }
}
