import { sleep } from './general'

export interface CachedValue {
  value: string | number | object
  type: string
}

export class BrowserCache {
  public available: boolean = false
  private cache!: Storage
  private clearingCache: boolean = false

  private keys = {
    shift: false,
    ctrl: false,
    x: false
  }

  constructor(addListeners: boolean = false) {
    if ('localStorage' in window) {
      this.available = true
      this.cache = window.localStorage
      if (addListeners) {
        this.addKeyListeners()
      }
    }
  }

  addKeyListeners() {
    document.addEventListener('keydown', (e: any) => {
      if (e.shiftKey) {
        this.keys.shift = true
        this.clear()
      }
      if (e.ctrlKey) {
        this.keys.ctrl = true
        this.clear()
      }
      if (e.code === 'KeyX') {
        this.keys.x = true
        this.clear()
      }
    })
    document.addEventListener('keyup', (e: any) => {
      if (e.shiftKey) {
        this.keys.shift = false
      }
      if (e.ctrlKey) {
        this.keys.ctrl = false
      }
      if (e.code === 'KeyX') {
        this.keys.x = false
      }
    })
  }

  async clear() {
    if (this.keys.shift && this.keys.ctrl && this.keys.x && !this.clearingCache) {
      this.clearingCache = true
      this.cache.clear()
      await sleep(3000)
      console.log('cache cleared')
      this.clearingCache = false
    }
  }

  get<R>(key: string): R | null {
    if (!this.available) {
      return null
    }
    const cached = this.cache.getItem(key)
    if (cached != null) {
      const cachedValue: CachedValue = JSON.parse(cached)
      return cachedValue as any as R
    }
    return null
  }

  add(key: string, value: string | object | number | boolean): void {
    if (this.available) {
      this.cache.setItem(key, JSON.stringify(value))
    }
  }
}
