import { render, staticRenderFns } from "./BtcItem.vue?vue&type=template&id=595a8f2a&scoped=true&"
import script from "./BtcItem.vue?vue&type=script&lang=ts&"
export * from "./BtcItem.vue?vue&type=script&lang=ts&"
import style0 from "./BtcItem.vue?vue&type=style&index=0&id=595a8f2a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "595a8f2a",
  null
  
)

export default component.exports