
import { Component, Vue, Watch } from 'vue-property-decorator'
import EntityTransactions from '@/subcomponents/EntityTransactions.vue'
import { Target } from '@/store/investigations/viz'
import { mapState } from 'vuex'

@Component({
  components: {
    EntityTransactions
  },
  computed: {
    ...mapState(['target'])
  }
})
export default class Transactions extends Vue {
  // from store
  public target!: Target | undefined

  @Watch('target')
  handleTargetChange() {
    this.$forceUpdate()
  }
}
