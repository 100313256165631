import {
  scaleLog,
  ScaleOrdinal,
  scaleOrdinal,
  ScaleLogarithmic,
  schemeSet2,
  treemap,
  treemapBinary,
  treemapResquarify,
  treemapSliceDice,
  treemapSquarify,
  HierarchyNode,
  HierarchyRectangularNode,
  schemeRdYlGn,
  hierarchy
} from 'd3'
import { EventEmitter } from 'events'
import { Viewport } from 'pixi-viewport'
import { Application, Graphics, Text } from 'pixi.js'
import { sum } from './bignum'
import { categoryColor, convertColorToNumeric, DEFAULT_RISK_COLOR_STR } from './colors'
import { chunkArray, titleCase, prettyRoundedNumber } from './general'
import { WebGLContext } from './graph'

interface Leaf {
  leaf: HierarchyRectangularNode<MapChild>
  gfx: Graphics
  color: string
  label: Text
}

export interface MapLeaf {
  name: string
  value: number
  [key: string]: number | string
}

export interface MapBranch {
  name: string
  valueLinear?: number | string
  children: MapChildren
}

function isMapBranch(obj: any): obj is HierarchyRectangularNode<MapBranch> {
  if (obj == null) {
    return false
  }
  return (obj.children as MapBranch) != null
}

function isMapBranchArray(arr: any): arr is MapBranch[] {
  if (arr == null || !Array.isArray(arr)) {
    return false
  }
  if (arr.length > 0) {
    if (isMapBranch(arr[0])) {
      return true
    }
  }
  return false
}

export type MapChild = MapBranch | MapLeaf
export type MapChildren = Array<MapChild>

export interface MapData {
  name: string
  valueLinear?: number
  children: MapChildren
}

export interface DrawLeafParams {
  el: Leaf
  leaf: HierarchyRectangularNode<MapChild>
  graph: Application
}

export interface Pos {
  x: number
  y: number
}

export type Direction = 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight'

export interface RenderItem<T> {
  params: T
  renderFunction: (params: T) => ((params: T) => void) | undefined
}

export interface TreemapParams extends WebGLContext {
  updateDepth?: (d: number, l: string) => void
  categoryScores: { [category: string]: number }
}

export interface LogScale {
  min: number
  max: number
}

// export function filterMapData(input: MapData, metric: string, threshold: number = 0.5): MapData | undefined {
//   const inputSum = input.children.reduce((s: string, item: MapBranch) => {
//     const subValues = item.children.map(
//       (c: MapLeaf) => c[metric] != null && (typeof c[metric] === 'number' || typeof c[metric] === 'string')
//         ? c[metric] : 0
//     )
//     const subSum = sum(subValues, 18, false)
//     return sum([s, subSum], 18, false) as string
//   }, '0.0')

//   const thresholdAmount = multiply(inputSum, threshold, 18, false)
//   const indexesToKeep: number[][] = []
//   for (const branch of input.children) {
//     const indexes: number[] = []
//     branch.children.forEach((leaf:MapLeaf, index: number) => {
//       const val = leaf[metric]
//       if (greaterThan(val, thresholdAmount)) {
//         indexes.push(index)
//       }
//     })
//     indexesToKeep.push(indexes)
//   }
//   indexesToKeep.forEach((indexes: number[], branchIndex: number) => {
//     const children: MapLeaf[] = []
//     indexes.forEach((i) => {
//       const leaf = input.children[branchIndex].children[i]
//       leaf.value = bignumParse(leaf.value, 16)
//       children.push(leaf)
//     })
//     input.children[branchIndex].children = children
//   })
//   const notEmpty: number[] = []
//   input.children.forEach((branch: MapBranch, index: number) => {
//     if (branch.children.length > 0) {
//       notEmpty.push(index)
//     }
//   })
//   const filtered: MapData = {
//     name: input.name,
//     children: notEmpty.map((index: number) => input.children[index])
//   }
//   if (filtered.children.length > 0) {
//     // return combineBranches(filtered)
//     return filtered
//   }
//   return undefined
// }

// export function filterOutSmallLeaves(data: MapData, prop: string): MapData | undefined {
//   if (data != null) {
//     const filtered = filterMapData(data, prop, 0.0001)
//     if (filtered != null) {
//       return filtered
//     }
//   }
// }

export function arrayRange(start: number, stop: number, step: number): Array<number> {
  return Array.from({ length: (stop - start) / step }, (v, i) => start + i * step)
}

export function getHierarchy(
  data: MapData,
  prop: string,
  logScale?: ScaleLogarithmic<number, number, never>
): HierarchyNode<MapData> {
  // @ts-ignore
  const createdHierarchy = hierarchy(data)
    // @ts-ignore
    .sum((d) => d[prop]) // linear sum
    .eachAfter((n) => {
      if (n.depth === 1) {
        // layer 1 (categories) - we want the log of the sum of the attributions
        if (logScale != null && n.value != null) {
          // @ts-ignore
          n.value = logScale(n.value)
        }
      }
      return n
    })
    .eachAfter((n) => {
      if (n.depth === 0) {
        // layer 0 (not visible) - this is the sum of the categories
        if (logScale != null && n.value != null) {
          // @ts-ignore
          n.value = sum(n.children!.map((c) => c.value))
        }
      }
      return n
    })
    // @ts-ignore
    .sort((a, b) => b[prop] - a[prop])
  return createdHierarchy
}

export function getHierarchyLog(data: MapData, prop: string, scale: LogScale): HierarchyNode<MapData> {
  const linearHierarchy = getHierarchy(data, prop)
  const values = linearHierarchy.children!.map((c: any) => c.value!)
  const total = sum(values, 2) as number
  const end = values[0]
  const start = values[values.length - 1]
  const domain = [start, total]
  const range = [scale.min, scale.max]
  const logScale = scaleLog().domain(domain).range(range)
  return getHierarchy(data, prop, logScale)
}

export function getLayout(algo: string, ratio: number) {
  switch (algo) {
    case 'binary':
      return treemapBinary
    case 'resquarify':
      return treemapResquarify
    case 'ratio':
      return treemapSquarify.ratio(ratio)
    case 'slicedice':
      return treemapSliceDice
    default:
      return treemapBinary
  }
}

export function nextPos(p1: Pos, p2: Pos, direction: Direction): Pos {
  switch (direction) {
    case 'topLeft': // -x, -y
      return { x: p1.x - p2.x, y: p1.y - p2.y }
    case 'topRight': // +x, -y
      return { x: p1.x + p2.x, y: p1.y - p2.y }
    case 'bottomLeft': // -x, +y
      return { x: p1.x - p2.x, y: p1.y + p2.y }
    case 'bottomRight': // +x, +y
      return { x: p1.x + p2.x, y: p1.y + p2.y }
  }
}

export class TreeMap {
  private borderWidth = 1
  private borderColor = 0x000000
  private borderOpacity = 1

  private mapColor = 0x1976d2
  private mapOpacity = 1

  private propFontSize = 12
  private textColor = '#000000'

  private speedFactor = 0.0001
  private framesPerSec = 60
  private labelPadding = 5

  private graph: Application
  private layers: Array<Viewport>
  private leaves: Array<Array<Leaf>> = []
  public height: number = 0
  public width: number = 0

  public depth: number = 0
  private depths: Map<number, MapData> = new Map()
  private updateDepth?: (d: number, l: string) => void
  private colorScaleOrdinal = schemeSet2
  private colorScale?: ScaleOrdinal<number, string, string>
  private categoryScores: { [category: string]: number }
  private attributionColors: Map<string, string> = new Map()
  private selectedLayout: string = 'binary'
  private sqRatio: number = 1.5

  private scale: string = 'log'
  private logScale: LogScale = { min: 10, max: 1000 }

  public events: EventEmitter = new EventEmitter()
  private layerAttribution: string = ''

  constructor({ graph, layers, height, width, updateDepth, categoryScores }: TreemapParams) {
    this.graph = graph
    this.layers = layers
    this.height = height
    this.width = width
    this.categoryScores = categoryScores
    for (let i = 0; i < layers.length; i++) {
      this.leaves.push([])
    }
    this.updateDepth = updateDepth
  }

  public setDepth(depth: number) {
    const data = this.depths.get(depth)
    if (data != null) {
      //zoom
      this.canvasMap({ data, depth, layout: this.selectedLayout })
    }
  }

  private treemap({
    data,
    prop = 'value',
    layout = 'binary',
    height,
    width
  }: {
    data: MapData
    prop?: string
    layout?: string
    height: number
    width: number
  }) {
    const layoutAlgo = getLayout(layout, this.sqRatio)
    // const filtered = this.filterOutSmallLeaves(data, prop)
    // data = filtered != null ? filtered : data
    const hierarchy = this.scale === 'log' ? getHierarchyLog(data, prop, this.logScale) : getHierarchy(data, prop)
    return treemap().tile(layoutAlgo).size([width, height]).round(true).paddingInner(1)(
      hierarchy as HierarchyNode<unknown>
    )
  }

  private drawLeaf({
    el,
    leaf,
    leaves,
    layer,
    opacity
  }: {
    el: Leaf
    leaf: HierarchyRectangularNode<MapChild>
    leaves: Array<Leaf>
    layer: Viewport
    opacity?: number
  }): Leaf {
    // const scaleValue = this.colorScale != null ? this.colorScale(leaf.value!) : this.mapColor
    const color = convertColorToNumeric(el.color)
    el.gfx.moveTo(leaf.x0, leaf.y0)
    el.gfx.lineStyle(this.borderWidth, this.borderColor, opacity)
    el.gfx.beginFill(color, opacity)
    el.gfx.lineTo(leaf.x0, leaf.y0) // top left
    el.gfx.lineTo(leaf.x1, leaf.y0) // top right
    el.gfx.lineTo(leaf.x1, leaf.y1) // bottom right
    el.gfx.lineTo(leaf.x0, leaf.y1) // bottom left
    el.gfx.closePath()
    el.gfx.endFill()
    el.label.x += leaf.x0 + this.labelPadding
    el.label.y += leaf.y0 + this.labelPadding

    el.gfx.addListener('click', this.leafClickHandler(leaf.depth, leaf))

    el.gfx.addListener('mouseover', (e) => {
      this.events.emit('leaf:mouseover', leaf)
    })

    return el
  }

  private drawLeafAnimate({
    el,
    leaf,
    graph,
    speedFactor,
    callback,
    leaves,
    layer,
    opacity = 1
  }: {
    el: Leaf
    leaf: HierarchyRectangularNode<MapChild>
    graph: Application
    speedFactor: number
    callback: () => void
    leaves: Array<Leaf>
    layer: Viewport
    opacity?: number
  }) {
    const animationUpdate = () => {
      el = this.drawLeaf({ el, leaf, leaves, layer, opacity })
    }
    animationUpdate()
    graph.ticker.update(performance.now() * speedFactor)
    graph.renderer.render(graph.stage)
    requestAnimationFrame(callback)
    return el
  }

  private drawLeafAnimateChunk({
    chunk,
    graph,
    speedFactor,
    callback,
    leaves,
    layer
  }: {
    chunk: { el: Leaf; leaf: HierarchyRectangularNode<any> }[]
    graph: Application
    speedFactor: number
    callback: () => void
    leaves: Array<Leaf>
    layer: Viewport
  }) {
    const animationUpdate = () => {
      chunk.forEach(({ el, leaf }) => {
        el = this.drawLeaf({ el, leaf, leaves, layer })
      })
    }
    animationUpdate()
    graph.ticker.update(performance.now() * speedFactor)
    graph.renderer.render(graph.stage)
    requestAnimationFrame(callback)
    return chunk.map(({ el }) => el)
  }

  private createText(label: string, line2?: string) {
    if (line2 != null) {
      label = `${label}\n${line2}`
    }
    return new Text(titleCase(label), {
      fontSize: this.propFontSize,
      fontWeight: 'bold',
      fill: this.textColor
    })
  }

  private getLine2(leaf: HierarchyRectangularNode<MapChild>): string | undefined {
    return this.depth < 2 && isMapBranch(leaf) && leaf.data.valueLinear != null
      ? `$${prettyRoundedNumber(leaf.data.valueLinear as number, 2, true)}`
      : undefined
  }

  private newLeaf({
    leaf,
    animate,
    callback = () => {},
    leaves,
    layer
  }: {
    leaf: HierarchyRectangularNode<MapChild>
    animate: boolean
    callback?: () => void
    leaves: Array<Leaf>
    layer: Viewport
  }) {
    const attributionName = this.depth === 2 ? this.layerAttribution : leaf.data.name
    const color = this.attributionColors.get(attributionName) ?? DEFAULT_RISK_COLOR_STR
    let el: Leaf = {
      leaf,
      color,
      gfx: new Graphics(),
      label: this.createText(leaf.data.name, this.getLine2(leaf))
    }
    el.gfx.addChild(el.label)
    layer.addChild(el.gfx)
    el.gfx.eventMode = 'static'
    const { graph, speedFactor } = this
    if (animate) {
      el = this.drawLeafAnimate({ el, leaf, graph, speedFactor, callback, leaves, layer })
    } else {
      el = this.drawLeaf({ el, leaf, leaves, layer })
    }
    leaves.push(el)
  }

  private updateLeaf({
    leaf,
    index,
    animate,
    callback = () => {},
    leaves,
    layer,
    opacity = 1
  }: {
    leaf: HierarchyRectangularNode<MapChild>
    index: number
    animate: boolean
    callback?: () => void
    leaves: Array<Leaf>
    layer: Viewport
    opacity?: number
  }) {
    let el = leaves[index]
    el.gfx.removeChildren()
    el.gfx.clear()
    el.gfx.removeAllListeners()
    el.label = this.createText(leaf.data.name, this.getLine2(leaf))
    el.gfx.addChild(el.label)
    const { graph, speedFactor } = this
    if (animate) {
      el = this.drawLeafAnimate({ el, leaf, graph, speedFactor, callback, leaves, layer, opacity })
    } else {
      el = this.drawLeaf({ el, leaf, leaves, layer, opacity })
    }
  }

  private newLeafChunk({
    leafNodes,
    callback = () => {},
    leaves,
    layer
  }: {
    leafNodes: HierarchyRectangularNode<MapBranch>[]
    callback?: () => void
    leaves: Array<Leaf>
    layer: Viewport
  }) {
    const chunk = leafNodes.map((leaf, index) => {
      const attributionName = this.depth === 2 ? this.layerAttribution : leaf.data.name
      const color = this.attributionColors.get(attributionName) ?? DEFAULT_RISK_COLOR_STR
      const el: Leaf = {
        leaf,
        color,
        gfx: new Graphics(),
        label: this.createText(leaf.data.name, this.getLine2(leaf as HierarchyRectangularNode<MapChild>))
      } as Leaf
      el.gfx.addChild(el.label)
      layer.addChild(el.gfx)
      el.gfx.eventMode = 'static'
      leaves.push(el)
      return { el, leaf }
    })
    const { graph, speedFactor } = this
    const els = this.drawLeafAnimateChunk({ chunk, graph, speedFactor, callback, leaves, layer })
  }

  private updateLeafChunk({
    chunk,
    callback = () => {},
    leaves,
    layer
  }: {
    chunk: { el: Leaf; leaf: HierarchyRectangularNode<any> }[]
    callback?: () => void
    leaves: Array<Leaf>
    layer: Viewport
  }) {
    chunk.forEach(({ el, leaf }) => {
      el.gfx.removeChildren()
      el.gfx.clear()
      el.gfx.removeAllListeners()
      el.label = this.createText(leaf.data.name, this.getLine2(leaf))
      el.gfx.addChild(el.label)
    })
    const { graph, speedFactor } = this
    const els = this.drawLeafAnimateChunk({ chunk, graph, speedFactor, callback, leaves, layer })
  }

  private async expandLeaf(leaf: HierarchyRectangularNode<any>, leaves: Array<Leaf>, layer: Viewport) {
    layer.visible = true
    const { x0, y0, x1, y1 } = leaf
    const { framesPerSec } = this
    const xMax = this.width
    const yMax = this.height

    const topLeftInitial: Pos = { x: x0, y: y0 }
    const topLeftDelta: Pos = { x: x0, y: y0 }
    const topLeftStep: Pos = {
      x: topLeftDelta.x > 0 ? topLeftDelta.x / framesPerSec : 0,
      y: topLeftDelta.y > 0 ? topLeftDelta.y / framesPerSec : 0
    }

    const bottomRightInitial: Pos = { x: x1, y: y1 }
    const bottomRightDelta: Pos = { x: xMax - x1, y: yMax - y1 }
    const bottomRightStep: Pos = {
      x: bottomRightDelta.x > 0 ? bottomRightDelta.x / framesPerSec : 0,
      y: bottomRightDelta.y > 0 ? bottomRightDelta.y / framesPerSec : 0
    }

    const posUpdates: { topLeft: Pos; bottomRight: Pos }[] = [
      {
        topLeft: nextPos(topLeftInitial, topLeftStep, 'topLeft'),
        bottomRight: nextPos(bottomRightInitial, bottomRightStep, 'bottomRight')
      }
    ]

    for (let i = 1; i < framesPerSec; i++) {
      const prev = posUpdates[posUpdates.length - 1]
      const next = {
        topLeft: nextPos(prev.topLeft, topLeftStep, 'topLeft'),
        bottomRight: nextPos(prev.bottomRight, bottomRightStep, 'bottomRight')
      }
      posUpdates.push(next)
    }

    // add new layer in initial position to imitate the leaf that was clicked on, so that it is on top.
    this.newLeaf({ leaf, animate: false, layer, leaves })
    const newLeafIndex = leaves.length - 1
    const leafData = leaves[newLeafIndex].leaf
    const lastPosition = JSON.parse(JSON.stringify(posUpdates[posUpdates.length - 1]))

    const expandAnimation = () =>
      new Promise<void>((resolve, reject) => {
        const expand = () => {
          const pos = posUpdates.shift()
          if (pos != null) {
            const newLeaf = leafData
            newLeaf.x0 = pos.topLeft.x
            newLeaf.y0 = pos.topLeft.y
            newLeaf.x1 = pos.bottomRight.x
            newLeaf.y1 = pos.bottomRight.y
            this.updateLeaf({
              leaf: newLeaf,
              index: newLeafIndex,
              animate: true,
              callback: expand,
              layer,
              leaves
            })
          } else {
            resolve()
          }
        }
        expand()
      })
    const opacities = arrayRange(1, 0, -0.05)
    const fadeAnimation = () => {
      const opacity = opacities.shift()
      if (opacity != null) {
        const newLeaf = leafData
        newLeaf.x0 = lastPosition.topLeft.x
        newLeaf.y0 = lastPosition.topLeft.y
        newLeaf.x1 = lastPosition.bottomRight.x
        newLeaf.y1 = lastPosition.bottomRight.y
        this.updateLeaf({
          leaf: newLeaf,
          index: newLeafIndex,
          animate: true,
          callback: fadeAnimation,
          layer,
          leaves,
          opacity
        })
      } else {
        // layer.removeChildAt(newLeafIndex)
        layer.removeChildren()
        layer.visible = false
      }
    }

    await expandAnimation()
    fadeAnimation()
  }

  public async nextDepth(depth: number, leaf: HierarchyRectangularNode<any>, attribution?: string) {
    const leaves = this.leaves[leaf.depth]
    const layer = this.layers[leaf.depth]
    layer.visible = true
    if (attribution != null) {
      this.layerAttribution = attribution
    }
    await this.expandLeaf(leaf, leaves, layer)
    this.canvasMap({ data: leaf.data, depth, layout: this.selectedLayout })
    if (this.updateDepth != null) {
      this.updateDepth(depth, leaf.data.name)
    }
  }

  private leafClickHandler(depth: number, leaf: HierarchyRectangularNode<any>) {
    //leaves: Array<Leaf>, layer: Viewport
    this.leaves = []
    for (let i = 0; i < this.layers.length; i++) {
      this.leaves.push([])
    }
    return async (e: MouseEvent) => {
      if (this.depth === 0 && leaf.data != null && leaf.data.children != null && leaf.data.children.length > 0) {
        await this.nextDepth(depth, leaf)
      } else if (this.depth === 1) {
        // this is an attribution in a category, request it's flows
        this.layerAttribution = ''
        const attribution = leaf.data.name
        depth++
        this.events.emit('attributionFlow', { depth, leaf, attribution })
        this.depth++
      } else if (this.depth === 2) {
        this.events.emit('graphFlow', leaf.data)
      }
    }
  }

  private animateBySquare({
    items,
    existingLeaves,
    leaves,
    layer
  }: {
    items: HierarchyRectangularNode<any>[]
    existingLeaves: number
    leaves: Array<Leaf>
    layer: Viewport
  }) {
    let index = 0
    // separate into new and existing, beacuse we re-use the existing
    const totalCount = items.length
    const newLeaves = items.splice(existingLeaves + 1)
    const maxChunks = this.framesPerSec
    const chunkSize = totalCount < maxChunks ? totalCount : Math.floor(totalCount / this.framesPerSec) || 1
    const newLeafChunks = chunkArray(newLeaves, chunkSize)
    const existingLeafChunks = chunkArray(items, chunkSize)

    const frame = () => {
      if (index === items.length - 1) {
        return
      }
      // draw 1 chunk at a time

      // const leaf = items[index]
      if (index <= existingLeaves) {
        const leafChunk = existingLeafChunks.shift()
        if (leafChunk != null) {
          // this.updateLeaf({ leaf, index, animate: true, callback: frame })
          const combined: { el: Leaf; leaf: HierarchyRectangularNode<any> }[] = []
          let i = parseInt(`${index}`, 10)
          for (i; i < chunkSize; i++) {
            const leaf = leafChunk.shift()
            if (leaf != null) {
              combined.push({
                el: leaves[i],
                leaf
              })
              index++
            }
          }
          this.updateLeafChunk({ chunk: combined, callback: frame, leaves, layer })
        }
      } else {
        // this.newLeaf({ leaf, animate: true, callback: frame })
        const leafChunk = newLeafChunks.shift()
        if (leafChunk != null) {
          this.newLeafChunk({ leafNodes: leafChunk, callback: frame, leaves, layer })
          index += leaves.length
        }
      }
      // index += chunkSize
    }
    frame()
  }

  private drawAtOnce({
    items,
    existingLeaves,
    layer,
    leaves
  }: {
    items: HierarchyRectangularNode<any>[]
    existingLeaves: number
    layer: Viewport
    leaves: Array<Leaf>
  }) {
    items.forEach((leaf, index) => {
      if (index <= existingLeaves) {
        this.updateLeaf({ leaf, index, animate: false, layer, leaves })
      } else {
        this.newLeaf({ leaf, animate: false, layer, leaves })
      }
    })
  }

  canvasMap({
    data,
    prop = 'amount',
    layout = 'binary',
    animateSquares = true,
    depth = 0,
    layer = 0,
    sqRatio = 1.5,
    scale = 'log',
    logScale = { min: 10, max: 1000 }
  }: {
    data: MapData
    prop?: string
    layout?: string
    animateSquares?: boolean
    depth?: number
    layer?: number
    sqRatio?: number
    scale?: string
    logScale?: LogScale
  }): Viewport {
    const { height, width } = this
    this.depth = depth
    this.depths.set(depth, data)
    this.sqRatio = sqRatio
    this.selectedLayout = layout
    this.scale = scale
    this.logScale = logScale
    const root = this.treemap({ data, prop, layout: this.selectedLayout, height, width })
    // pre-set colors for attributions
    if (depth === 0) {
      data.children.forEach((category: MapBranch | MapLeaf) => {
        const color = categoryColor(category.name, this.categoryScores, true) as string
        this.attributionColors.set(category.name, color)
        if (category.children != null) {
          // attributions
          ;(<MapBranch>category).children.forEach((attribution) => {
            const { name } = attribution
            this.attributionColors.set(name, color)
          })
        }
      })
    }

    const branches: HierarchyRectangularNode<any>[] = root.children != null ? root.children : []
    const items = branches
    const sacleOrdinalDomain = items.map((i) => i.value!).sort((a, b) => a - b)

    const colorRange =
      schemeRdYlGn[sacleOrdinalDomain.length > 11 ? 11 : sacleOrdinalDomain.length < 3 ? 3 : sacleOrdinalDomain.length]
    this.colorScale = scaleOrdinal(sacleOrdinalDomain, colorRange)

    // const leaves = this.leaves[layer]
    const existingLeaves = this.leaves[layer].length - 1 // zero based
    const newLeaves = items.length - 1 - existingLeaves

    if (animateSquares) {
      this.animateBySquare({ items, existingLeaves, leaves: this.leaves[layer], layer: this.layers[layer] })
    } else {
      this.drawAtOnce({ items, existingLeaves, leaves: this.leaves[layer], layer: this.layers[layer] })
    }

    if (newLeaves < 0) {
      for (let i = 0; i < Math.abs(newLeaves); i++) {
        const toRemove = this.layers[layer].getChildAt(this.layers[layer].children.length - 1)
        this.layers[layer].removeChild(toRemove)
        this.leaves[layer].pop()
      }
    }
    if (this.graph.view.addEventListener) {
      this.graph.view.addEventListener('mouseout', () => {
        this.events.emit('graph:mouseout')
      })
      this.graph.view.addEventListener('mousemove', (e: Event) => {
        this.events.emit('graph:mousemove', e)
      })
    }
    return this.layers[layer]
  }
}
