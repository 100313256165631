
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import {
  Filter,
  FilterValue,
  Header,
  RecursiveStringMap,
  ServerFilter,
  SingleValueStringTransform,
  TableStringTransform,
  TableTransform
} from './types/genericTable'
import { timeToMilliseconds } from '@/utils/general'
import { filterObjects } from '@/utils/filters'
import BtcItem from '@/subcomponents/BtcItem.vue'
import EthItem from '@/subcomponents/EthItem.vue'
import CopyBtn from '@/subcomponents/CopyBtn.vue'
import BtcCluster from '@/subcomponents/BtcCluster.vue'
import ServerTableDefaultRow from '@/subcomponents/ServerTableDefaultRow.vue'
import BtcAddress from '@/subcomponents/BtcAddress.vue'
import { SortDirection } from './types/serverTable'
import { mapState } from 'vuex'
import { LRUCache } from '@splunkdlt/cache'
import { isOutput, TxHeader } from '@/types/bitcoin'
import BtcTransactionItem from './BtcTransactionItem.vue'
import BtcCounterpartyInputCard from './BtcCounterpartyInputCard.vue'
import BtcCounterpartyOutputCard from './BtcCounterpartyOutputCard.vue'
import ClusteredCounterpartyInputCard from './ClusteredCounterpartyInputCard.vue'
import ClusteredCounterpartyOutputCard from './ClusteredCounterpartyOutputCard.vue'
import EntityTransaction from './EntityTransaction.vue'
import EntityPairTransaction from './EntityPairTransaction.vue'
import { prettyRoundedNumber } from '@/utils/general'

export interface ColumnLabel {
  label: string
  cols: number
  class?: string
}

interface SortDirections {
  [key: string]: SortDirection | null
}

function defaultSort({
  sortable,
  defaultSort
}: {
  sortable?: boolean
  defaultSort?: SortDirection | null | undefined
}) {
  if (!!sortable) {
    return defaultSort != null ? defaultSort : null
  }
  return null
}

@Component({
  name: 'ServerTable',
  components: {
    BtcItem,
    EthItem,
    BtcTransactionItem,
    BtcAddress,
    BtcCluster,
    CopyBtn,
    ServerTableDefaultRow,
    BtcCounterpartyInputCard,
    BtcCounterpartyOutputCard,
    ClusteredCounterpartyInputCard,
    ClusteredCounterpartyOutputCard,
    EntityTransaction,
    EntityPairTransaction
  },
  computed: mapState(['txnHeadersCacheCount', 'txnHeadersCache'])
})
export default class ServerTable extends Vue {
  private network: string = ''
  @Prop() label!: string
  @Prop() columnLabels!: ColumnLabel[]
  @Prop() headers!: Header[]
  @Prop() data!: any[]
  @Prop() onItemClick!: Function
  @Prop() itemKey!: string
  @Prop() tsMultiplier!: number
  @Prop() isLoading!: boolean
  @Prop() template!: string
  @Prop() headerTemplate!: string
  @Prop() meta!: Function
  @Prop() filters!: Filter[]
  @Prop() serverFilters!: ServerFilter[]
  @Prop() onPageUpdated!: (page: number) => void
  @Prop() onItemsPerPageUpdated!: (count: number) => void
  @Prop() onSort!: (header: Header, direction: SortDirection | null, index: number) => void
  @Prop() onItemExpanded!: (update: { item: any; value: boolean }) => void
  @Prop() itemsPerPageOptions!: number[]
  @Prop() itemsPerPage!: number
  @Prop() totalItems!: number
  @Prop() page!: number
  @Prop() expandAll!: boolean
  @Prop() multiExpand!: boolean
  @Prop() showExpand!: boolean
  @Prop() showFooter!: boolean
  @Prop() fixedHeader!: boolean
  @Prop() itemClass!: string
  @Prop() headerClass!: string
  @Prop() expandedRows!: any[]
  // nested props
  @Prop() nestedTable!: boolean
  @Prop() nestedTableLabel!: string
  @Prop() nestedTableColumnLabels!: (item: any) => ColumnLabel[]
  @Prop() nestedTableHeaders!: Header[]
  @Prop() nestedData?: (item: any) => any[]
  @Prop() isNestedLoading!: boolean
  @Prop() nestedDataTotal!: number
  @Prop() onNestedPageUpdated!: (page: number) => void
  @Prop() onNestedItemsPerPageUpdated!: (count: number) => void
  @Prop() nestedPage!: number
  @Prop() nestedItemsPerPage!: number
  @Prop() nestedTemplate!: string
  @Prop() parentItem!: any
  // end nested props
  private items: any[] = []
  private filteredItems: any[] = []
  @Prop() onDataFiltered!: (data: any[]) => void
  public sortDirections: SortDirections = {}
  private currentSortKey: string = ''
  private defaultItemsPerPage: number[] = [10]
  private activeFilters: Array<FilterValue | Array<FilterValue>> = []
  private filtersModel: { [key: string]: { filter: FilterValue | Array<FilterValue> } } = {}
  public asyncFilterModels: string[] = []
  public expanded: any[] = []
  private singleExpandedItem?: any

  public txnHeadersCacheCount!: number
  public txnHeadersCache!: LRUCache<string, TxHeader>
  private txnHeadersMap: { [txid: string]: TxHeader } = {}

  public decimalFormatter!: (n: number | string) => string
  public isOutput = isOutput

  async created() {
    this.network = (this.$route.params.network ?? '').toLowerCase()
    this.items = [...(this.data ?? [])]
    this.filteredItems = [...this.items]
    this.sortDirections = this.headers
      .filter((header) => header.text != '')
      .reduce(
        (merged, header) => ({
          ...merged,
          [`${header.text.replace(' ', '')}`]: defaultSort({ sortable: header.sortable, defaultSort: header.sorted })
        }),
        <SortDirections>{}
      )

    this.currentSortKey = Object.keys(this.sortDirections).reduce((current, key) => {
      return this.sortDirections[key] != null ? key : current
    }, '')
    this.expandedRowsChanged()
    this.toggleRounding()
  }

  @Watch('expandedRows')
  private expandedRowsChanged() {
    if (this.expandedRows != null && Array.isArray(this.expandedRows)) {
      this.expanded = this.expandedRows
      if (!this.multiExpand && this.expanded.length === 1) {
        this.singleExpandedItem = this.expanded[0]
      }
    }
  }

  get dataTemplate() {
    return this.template ?? 'default'
  }

  get dataFilters() {
    return this.filters != null ? this.filters : []
  }

  get asyncFilters() {
    return this.serverFilters != null ?  this.serverFilters : []
  }

  get metadata() {
    return this.meta ?? ((a: any, b: any, c: any) => '')
  }

  get itemsPerPageOpts() {
    return this.itemsPerPageOptions ?? this.defaultItemsPerPage
  }

  get itemsPerPageUpdated() {
    return this.onItemsPerPageUpdated ?? this.defaultItemsPerPageUpdated
  }

  get pageUpdated() {
    return this.onPageUpdated ?? this.defaultPageUpdated
  }

  get expandAllItems() {
    return !!this.expandAll
  }

  public getHeaderClass(sortable: boolean): string {
    const classes: string[] = []
    if (sortable) {
      classes.push('sortable')
    }
    if (this.headerClass == null && this.itemClass != null) {
      classes.push(this.itemClass)
    }
    if (this.headerClass != null) {
      classes.push(this.headerClass)
    }
    return classes.join(' ')
  }

  public expandClick(item: any) {
    if (item.isExpanded) {
      item.isExpanded = false
      this.singleExpandedItem = undefined
      this.expanded.shift()
    } else {
      if (this.singleExpandedItem != null) {
        this.singleExpandedItem.isExpanded = false
        this.itemExpanded({ item: this.singleExpandedItem, value: false })
      }
      item.isExpanded = true
      if (!this.multiExpand) {
        this.singleExpandedItem = item
        this.expanded = [item]
      } else {
        this.expanded.push(item)
      }
    }
    this.itemExpanded({ item, value: item.isExpanded })
  }

  // used in ethereum template
  get receipts() {
    return this.$store.state.receipts
  }

  private sortDirection(header: Header) {
    const key = header.text.replace(' ', '')
    return this.sortDirections[key]
  }

  private updateSortDirections(header: Header, direction: SortDirection | null) {
    const key = header.text.replace(' ', '')
    this.sortDirections[key] = direction
    if (key !== this.currentSortKey) {
      this.sortDirections[this.currentSortKey] = null
      this.currentSortKey = key
    }
  }

  private nextDirection(direction: SortDirection | null): SortDirection | null {
    switch (direction) {
      case null:
        return 'desc'
      case 'desc':
        return 'asc'
      case 'asc':
        return null
    }
  }

  public sortIcon(header: Header) {
    const direction = this.sortDirection(header)
    switch (direction) {
      case null:
        return null
      case 'asc':
        return 'mdi-arrow-up'
      case 'desc':
        return 'mdi-arrow-down'
    }
  }

  sort(header: Header, index: number) {
    const currentDirection = this.sortDirection(header)
    const nextDirection = this.nextDirection(currentDirection)
    this.updateSortDirections(header, nextDirection)
    if (this.onSort != null) {
      this.onSort(header, nextDirection, index)
    } else {
      this.defaultSort(header, nextDirection, index)
    }
  }

  private refreshData() {
    this.items = [...this.data]
    this.filteredItems = [...this.data]
    if (this.onDataFiltered != null) {
      this.onDataFiltered(this.filteredItems)
    }
    this.singleExpandedItem = undefined
  }

  @Watch('isLoading')
  private doneLoading() {
    if (!this.isLoading) {
      this.refreshData()
    }
  }

  @Watch('data')
  private updateData() {
    this.refreshData()
  }

  get title() {
    return this.label == null ? '' : this.label
  }

  get loading() {
    return this.isLoading ?? false
  }

  get nestedLoading() {
    return this.isNestedLoading ?? false
  }

  public itemExpanded(update: { item: any; value: boolean }) {
    if (this.onItemExpanded != null) {
      this.onItemExpanded(update)
    }
  }

  public renderItemClass({
    item,
    value,
    itemClass
  }: {
    item?: any
    value?: any
    itemClass?: string | TableStringTransform
  }): string {
    if (itemClass != null) {
      if (typeof itemClass === 'string') {
        return itemClass
      }
      if (typeof itemClass === 'function') {
        return this.renderValue({ item, value, transform: itemClass }) as string
      }
    }
    return ''
  }

  public renderValue({
    item,
    value,
    transform
  }: {
    item: any
    value: number | string | string[]
    transform?: string | TableTransform
  }): string | number {
    if (typeof transform === 'string') {
      return transform
    }
    if (typeof value === 'string' && value === '') {
      if (transform) {
        // pass full item to transform function
        return transform(item)
      }
      if (item != null && typeof value === 'string') {
        return item
      }
    }
    if (Array.isArray(value) && transform == null) {
      return value.map(v => this.renderValue({ item, value: v, transform })).join(' ')
    }
    if (Array.isArray(value) && transform) {
      if (value.length === 3) {
        return transform(item[value[0]], item[value[1]], item[value[2]])
      }
      return transform(item[value[0]], item[value[1]])
    } else if (typeof value == 'string') {
      return transform ? transform(item[value]) : item[value]
    }
    return ''
  }

  applyClipping({
    item,
    header,
    transform
  }: {
    item: any
    header: Header
    transform: string | TableTransform | SingleValueStringTransform
  }) {
    if (typeof transform === 'string') {
      return transform
    }
    const value = this.renderValue({ item, value: header.value, transform })
    if (header.clipping && header.clipping.enabled) {
      return header.clipping.function(value as string, header.clipping.length)
    }
    return value
  }

  renderSubtext({ item, header }: { item: any; header: Header }): string | number {
    const subtext = header.subtext!
    const rendered = this.renderValue({
      item,
      value: header.value,
      transform: subtext.text
    })
    if (subtext.type === 'cryptotime') {
      return this.formatDate(rendered)
    }
    return rendered
  }

  public clicked(item: any, row?: any) {
    if (this.onItemClick != null) {
      this.onItemClick(item)
    }
  }

  defaultPageUpdated(page: number) {
    console.log(`defaultPageUpdated ${page}`)
  }

  defaultItemsPerPageUpdated(count: number) {
    console.log(`defaultItemsPerPageUpdated ${count}`)
  }

  defaultSort(header: Header, direction: SortDirection | null, index: number) {
    console.log(`defaultSort ${header.text} ${direction}`)
  }

  getNested(props: string[], item: any) {
    let value = item
    for (const prop of props) {
      value = value[prop]
      if (value == null) {
        return null
      }
    }
    return value as string
  }

  filterOptions(value: string, transform?: (v: any, p?: any, c?: any) => any) {
    const props = value.split('.')
    const values = new Set()
    for (const item of this.filteredItems) {
      const transformed = transform ? transform(item) : this.getNested(props, item)
      if (transformed) {
        if (Array.isArray(transformed)) {
          transformed.forEach((v) => values.add(v))
        } else {
          values.add(transformed)
        }
      }
    }
    if (props[0] === 'to') {
      return Array.from(values).sort().reverse()
    }
    return Array.from(values).sort()
  }

  filterItems(selected: string | string[], f: Filter) {
    if (selected == null && f == null) {
      return
    }
    const filterId = (f.nestedProp != null) ? `${f.value}.${f.nestedProp}` : `${f.value}`
    if (selected == null) {
      // remove filter
      if (this.filtersModel[filterId] != null) {
        delete this.filtersModel[filterId]
      }
    } else {
      if (typeof selected === 'string' || typeof selected === 'boolean' || typeof selected === 'number') {
        this.filtersModel[filterId] = { filter: { selected, ...f } }
      } else {
        this.filtersModel[filterId] = { filter: selected.map(v => ({ selected: v, ...f })) }
      }
    }
    this.activeFilters = Object.keys(this.filtersModel).map(k => this.filtersModel[k].filter)

    const filterMap = this.activeFilters.map(af => {
      if (Array.isArray(af)) {
        return af.map((sf) => {
          return this.getFilter(sf)
        })
      }
      return this.getFilter(af)
    })
    this.filteredItems = filterObjects(this.items, filterMap)
    if (this.onDataFiltered != null) {
      this.onDataFiltered(this.filteredItems)
    }
  }

  private getFilter(fv: FilterValue) {
    const nestedProps = fv.nestedProp != null? fv.nestedProp.split('.') : []
    if (nestedProps.length > 0) {
      const filterObj: RecursiveStringMap = nestedProps.reverse().reduce((obj, prop) =>
        (Object.keys(obj).length === 0) ? { [`${prop}`]: fv.selected } : { [`${prop}`]: structuredClone(obj) }
      , <RecursiveStringMap>{})
      return { [`${fv.value}`]: filterObj }
    }
    if (fv.nestedProp != null) {
      return { [`${fv.value}`]: { [`${fv.nestedProp}`]: fv.selected } }
    }
    return { [`${fv.value}`]: fv.selected }
  }

  public resetFilters() {
    this.activeFilters = []
    this.filteredItems = this.items
  }

  public async asyncFilter(modelIndex: number, f: ServerFilter) {
    const value = this.asyncFilterModels[modelIndex]
    const payload = f.dispatchTransform(value)
    const response = await this.$store.dispatch(f.dispatch, payload)
    if (response != null) {
      const filterValues = f.responseTransform(response)
      
      this.filterItems(filterValues, { text: f.text, value: f.value })
    }
  }

  public formatDate(timestamp: number | string) {
    if (typeof timestamp === 'number') {
      const epoch = timeToMilliseconds(timestamp)
      return this.$store.state.formatDate(epoch, true)
    }
    try {
      const epoch = new Date(timestamp).getTime()
      return this.$store.state.formatDate(epoch, true)
    } catch (e) {
      console.warn('couldnt format date', timestamp, e)
    }
  }

  private paginationText(pageStart: number, pageStop: number, itemsLength: number) {
    if (this.page != null && this.itemsPerPage != null) {
      return `${this.page} of ${Math.ceil(itemsLength / this.itemsPerPage)}`
    }
    return `${pageStart}-${pageStop} of ${itemsLength}`
  }

  @Watch('txnHeadersCacheCount')
  updateTxnHeadersMap() {
    if (this.template === 'bitcoinItem') {
      for (const item of this.items) {
        const header = this.txnHeadersCache.get(item.id)
        if (header != null) {
          this.txnHeadersMap[item.id] = header
        }
      }
    }
  }

  @Watch('$store.state.settings', { deep: true })
  toggleRounding() {
    if (this.$store.state.settings.roundDecimalsSwitch) {
      this.decimalFormatter = (n: number | string) => prettyRoundedNumber(n, 8)
    } else {
      this.decimalFormatter = (n: number | string) => prettyRoundedNumber(n)
    }
  }
}
