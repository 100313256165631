import { SimulationNodeDatum, SimulationLinkDatum, DSVRowArray } from 'd3'
import { Application, Graphics, ICanvas, Mesh, Shader, Sprite } from 'pixi.js'
import { Commit, Dispatch } from 'vuex'
import {
  AggregatedBitcoinTransaction,
  Api,
  BalancesSymbol,
  BasicLinkData,
  EntitySummary,
  EntityType,
  ExternalResult,
  ReportData,
  StringMap,
  TripletCluster,
  failedResponse
} from '@/utils/api'
import { State } from '../index'
import { HistoryItem, LinkColor } from './investigations'
import { NodeType, classifyNodeId, graphDataFromLedger, graphDataFromTargets, nodeTypeToIdString, sameNetworkNodePairLinks, symbolNetwork } from '@/utils/viz'
import { classifyInput, isBitcoinClassification, isEthereumClassification } from '@/utils/validate'
import { track } from '@/utils/tracking'
import { SortDirection } from '@/subcomponents/types/serverTable'
import { NETWORKS_SET, chunkArray, downloadCSV, formatNetwork, sleep, sortReports } from '@/utils/general'
import { filter } from '@/utils/filters'
import { FormattedSummaryLink, isTransactionLevel } from '@/utils/graph-links'
import { addBigNum } from '@/utils/bignum'
import { TextInput } from 'pixi-text-input'

export interface SettingsCollection {
  nodeLabelSwitch: boolean
  fullAddressLabelSwitch: boolean
  autoLinksSwitch: boolean
  txnNodeSwitch: boolean
  txnNodeLabelSwitch: boolean
  linkLabelSwitch: boolean
  linkDateLabelSwitch: boolean
  roundDecimalsSwitch: boolean
  showSelfSenders: boolean
  nodeSizeRadio: 'linear' | 'log'
  linkThicknessRadio: 'amount' | 'flow'
}

export interface ForceControls {
  playing: boolean
  linkDistance: number
  linkStrength: number
  chargeStrengthMult: number
  chargeTheta: number
  chargeDistance: NumRange
  xEmbedRadio: 'appearance' | 'hops'
  xEmbedTarget?: TransactionFlowTarget
  xEmbedStrength: number
  yEmbedStrength: number
  xCenterRatio: number
  alpha: number
  velocityDecay: number
}

export interface Flow {
  sending: boolean
  targetTransaction: TripletCluster
  value: number
  minHops: number
}

export interface TransactionFlowTarget extends Omit<Flow, 'value' | 'minHops'> {}

export interface Location {
  x: number
  y: number
}

export interface Edge {
  source: string
  target: string
}

export interface Node {
  id: string
  appearance: number
  flows?: Flow[]
  unspent?: boolean
  permanent?: boolean
}

export interface TransAmount {
  id: string
  timestamp: number
  amount: number | string
  symbol: string
  flows?: Flow[]
}

export interface VizTransaction extends TransAmount {
  sender: string
  receiver: string
  permanent?: boolean
}

export interface Link extends Edge {
  amount: TransAmount
  permanent?: boolean
  color?: string
}

export interface SummaryLink extends Edge {
  linkSummaries: LinkNetworkSummary[]
  transactions: TransAmount[]
  color?: string
}

export interface LinkNetworkSummary {
  network: string
  amount: number
  transactions: number
  first: number
  last: number
  symbol: string
}

export interface Ranges {
  appearance: NumRange
  flows: { [target: string]: FlowRange }
}

export interface FlowRange {
  flows: NumRange
  hops: NumRange
}

export interface NumRange {
  min: number
  max: number
}

export interface FormattedNode extends Node, SimulationNodeDatum {
  size: number
  gfx?: Graphics
  label?: Mesh<Shader>
  upperLabel?: Mesh<Shader>
  freeze?: Location
  display?: string
  icon?: Sprite
  networkIcon?: Sprite
}

export interface NoteNode extends SimulationNodeDatum {
  id: string
  note: true
  input: TextInput
  freeze?: Location
}

export function isNoteNode(node: FormattedNode | NoteNode): node is NoteNode {
  return (node as NoteNode).note === true
}

export interface FormattedLinkBase<T> extends SimulationLinkDatum<SimulationNodeDatum> {
  source: FormattedNode
  target: FormattedNode
  gfx?: Graphics
  amountLabel?: Mesh<Shader>
  timeLabel?: Mesh<Shader>
  forwardThickness?: number
  totalThickness?: number
  reversedLink?: T
}

export interface FormattedLink extends Omit<Link, 'source' | 'target'>, FormattedLinkBase<FormattedLink> {}

export interface NoteLink extends SimulationLinkDatum<SimulationNodeDatum> {
  source: NoteNode
  target: FormattedNode
  gfx: Graphics
  reversedLink: null
  note: true
}

export function isNoteLink(link: GraphLink): link is NoteLink {
  return (link as NoteLink).note === true
}

export interface NetworkDupesLink extends SimulationLinkDatum<SimulationNodeDatum> {
  source: FormattedNode
  target: FormattedNode
  gfx: Graphics
  reversedLink: null
  dupes: true
}

export function isNetworkDupesLink(link: GraphLink): link is NetworkDupesLink {
  return (link as NetworkDupesLink).dupes === true
}

export type InteractiveLink = FormattedLink | FormattedSummaryLink

export function isInteractiveLink(link: GraphLink): link is InteractiveLink {
  return !(isNoteLink(link) || isNetworkDupesLink(link))
}

export type GraphLink = InteractiveLink | NoteLink | NetworkDupesLink

export interface FormattedGraph {
  nodes: FormattedNode[]
  nodesRemoved: FormattedNode[]
  notes: NoteNodeInfo[]
  notesRemoved: NoteNodeInfo[]
  links: FormattedLink[]
  linksRemoved: FormattedLink[]
  summaryLinks: FormattedSummaryLink[]
  summaryLinksRemoved: FormattedSummaryLink[]
  app: Application<ICanvas>
}

export interface NoteNodeInfo extends NoteNode {
  text: string
  deleted?: boolean
}

export type IdType = 'transaction' | EntityType

export interface Target {
  id: string
  name?: string
  type: IdType
  network: string
}

type NetworkId = Omit<Target, 'type'>

interface FilteredNetworkId extends NetworkId {
  address: string
}

export interface FlowTarget {
  transaction: AggregatedBitcoinTransaction
  network: string
}

export interface ReportTransaction extends BalancesSymbol {
  transaction: string
  entity: string
  type: 'address' | 'cluster' | 'clusterAttribution'
  isOutput: boolean
  root?: string
  clusterAttribution?: string
  addressAttribution?: string
  amount: string | number
  time: number
  flows?: Flow[]
}

export interface Macrotized {
  deleted: boolean
  oldEntity: string
  macro: string
  macroIndex: number
  changedLinks: ChangedLink[]
  newLinksStart?: number
}

export interface ChangedLink {
  index?: number
  deleted: boolean
  side: 'source' | 'target'
  macro: boolean
  counterparty: string
}

export interface OldLink {
  index?: number
  deleted: boolean
  source: string
  target: string
}

export const vizState = {
  // settings
  settings: <SettingsCollection>{
    nodeLabelSwitch: true,
    fullAddressLabelSwitch: false,
    autoLinksSwitch: true,
    txnNodeSwitch: false,
    txnNodeLabelSwitch: false,
    linkLabelSwitch: true,
    linkDateLabelSwitch: true,
    roundDecimalsSwitch: true,
    showSelfSenders: true,
    nodeLabelRadio: 'attribution',
    nodeSizeRadio: 'log',
    linkThicknessRadio: 'amount'
  },
  nodeSizeScales: <string[]>['linear', 'log'],
  linkThicknessScales: <string[]>['amount', 'flow'],
  xEmbedScales: <string[]>['appearance', 'hops'],
  transactionFlowTargets: <TransactionFlowTarget[]>[],
  transactionFlowTarget: <TransactionFlowTarget | undefined>undefined,
  transactionFlowTargetsMap: <{ [key: string]: AggregatedBitcoinTransaction }>{},
  flowScaleTarget: <TransactionFlowTarget | undefined>undefined,
  forces: <ForceControls>{
    playing: false,
    linkDistance: 25,
    linkStrength: 1,
    chargeStrengthMult: -70,
    chargeTheta: 1.5,
    chargeDistance: {
      min: 1,
      max: 600
    },
    xEmbedRadio: 'hops',
    xEmbedStrength: 0.1,
    yEmbedStrength: 0.1,
    xCenterRatio: 3,
    alpha: 0.3,
    velocityDecay: 0.2
  },
  // general
  graphLoading: <boolean>false,
  deletionConfirmations: <boolean>true,
  // these are current nodes in the graph
  nodes: <Node[]>[],
  newestNodes: new Set<string>(),
  // these are current nodes in the graph with placements
  formattedNodes: <FormattedNode[]>[],
  removedNodes: <FormattedNode[]>[],
  // note nodes with placements
  noteNodes: <NoteNodeInfo[]>[],
  removedNotes: <NoteNodeInfo[]>[],
  // these are links in the graph
  links: <Link[]>[],
  newestLinks: new Set<string>(),
  // these are links between current nodes (with placements) in the graph
  formattedLinks: <FormattedLink[]>[],
  removedLinks: <FormattedLink[]>[],
  ranges: <Ranges>{
    appearance: {
      min: Infinity,
      max: 0
    },
    flows: {}
  },
  // summary links that encapsulate entire source-target relationship
  summaryLinks: <SummaryLink[]>[],
  formattedSummaryLinks: <FormattedSummaryLink[]>[],
  removedSummaryLinks: <FormattedSummaryLink[]>[],
  deletedLinks: <Edge[]>[],
  linkColors: <StringMap>{},
  target: <Target | undefined>undefined,
  targetNetwork: <string>'',
  targetSupportedNetworks: <string[]>[],
  entitySummary: <EntitySummary | undefined>undefined,
  flowTarget: <FlowTarget | undefined>undefined,
  ledger: <VizTransaction[]>[],
  report: <ReportTransaction[]>[],
  reportLoading: <boolean>false,
  selectedNode: <string>'',
  macrotized: <Macrotized | undefined>undefined,
  nodesToRemove: <string[] | undefined>undefined,
  linksToRemove: <string[] | undefined>undefined,
  highlightedEdge: <string[]>[],
  selectedLink: <FormattedLink | FormattedSummaryLink | undefined>undefined,
  history: <HistoryItem[]>[],
  graphRedraw: <boolean>false,
  targetNodeRename: <string | undefined>undefined,
  freezeCounter: <number>0,
  unfreezeCounter: <number>0
}

export const vizMutations = {
  // charts
  SET_SETTINGS(state: State, settings: SettingsCollection) {
    state.settings = { ...settings }
  },
  SET_TRANSACTION_FLOW_TARGETS(state: State, transactionFlowTargets: TransactionFlowTarget[]) {
    state.transactionFlowTargets = transactionFlowTargets
  },
  SET_TRANSACTION_FLOW_TARGET(state: State, transactionFlowTarget: TransactionFlowTarget) {
    state.transactionFlowTarget = transactionFlowTarget
  },
  ADD_TRANSACTION_FLOW_TARGET(state: State, transactionFlowTarget: TransactionFlowTarget) {
    state.transactionFlowTargets.push(transactionFlowTarget)
  },
  SET_TRANSACTION_FLOW_TARGETS_MAP(
    state: State,
    transactionFlowTargetsMap: { [key: string]: AggregatedBitcoinTransaction }
  ) {
    state.transactionFlowTargetsMap = transactionFlowTargetsMap
  },
  ADD_TRANSACTION_FLOW_TARGETS_MAP(
    state: State,
    transactionFlowTargetsMap: { [key: string]: AggregatedBitcoinTransaction }
  ) {
    for (const [key, value] of Object.entries(transactionFlowTargetsMap)) {
      state.transactionFlowTargetsMap[key] = value
    }
  },
  SET_FLOW_SCALE_TARGET(state: State, flowScaleTarget: TransactionFlowTarget) {
    state.flowScaleTarget = flowScaleTarget
  },
  SET_FORCES(state: State, forces: ForceControls) {
    state.forces = { ...forces }
  },
  SET_GRAPH_LOADING(state: State, loading: boolean) {
    state.graphLoading = loading
  },
  SET_DELETION_CONFIRMATIONS(state: State, on: boolean) {
    state.deletionConfirmations = on
  },
  SET_NODES(state: State, nodes: Node[]) {
    state.nodes = nodes
  },
  REMOVE_NODE(state: State, index: number) {
    state.nodes.splice(index, 1)
  },
  CLEAR_NEWEST_NODES(state: State) {
    state.newestNodes.clear()
  },
  ADD_NEWEST_NODES(state: State, nodes: Iterable<string>) {
    for (const node of nodes) {
      state.newestNodes.add(node)
    }
  },
  SET_LINKS(state: State, links: Link[]) {
    state.links = links
  },
  CLEAR_NEWEST_LINKS(state: State) {
    state.newestLinks.clear()
  },
  ADD_NEWEST_LINKS(state: State, links: Iterable<string>) {
    for (const link of links) {
      state.newestLinks.add(link)
    }
  },
  SET_NODES_FORMATTED(state: State, nodes: FormattedNode[]) {
    state.formattedNodes = nodes
  },
  SET_NODES_REMOVED(state: State, nodes: FormattedNode[]) {
    state.removedNodes = nodes
  },
  SET_NOTE_NODES(state: State, notes: NoteNodeInfo[]) {
    state.noteNodes = notes
  },
  SET_NOTES_REMOVED(state: State, notes: NoteNodeInfo[]) {
    state.removedNotes = notes
  },
  SET_LINKS_FORMATTED(state: State, links: FormattedLink[]) {
    state.formattedLinks = links
  },
  SET_LINKS_REMOVED(state: State, links: FormattedLink[]) {
    state.removedLinks = links
  },
  ADD_SUMMARY_LINKS(state: State, links: SummaryLink[]) {
    state.summaryLinks.push(...links)
  },
  CLEAR_SUMMARY_LINKS(state: State) {
    state.summaryLinks.splice(0)
  },
  SET_SUMMARY_LINKS_FORMATTED(state: State, links: FormattedSummaryLink[]) {
    state.formattedSummaryLinks = links
  },
  SET_SUMMARY_LINKS_REMOVED(state: State, links: FormattedSummaryLink[]) {
    state.removedSummaryLinks = links
  },
  SET_DELETED_LINKS(state: State, links: Edge[]) {
    state.deletedLinks = links
  },
  SET_LINK_COLORS(state: State, linkColors: LinkColor[]) {
    state.linkColors = linkColors.reduce((map, linkColor) => {
      const { source, target, color } = linkColor
      if (color != null) {
        map[`${source}${target}`] = color
      }
      return map
    }, <StringMap>{})
  },
  SET_TARGET(state: State, target?: Target) {
    state.target = target
  },
  SET_TARGET_NETWORK(state: State, network: string) {
    state.targetNetwork = network
  },
  SET_TARGET_SUPPORTED_NETWORKS(state: State, networks: string[]) {
    state.targetSupportedNetworks = networks
  },
  SET_ENTITY_SUMMARY(state: State, summary?: EntitySummary) {
    state.entitySummary = summary
  },
  ADD_GRAPH_TXNS(state: State, txns: VizTransaction[]) {
    for (const txn of txns) {
      state.ledger.push(txn)
    }
  },
  SET_LEDGER(state: State, txns: VizTransaction[]) {
    state.ledger = txns
  },
  ADD_TO_REPORT(state: State, report: ReportTransaction[]) {
    for (const txn of report) {
      state.report.push(txn)
    }
  },
  SET_REPORT(state: State, report: ReportTransaction[]) {
    state.report = report
  },
  SET_REPORT_LOADING(state: State, loading: boolean) {
    state.reportLoading = loading
  },
  SET_RANGES(state: State, ranges: Ranges) {
    state.ranges = ranges
  },
  SET_SELECTED_NODE(state: State, node: string) {
    state.selectedNode = node
  },
  CHANGE_NODE_ID(state: State, { index, id }: { index: number; id: string }) {
    state.nodes[index].id = id
  },
  ADJUST_NODE_APPEARANCE(state: State, { index, appearance }: { index: number; appearance: number }) {
    state.nodes[index].appearance = appearance
  },
  ADD_NODE(state: State, node: Node) {
    state.nodes.push(node)
  },
  DELETE_NODE(state: State, index: number) {
    state.nodes.splice(index, 1)
  },
  ADD_LINK(state: State, link: Link) {
    state.links.push(link)
  },
  DELETE_LINK(state: State, index: number) {
    state.links.splice(index, 1)
  },
  MACROTIZED(state: State, macrotized?: Macrotized) {
    state.macrotized = macrotized
  },
  SET_NODES_TO_REMOVE(state: State, { ids }: { ids: string[] }) {
    state.nodesToRemove = ids
  },
  CLEAR_NODES_TO_REMOVE(state: State) {
    state.nodesToRemove = undefined
  },
  SET_LINKS_TO_REMOVE(state: State, { links }: { links: string[] }) {
    state.linksToRemove = links
  },
  CLEAR_LINKS_TO_REMOVE(state: State) {
    state.linksToRemove = undefined
  },
  HIGHLIGHT_EDGE(state: State, edge: string[]) {
    state.highlightedEdge = edge
  },
  SET_SELECTED_LINK(state: State, link: FormattedLink | FormattedSummaryLink) {
    state.selectedLink = link
  },
  SET_HISTORY(state: State, historyItems: HistoryItem[]) {
    state.history = historyItems
  },
  ADD_HISTORY(state: State, historyItem: HistoryItem) {
    state.history.push(historyItem)
  },
  SET_GRAPH_REDRAW(state: State, redraw: boolean) {
    state.graphRedraw = redraw
  },
  SET_NODE_RENAME(state: State, name?: string) {
    state.targetNodeRename = name
  },
  RENAME_TARGET(state: State, name: string) {
    if (state.target != null) state.target.name = name
  },
  INCREMENT_FREEZE(state: State) {
    state.freezeCounter++
  },
  INCREMENT_UNFREEZE(state: State) {
    state.unfreezeCounter++
  }
}

export const vizActions = (state: State, api: Api, dispatch: Dispatch) => ({
  setSettings({ commit }: { commit: Commit }, settings: SettingsCollection) {
    commit('SET_SETTINGS', settings)
  },
  toggleTxnNodes({ commit }: { commit: Commit }) {
    const settings: SettingsCollection = { ...state.settings, txnNodeSwitch: !state.settings.txnNodeSwitch }
    commit('SET_SETTINGS', settings)
  },
  setFlowScaleTarget({ commit }: { commit: Commit }, flowScaleTarget: TransactionFlowTarget) {
    commit('SET_FLOW_SCALE_TARGET', flowScaleTarget)
  },
  setForces({ commit }: { commit: Commit }, forces: ForceControls) {
    commit('SET_FORCES', forces)
  },
  setTransactionFlowTarget({ commit }: { commit: Commit }, transactionFlowTarget: TransactionFlowTarget) {
    commit('SET_TRANSACTION_FLOW_TARGET', transactionFlowTarget)
  },
  clearVizData({ commit }: { commit: Commit }) {
    commit('SET_TARGET', undefined)
    commit('SET_TARGET_NETWORK', '')
    commit('SET_TARGET_SUPPORTED_NETWORKS', [])
    commit('SET_TRANSACTION_FLOW_TARGETS', [])
    commit('SET_TRANSACTION_FLOW_TARGET', undefined)
    commit('SET_TRANSACTION_FLOW_TARGETS_MAP', {})
    commit('SET_FLOW_SCALE_TARGET', undefined)
    commit('SET_LEDGER', [])
    commit('SET_REPORT', [])
    commit('SET_NODES', [])
    commit('CLEAR_NEWEST_NODES')
    commit('SET_NODES_FORMATTED', [])
    commit('SET_NODES_REMOVED', [])
    commit('SET_LINKS', [])
    commit('CLEAR_NEWEST_LINKS')
    commit('SET_LINKS_FORMATTED', [])
    commit('SET_LINKS_REMOVED', [])
    commit('CLEAR_SUMMARY_LINKS')
    commit('SET_SUMMARY_LINKS_FORMATTED', [])
    commit('SET_SUMMARY_LINKS_REMOVED', [])
    commit('SET_DELETED_LINKS', [])
    commit('SET_RANGES', {
      appearance: {
        min: Infinity,
        max: 0
      },
      flows: {}
    } as Ranges)
    commit('SET_FORCES', { ...state.forces, xEmbedTarget: undefined } as ForceControls)
    commit('CLEAR_TABULAR')
    commit('CLEAR_FLOWS')
    commit('SET_HISTORY', [])
    commit('SET_NOTES', { notes: '' })
  },
  setDeletionConfirmations({ commit }: { commit: Commit }, { on }: { on: boolean }) {
    commit('SET_DELETION_CONFIRMATIONS', on)
  },
  clearNewestNodes({ commit }: { commit: Commit }) {
    commit('CLEAR_NEWEST_NODES')
  },
  clearNewestLinks({ commit }: { commit: Commit }) {
    commit('CLEAR_NEWEST_LINKS')
  },
  async graphAllNodePairs(
    { commit }: { commit: Commit },
    { nodes, allNew, endLoading }: { nodes: NodeType[]; allNew: boolean; endLoading?: boolean }
  ) {
    const oldNodeList = filter(
      state.formattedNodes.map(n => classifyNodeId(n.id)),
      ({ type }) => type !== 'transaction'
    )

    // get only new pairs to request link data for
    const oldNodes = new Set(allNew ? [] : oldNodeList.map(nodeType => JSON.stringify(nodeType)))
    const newNodes = filter(nodes, n => !oldNodes.has(JSON.stringify(n)))
    // first get all link data between the new nodes of same network
    const networkLinksData = sameNetworkNodePairLinks(newNodes)
    // add all pairs between old and new nodes of same network
    for (const newNode of newNodes) {
      const { id: newId, type: newType, network: newNetwork } = newNode
      for (const oldNode of oldNodes) {
        const { id: oldId, type: oldType, network: oldNetwork } = JSON.parse(oldNode) as NodeType
        if (newNetwork === oldNetwork) {
          if (!(newNetwork in networkLinksData)) {
            networkLinksData[newNetwork] = []
          }
          networkLinksData[newNetwork].push(...[
            {
              sender: oldId,
              senderType: oldType as EntityType,
              receiver: newId,
              receiverType: newType as EntityType
            },
            {
              sender: newId,
              senderType: newType as EntityType,
              receiver: oldId,
              receiverType: oldType as EntityType
            }
          ])
        }
      }
    }
    for (const [network, linksData] of Object.entries(networkLinksData)) {
      if (linksData.length !== 0) {
        await dispatch('graphNodePairs', { linksData, network, endLoading })
      }
    }
    await dispatch('traceIDs', { ids: nodes, endLoading })
  },
  async graphNodePairs(
    { commit }: { commit: Commit },
    { linksData, network, endLoading }: { linksData: BasicLinkData[]; network: string; endLoading?: boolean }
  ) {
    dispatch('showLoadingDialog', { show: true, value: 0, text: 'Loading autolinks...' })
    commit('SET_GRAPH_LOADING', true)

    // don't try to fetch anything that's already been fetched
    const summaryLinksToFetchMap: { [key: string]: SummaryLink } = {}
    const summaryLinksRemainingMap: { [key: string]: SummaryLink } = {}
    for (const link of state.summaryLinks) {
      const { source, target } = link
        const key = `${source}||${target}`
      if (link.linkSummaries.find(l => l.network === network) != null) {
        // already have data for this link and network
        summaryLinksRemainingMap[key] = structuredClone(link)
      } else {
        summaryLinksToFetchMap[key] = structuredClone(link)
      }
    }
    const filtered = filter(linksData, ({ sender, senderType, receiver, receiverType }) => {
      const source = nodeTypeToIdString({ id: sender, type: senderType, network })
      const target = nodeTypeToIdString({ id: receiver, type: receiverType, network })
      return !(`${source}||${target}` in summaryLinksRemainingMap)
    })
    const chunks = chunkArray(filtered, 5000) // will be comfortably below 1mb but high enough to be efficient
    if (!state.listeningToEvents) {
      await sleep(1000)
    }
    const responses = await Promise.all(chunks.map(chunk => api.getLinksSummaries({ network, linksData: chunk })))
    const newSummaryLinks: SummaryLink[] = []
    const ranges = structuredClone(state.ranges)
    for (const res of responses) {
      if (failedResponse(res)) {
        console.warn('something went wrong when fetching links summaries')
      } else if (res != null) {
        for (const [key, summary] of Object.entries(res)) {
          const { sender, senderType, receiver, receiverType } = JSON.parse(key) as BasicLinkData
          const source = nodeTypeToIdString({ id: sender, type: senderType, network })
          const target = nodeTypeToIdString({ id: receiver, type: receiverType, network })
          const linkKey = `${source}||${target}`
          const insertedIntoNew = new Map<string, SummaryLink>()
          for (const [symbol, summaryData] of Object.entries(summary.symbols)) {
            const { amount, numberOfTxids: transactions, minCryptotime: first, maxCryptotime: last } = summaryData
            if (linkKey in summaryLinksToFetchMap) {
              summaryLinksToFetchMap[linkKey].linkSummaries.push({
                network,
                amount,
                transactions,
                first,
                last,
                symbol
              })
            } else {
              const present = insertedIntoNew.get(linkKey)
              if (present != null) {
                present.linkSummaries.push({
                  network,
                  amount,
                  transactions,
                  first,
                  last,
                  symbol
                })
              } else {
                const summaryLink = {
                  source,
                  target,
                  linkSummaries: [{
                    network,
                    amount,
                    transactions,
                    first,
                    last,
                    symbol
                  }],
                  transactions: [],
                  color: state.linkColors[`${source}${target}`]
                }
                newSummaryLinks.push(summaryLink)
                insertedIntoNew.set(linkKey, summaryLink)
              }
            }
          }
        }
      }
    }
    commit('CLEAR_SUMMARY_LINKS')
    commit('ADD_SUMMARY_LINKS', Object.values(summaryLinksRemainingMap))
    commit('ADD_SUMMARY_LINKS', Object.values(summaryLinksToFetchMap))
    commit('ADD_SUMMARY_LINKS', newSummaryLinks)
    commit('SET_RANGES', ranges)
  },
  async graphLedger(
    { commit }: { commit: Commit },
    { ledger }: { ledger: VizTransaction[] }
  ) {
    commit('SET_LEDGER', ledger)
    await dispatch('createGraphFromLedger')

    // compile a list of the flow targets in the flow data
    const flowTargets: { [key: string]: { tc: TripletCluster; network: string } } = {}
    for (const txn of state.ledger) {
      const { flows, sender, receiver } = txn
      if (flows) {
        const network = classifyInput(classifyNodeId(sender).id).networkType || classifyInput(classifyNodeId(receiver).id).networkType
        for (const flow of flows) {
          const { id, index, isOutput } = flow.targetTransaction
          flowTargets[`${id}|${isOutput}|${index}|${flow.sending}`] = { tc: flow.targetTransaction, network }
        }
      }
    }
    // specific clusters involved for attributions won't have gotten their metadata pulled yet, pull here
    const networkNodes: { [network: string]: Node[] } = {}
    for (const { tc, network } of Object.values(flowTargets)) {
      const tcNode = nodeTypeToIdString({ id: tc.cluster, type: 'cluster', network })
      if (network in networkNodes) {
        networkNodes[network].push({
          id: tcNode,
          appearance: 0
        })
      } else {
        networkNodes[network] = [{
          id: tcNode,
          appearance: 0
        }]
      }
    }
    for (const [network, nodes] of Object.entries(networkNodes)) {
      await dispatch('getInvestigationClusters', { nodes, network })
    }

    const txnFlowTargets: TransactionFlowTarget[] = []
    const txnFlowTargetsMap: { [key: string]: AggregatedBitcoinTransaction } = {}
    for (const [key, value] of Object.entries(flowTargets)) {
      const [id, isOutputString, indexString, sendingString] = key.split('|')
      const sending = sendingString === 'true'
      txnFlowTargets.push({
        sending,
        targetTransaction: value.tc
      })
      let clusterAttribution = null
      const cluster = state.shared.clusterAddressCache.get(value.tc.cluster)
      if (cluster) {
        const { topAttribution } = cluster
        if (topAttribution) {
          clusterAttribution = topAttribution
        }
      }
      txnFlowTargetsMap[`${id}|${isOutputString}|${indexString}`] = {
        ...value.tc,
        clusterAttribution,
        minIndex: value.tc.index
      }
    }
    commit('SET_TRANSACTION_FLOW_TARGETS', txnFlowTargets)
    commit('SET_TRANSACTION_FLOW_TARGETS_MAP', txnFlowTargetsMap)
    if (txnFlowTargets.length > 0 && state.forces.xEmbedTarget === undefined) {
      const forces = { ...state.forces }
      forces.xEmbedTarget = txnFlowTargets[0]
      commit('SET_FORCES', forces)
    }
  },
  async createGraphFromLedger({ commit }: { commit: Commit }) {
    dispatch('showLoadingDialog', { show: true, value: 60, text: 'Loading cluster data...' })
    commit('SET_GRAPH_LOADING', true)
    const { nodes: newNodes, links, ranges } = graphDataFromLedger(state.ledger, state.linkColors)

    const oldNodes = new Set(state.nodes.map(n => n.id))
    const nodes = filter(newNodes, n => !oldNodes.has(n.id))

    await dispatch('getInvestigationAttributions', { nodes })
    // get clusters by network
    const networkNodes: { [network: string]: Node[] } = {}
    for (const node of nodes) {
      const { network } = classifyNodeId(node.id)
      if (network in networkNodes) {
        networkNodes[network].push(node)
      } else {
        networkNodes[network] = [node]
      }
    }
    for (const [network, nodes] of Object.entries(networkNodes)) {
      await dispatch('getInvestigationClusters', { nodes, network })

      // set node sizes and appearances, plus ranges
      await dispatch('setNodeFields', { nodes, ranges, network })
    }

    // add old nodes to new nodes to get all nodes
    for (const node of state.nodes) {
      nodes.push(node)
    }
    commit('SET_NODES', nodes)
    commit('SET_LINKS', links)

    await dispatch('setSummaryLinkTransactions') // this won't get called in addToGraph if that has no links

    await sleep(300) // the watcher trigger in Graph component doesn't catch the change if it's too fast
    commit('SET_GRAPH_LOADING', false)
  },
  async addToReport({ commit }: { commit: Commit }, { txns, network }: { txns: VizTransaction[]; network?: string }) {
    commit('SET_REPORT_LOADING', true)

    if (network === undefined) {
      if (txns.length > 0) {
        let index = 0
        while (!network) {
          const id = txns[index].sender
          network = classifyInput(classifyNodeId(id).id).networkType
          index++
        }
      }
    }

    const report: ReportTransaction[] = []
    if (network === undefined) {
      console.warn('no network could be detected to generate report')
    } else {
      const formatted = filter(
        state.ledger.map((t) => {
          const { sender, receiver, amount, timestamp: time, flows } = t
          const { id: senderId, type: senderType } = classifyNodeId(sender)
          const { id: receiverId, type: receiverType } = classifyNodeId(receiver)
          let transaction = '',
            isOutput = true,
            type: 'address' | 'cluster' | 'clusterAttribution' | 'transaction' = 'transaction',
            entity = '',
            root = ''
          if (senderType === 'transaction') {
            transaction = senderId
            type = receiverType === 'attribution' ? 'clusterAttribution' : receiverType
            entity = receiverId
          } else if (receiverType === 'transaction') {
            transaction = receiverId
            isOutput = false
            type = senderType === 'attribution' ? 'clusterAttribution' : senderType
            entity = senderId
          }
          if (transaction && type !== 'transaction') {
            let clusterAttribution, addressAttribution
            if (type !== 'clusterAttribution') {
              root = entity
              const clusterMetadata = state.shared.clusterAddressCache.get(entity)
              if (clusterMetadata != null) {
                const { topAttribution } = clusterMetadata
                if (topAttribution != null) clusterAttribution = topAttribution
              }
              if (type === 'address') {
                const attributions = state.shared.attributionsCache.get(entity)
                if (attributions != null) {
                  addressAttribution = attributions[0].attributions[0]
                }
              }
            }
            return {
              transaction,
              entity,
              type,
              root,
              clusterAttribution,
              addressAttribution,
              isOutput,
              amount,
              time,
              flows
            }
          }
          return null
        }),
        f => f != null) as ReportTransaction[]

      const responses = await Promise.all(
        formatted.map((t) => {
          const { transaction: id, isOutput, entity, type } = t
          return api.getTransactionLedger({
            network: network!, // typescript won't detect that this can't be undefined for some reason
            id,
            page: 1,
            perPage: 1,
            sorts: { index: 'desc' },
            isOutput,
            [type]: entity,
            report: true
          })
        })
      )
      for (const [index, response] of responses.entries()) {
        if (!failedResponse(response) && response != null) {
          const reportData = response.ledger[0] as ReportData
          const formattedTxn = formatted[index]
          if (formattedTxn != null) {
            const { root } = formattedTxn
            report.push({ ...formattedTxn, ...reportData, root: root ? root : reportData.cluster }) // assign root if entity is attribution
          }
        }
      }
    }

    commit('ADD_TO_REPORT', report)
    commit('SET_REPORT_LOADING', false)
  },
  async getReport({ commit }: { commit: Commit }, { network }: { network?: string }) {
    commit('SET_REPORT', [])

    await dispatch('addToReport', { txns: state.ledger, network })
  },
  sortReport(
    { commit }: { commit: Commit },
    { field, direction, condition }: { field: string; direction: SortDirection; condition?: (value: any) => boolean }
  ) {
    commit('SET_REPORT_LOADING', true)
    const reports: ReportTransaction[] = structuredClone(state.report)
    const sorted =
      direction == 'asc' ? sortReports(reports, field, condition) : sortReports(reports, field, condition).reverse()

    commit('SET_REPORT', sorted)
    commit('SET_REPORT_LOADING', false)
  },
  async downloadAddresses({ commit }: { commit: Commit }, { size }: { size: number }) {
    if (state.target) {
      const { id, type } = state.target
      const network = state.target.network || state.targetNetwork
      const addresses: string[] = []
      const perPage = 1000
      const pages = Math.ceil(size / perPage)
      const chunkSize = 50 // this seems like a good number for speed
      const chunks = Math.ceil(pages / chunkSize)
      for (let chunk=0; chunk<chunks; chunk++) {
        const responses = []
        for (let page=1+chunk*chunkSize; page<=(chunk+1)*chunkSize; page++) {
          responses.push(api.clusterAddresses({ network, id, page, perPage, type }))
        }
        await Promise.all(responses).then(responses => {
          for (const response of responses) {
            if (response != undefined) {
              const { addresses: pageAddresses } = response
              if (pageAddresses.length) {
                for (const address of pageAddresses) {
                  addresses.push(address)
                }
              }
            }
          }
        })
        console.log(`chunk ${chunk+1}/${chunks} downloaded`) // this can be converted into a loading bar somewhere
      }
      if (addresses.length) {
        downloadCSV(addresses.map(a => ({Address: a})), `${id.replaceAll('.', '_')} addresses`)
      }
    }
  },
  addHistory({ commit }: { commit: Commit }, historyItem: HistoryItem) {
    // stop adding to history since it's no longer up-to-date and isn't being used
    // commit('ADD_HISTORY', historyItem)
    dispatch('updateInvestigation')
  },
  setSummaryLinkTransactions({ commit }: { commit: Commit }) {
    // map input/output transaction link pairs to their transactions
    const txnSidesMap: { [txn: string]: { inputs: Link[]; outputs: Link[] } } = {}
    for (const link of state.links) {
      const { type: sourceType } = classifyNodeId(link.source)
      const { type: targetType } = classifyNodeId(link.target)
      if (sourceType === 'transaction') {
        if (link.source in txnSidesMap) {
          txnSidesMap[link.source].outputs.push(link)
        } else {
          txnSidesMap[link.source] = {
            inputs: [],
            outputs: [link]
          }
        }
      } else if (targetType === 'transaction') {
        if (link.target in txnSidesMap) {
          txnSidesMap[link.target].inputs.push(link)
        } else {
          txnSidesMap[link.target] = {
            inputs: [link],
            outputs: []
          }
        }
      }
    }

    const summaryLinksMap: { [key: string]: SummaryLink } = {}
    for (const link of state.summaryLinks) {
      const { source, target } = link
      const key = `${source}|${target}`
      summaryLinksMap[key] = structuredClone(link)
    }
    const newestSummaryLinks = new Set<string>()
    for (const [txn, sides] of Object.entries(txnSidesMap)) {
      const { inputs, outputs } = sides
      if (inputs.length && outputs.length) {
        for (const input of inputs) {
          const { source } = input
          let currentInNewest = state.newestLinks.has(`${source}||${txn}`)
          for (const output of outputs) {
            const { target, amount: originalAmount } = output
            const amount: TransAmount = structuredClone(originalAmount)
            amount.id = txn
            if (!currentInNewest) currentInNewest = state.newestLinks.has(`${txn}||${target}`)
            if (source !== target) { // exclude self edges
              const key = `${source}|${target}`
              if (currentInNewest) {
                newestSummaryLinks.add(key)
              }
              if (key in summaryLinksMap) { // must exist if summary link exists, but not if summary links aren't up to date
                const { transactions } = summaryLinksMap[key]
                const foundIndex = transactions.findIndex(t => t.id === amount.id)
                if (foundIndex === -1) {
                  transactions.push(amount)
                } else { // replace
                  transactions[foundIndex] = amount
                }
              } else {
                // manufacture a summary link
                summaryLinksMap[key] = {
                  source,
                  target,
                  linkSummaries: [
                    {
                      network: classifyInput(txn).networkType,
                      amount: -1,
                      transactions: 1,
                      first: -1,
                      last: -1,
                      symbol: ''
                    }
                  ],
                  transactions: [amount],
                  color: state.linkColors[`${source}${target}`]
                }
                // notify user that it's not in the data
                dispatch('updateSnackbar', {
                  show: true,
                  text: `Warning: No summary link currently exists in the data for ${source} -> ${target}`,
                  timeout: 2000
                })
              }
            }
          }
        }
      } // otherwise there's only one side of the txn graphed, so can't add data to summary link
    }
    commit('CLEAR_SUMMARY_LINKS')
    commit('ADD_SUMMARY_LINKS', Object.values(summaryLinksMap))
  },
  async setTarget({ commit }: { commit: Commit }, { id, name, network, type }: Target) {
    commit('CLEAR_TABULAR')
    commit('SET_ENTITY_SUMMARY', undefined)
    if (!state.graphedLifoTransaction) {
      dispatch('hideDirectLIFO')
    }
    if (id === undefined) {
      commit('SET_TARGET', undefined)
      // commit('SET_TARGET_NETWORK', '') // this causes issues with knowing the previous network when switching to link
      commit('SET_TARGET_SUPPORTED_NETWORKS', [])
    } else {
      track('setTarget', { id, name, network, type })
      if (type === 'transaction') {
        commit('SET_TARGET_SUPPORTED_NETWORKS', [network])
        // dispatch('setFlowsFromTxnTarget', { id, network })
      } else {
        await dispatch('getEntitySummary', { id, type, network })
      }
      commit('SET_TARGET', { id, name, network, type } as Target)
      if (!state.graphedLifoTransaction) { // don't switch to summary tab if doing direct lifo tracing
        dispatch('setSidepanelTab', 'Summary')
      }
    }
  },
  setTargetNetwork({ commit }: { commit: Commit }, { network }: { network: string }) {
    commit('SET_TARGET_NETWORK', network)
  },
  async getEntitySummary(
    { commit }: { commit: Commit },
    { id, type, network }: { id: string; type: EntityType; network: string }
  ) {
    if (network === '') {
      const targetSupportedNetworks = []
      let summary: EntitySummary | undefined = undefined
      for (const supportedNetwork of state.supportedNetworks) {
        const response = await api.getSummary({ id, idType: type, network: supportedNetwork })
        if (!failedResponse(response) && response != null) {
          targetSupportedNetworks.push(supportedNetwork);
          ({ summary } = response)
        }
      }
      if (targetSupportedNetworks.length > 0 && summary != null) {
        commit('SET_TARGET_SUPPORTED_NETWORKS', targetSupportedNetworks)
        const currentNetwork = targetSupportedNetworks.includes(state.targetNetwork) ?
          state.targetNetwork :
          targetSupportedNetworks[targetSupportedNetworks.length - 1]
        commit('SET_TARGET_NETWORK', currentNetwork)
        commit('SET_ENTITY_SUMMARY', summary)
      } else {
        dispatch('updateSnackbar', {
          show: state.enableErrors,
          text: `summary fetch failed: no data for any supported networks`,
          timeout: -1
        })
      }
    } else {
      if (type !== 'attribution') {
        commit('SET_TARGET_SUPPORTED_NETWORKS', [network])
      }
      commit('SET_TARGET_NETWORK', network)
      const response = await api.getSummary({ id, idType: type, network })
      if (failedResponse(response)) {
        dispatch('updateSnackbar', {
          show: state.enableErrors,
          text: `summary fetch failed: ${response.message} ${JSON.stringify(response.original)}`,
          timeout: -1
        })
      } else if (response != null) {
        const { summary } = response
        commit('SET_ENTITY_SUMMARY', summary)
      }
    }
  },
  async traceIDs({ commit }: { commit: Commit }, { ids, endLoading }: { ids: NodeType[]; endLoading?: boolean }) {
    track('traceIDs', { ids })
    const processed: string[] = []
    for (const nodeId of new Set(ids.map(id => nodeTypeToIdString(id)))) {
      const { type } = classifyNodeId(nodeId)
      if (type !== 'transaction') {
        processed.push(nodeId)
      }
    }
    if (processed.length > 0) {
      commit('ADD_NEWEST_NODES', processed)
      const data = { ...graphDataFromTargets(processed), endLoading }
      await dispatch('addToGraph', data)
    }
  },
  async graphFullTransactions({ commit }: { commit: Commit }, { ids }: { ids: NetworkId[] }) {
    await Promise.all(filter(ids, id => id.network !== 'bitcoin' ).map(({ network, id }) => dispatch('getReceipts', { network, id })))
    await Promise.all(ids.map(({ id, network }) => api.getTransactionLedger({
      network,
      id,
      page: 1,
      perPage: 1000,
      simple: true,
      aggregated: true,
      groupBy: 'cluster',
      externalAttributions: state.useExternalApi ? state.externalApiRequest : undefined
    }))).then(async responses => {
      const nodeStrings: Set<string> = new Set()
      const txns: VizTransaction[] = []
      for (const [index, response] of responses.entries()) {
        if (failedResponse(response)) {
          dispatch('updateSnackbar', {
            show: state.enableErrors,
            text: `transaction fetch failed: ${response.message} ${JSON.stringify(response.original)}`,
            timeout: 500
          })
        } else if (response != null) {
          const network = ids[index].network
          const inputAttributions: { [node: string]: string | number } = {}
          const outputAttributions: { [node: string]: string | number } = {}
          const inputNodes: string[] = []
          const outputNodes: string[] = []
          for (const txn of response.ledger as AggregatedBitcoinTransaction[]) {
            const { cluster, clusterAttribution, isOutput, externalResult, amount, symbol, time, id } = txn
            let entity = nodeTypeToIdString(
              clusterAttribution ? { id: clusterAttribution, type: 'attribution', network }
              : { id: cluster || '', type: 'cluster', network }
            )
            const idNode = nodeTypeToIdString({ id, type: 'transaction', network })
            if (externalResult != null) {
              const { data: externalAttribution } = externalResult as ExternalResult
              if (externalAttribution != null) {
                entity = nodeTypeToIdString({ id: `ext-${externalAttribution}`, type: 'attribution', network })
                if (state.externalAttributionsCache.get(cluster!) == null) {
                  // cluster can't be undefined because that's the agg level
                  commit('ADD_EXTERNAL_ATTRIBUTION', { address: cluster, attribution: externalAttribution })
                }
              }
            }
            nodeStrings.add(entity)
            if (isOutput) {
              outputNodes.push(entity)
              if (classifyNodeId(entity).type === 'attribution') {
                if (entity in outputAttributions) {
                  outputAttributions[entity] = addBigNum(outputAttributions[entity], amount)
                } else {
                  outputAttributions[entity] = amount
                }
              } else { // it's a cluster
                txns.push({
                  id: `${idNode}|${entity}`,
                  sender: idNode,
                  receiver: entity,
                  amount,
                  symbol,
                  timestamp: time
                })
              }
            } else {
              inputNodes.push(entity)
              if (classifyNodeId(entity).type === 'attribution') {
                if (entity in inputAttributions) {
                  inputAttributions[entity] = addBigNum(inputAttributions[entity], amount)
                } else {
                  inputAttributions[entity] = amount
                }
              } else { // it's a cluster
                txns.push({
                  id: `${entity}|${idNode}`,
                  sender: entity,
                  receiver: idNode,
                  amount,
                  symbol,
                  timestamp: time
                })
              }
            }
          }
          const linksData: BasicLinkData[] = []
          const newestLinks: string[] = []
          for (const input of inputNodes) {
            const { id: sender, type: senderType } = classifyNodeId(input)
            for (const output of outputNodes) {
              const { id: receiver, type: receiverType } = classifyNodeId(output)
              linksData.push({
                sender,
                senderType: senderType as EntityType,
                receiver,
                receiverType: receiverType as EntityType
              })
              newestLinks.push(`${input}||${output}`)
            }
          }
          const { id, time, symbol } = response.ledger[0] as AggregatedBitcoinTransaction
          const idNode = nodeTypeToIdString({ id, type: 'transaction', network })
          for (const [entity, amount] of Object.entries(inputAttributions)) {
            txns.push({
              id: `${entity}|${idNode}`,
              sender: entity,
              receiver: idNode,
              amount,
              symbol,
              timestamp: time
            })
          }
          for (const [entity, amount] of Object.entries(outputAttributions)) {
            txns.push({
              id: `${idNode}|${entity}`,
              sender: idNode,
              receiver: entity,
              amount,
              symbol,
              timestamp: time
            })
          }
          if (!state.settings.autoLinksSwitch) {
            await dispatch('graphNodePairs', { linksData, network, endLoading: false })
          }
          commit('ADD_NEWEST_NODES', nodeStrings)
          commit('ADD_NEWEST_LINKS', newestLinks)
        }
      }
      const nodes = Array.from(nodeStrings).map(n => classifyNodeId(n))
      if (state.settings.autoLinksSwitch) {
        await dispatch('graphAllNodePairs', { nodes, allNew: false, endLoading: false })
      } else {
        await dispatch('traceIDs', { ids: nodes, endLoading: false })
      }
      commit('ADD_GRAPH_TXNS', txns)
      const data = graphDataFromLedger(txns, state.linkColors)
      dispatch('addToGraph', data)
    })
  },
  async graphPartialTransactions({ commit }: { commit: Commit }, { ids }: { ids: FilteredNetworkId[] }) {
    // TODO: apply receipt request to all evm chains, not just eth
    await Promise.all(filter(ids, id => id.network === 'ethereum' ).map(({ network, id }) => dispatch('getReceipts', { network, id })))
    await Promise.all(ids.map(({ id, network }) => api.getTransactionLedger({
      network,
      id,
      page: 1,
      perPage: 1000,
      simple: true,
      aggregated: true,
      groupBy: 'address',
      externalAttributions: state.useExternalApi ? state.externalApiRequest : undefined
    }))).then(async responses => {
      const uniqueNodes = new Set<string>()
      const txns: VizTransaction[] = []
      for (const [index, response] of responses.entries()) {
        if (failedResponse(response)) {
          dispatch('updateSnackbar', {
            show: state.enableErrors,
            text: `transaction fetch failed: ${response.message} ${JSON.stringify(response.original)}`,
            timeout: 500
          })
        } else if (response != null) {
          const network = ids[index].network
          const inputNodes: string[] = []
          const outputNodes: string[] = []
          const aggTransactions: { [key: string]: string | number } = {}
          for (const txn of response.ledger as AggregatedBitcoinTransaction[]) {
            const { address, cluster, clusterAttribution, isOutput, amount, externalResult } = txn
            // only graph if it's an input or if the output address matches the requested address
            if (!isOutput || address === ids[index].address) {
              let entity = nodeTypeToIdString(
                clusterAttribution ? { id: clusterAttribution, type: 'attribution', network }
                : cluster ? { id: cluster, type: 'cluster', network }
                : { id: address || '', type: 'address', network }
              )
              if (externalResult != null) {
                const { data: externalAttribution } = externalResult as ExternalResult
                if (externalAttribution != null) {
                  entity = nodeTypeToIdString({ id: `ext-${externalAttribution}`, type: 'attribution', network })
                  if (address && state.externalAttributionsCache.get(address) == null) {
                    commit('ADD_EXTERNAL_ATTRIBUTION', { address, attribution: externalAttribution })
                  }
                }
              }
              const key = `${entity}||${isOutput}`
              if (key in aggTransactions) {
                aggTransactions[key] = addBigNum(aggTransactions[key], amount)
              } else {
                aggTransactions[key] = amount
              }
            }
          }
          const { id, time: timestamp, symbol } = response.ledger[0] as AggregatedBitcoinTransaction
          const idNode = nodeTypeToIdString({ id, type: 'transaction', network })
          for (const [key, amount] of Object.entries(aggTransactions)) {
            const [entity, isOutputString] = key.split('||')
            uniqueNodes.add(entity)
            const isOutput = isOutputString === 'true'
            if (isOutput) {
              outputNodes.push(entity)
              txns.push({
                id: `${idNode}|${entity}`,
                sender: idNode,
                receiver: entity,
                amount,
                symbol,
                timestamp
              })
            } else {
              inputNodes.push(entity)
              txns.push({
                id: `${entity}|${idNode}`,
                sender: entity,
                receiver: idNode,
                amount,
                symbol,
                timestamp
              })
            }
          }
          const linksData: BasicLinkData[] = []
          const newestLinks: string[] = []
          for (const input of inputNodes) {
            const { id: sender, type: senderType } = classifyNodeId(input)
            for (const output of outputNodes) {
              const { id: receiver, type: receiverType } = classifyNodeId(output)
              linksData.push({
                sender,
                senderType: senderType as EntityType,
                receiver,
                receiverType: receiverType as EntityType
              })
              newestLinks.push(`${input}||${output}`)
            }
          }
          if (!state.settings.autoLinksSwitch) {
            await dispatch('graphNodePairs', { linksData, network, endLoading: false })
          }
          commit('ADD_NEWEST_NODES', uniqueNodes)
          commit('ADD_NEWEST_LINKS', newestLinks)
        }
      }
      const nodes = Array.from(uniqueNodes).map(n => classifyNodeId(n))
      if (state.settings.autoLinksSwitch) {
        await dispatch('graphAllNodePairs', { nodes, allNew: false, endLoading: false })
      } else {
        await dispatch('traceIDs', { ids: nodes, endLoading: false })
      }
      commit('ADD_GRAPH_TXNS', txns)
      const data = graphDataFromLedger(txns, state.linkColors)
      dispatch('addToGraph', data)
    })
  },
  async graphFileData({ commit }: { commit: Commit }, { data }: { data: DSVRowArray<string>[] }) {
    for (const sheet of data) {
      // look for addresses and transactions
      let addressField = '',
        transactionField = '',
        networkField = '',
        network = ''
      if (sheet.length > 0) {
        for (const [key, value] of Object.entries(sheet[0])) {
          if (value != null) {
            if (networkField === '') {
              const lowerCasedKey = key.toLowerCase().trim()
              if (lowerCasedKey === 'network' || lowerCasedKey === 'currency' || lowerCasedKey === 'asset') {
                networkField = key
                continue
              }
              const lowerCasedValue = value.toLowerCase()
              if (NETWORKS_SET.has(lowerCasedValue)) {
                networkField = key
                continue
              }
            }
            if (addressField === '' || transactionField === '') {
              const classified = classifyInput(value)
              if (isBitcoinClassification(classified)) {
                if (classified.isBitcoinTxid) {
                  transactionField = key
                } else if (
                  classified.isBitcoinAddress ||
                  classified.isBitcoinBech32Address ||
                  classified.isBitcoinBech32mAddress ||
                  classified.isBitcoinLegacyAddress
                ) {
                  addressField = key
                }
              } else if (isEthereumClassification(classified)) {
                if (classified.isEthBlockOrHash) {
                  transactionField = key
                } else if (classified.isEthAddress) {
                  addressField = key
                }
              }
              if (network === '' || addressField === key) { // network from address supercedes from transaction
                network = classified.networkType
              }
            }
          }
        }
        if (transactionField !== '' && addressField !== '') {
          const ids = filter(
            sheet.map((d) => ({ 
              id: d[transactionField],
              address: d[addressField],
              network: d[networkField] ? formatNetwork(d[networkField]!) : network})),
            ({ id }) => id != null && id !== '')
          if (ids.length) await dispatch('graphPartialTransactions', { ids })
        } else if (transactionField !== '') {
          const ids = filter(
            sheet.map((d) => ({
              id: d[transactionField],
              network: d[networkField] ? formatNetwork(d[networkField]!) : network})), 
            ({ id }) => id != null && id !== '')
          if (ids.length) await dispatch('graphFullTransactions', { ids })
        } else if (addressField !== '') { // only graph addresses if there were no transactions
          const networkAddresses = filter(
            sheet.map((d) => ({
              id: d[addressField],
              network: d[networkField] ? formatNetwork(d[networkField]!) : network})), 
            ({ id }) => id != null && id !== ''
          ).reduce((map, next) => {
            if (next.network in map) {
              map[next.network].push(next.id as string)
            } else {
              map[next.network] = [next.id as string]
            }
            return map
          }, {} as { [network: string]: string[] })
          const ids: NodeType[] = []
          for (const [network, addresses] of Object.entries(networkAddresses)) {
            await dispatch('getTargetClusters', { addresses, network })
            for (const address of addresses) {
              const cluster = state.shared.clusterAddressCache.get(address)
              if (cluster != null) {
                const { id, topAttribution } = cluster
                if (topAttribution != null && topAttribution !== '') {
                  ids.push({ id: topAttribution, type: 'attribution', network })
                } else {
                  ids.push({ id, type: 'cluster', network })
                }
              } else {
                ids.push({ id: address, type: 'address', network })
              }
            }
          }
          
          if (ids.length) {
            if (state.settings.autoLinksSwitch) {
              await dispatch('graphAllNodePairs', { nodes: ids, allNew: false })
            } else {
              await dispatch('traceIDs', { ids })
            }
          }
        }
      }
    }
  },
  async addToGraph(
    { commit }: { commit: Commit },
    { nodes, links, ranges, endLoading }: { nodes: Node[]; links: Link[]; ranges: Ranges; endLoading?: boolean }
  ) {
    dispatch('showLoadingDialog', { show: true, value: 60, text: 'Loading cluster data...' })
    commit('SET_GRAPH_LOADING', true)
    // put all the nodes together
    // calc new mins/maxes while adjusting nodes (if applicable)

    const flowMins: { [target: string]: number } = {}
    const flowMaxes: { [target: string]: number } = {}
    const hopMins: { [target: string]: number } = {}
    const hopMaxes: { [target: string]: number } = {}
    let appearanceMin = Infinity, appearanceMax = 0
    for (const flowKey of Object.keys(state.ranges.flows)) {
      flowMins[flowKey] = state.ranges.flows[flowKey].flows.min
      flowMaxes[flowKey] = state.ranges.flows[flowKey].flows.max
      hopMins[flowKey] = state.ranges.flows[flowKey].hops.min
      hopMaxes[flowKey] = state.ranges.flows[flowKey].hops.max
    }

    const existingNodes: { [id: string]: Node } = {}
    state.nodes.forEach((n) => {
      existingNodes[n.id] = n
    })
    nodes.forEach((n) => {
      const { id, appearance, flows, permanent } = n
      if (flows) {
        for (const flow of flows) {
          const { sending, targetTransaction, value, minHops } = flow
          const { id, isOutput, index } = targetTransaction
          const key = `${id}|${isOutput}|${index ?? 0}|${sending}`
          if (!flowMins[key]) {
            flowMins[key] = value
            flowMaxes[key] = value
            hopMins[key] = minHops
            hopMaxes[key] = minHops
          } else {
            if (flowMins[key] > value) flowMins[key] = value
            if (flowMaxes[key] < value) flowMaxes[key] = value
            if (hopMins[key] > minHops) hopMins[key] = minHops
            if (hopMaxes[key] < minHops) hopMaxes[key] = minHops
          }
        }
      }
      if (!existingNodes[id]) {
        // it's new, just stick it in and check mins/maxes
        existingNodes[id] = n
        if (appearance < appearanceMin) appearanceMin = appearance
        if (appearance > appearanceMax) appearanceMax = appearance
      } else {
        // it's old, adjust it accordingly
        const prev = existingNodes[id]
        if (flows && flows.length > 0) {
          if (prev.flows == null) {
            prev.flows = flows
          } else {
            // check if new flow
            const existingFlows = new Set(prev.flows.map(flow => {
              const { id, isOutput, index } = flow.targetTransaction
              return `${id}|${isOutput}|${index}|${flow.sending}`
            }))
            for (const flow of flows) {
              const { id, isOutput, index } = flow.targetTransaction
              const key = `${id}|${isOutput}|${index}|${flow.sending}`
              if (!existingFlows.has(key)) {
                prev.flows.push(flow)
              }
            }
          }
        }
        prev.permanent = prev.permanent || permanent
      }
    })

    // add in new links
    const existingLinks: { [id: string]: Link } = {}
    state.links.forEach((l) => {
      existingLinks[`${l.source}|${l.target}`] = l
    })
    const newLinks: Link[] = []
    links.forEach((l) => {
      const id = `${l.source}|${l.target}`
      if (!existingLinks[id]) {
        newLinks.push(l)
      } else {
        const prev = existingLinks[id]
        // combine flows
        const { flows } = l.amount
        const { amount: prevAmount } = prev
        if (flows && flows.length > 0) {
          if (prevAmount.flows == null) {
            prevAmount.flows = flows
          } else {
            // check if new flow
            const existingFlows = new Set(prevAmount.flows.map(flow => {
              const { id, isOutput, index } = flow.targetTransaction
              return `${id}|${isOutput}|${index}|${flow.sending}`
            }))
            for (const flow of flows) {
              const { id, isOutput, index } = flow.targetTransaction
              const key = `${id}|${isOutput}|${index}|${flow.sending}`
              if (!existingFlows.has(key)) {
                prevAmount.flows.push(flow)
              }
            }
          }
        }
        prev.permanent = prev.permanent || l.permanent
      }
    })

    const allLinks = [...Object.values(existingLinks), ...newLinks]

    // calc new ranges
    const rangesCopy = structuredClone(state.ranges)
    // flows
    const flowRanges: { [target: string]: FlowRange } = {}
    for (const key of Object.keys(flowMins)) {
      flowRanges[key] = {
        flows: {
          min: flowMins[key],
          max: flowMaxes[key]
        },
        hops: {
          min: hopMins[key],
          max: hopMaxes[key]
        }
      }
    }
    rangesCopy.flows = flowRanges
    // appearance
    rangesCopy.appearance = {
      min: Math.min(appearanceMin, rangesCopy.appearance.min),
      max: Math.max(appearanceMax, rangesCopy.appearance.max)
    }

    await dispatch('getInvestigationAttributions', { nodes })
    // get clusters by network
    const networkNodes: { [network: string]: Node[] } = {}
    for (const node of nodes) {
      const { type, id } = classifyNodeId(node.id)
      if (type === 'attribution') {
        // add for each available network
        for (const network of state.supportedNetworks) {
          if (network in networkNodes) {
            networkNodes[network].push(node)
          } else {
            networkNodes[network] = [node]
          }
        }
      } else {
        const network = classifyInput(id).networkType
        if (network in networkNodes) {
          networkNodes[network].push(node)
        } else {
          networkNodes[network] = [node]
        }
      }
    }
    for (const [network, nodes] of Object.entries(networkNodes)) {
      await dispatch('getInvestigationClusters', { nodes, network })

      // set node sizes and appearances, plus ranges
      await dispatch('setNodeFields', {
        nodes,
        ranges: rangesCopy,
        network
      })
    }

    const allNodes = Object.values(existingNodes)
    commit('SET_NODES', allNodes)
    commit('SET_LINKS', allLinks)

    if (links.length) await dispatch('setSummaryLinkTransactions') // only need to do this if adding links

    if (endLoading == null || endLoading) {
      await sleep(300) // the watcher trigger in Graph component doesn't catch the change if it's too fast
      commit('SET_GRAPH_LOADING', false)
    }
  },
  async setNodeFields(
    { commit }: { commit: Commit },
    { nodes, ranges, network }: { nodes: Node[]; ranges: Ranges; network: string }
  ) {
    for (const node of nodes) {
      const { id, type } = classifyNodeId(node.id)
      if (type === 'address' && node.unspent == null) {
        const counterpartiesResponse = await api.getCounterparties({
          network,
          id,
          idType: 'address',
          page: 1,
          perPage: 1,
          isOutput: true
        })
        if (failedResponse(counterpartiesResponse)) {
          node.unspent = (node.unspent == null ? true : node.unspent) && counterpartiesResponse.status === 404
        }
      }
    }

    commit('SET_RANGES', ranges)
  },
  async macrotize({ commit }: { commit: Commit }, { id, type, network }: Target) {
    const clusterMetadata = state.shared.clusterAddressCache.get(id)
    if (clusterMetadata == null) {
      console.warn('no cluster stored for the id attempted to be macrotized')
      return
    }
    const currentNode = nodeTypeToIdString({ id, type, network })
    let macroId: string, macroType: 'attribution' | 'cluster'
    const { id: clusterId, topAttribution } = clusterMetadata
    if (topAttribution) {
      macroId = topAttribution
      macroType = 'attribution'
    } else {
      macroId = clusterId
      macroType = 'cluster'
    }
    const macroNode = nodeTypeToIdString({ id: macroId, type: macroType, network })

    let macrotized: Macrotized

    // check to see if the macro node already exists
    const macroIndex = state.nodes.findIndex((n) => n.id === macroNode)

    // get index of micro node
    const targetIndex = state.nodes.findIndex((n) => n.id === currentNode)

    if (macroIndex === -1) {
      // just change all references to the currentId into the macroId
      const ledgerCopy: VizTransaction[] = structuredClone(state.ledger)
      for (const txn of ledgerCopy) {
        const { sender, receiver } = txn
        if (sender === currentNode) {
          txn.sender = macroNode
        } else if (receiver === currentNode) {
          txn.receiver = macroNode
        }
      }
      commit('SET_LEDGER', ledgerCopy)

      commit('CHANGE_NODE_ID', { index: targetIndex, id: macroNode })

      const linksCopy: Link[] = structuredClone(state.links)
      const changedLinks: ChangedLink[] = []
      for (const [index, link] of linksCopy.entries()) {
        const { source, target } = link
        if (source === currentNode) {
          link.source = macroNode
          changedLinks.push({
            index,
            deleted: false,
            side: 'source',
            macro: false,
            counterparty: target
          })
        } else if (target === currentNode) {
          link.target = macroNode
          changedLinks.push({
            index,
            deleted: false,
            side: 'target',
            macro: false,
            counterparty: source
          })
        }
      }
      commit('SET_LINKS', linksCopy)

      macrotized = {
        deleted: false,
        oldEntity: currentNode,
        macro: macroNode,
        macroIndex: targetIndex,
        changedLinks
      }
    } else {
      // the macro node already exists, so merge the target node into it
      const targetTxnAmountsMap: { [key: string]: TransAmount } = {}
      const macroInteractions = new Set<string>() // keep track to delete dupe txns, old links
      const currentInteractions: { key: string, ledgerIndex: number }[] = [] // keep track to delete dupe txns
      const ledgerCopy: VizTransaction[] = structuredClone(state.ledger)
      for (const [ledgerIndex, txn] of ledgerCopy.entries()) {
        const { sender, receiver } = txn
        if (sender === currentNode) {
          const key = `${receiver}_out`
          currentInteractions.push({ key, ledgerIndex })
        } else if (receiver === currentNode) {
          const key = `${sender}_in`
          currentInteractions.push({ key, ledgerIndex })
        } else if (sender === macroNode) {
          macroInteractions.add(`${receiver}_out`)
        } else if (receiver === macroNode) {
          macroInteractions.add(`${sender}_in`)
        }
      }
      let subtractIndices = 0
      for (let { key, ledgerIndex } of currentInteractions) {
        ledgerIndex -= subtractIndices
        if (macroInteractions.has(key)) { // it's duplicate data, so just delete
          ledgerCopy.splice(ledgerIndex, 1)
          subtractIndices++
        } else { // adjust the ledger accordingly
          const txn = ledgerCopy[ledgerIndex]
          const { sender, receiver, amount, symbol, timestamp, flows } = txn
          const partialTransAmount = {
            amount,
            symbol,
            timestamp,
            flows
          }
          if (sender === currentNode) {
            txn.sender = macroNode
            const transAmount: TransAmount = { id: `${macroNode}|${receiver}`, ...partialTransAmount }
            targetTxnAmountsMap[key] = transAmount
          } else if (receiver === currentNode) {
            txn.receiver = macroNode
            const transAmount: TransAmount = { id: `${sender}|${macroNode}`, ...partialTransAmount }
            targetTxnAmountsMap[key] = transAmount
          }
        }
      }
      commit('SET_LEDGER', ledgerCopy)

      const appearance = Math.min(state.nodes[targetIndex].appearance, state.nodes[macroIndex].appearance)
      commit('CHANGE_NODE_ID', { index: macroIndex, id: macroNode }) // change id in case it's not prefixed yet
      commit('ADJUST_NODE_APPEARANCE', { index: macroIndex, appearance })
      commit('DELETE_NODE', targetIndex)

      // use trans amounts from target to adjust macro links
      const linksCopy: Link[] = structuredClone(state.links)
      const changedLinks: ChangedLink[] = []
      let linkIndex = 0
      let newLinksStart = linksCopy.length
      while (linkIndex < linksCopy.length) {
        // use while loop to delete links that will be merged
        const { source, target, amount: linkAmount } = linksCopy[linkIndex]
        let key = ''
        if (source === currentNode) {
          if (macroInteractions.has(`${target}_out`)) {
            // will merge with macro, so delete this link
            linksCopy.splice(linkIndex, 1)
            changedLinks.push({
              index: linkIndex,
              deleted: true,
              side: 'source',
              macro: false,
              counterparty: target
            })
            newLinksStart--
            continue
          } else {
            // just replace the entity
            linksCopy[linkIndex].source = macroNode
            changedLinks.push({
              index: linkIndex,
              deleted: false,
              side: 'source',
              macro: false,
              counterparty: target
            })
          }
        } else if (target === currentNode) {
          if (macroInteractions.has(`${source}_in`)) {
            linksCopy.splice(linkIndex, 1)
            changedLinks.push({
              index: linkIndex,
              deleted: true,
              side: 'target',
              macro: false,
              counterparty: source
            })
            newLinksStart--
            continue
          } else {
            linksCopy[linkIndex].target = macroNode
            changedLinks.push({
              index: linkIndex,
              deleted: false,
              side: 'target',
              macro: false,
              counterparty: source
            })
          }
        } else if (source === macroNode) {
          key = `${target}_out`
        } else if (target === macroNode) {
          key = `${source}_in`
        }
        if (key !== '' && key in targetTxnAmountsMap) {
          // there's relevant target data also, combine trans amounts
          const { flows } = targetTxnAmountsMap[key]
          if (flows != null) {
            if (linkAmount.flows) {
              // TODO: combine the flows data
            } else {
              linkAmount.flows = flows
            }
          }
          delete targetTxnAmountsMap[key]
          const [counterparty, sideCode] = key.split('_')
          changedLinks.push({
            index: linkIndex,
            deleted: false,
            side: sideCode === 'in' ? 'target' : 'source',
            macro: true,
            counterparty
          })
        }
        linkIndex++
      }
      commit('SET_LINKS', linksCopy)

      macrotized = {
        deleted: true,
        oldEntity: currentNode,
        macro: macroNode,
        macroIndex: macroIndex,
        changedLinks,
        newLinksStart
      }
    }

    if (macroIndex === -1) { // if macro didn't exist, change size of macrotized micro based on cluster data
      await dispatch('getInvestigationClusters', { nodes: [{ id: macroNode }], network })
      let size = 0
      switch (macroType) {
        case 'cluster':
          const metadata = state.shared.clusterAddressCache.get(macroId)
          if (metadata != null) {
            size = metadata.size
          }
          break
        case 'attribution':
          size = 1
          const networkSizeMap = state.shared.attributionSizeCache.get(macroId)
          if (networkSizeMap != null) {
            size = Object.values(networkSizeMap).reduce((a, b) => a + b, 0)
          }
      }
    }

    await dispatch('setSummaryLinkTransactions')

    commit('MACROTIZED', macrotized)

    if (state.target != null && state.target.id === id) {
      dispatch('setTarget', { id: macroId, network, type: macroType } as Target)
    }

    dispatch('getReport', { network })
  },
  macrotizedHandled({ commit }: { commit: Commit }) {
    commit('MACROTIZED', undefined)
  },
  removeNodes({ commit }: { commit: Commit }, { ids }: { ids: string[] }) {
    if (!ids.length) {
      commit('CLEAR_NODES_TO_REMOVE')
    } else {
      commit('SET_NODES_TO_REMOVE', { ids })
    }
  },
  removeLinks({ commit }: { commit: Commit }, { links }: { links: string[] }) {
    if (!links.length) {
      commit('CLEAR_LINKS_TO_REMOVE')
    } else {
      commit('SET_LINKS_TO_REMOVE', { links })
    }
  },
  graphRedrawn({ commit }: { commit: Commit }) {
    commit('SET_GRAPH_REDRAW', false)
  },
  updateGraphState(
    { commit }: { commit: Commit },
    { nodes, nodesRemoved, notes, notesRemoved, links, linksRemoved, summaryLinks, summaryLinksRemoved, app }: FormattedGraph
  ) {
    commit('SET_NODES_FORMATTED', nodes)
    commit('SET_NODES_REMOVED', nodesRemoved)
    commit('SET_NOTE_NODES', notes)
    commit('SET_NOTES_REMOVED', notesRemoved)
    commit('SET_LINKS_FORMATTED', links)
    commit('SET_LINKS_REMOVED', linksRemoved)
    commit('SET_SUMMARY_LINKS_FORMATTED', summaryLinks)
    commit('SET_SUMMARY_LINKS_REMOVED', summaryLinksRemoved)

    const deletedLinks: Edge[] = []
    for (const link of linksRemoved) {
      deletedLinks.push({
        source: link.source.id,
        target: link.target.id
      })
    }
    for (const link of summaryLinksRemoved) {
      deletedLinks.push({
        source: link.source.id,
        target: link.target.id
      })
    }
    commit('SET_DELETED_LINKS', deletedLinks)

    // TODO: figure out how to do this with the web worker (can't serialize as is)
    // const canvas = app.renderer.extract.canvas(app.stage)
    // if (canvas.toBlob) {
    //   canvas.toBlob(
    //     (snapshot: Blob | null) => {
    //       commit('SET_SNAPSHOT', { snapshot })
    //       dispatch('updateInvestigation')
    //     },
    //     'image/png',
    //     1
    //   )
    // }
    dispatch('updateInvestigation')
  },
  selectNode({ commit }: { commit: Commit }, node: string) {
    track('selectNode', { node })
    commit('SET_SELECTED_NODE', node)
  },
  highlightEdge({ commit }: { commit: Commit }, edge: string[]) {
    track('highlightEdge', { edge })
    commit('HIGHLIGHT_EDGE', edge)
  },
  selectLink({ commit }: { commit: Commit }, { link }: { link?: FormattedLink | FormattedSummaryLink }) {
    track('selectLink', { link: link ? `${link.source.id} -> ${link.target.id}` : null })
    commit('SET_SELECTED_LINK', link)
    commit('SET_SUMMARY_LINK_DIRECTION', 0)
    if (link !== undefined) {
      if (!isTransactionLevel(link)) {
        const targetNetwork = link.linkSummaries.map(s => s.network).includes(state.targetNetwork) ?
          state.targetNetwork :
          link.linkSummaries.length > 0 ? link.linkSummaries[0].network : '' // should never be ''
        commit('SET_TARGET_NETWORK', targetNetwork)
      } else {
        const network = symbolNetwork(link.amount.symbol)
        commit('SET_TARGET_NETWORK', network)
      }
      dispatch('setSidepanelTab', 'Summary')
      // dispatch('setFlowsFromLink', { link })
    }
  },
  renameTargetNode({ commit }: { commit: Commit }, { name }: { name?: string }) {
    commit('SET_NODE_RENAME', name)
  },
  renameTarget({ commit }: { commit: Commit }, { name }: { name: string }) {
    commit('RENAME_TARGET', name)
  },
  freezeNodes({ commit }: { commit: Commit }) {
    commit('INCREMENT_FREEZE')
  },
  unfreezeNodes({ commit }: { commit: Commit }) {
    commit('INCREMENT_UNFREEZE')
  },
  // osint
  osintResultExpanded({ commit }: { commit: Commit }, resultData: string) {
    dispatch('addHistory', {
      type: 'result',
      target: resultData
    })
  },
  linkCopied({ commit }: { commit: Commit }, link: string) {
    dispatch('addHistory', {
      type: 'link',
      target: link
    })
  },
  keywordSearched({ commit }: { commit: Commit }, searchData: string) {
    dispatch('addHistory', {
      type: 'search',
      target: searchData
    })
  }
})
