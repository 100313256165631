
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { ExtendedCoinbase, ExtendedInput, ExtendedTx, isExtendedCoinbase, TxHeader } from '@/types/bitcoin'
import { decodeBtcHex, isDust } from '@/utils/bitcoin'
import { copyToClipboard, cutMiddle } from '@/utils/general'
import { ClusterMetadata, Attribution, EntitySummary } from '@/utils/api'
import { BtcHerustics } from '@/store/chain'
import { mapState } from 'vuex'
import { prettyRoundedNumber } from '@/utils/general'
import { LRUCache } from '@splunkdlt/cache'

@Component({
  computed: mapState(['clusterSummaries', 'clusterSummariesUpdated'])
})
export default class BtcInputCard extends Vue {
  public isExtendedCoinbase = isExtendedCoinbase
  public cutMiddle = cutMiddle
  public decodeHex = decodeBtcHex
  public isDust = isDust
  public show = false
  public clusterId: string = ''
  public attribution = ''
  public heuristics = {
    haveHeuristics: false,
    haveHeader: false,
    svb: 0,
    rbf: false,
    locktime: 0,
    version: 0,
    witness: false,
    multisig: ''
  }
  public size: string = 'Loading'
  public transactionCount: string = 'Loading'

  @Prop() transaction!: ExtendedTx
  @Prop() item!: ExtendedInput | ExtendedCoinbase
  @Prop() network!: string
  @Prop() color!: string
  @Prop() selected!: boolean
  @Prop() update!: number

  public clusterSummaries!: LRUCache<string, EntitySummary>
  public clusterSummariesUpdated!: number

  get humanize() {
    return this.$store.state.dateHumanizer
  }

  get isCoinbase() {
    const inputs = this.transaction.vin
    if (inputs && isExtendedCoinbase(inputs[0])) {
      return true
    }
    return false
  }

  get shortenedMultisig() {
    if (!isExtendedCoinbase(this.item)) {
      const { type } = this.item.spentOutput
      if (type.startsWith('ms')) {
        const [numerator, denominator] = type.split('/')
        return `${numerator.slice(-1)}/${denominator.slice(0, 1)}`
      }
    }
    return ''
  }

  @Watch('update')
  created() {
    this.setAttribution()
    // this.heuristicsCacheUpdated()
    this.txnHeadersCacheUpdated()
  }

  public cardClasses(hover: boolean, selected: boolean): string {
    if (selected) {
      return 'hover selected'
    }
    if (hover) {
      return 'hover'
    }
    return ''
  }

  public getAttributions(address: string): Attribution[] {
    const has = this.$store.state.shared.attributionsCache.has(address)
    return has ? this.$store.state.shared.attributionsCache.get(address) : []
  }

  public getAttribution(address: string): string {
    const attributions = this.getAttributions(address)
    if (attributions.length > 0) {
      const valueSet = new Set(attributions.flatMap((a) => a.attributions))
      if (valueSet.size > 0) {
        return `${Array.from(valueSet).join(', ')}`
      }
    }
    return ''
  }

  public getCluster(address: string): ClusterMetadata | null {
    const has = this.$store.state.shared.clusterAddressCache.has(address)
    return has ? this.$store.state.shared.clusterAddressCache.get(address) : null
  }

  public getClusterAttribution(address: string): { attribution?: string; clusterId?: string } {
    const cluster = this.getCluster(address)
    if (cluster != null) {
      const { id, topAttribution, size } = cluster
      this.clusterId = id
      if (topAttribution != null && topAttribution !== '') {
        return {
          attribution: topAttribution,
          clusterId: id
        }
      }
      this.size = prettyRoundedNumber(size)
      return {
        clusterId: cluster.id
      }
    }
    this.clusterId = ''
    return {}
  }

  public getLabel(attribution: string, cluster: { attribution?: string; clusterId?: string }): string {
    if (cluster.attribution != null && cluster.attribution !== '') {
      return cluster.attribution
    }
    if (attribution != '') {
      return attribution
    }
    if (cluster.clusterId != null && cluster.clusterId !== '') {
      return cutMiddle(cluster.clusterId, 9)
    }
    return ''
  }

  public setAttribution() {
    if (!isExtendedCoinbase(this.item)) {
      if (this.item.spentOutput.address != null && this.item.spentOutput.address !== '') {
        const clusterAttribution = this.getClusterAttribution(this.item.spentOutput.address)
        const attribution = this.getAttribution(this.item.spentOutput.address)
        this.attribution = this.getLabel(attribution, clusterAttribution)
      }
    }
  }

  public humanizeAge(input: ExtendedInput): string {
    const age = isExtendedCoinbase(input) ? 0 : input.time - input.spentOutput.time
    return this.humanize(age * 1000)
  }

  public copy(text: string) {
    copyToClipboard(text)
    this.$store.dispatch('updateSnackbar', { show: true, text: `Copied ${text} to clipboard.` })
  }

  public toggleShow() {
    if (!this.show && this.clusterId) {
      this.$store.dispatch('getClusterSummary', { network: this.network, id: this.clusterId })
    }
    this.show = !this.show
  }

  @Watch('$store.state.heuristicsCacheCount')
  private heuristicsCacheUpdated() {
    if (!isExtendedCoinbase(this.item)) {
      if (this.$store.state.heuristicsCache.has(this.item.previousOutput)) {
        const h: BtcHerustics = this.$store.state.heuristicsCache.get(this.item.previousOutput)
        this.heuristics.svb = h.svb
        this.heuristics.haveHeuristics = true
      }
    }
  }

  @Watch('$store.state.txnHeadersCacheCount')
  private txnHeadersCacheUpdated() {
    if (!isExtendedCoinbase(this.item)) {
      if (this.$store.state.txnHeadersCache.has(this.item.previousOutput)) {
        const h: TxHeader = this.$store.state.txnHeadersCache.get(this.item.previousOutput)
        this.heuristics.locktime = h.locktime
        this.heuristics.version = h.version
        this.heuristics.witness = h.segwit
        this.heuristics.rbf = h.rbf
        this.heuristics.multisig = this.shortenedMultisig
        this.heuristics.haveHeader = true
      }
    }
  }

  @Watch('clusterSummariesUpdated')
  private summariesUpdated() {
    const summary = this.clusterSummaries.get(this.clusterId)
    if (summary != null) {
      this.transactionCount = prettyRoundedNumber(summary.symbols.bitcoin.currentTransactionCount)
    }
  }
}
