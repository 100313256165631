
import { Component, Vue, Watch } from 'vue-property-decorator'
import Blocks from '@/components/explorer/Blocks.vue'
import { mapState } from 'vuex'
import { titleCase, ServerEvent, sleep } from '@/utils/general'
import { ExtendedTx, SimpleAggregatedTransaction } from '@/types/bitcoin'
import { ComparisonConstraints, SortMap } from '@/utils/api'
import FilterBar from '@/subcomponents/FilterBar.vue'
import ServerTable from '@/subcomponents/ServerTable.vue'
import { BITCOIN_START, currentCryptotime } from '@/utils/bitcoin'

@Component({
  components: {
    Blocks,
    FilterBar,
    ServerTable
  },
  computed: mapState(['entityLedgerSimple', 'entityLedgerFull', 'listeningToEvents'])
})
export default class ExplorerNetwork extends Vue {
  public network: string = ''
  public networkDisplayName: string = ''

  public entityLedgerSimple!: SimpleAggregatedTransaction[]
  public entityLedgerFull!: ExtendedTx[]
  public listeningToEvents!: boolean
  
  public perPage: number = 100
  public page: number = 1
  public perPageOptions = [10, 25, 50, 100]
  public loading = true
  public pageView: 'Transactions' | 'Blocks' = 'Transactions'

  public sorts = [
    { label: 'time', key: 'time' },
    { label: 'value', key: 'amount' }
  ]
  public sort: SortMap = { time: 'desc' }

  private amountConstraint?: ComparisonConstraints
  private timeConstraint?: ComparisonConstraints
  private inputs: boolean = true
  private outputs: boolean = true

  private countFromStream: number = 0

  get totalCount(): number {
    return this.countFromStream > 0 ? this.countFromStream : this.$store.state.entityLedgerCount || 0
  }

  @Watch('entityLedgerSimple')
  async ledgerUpdated() {
    this.loading = false
  }

  async created() {
    this.network = this.$route.params.network
    this.networkDisplayName = titleCase(this.network)
    await this.refreshPageView()
  }

  private async refreshData() {
    if (this.pageView === 'Transactions') {
      await this.getFilteredTxns()
    }
  }

  private async getFilteredTxns() {
    const network = this.network
    const amountConstraint = this.amountConstraint
    const timeConstraint = this.timeConstraint ?? {}
    const currentTime = Math.floor(currentCryptotime())
    if (timeConstraint.gte == null) {
      timeConstraint.gte = currentTime - 15552000 // about six months ago
    }
    if (timeConstraint.lte == null) {
      timeConstraint.lte = currentTime
    }
    const params = {
      network,
      page: this.page,
      perPage: this.perPage,
      sorts: this.sort,
      amountConstraint,
      timeConstraint,
      inputs: this.inputs,
      outputs: this.outputs
    }
    const countParams = {
      network,
      amountConstraint,
      timeConstraint,
      stream: true
    }
    this.countFromStream = 0
    await this.$store.dispatch('getEntity', params)
    if (!this.listeningToEvents) {
      await sleep(1000)
    }
    this.$store.dispatch('getEntityLedgerCount', countParams)
  }

  public async refreshPageView() {
    this.loading = true
    await this.refreshData()
    this.loading = false
  }

  public onPageUpdated(page: number) {
    this.page = page
    this.refreshPageView()
  }

  public onItemsPerPageUpdated(count: number) {
    this.perPage = count
    this.refreshPageView()
  }

  public onUpdateSort(sort: SortMap) {
    this.sort = sort
  }

  public onFilter({
    amount,
    time,
    inputs,
    outputs
  } : {
    amount?: ComparisonConstraints
    time?: ComparisonConstraints
    inputs: boolean
    outputs: boolean
  }) {
    this.amountConstraint = amount
    this.timeConstraint = time
    this.inputs = inputs
    this.outputs = outputs
    this.refreshPageView()
  }

  @Watch('$store.state.eventCount')
  private newEvent() {
    const eventsLength = this.$store.state.eventStack.length
    if (eventsLength > 0) {
      const event: ServerEvent = this.$store.state.eventStack[eventsLength - 1]
      if (event.type === 'count') {
        this.countFromStream = event.data as number
      }
    }
  }
}
