
import { NftMetadata } from '@/store/chain'
import {
  EventData,
  FormattedLogEvent,
  FormattedTransaction,
  FunctionCall,
  isContractInfo,
  isFormattedTransaction
} from '@/types/eth'
import { trimZeros } from '@/utils/bignum'
import {
  displayName,
  formatERC20,
  formatEth,
  formatGas,
  getNftInfo,
  isAtomicMatch,
  isErc20FromTo,
  isErc20To,
  isErc20Transfer,
  isNftTransfer,
  isNftTransferSingle,
  wyvernAtomicMatchDisplay
} from '@/utils/eth'
import { titleCase } from '@/utils/general'
import { filter } from '@/utils/filters'
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'
import { Component, Vue, Watch } from 'vue-property-decorator'

function handleMetadataUrl(raw: string, ipfsGateway: string): string | NftMetadata | undefined {
  const url =
    typeof raw === 'string' && raw.startsWith('data:application/json;utf8,')
      ? <NftMetadata>JSON.parse(raw.replace('data:application/json;utf8,', ''))
      : raw
  if (typeof url === 'string' && url.startsWith('ipfs://')) {
    return url.replace('ipfs://', ipfsGateway)
  } else if (typeof url === 'string') {
    return url
  } else if (typeof url === 'object') {
    return <NftMetadata>url
  }
  return undefined
}

@Component({
  components: {
    VueJsonPretty
  }
})
export default class TransactionEthereum extends Vue {
  private network: string = ''
  private txnId: string = ''
  public networkDisplayName: string = ''
  private triedToFetchMetadata: boolean = false
  public formatEth = formatEth
  public formatGas = formatGas
  public formatERC20 = formatERC20
  public displayName = displayName
  public trimZeros = trimZeros
  public isErc20Transfer = isErc20Transfer
  public isErc20To = isErc20To
  public isErc20FromTo = isErc20FromTo
  public wyvernAtomicMatchDisplay = wyvernAtomicMatchDisplay
  public isContractInfo = isContractInfo

  get transaction() {
    return this.$store.state.transaction
  }

  get block() {
    return this.$store.state.block
  }

  get receipts() {
    return this.$store.state.receipts
  }

  get tokenMetadata() {
    return this.$store.state.tokenMetadata
  }

  created() {
    this.network = this.$route.params.network.toLowerCase()
    this.txnId = this.$route.params.txnId
    this.networkDisplayName = titleCase(this.network)
    this.getBlock()
    this.getReceipts()
  }

  @Watch('$store.state.transaction')
  private getBlock() {
    if (this.transaction != null) {
      this.$store.dispatch('getBlock', { network: this.network, id: this.transaction.blockNumber })
    }
  }

  private getReceipts() {
    this.$store.dispatch('getReceipts', { network: this.network, id: this.txnId })
  }

  public hasErc20Transfer(txn: FormattedTransaction | undefined): boolean {
    if (txn == null) {
      return false
    }
    return isErc20Transfer(txn.call, txn.toInfo)
  }

  public receiptsHaveErc20Transfer(receipts: FormattedLogEvent[] | undefined): boolean {
    if (receipts == null) {
      return false
    }
    for (const r of receipts) {
      if (isErc20Transfer(r.event, r.addressInfo)) {
        return true
      }
    }
    return false
  }

  public erc20TransferReceipts(receipts: FormattedLogEvent[]): FormattedLogEvent[] {
    return filter(receipts, (r) => isErc20Transfer(r.event, r.addressInfo))
  }

  public hasNftTransfer(txn: FormattedTransaction | undefined): boolean {
    if (txn == null) {
      return false
    }
    return isNftTransfer(txn.call) || isNftTransferSingle(txn.call)
  }

  public receiptsHaveNftTarnsfer(receipts: FormattedLogEvent[] | undefined): boolean {
    if (receipts == null) {
      return false
    }
    for (const r of receipts) {
      if (isNftTransfer(r.event) || isNftTransferSingle(r.event)) {
        return true
      }
    }
    return false
  }

  public nftTransferReceipts(receipts: FormattedLogEvent[]): FormattedLogEvent[] {
    return filter(receipts, (r) => isNftTransfer(r.event) || isNftTransferSingle(r.event))
  }

  public nftTransferDisplay(item: FormattedTransaction | FormattedLogEvent): string {
    const nftInfo = isFormattedTransaction(item)
      ? getNftInfo(item.call, item.toInfo)
      : getNftInfo(item.event!, item.addressInfo)
    const nft = nftInfo ? `${nftInfo.name} NFT ID ${nftInfo.tokenId}` : 'a NFT'
    return `Transfer ${nft} from ${nftInfo?.owner} to ${nftInfo?.recipient}`
  }

  public atomicMatch(txn: FormattedTransaction | undefined, receipts: FormattedLogEvent[] | undefined): boolean {
    if (txn == null || receipts == null) {
      return false
    }
    if (receipts.length === 0) {
      return false
    }
    return isAtomicMatch(txn.call)
  }

  public isContract(txn: FormattedTransaction | undefined, checkCall: boolean): boolean {
    if (txn == null) {
      return false
    }
    if (txn.toInfo && txn.toInfo.isContract) {
      if (checkCall) {
        if (txn.call) {
          return true
        } else {
          return false
        }
      }
      return true
    }
    return false
  }

  public hasTokenUri(call: FunctionCall | EventData): boolean {
    if (call == null) {
      return false
    }
    if (call.extra && call.extra.tokenUri) {
      if (!this.triedToFetchMetadata) {
        const url = handleMetadataUrl(call.extra.tokenUri, this.$store.state.ipfsGateway)
        if (typeof url === 'string') {
          this.$store.dispatch('getTokenUri', { url })
        } else if (typeof url === 'object') {
          this.$store.dispatch('setTokenMetadata', { metadata: url })
        }
        this.triedToFetchMetadata = true
      }
      return true
    }
    return false
  }

  public formatDate(epoch: number) {
    return this.$store.state.formatDate(epoch, false)
  }
}
