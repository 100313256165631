
import { TxnCounterpartiesMap } from '@/store/chain'
import { AggregatedTypedCounterparty } from '@/store/investigations/tabular'
import { ExtendedTx, SimpleAggregatedTransaction, TxHeader } from '@/types/bitcoin'
import { Attribution } from '@/utils/api'
import { divideBigNum, floatToInt } from '@/utils/bignum'
import { calcFee, rbfEnabled } from '@/utils/bitcoin'
import { cutMiddle, timeToMilliseconds } from '@/utils/general'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'

@Component({
  computed: mapState([
    'entityLedgerFull',
    'txnCounterpartiesMap'
  ])
})
export default class BtcTransactionItem extends Vue {
  @Prop() item!: SimpleAggregatedTransaction
  @Prop() index!: number
  @Prop() txnHeadersMap!: { [txid: string]: TxHeader }

  public entityLedgerFull!: { [key: string]: ExtendedTx }
  public txnCounterpartiesMap?: TxnCounterpartiesMap

  private attribution: string = ''

  public cutMiddle = cutMiddle

  created() {
    if (this.item.address != null) {
      this.attribution = this.getLabel(this.item.cluster ?? '', this.item.clusterAttribution)
    }
  }

  public getAttributions(address: string): Attribution[] {
    const has = this.$store.state.shared.attributionsCache.has(address)
    return has ? this.$store.state.shared.attributionsCache.get(address) : []
  }

  public getAttribution(address: string): string {
    const attributions = this.getAttributions(address)
    if (attributions.length > 0) {
      const valueSet = new Set(attributions.flatMap((a) => a.attributions))
      if (valueSet.size > 0) {
        return `${Array.from(valueSet).join(', ')}`
      }
    }
    return ''
  }

  public getLabel(clusterId: string, clusterAttribution?: string): string {
    if (clusterAttribution != null && clusterAttribution !== '') {
      return clusterAttribution
    }
    const attribution = this.getAttribution(this.item.address!) // this is only getting called if address != null
    if (attribution != '') {
      return attribution
    }
    if (clusterId !== '') {
      return cutMiddle(clusterId, 9)
    }
    return ''
  }

  public getRbf(txid: string): boolean {
    const txn: ExtendedTx | undefined = this.entityLedgerFull[txid]
    if (txn != null) {
      return rbfEnabled(txn)
    }
    return false
  }

  public getFee(simpleTxn: SimpleAggregatedTransaction): string {
    return calcFee(simpleTxn.totalInputValue, simpleTxn.totalOutputValue)
  }

  public getSvb(simpleTxn: SimpleAggregatedTransaction): string {
    const txn: ExtendedTx | undefined = this.entityLedgerFull[simpleTxn.id]
    if (txn != null) {
      const fee = calcFee(simpleTxn.totalInputValue, simpleTxn.totalOutputValue)
      const feeSats = floatToInt(fee, 8)
      return divideBigNum(feeSats, txn.vsize, 1)
    }
    return ''
  }

  public getWitness(txid: string): boolean {
    const header = this.txnHeadersMap[txid]
    if (header != null) {
      return header.segwit
    }
    return false
  }

  public formatValue(item: SimpleAggregatedTransaction): string {
    const value = item.amount
    if (item.isOutput) {
      return `${value}`
    }
    return `-${value}`
  }

  public formatCounterPartyValue(value: number | string, isOutput: boolean): string {
    if (isOutput) {
      return `${value}`
    }
    return `-${value}`
  }

  public valueColor(output: boolean): string {
    if (output) {
      return 'value-output'
    }
    return 'value-input'
  }

  public formatDate(time: number) {
    const epoch = timeToMilliseconds(time)
    return this.$store.state.formatDate(epoch, true)
  }

  public counterparties(item: SimpleAggregatedTransaction): AggregatedTypedCounterparty[] {
    if (this.txnCounterpartiesMap != null) {
      const { id, isOutput } = item
      return this.txnCounterpartiesMap[`${id}${isOutput}`]
    }
    return []
  }
}
