
import { Component, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'
import VueJsonPretty from 'vue-json-pretty'

@Component({
  components: {
    VueJsonPretty
  },
  computed: mapState([])
})
export default class UserAccount extends Vue {
  public apiRequestInput = '{}'
  public apiRequestInputParses = false
  public apiRequestInputParsed: any = {}

  created() {}

  public apiRequestInputChanged() {
    try {
      const parsed = JSON.parse(this.apiRequestInput)
      this.apiRequestInputParsed = parsed
      this.apiRequestInputParses = true
      this.$store.dispatch('setExternalApiRequest', { data: parsed })
    } catch (e) {
      this.apiRequestInputParses = false
    }
  }
}
