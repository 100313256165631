
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
@Component({})
export default class ColorPicker extends Vue {
  public internalColor: any = null
  @Prop() color!: string
  @Prop() onUpdate!: (color: string) => void
  @Prop() disabled!: boolean
  @Prop() swatches!: string[][]
  @Prop() buttonColor!: string
  @Prop() tooltip!: string
  @Prop() small!: boolean

  public colorUpdated(color: string) {
    if (this.onUpdate != null) {
      this.onUpdate(color)
    }
  }

  @Watch('color')
  created() {
    this.internalColor = this.color
  }
}
