
import { Component, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex';
@Component({
  computed: {
    ...mapState(['loadingValue', 'loadingDialogText'])
  }
})
export default class Loader extends Vue {
  public loadingValue!: number
  public loadingDialogText!: string

  get value() {
    return this.loadingValue
  }

  set value(value) {}
}
