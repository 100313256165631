
import { FormComponent } from '@/components/Dialog.vue'
import { Component } from 'vue-property-decorator'

@Component
export default class SaveInvestigation extends FormComponent {
  public name: string = ''

  doAction() {
    this.$store.dispatch('saveInvestigation', { name: this.name })
  }
}
