import { State } from '../index'
import { Commit, Dispatch } from 'vuex'
import {
  Api,
  FlowDirection,
  FlowResponse,
  FlowsRequest,
  ComparisonConstraints,
  TxnTriplet,
  FlowRequest,
  TripletCluster,
  failedResponse,
  FailureResponse,
  TransactionFlow,
  AggregatedBitcoinTransaction,
  HierarchicalAggregateFlows,
  HierarchicalAggregateFlowsRequest,
  AggregateFlowsRequestFilters,
  RawFlow,
  RawFlowsRequest,
  ExternalResult,
  BasicLinkData,
  EntityType,
  SingleFlowDirection,
  AccountingMethod
} from '@/utils/api'
import {
  barDataFromFlows,
  barDataFromSubnetwork,
  classifyNodeId,
  convertHopsToReadable,
  graphDataFromLedger,
  NodeType,
  nodeTypeToIdString,
  SingleTransactionFlow,
  SubnetworkFlowMap,
  SubnetworkFlowTransaction,
  TransactionFlowMap
} from '@/utils/viz'
import {
  Flow,
  FlowTarget,
  ForceControls,
  FormattedLink,
  FormattedNode,
  IdType,
  Target,
  TransactionFlowTarget,
  VizTransaction
} from './viz'
import { stringToNumber } from '@/utils/bignum'
import { filter } from '@/utils/filters'
import { convertHopsToDB } from '@/utils/viz'
import { classifyInput } from '@/utils/validate'
import { track } from '@/utils/tracking'

export type FlowsBar = { [key: string]: number }

export type FlowsBars = FlowsBar[]

type IOMap = { [txn: string]: { inputs: Set<string>; outputs: Set<string> } }

export interface MinimalFlow extends Omit<Flow, 'targetTransaction' | 'value'> {
  targetTransaction: TxnTriplet
}

export const flowsState = {
  flowTarget: <FlowTarget | undefined>undefined,
  highlightFlowKeys: Array<string>(),
  sendingFlows: <FlowsBars>[],
  receivingFlows: <FlowsBars>[],
  transactionFlowMap: <TransactionFlowMap>{}, // cluster data is in shared.clusterAddressCache
  sendingFlowsLoading: <boolean>false,
  receivingFlowsLoading: <boolean>false,
  sendingFlowsPage: <number>1,
  receivingFlowsPage: <number>1,
  sendingFlowsHops: <ComparisonConstraints>{
    gte: 1
  },
  receivingFlowsHops: <ComparisonConstraints>{
    gte: 1
  },
  sendingFlowsHopsList: Array<string>(),
  receivingFlowsHopsList: Array<string>(),
  // intersection subnetwork
  subnetworkSender: <FlowTarget | undefined>undefined,
  senderOutputs: Array<AggregatedBitcoinTransaction>(),
  subnetworkReceiver: <FlowTarget | undefined>undefined,
  receiverOutputs: Array<AggregatedBitcoinTransaction>(),
  subnetworkLoading: <boolean>false,
  subnetworkFlow: <FlowResponse | undefined>undefined,
  subnetworkFlowMap: <SubnetworkFlowMap | undefined>undefined, // cluster data is in shared.clusterAddressCache
  subnetworkFlows: <FlowsBars>[],
  subnetworkFlowsHopsList: Array<string>(),
  // treemap hierarchy
  hierarchy: <HierarchicalAggregateFlows | undefined>undefined,
  attributionFlows: new Map<string, HierarchicalAggregateFlows>(),
  attributionFlowsKey: <string>'',
  attributionFlowsFail: <number>0,
  // raw flows
  rawFlows: Array<RawFlow>(),
  rawFlowsLoaded: <boolean>false,
  rawFlowsGraphed: <boolean>false,
  rawFlowTransactions: Array<AggregatedBitcoinTransaction>(),
  rawFlowFilterSelectMode: <boolean>false
}

export const flowsMutations = {
  ADD_FLOW_TRANSACTION_MAP(state: State, { map }: { map: TransactionFlowMap }) {
    for (const key in map) {
      state.transactionFlowMap[key] = map[key]
    }
  },
  SET_FLOW_TARGET(state: State, { target }: { target: FlowTarget }) {
    state.flowTarget = target
  },
  CLEAR_FLOWS(state: State) {
    state.flowTarget = undefined
    state.sendingFlows = []
    state.receivingFlows = []
    state.transactionFlowMap = {}
    state.subnetworkSender = undefined
    state.subnetworkReceiver = undefined
    state.subnetworkFlow = undefined
    state.subnetworkFlowMap = {}
    state.subnetworkFlows = []
    state.subnetworkFlowsHopsList = []
    state.hierarchy = undefined
    state.attributionFlows = new Map<string, HierarchicalAggregateFlows>()
    state.rawFlows = []
  },
  SET_FLOWS_LOADING(state: State, { sending, value }: { sending: boolean; value: boolean }) {
    if (sending) {
      state.sendingFlowsLoading = value
    } else {
      state.receivingFlowsLoading = value
    }
  },
  SET_FLOWS(state: State, { sending, flows }: { sending: boolean; flows: FlowsBars }) {
    if (sending) {
      state.sendingFlows = flows
    } else {
      state.receivingFlows = flows
    }
  },
  SET_FLOWS_PAGE(state: State, { sending, page }: { sending: boolean; page: number }) {
    if (sending) {
      state.sendingFlowsPage = page
    } else {
      state.receivingFlowsPage = page
    }
  },
  SET_FLOWS_HOPS(state: State, { sending, hops }: { sending: boolean; hops: ComparisonConstraints }) {
    if (sending) {
      state.sendingFlowsHops = hops
    } else {
      state.receivingFlowsHops = hops
    }
  },
  SET_FLOWS_HOPS_LIST(state: State, { sending, hopsList }: { sending: boolean; hopsList: string[] }) {
    if (sending) {
      state.sendingFlowsHopsList = hopsList
    } else {
      state.receivingFlowsHopsList = hopsList
    }
  },
  SET_SUBNETWORK_SENDER(state: State, target: FlowTarget) {
    state.subnetworkSender = target
  },
  SET_SUBNETWORK_TARGET_OUTPUTS(
    state: State,
    { sender, outputs }: { sender: boolean; outputs: AggregatedBitcoinTransaction[] }
  ) {
    if (sender) state.senderOutputs = outputs
    else state.receiverOutputs = outputs
  },
  SET_SUBNETWORK_RECEIVER(state: State, target: FlowTarget) {
    state.subnetworkReceiver = target
  },
  SET_SUBNETWORK_LOADING(state: State, value: boolean) {
    state.subnetworkLoading = value
  },
  SET_SUBNETWORK_FLOW(state: State, { flow }: { flow: FlowResponse }) {
    state.subnetworkFlow = flow
  },
  SET_SUBNETWORK_MAP(state: State, { map }: { map: SubnetworkFlowMap }) {
    state.subnetworkFlowMap = map
  },
  SET_SUBNETWORK_FLOWS(state: State, { bars }: { bars: FlowsBars }) {
    state.subnetworkFlows = bars
  },
  SET_SUBNETWORK_HOPS_LIST(state: State, { hopsList }: { hopsList: string[] }) {
    state.subnetworkFlowsHopsList = hopsList
  },
  SET_HIERARCHY(state: State, { hierarchy }: { hierarchy: HierarchicalAggregateFlows }) {
    state.hierarchy = hierarchy
  },
  SET_ATTRIBUTION_FLOWS(state: State, { key, flows }: { key: string; flows: HierarchicalAggregateFlows }) {
    state.attributionFlows.set(key, flows)
    state.attributionFlowsKey = key
  },
  ATTRIBUTION_FLOWS_FAIL(state: State) {
    state.attributionFlowsFail++
  },
  SET_RAW_FLOWS(state: State, { flows }: { flows: RawFlow[] }) {
    state.rawFlows = flows
  },
  SET_RAW_FLOWS_LOADED(state: State, { loaded }: { loaded: boolean }) {
    state.rawFlowsLoaded = loaded
  },
  SET_RAW_FLOWS_GRAPHED(state: State, { graphed }: { graphed: boolean }) {
    state.rawFlowsGraphed = graphed
  },
  SET_RAW_FLOW_TXNS(state: State, { transactions }: { transactions: AggregatedBitcoinTransaction[] }) {
    state.rawFlowTransactions = transactions
  },
  SET_RAW_FLOW_FILTER_SELECT_MODE(state: State, { mode }: { mode: boolean }) {
    state.rawFlowFilterSelectMode = mode
  },
  SET_HIGHLIGHT_FLOW_KEYS(state: State, { keys }: { keys: string[] }) {
    state.highlightFlowKeys = keys
  }
}

const perPage = 500

export const flowsActions = (state: State, api: Api, dispatch: Dispatch) => ({
  // flows stuff
  async setFlowsFromTxnTarget({ commit }: { commit: Commit }, { id, network }: { id: string; network: string }) {
    // get txn data for input
    const res = await api.getTransactionLedger({
      id,
      network,
      page: 1,
      perPage: 1,
      simple: true,
      aggregated: true,
      groupBy: 'cluster',
      isOutput: false,
      index: 0,
      externalAttributions: state.useExternalApi ? state.externalApiRequest : undefined
    })
    if (failedResponse(res)) {
      dispatch('updateSnackbar', {
        show: state.enableErrors,
        text: `Transaction data couldn't be fetched: ${JSON.stringify(res.original)}`,
        timeout: -1
      })
      commit('TRANSACTIONS_LOADED')
      return
    }
    if (res) {
      track('setFlowsFromTxnTarget', { network, id })
      const transaction = res.ledger[0]
      dispatch('setFlows', { transaction, network })
    }
  },
  async setFlowsFromLink({ commit }: { commit: Commit }, { link }: { link: FormattedLink }) {
    const { source, target } = link
    let id, isOutput, entity
    const { id: sourceId, type: sourceType, network } = classifyNodeId((source as FormattedNode).id)
    const { id: targetId, type: targetType } = classifyNodeId((target as FormattedNode).id)
    if (sourceType === 'transaction') {
      id = sourceId
      isOutput = true
      entity = targetId
    } else if (targetType === 'transaction') {
      id = targetId
      isOutput = false
      entity = sourceId
    }
    if (id) { // will bypass if it's an entity to entity relationship
      // look up ledger to get min index
      const res = await api.getTransactionLedger({
        id,
        network,
        page: 1,
        perPage: 1000,
        simple: false,
        aggregated: true,
        groupBy: 'address',
        isOutput,
        sorts: { minIndex: 'asc' }
      })
      if (failedResponse(res)) {
        dispatch('updateSnackbar', {
          show: state.enableErrors,
          text: `Couldn't fetch transaction data for id ${id}: ${res.message} ${JSON.stringify(res.original)}`,
          timeout: -1
        })
        return
      }
      if (res) {
        const ledger = res.ledger as AggregatedBitcoinTransaction[]
        let found = false
        let index = 0
        while (!found && index < ledger.length) {
          const txn = ledger[index]
          const { address, cluster, clusterAttribution } = txn
          if (entity === address || entity === cluster || entity === clusterAttribution) {
            const transaction: AggregatedBitcoinTransaction = {
              ...txn,
              amount: stringToNumber(link.amount.amount) // use link amount in case it's the cluster/attribution
            }
            dispatch('setFlows', { transaction, network })
            found = true
          }
          index++
        }
      }
    }
  },
  async setFlows(
    { commit }: { commit: Commit },
    { transaction, network }: { transaction: AggregatedBitcoinTransaction; network: string }
  ) {
    commit('SET_RAW_FLOWS_LOADED', { loaded: false })
    commit('SET_RAW_FLOWS_GRAPHED', { graphed: false })
    commit('SET_FLOW_TARGET', { target: { network, transaction } as FlowTarget })
    const { id, minIndex: index, isOutput } = transaction
    const triplet: TxnTriplet = {
      id,
      index,
      isOutput
    }
    const transactions = [triplet]
    const page = 1
    const hops = { gte: 1 }
    const sendingHops = convertHopsToDB(hops, isOutput, true)
    const receivingHops = convertHopsToDB(hops, isOutput, false)

    dispatch('setSendingFlows', { network, transactions, page, hops: sendingHops })
    dispatch('setReceivingFlows', { network, transactions, page, hops: receivingHops })
  },
  async setSendingFlows(
    { commit }: { commit: Commit },
    {
      network,
      transactions,
      page,
      hops
    }: { network: string; transactions: TxnTriplet[]; page: number; hops: ComparisonConstraints }
  ) {
    const sending = true
    const direction: FlowDirection = 'source'
    const flowsRequest: FlowsRequest = { network, transactions, page, direction, hops, perPage }

    dispatch('fetchAndSetFlowBars', { sending, flowsRequest })
  },
  async setReceivingFlows(
    { commit }: { commit: Commit },
    {
      network,
      transactions,
      page,
      hops
    }: { network: string; transactions: TxnTriplet[]; page: number; hops: ComparisonConstraints }
  ) {
    const sending = false
    const direction: FlowDirection = 'destination'
    const flowsRequest: FlowsRequest = { network, transactions, page, direction, hops, perPage }

    dispatch('fetchAndSetFlowBars', { sending, flowsRequest })
  },
  async fetchAndSetFlowBars(
    { commit }: { commit: Commit },
    { sending, flowsRequest }: { sending: boolean; flowsRequest: FlowsRequest }
  ) {
    const { network, transactions, page, perPage, direction, hops, trace } = flowsRequest
    commit('SET_FLOWS_LOADING', { sending, value: true })
    const flows = await api.flows({ network, transactions, page, perPage, direction, hops, trace })
    if (failedResponse(flows)) {
      dispatch('updateSnackbar', {
        show: state.enableErrors,
        text: `Couldn't fetch ${sending ? 'sending' : 'receiving'} flows: ${flows.message} ${JSON.stringify(
          flows.original
        )}`,
        timeout: -1
      })
      commit('SET_FLOWS_LOADING', { sending, value: false })
      return
    }
    if (flows) {
      track('fetchAndSetFlowBars', { network })
      const {
        bars,
        map,
        hops: hopsList,
        addresses,
        attributions
      } = barDataFromFlows(state.flowTarget!.transaction.isOutput, sending, flows)
      commit('ADD_FLOW_TRANSACTION_MAP', { map })
      commit('SET_FLOWS', { sending, flows: bars })
      commit('SET_FLOWS_PAGE', { sending, page })
      commit('SET_FLOWS_HOPS', { sending, hops })
      commit('SET_FLOWS_HOPS_LIST', { sending, hopsList })

      const filteredAddresses = filter(addresses, (a) => state.shared.clusterAddressCache.get(a) == null)
      if (filteredAddresses.length > 0) {
        const clusters = await api.getClustersForAddresses({ network, ids: filteredAddresses })
        if (clusters) {
          for (const [address, cluster] of Object.entries(clusters)) {
            commit('CLUSTER_CACHE_ADD', { address, cluster })
          }
        }
      }

      await dispatch('getAttributionsClusters', { network, attributions })

      setTimeout(() => commit('SET_FLOWS_LOADING', { sending, value: false }), 1000) // the watchers don't catch the change without delay
    }
  },
  async graphFlows(
    { commit }: { commit: Commit },
    { sending, transactions }: { sending: boolean; transactions: SingleTransactionFlow[] }
  ) {
    if (state.flowTarget == null) {
      return
    }
    track('graphFlows')
    const { network, transaction } = state.flowTarget

    const {
      id: targetId,
      address: targetAddress,
      cluster: targetCluster,
      clusterAttribution: targetAttribution,
      isOutput: targetIsOutput,
      minIndex: targetIndex,
      amount: targetAmount,
      symbol: targetSymbol,
      time: targetTime
    } = transaction
    const nodeSet = new Set<string>()
    const txnSidesMap: IOMap = {}
    const txns: VizTransaction[] = transactions.map((t) => {
      const { id, address, cluster, clusterAttribution, amount, time, isOutput } = t.transaction
      const ghost = nodeTypeToIdString({ id, type: 'transaction', network })
      const entity = nodeTypeToIdString(
        clusterAttribution ? { id: clusterAttribution, type: 'attribution', network }
        : cluster ? { id: cluster, type: 'cluster', network }
        : { id: address || '', type: 'address', network }
      )
      nodeSet.add(entity)
      let sender, receiver
      if (isOutput) {
        sender = ghost
        receiver = entity
        if (!txnSidesMap[id]) {
          txnSidesMap[id] = { inputs: new Set(), outputs: new Set([entity]) }
        } else {
          txnSidesMap[id].outputs.add(entity)
        }
      } else {
        sender = entity
        receiver = ghost
        if (!txnSidesMap[id]) {
          txnSidesMap[id] = { inputs: new Set([entity]), outputs: new Set() }
        } else {
          txnSidesMap[id].inputs.add(entity)
        }
      }
      return {
        id: `${sender}|${receiver}`,
        sender,
        receiver,
        amount,
        timestamp: time,
        flows: [
          {
            sending,
            targetTransaction: {
              id: targetId,
              index: targetIndex,
              isOutput: targetIsOutput
            },
            value: t.amount,
            minHops: t.shortestPath
          }
        ]
      } as VizTransaction
    })
    const transactionNode = nodeTypeToIdString({ id: targetId, type: 'transaction', network })
    const entity = nodeTypeToIdString(
      targetAttribution ? { id: targetAttribution, type: 'attribution', network }
      : targetCluster ? { id: targetCluster, type: 'cluster', network }
      : { id: targetAddress || '', type: 'address', network }
    )
    nodeSet.add(entity)
    let sender, receiver
    if (targetIsOutput) {
      sender = transactionNode
      receiver = entity
      if (!txnSidesMap[targetId]) {
        txnSidesMap[targetId] = { inputs: new Set(), outputs: new Set([entity]) }
      } else {
        txnSidesMap[targetId].outputs.add(entity)
      }
    } else {
      sender = entity
      receiver = transactionNode
      if (!txnSidesMap[targetId]) {
        txnSidesMap[targetId] = { inputs: new Set([entity]), outputs: new Set() }
      } else {
        txnSidesMap[targetId].inputs.add(entity)
      }
    }
    txns.push({
      id: `${sender}|${receiver}`,
      sender,
      receiver,
      amount: targetAmount,
      symbol: targetSymbol,
      timestamp: targetTime
    })

    await dispatch('addNewestLinksFromIOMap', { txnSidesMap })
    if (state.settings.autoLinksSwitch) {
      const nodes = Array.from(nodeSet).map(n => classifyNodeId(n))
      await dispatch('graphAllNodePairs', { nodes, allNew: false, endLoading: false })
    } else {
      await dispatch('graphNodePairsFromIOMap', { txnSidesMap, network, endLoading: false })
    }

    commit('ADD_GRAPH_TXNS', txns)
    const data = graphDataFromLedger(txns, state.linkColors)
    const targetTransaction: TripletCluster = {
      ...transaction,
      cluster: targetCluster ?? '',
      address: targetAddress ?? '',
      index: targetIndex
    }
    const transactionFlowTarget: TransactionFlowTarget = { targetTransaction, sending }
    dispatch('addTransactionFlowTarget', transactionFlowTarget)
    const forces: ForceControls = {
      ...state.forces,
      xEmbedTarget: transactionFlowTarget
    }
    commit('SET_FORCES', forces)
    dispatch('addToGraph', data)
  },
  async graphFlowTransaction(
    { commit }: { commit: Commit },
    { sending, transactionFlow }: { sending: boolean; transactionFlow: SingleTransactionFlow }
  ) {
    track('graphFlowTransaction')
    if (state.flowTarget == null) return
    const { network, transaction } = state.flowTarget
    const {
      id: targetId,
      address: targetAddress,
      cluster: targetCluster,
      isOutput: targetIsOutput,
      minIndex: targetIndex
    } = transaction
    const { id, address, cluster, clusterAttribution, amount, time, isOutput } = transactionFlow.transaction
    const ghost = nodeTypeToIdString({ id, type: 'transaction', network })
    const entityType: NodeType = clusterAttribution ? { id: clusterAttribution, type: 'attribution', network }
      : cluster ? { id: cluster, type: 'cluster', network }
      : { id: address || '', type: 'address', network }
    const entity = nodeTypeToIdString(entityType)
    if (state.settings.autoLinksSwitch) {
      await dispatch('graphAllNodePairs', { nodes: [entityType], allNew: false, endLoading: false })
    } // no need to do anything if autolinks are off, there's only half a txn here
    commit('ADD_NEWEST_NODES', [entity])

    const sender = isOutput ? ghost : entity
    const receiver = isOutput ? entity : ghost
    const txns: VizTransaction[] = [
      {
        id: `${sender}|${receiver}`,
        sender,
        receiver,
        amount,
        timestamp: time,
        flows: [
          {
            sending,
            targetTransaction: {
              id: targetId,
              index: targetIndex,
              isOutput: targetIsOutput
            },
            value: transactionFlow.amount,
            minHops: transactionFlow.shortestPath
          }
        ]
      } as VizTransaction
    ]
    commit('ADD_NEWEST_LINKS', [`${sender}|${receiver}`])
    commit('ADD_GRAPH_TXNS', txns)
    const data = graphDataFromLedger(txns, state.linkColors)
    const targetTransaction: TripletCluster = {
      ...state.flowTarget!.transaction,
      cluster: targetCluster ?? '',
      address: targetAddress ?? '',
      index: targetIndex
    }
    const transactionFlowTarget: TransactionFlowTarget = { targetTransaction, sending }
    dispatch('addTransactionFlowTarget', transactionFlowTarget)
    dispatch('addToGraph', data)
  },
  // intersection stuff
  clearTargetOutputs({ commit }: { commit: Commit }, { sender }: { sender: boolean }) {
    commit('SET_SUBNETWORK_TARGET_OUTPUTS', { sender, outputs: [] })
  },
  async setTargetOutputs(
    { commit }: { commit: Commit },
    { network, id, sender }: { network: string; id: string; sender: boolean }
  ) {
    dispatch('clearTargetOutputs', { sender })
    const res = await api.getTransactionLedger({
      id,
      network,
      page: 1,
      perPage: 1000,
      simple: false,
      aggregated: true,
      groupBy: 'cluster',
      isOutput: true
    })
    if (failedResponse(res)) {
      dispatch('updateSnackbar', {
        show: state.enableErrors,
        text: `Couldn't fetch transaction data for id ${id}: ${res.message} ${JSON.stringify(res.original)}`,
        timeout: -1
      })
      return
    }
    if (res) {
      track('setTargetOutput', { network, id, sender })
      commit('SET_SUBNETWORK_TARGET_OUTPUTS', { sender, outputs: res.ledger })
    }
  },
  async setSubnetworkTargetFromTxnInput(
    { commit }: { commit: Commit },
    { isSender, id, network }: { isSender: boolean; id: string; network: string }
  ) {
    // get txn data for input
    const res = await api.getTransactionLedger({
      id,
      network,
      page: 1,
      perPage: 1,
      simple: true,
      aggregated: true,
      groupBy: 'cluster',
      isOutput: false,
      index: 0
    })
    if (failedResponse(res)) {
      dispatch('updateSnackbar', {
        show: state.enableErrors,
        text: `Transaction data couldn't be fetched: ${JSON.stringify(res.original)}`,
        timeout: -1
      })
      return
    }
    if (res) {
      track('setSubnetworkTargetFromTxnInput', { network, id, isSender })
      const target = res.ledger[0]
      dispatch('setSubnetworkTarget', { isSender, target, network })
    }
  },
  async setSubnetworkTarget(
    { commit }: { commit: Commit },
    {
      isSender,
      target,
      network,
      trace
    }: {
      isSender: boolean;
      target:
      AggregatedBitcoinTransaction;
      network: string;
      trace?: AccountingMethod
    }
  ) {
    track('setSubnetworkTarget', { network, target, isSender })
    if (isSender) {
      commit('SET_SUBNETWORK_SENDER', { network, transaction: target } as FlowTarget)
    } else {
      commit('SET_SUBNETWORK_RECEIVER', { network, transaction: target } as FlowTarget)
    }
    const sender = state.subnetworkSender
    const receiver = state.subnetworkReceiver
    if (sender && receiver) {
      commit('SET_SUBNETWORK_LOADING', true)
      commit('SET_SUBNETWORK_FLOW', { flow: undefined })
      commit('SET_SUBNETWORK_MAP', { map: undefined })
      commit('SET_SUBNETWORK_FLOWS', { bars: [] })
      const { id: sourceId, minIndex: sourceIndex, isOutput: sourceIsOutput } = sender.transaction
      const { id: destinationId, minIndex: destinationIndex, isOutput: destinationIsOutput } = receiver.transaction
      const params: FlowRequest = {
        network,
        source: {
          sourceId,
          sourceIndex,
          sourceIsOutput
        },
        destination: {
          destinationId,
          destinationIndex,
          destinationIsOutput
        },
        trace
      }
      const flow = await api.flow(params)
      if (failedResponse(flow)) {
        dispatch('updateSnackbar', {
          show: state.enableErrors,
          text: `Flow data couldn't be fetched: ${JSON.stringify(flow.original)}`,
          timeout: -1
        })
        commit('SET_SUBNETWORK_LOADING', false)
        return
      }
      if (flow) {
        flow.shortestPath = convertHopsToReadable(flow.shortestPath, !sourceIsOutput, true)
        flow.longestPath = convertHopsToReadable(flow.longestPath, !sourceIsOutput, true)
        commit('SET_SUBNETWORK_FLOW', { flow })
        let subnetwork: FailureResponse | TransactionFlow[] | undefined = undefined
        if (flow.longestPath > 1) {
          subnetwork = await api.subnetwork({
            network,
            source: {
              id: sourceId,
              index: sourceIndex,
              isOutput: sourceIsOutput
            },
            destination: {
              id: destinationId,
              index: destinationIndex,
              isOutput: destinationIsOutput
            },
            trace
          })
          if (failedResponse(subnetwork)) {
            dispatch('updateSnackbar', {
              show: state.enableErrors,
              text: `Flow subnetwork couldn't be fetched: ${JSON.stringify(subnetwork.original)}`,
              timeout: -1
            })
            commit('SET_SUBNETWORK_LOADING', false)
            return
          }
        } else {
          subnetwork = []
        }
        if (subnetwork !== undefined) {
          const {
            bars,
            map,
            hops: hopsList,
            addresses,
            attributions
          } = barDataFromSubnetwork(sender.transaction, receiver.transaction, subnetwork)

          const filteredAddresses = filter(addresses, (a) => state.shared.clusterAddressCache.get(a) == null)
          if (filteredAddresses.length > 0) {
            const clusters = await api.getClustersForAddresses({ network, ids: filteredAddresses })
            if (clusters) {
              for (const [address, cluster] of Object.entries(clusters)) {
                commit('CLUSTER_CACHE_ADD', { address, cluster })
              }
            }
          }

          await dispatch('getAttributionsClusters', { network, attributions })

          commit('SET_SUBNETWORK_MAP', { map })
          commit('SET_SUBNETWORK_FLOWS', { bars })
          commit('SET_SUBNETWORK_HOPS_LIST', { hopsList })
        }
      }
      commit('SET_SUBNETWORK_LOADING', false)
    }
  },
  async graphFlowSubnetwork({ commit }: { commit: Commit }) {
    if (!(state.subnetworkSender && state.subnetworkReceiver && state.subnetworkFlow)) {
      console.warn('intersection sender or receiver is not set or no flow, but flow subnetwork is attempting to be graphed')
      return
    }
    const { transaction: sender, network } = state.subnetworkSender
    const {
      id: senderTransaction,
      isOutput: senderIsOutput,
      minIndex: senderIndex,
      address: senderAddress,
      cluster: senderCluster,
      clusterAttribution: senderAttribution,
      amount: senderAmount,
      symbol: senderSymbol,
      time: senderTime
    } = sender
    const senderTripletCluster: TripletCluster = {
      ...sender,
      cluster: senderCluster ?? '',
      address: senderAddress ?? '',
      index: senderIndex
    }
    const receiver = state.subnetworkReceiver.transaction
    const {
      id: receiverTransaction,
      isOutput: receiverIsOutput,
      minIndex: receiverIndex,
      address: receiverAddress,
      cluster: receiverCluster,
      clusterAttribution: receiverAttribution,
      amount: receiverAmount,
      symbol: receiverSymbol,
      time: receiverTime
    } = receiver
    const receiverTripletCluster: TripletCluster = {
      ...receiver,
      cluster: receiverCluster ?? '',
      address: receiverAddress ?? '',
      index: receiverIndex
    }
    const map = state.subnetworkFlowMap
    const transactions: VizTransaction[] = []
    const nodeSet = new Set<string>()
    const txnSidesMap: IOMap = {}
    if (map) {
      for (const key of new Set(state.subnetworkFlows.flatMap((d) => Object.keys(d)))) {
        const subnetworkTxn = map![key]
        const { id, address, cluster, clusterAttribution, amount, symbol, time, isOutput, sentFlow, receivedFlow } =
          subnetworkTxn
        const ghost = nodeTypeToIdString({ id, type: 'transaction', network })
        const entity = nodeTypeToIdString(
          clusterAttribution ? { id: clusterAttribution, type: 'attribution', network }
          : cluster ? { id: cluster, type: 'cluster', network }
          : { id: address || '', type: 'address', network }
        )
        nodeSet.add(entity)
        let sender, receiver
        if (isOutput) {
          sender = ghost
          receiver = entity
          if (!txnSidesMap[id]) {
            txnSidesMap[id] = { inputs: new Set(), outputs: new Set([entity]) }
          } else {
            txnSidesMap[id].outputs.add(entity)
          }
        } else {
          sender = entity
          receiver = ghost
          if (!txnSidesMap[id]) {
            txnSidesMap[id] = { inputs: new Set([entity]), outputs: new Set() }
          } else {
            txnSidesMap[id].inputs.add(entity)
          }
        }
        transactions.push({
          id: `${sender}|${receiver}`,
          sender,
          receiver,
          amount,
          symbol,
          timestamp: time,
          flows: [
            {
              sending: true,
              targetTransaction: senderTripletCluster,
              value: receivedFlow.amount,
              minHops: receivedFlow.shortestPath
            },
            {
              sending: false,
              targetTransaction: receiverTripletCluster,
              value: sentFlow.amount,
              minHops: sentFlow.shortestPath
            }
          ]
        })
      }
    }
    const { amount, longestPath } = state.subnetworkFlow
    const senderTransactionNode = nodeTypeToIdString({ id: senderTransaction, type: 'transaction', network })
    let senderSender, senderReceiver
    const senderEntity = nodeTypeToIdString(
      senderAttribution ? { id: senderAttribution, type: 'attribution', network }
      : senderCluster ? { id: senderCluster, type: 'cluster', network }
      : { id: senderAddress || '', type: 'address', network }
    )
    nodeSet.add(senderEntity)
    if (senderIsOutput) {
      senderSender = senderTransactionNode
      senderReceiver = senderEntity
      if (!txnSidesMap[senderTransaction]) {
        txnSidesMap[senderTransaction] = { inputs: new Set(), outputs: new Set([senderEntity]) }
      } else {
        txnSidesMap[senderTransaction].outputs.add(senderEntity)
      }
    } else {
      senderSender = senderEntity
      senderReceiver = senderTransactionNode
      if (!txnSidesMap[senderTransaction]) {
        txnSidesMap[senderTransaction] = { inputs: new Set([senderEntity]), outputs: new Set() }
      } else {
        txnSidesMap[senderTransaction].inputs.add(senderEntity)
      }
    }
    transactions.push({
      id: `${senderSender}|${senderReceiver}`,
      sender: senderSender,
      receiver: senderReceiver,
      amount: senderAmount,
      symbol: senderSymbol,
      timestamp: senderTime,
      flows: [
        {
          sending: false,
          targetTransaction: receiverTripletCluster,
          value: amount,
          minHops: longestPath
        },
        {
          sending: true,
          targetTransaction: senderTripletCluster,
          value: amount,
          minHops: 0
        }
      ]
    })
    const receiverTransactionNode = nodeTypeToIdString({ id: receiverTransaction, type: 'transaction', network })
    let receiverSender, receiverReceiver
    const receiverEntity = nodeTypeToIdString(
      receiverAttribution ? { id: receiverAttribution, type: 'attribution', network }
      : receiverCluster ? { id: receiverCluster, type: 'cluster', network }
      : { id: receiverAddress || '', type: 'address', network }
    )
    nodeSet.add(receiverEntity)
    if (receiverIsOutput) {
      receiverSender = receiverTransactionNode
      receiverReceiver = receiverEntity
      if (!txnSidesMap[receiverTransaction]) {
        txnSidesMap[receiverTransaction] = { inputs: new Set(), outputs: new Set([receiverEntity]) }
      } else {
        txnSidesMap[receiverTransaction].outputs.add(receiverEntity)
      }
    } else {
      receiverSender = receiverEntity
      receiverReceiver = receiverTransactionNode
      if (!txnSidesMap[receiverTransaction]) {
        txnSidesMap[receiverTransaction] = { inputs: new Set([receiverEntity]), outputs: new Set() }
      } else {
        txnSidesMap[receiverTransaction].inputs.add(receiverEntity)
      }
    }
    transactions.push({
      id: `${receiverSender}|${receiverReceiver}`,
      sender: receiverSender,
      receiver: receiverReceiver,
      amount: receiverAmount,
      symbol: receiverSymbol,
      timestamp: receiverTime,
      flows: [
        {
          sending: true,
          targetTransaction: senderTripletCluster,
          value: amount,
          minHops: longestPath
        },
        {
          sending: false,
          targetTransaction: receiverTripletCluster,
          value: amount,
          minHops: 0
        }
      ]
    })

    await dispatch('addNewestLinksFromIOMap', { txnSidesMap })
    if (state.settings.autoLinksSwitch) {
      const nodes = Array.from(nodeSet).map(n => classifyNodeId(n))
      await dispatch('graphAllNodePairs', { nodes, allNew: false, endLoading: false })
    } else {
      await dispatch('graphNodePairsFromIOMap', { txnSidesMap, network, endLoading: false })
    }

    // set up forces before adding to graph to avoid missing flow targets
    dispatch('addTransactionFlowTarget', {
      targetTransaction: senderTripletCluster,
      sending: true
    } as TransactionFlowTarget)
    dispatch('addTransactionFlowTarget', {
      targetTransaction: receiverTripletCluster,
      sending: false
    } as TransactionFlowTarget)
    let forces: ForceControls
    if (state.target != null && receiverEntity === nodeTypeToIdString(state.target)) { // receiving flow
      forces = {
        ...state.forces,
        xEmbedTarget: {
          targetTransaction: receiverTripletCluster,
          sending: false
        }
      }
    } else {
      forces = {
        ...state.forces,
        xEmbedTarget: {
          targetTransaction: senderTripletCluster,
          sending: true
        }
      }
    }
    commit('SET_FORCES', forces)

    commit('ADD_GRAPH_TXNS', transactions)
    const data = graphDataFromLedger(transactions, state.linkColors)
    await dispatch('addToGraph', data)
  },
  async graphFlowSubnetworkTransaction(
    { commit }: { commit: Commit },
    { transactionFlow }: { transactionFlow: SubnetworkFlowTransaction }
  ) {
    if (state.subnetworkSender == null || state.subnetworkReceiver == null) return
    const { transaction: subnetworkSender, network } = state.subnetworkSender
    const { minIndex: senderIndex, cluster: senderCluster, address: senderAddress } = subnetworkSender
    const senderTripletCluster: TripletCluster = {
      ...subnetworkSender,
      cluster: senderCluster ?? '',
      address: senderAddress ?? '',
      index: senderIndex
    }
    const { transaction: subnetworkReceiver } = state.subnetworkReceiver
    const { minIndex: receiverIndex, cluster: receiverCluster, address: receiverAddress } = subnetworkReceiver
    const receiverTripletCluster: TripletCluster = {
      ...subnetworkReceiver,
      cluster: receiverCluster ?? '',
      address: receiverAddress ?? '',
      index: receiverIndex
    }
    const { id, isOutput, address, cluster, clusterAttribution, amount, time, sentFlow, receivedFlow } = transactionFlow
    const entityType: NodeType = clusterAttribution ? { id: clusterAttribution, type: 'attribution', network }
      : cluster ? { id: cluster, type: 'cluster', network }
      : { id: address || '', type: 'address', network }
    const entity = nodeTypeToIdString(entityType)
    if (state.settings.autoLinksSwitch) {
      await dispatch('graphAllNodePairs', { nodes: [entityType], allNew: false, endLoading: false })
    } // no need to do anything if autolinks are off, there's only half a txn here
    commit('ADD_NEWEST_NODES', [entity])

    const ghost = nodeTypeToIdString({ id, type: 'transaction', network })
    const sender = isOutput ? ghost : entity
    const receiver = isOutput ? entity : ghost
    const txns: VizTransaction[] = [
      {
        id: `${sender}|${receiver}`,
        sender,
        receiver,
        amount,
        timestamp: time,
        flows: [
          {
            sending: false,
            targetTransaction: senderTripletCluster,
            value: receivedFlow.amount,
            minHops: receivedFlow.shortestPath
          },
          {
            sending: true,
            targetTransaction: receiverTripletCluster,
            value: sentFlow.amount,
            minHops: sentFlow.shortestPath
          }
        ]
      } as VizTransaction
    ]
    commit('ADD_NEWEST_LINKS', [`${sender}|${receiver}`])
    commit('ADD_GRAPH_TXNS', txns)
    const data = graphDataFromLedger(txns, state.linkColors)
    dispatch('addTransactionFlowTarget', { targetTransaction: senderTripletCluster, sending: true })
    dispatch('addTransactionFlowTarget', { targetTransaction: receiverTripletCluster, sending: false })
    dispatch('addToGraph', data)
  },
  // other
  addTransactionFlowTarget({ commit }: { commit: Commit }, { targetTransaction, sending }: TransactionFlowTarget) {
    const { id, index, isOutput } = targetTransaction
    const flowTargets = state.transactionFlowTargets.map(
      (t) => `${t.targetTransaction.id}|${t.targetTransaction.isOutput}|${t.targetTransaction.index}|${t.sending}`
    )
    if (!flowTargets.includes(`${id}|${isOutput}|${index}|${sending}`)) {
      commit('ADD_TRANSACTION_FLOW_TARGET', { sending, targetTransaction })
      commit('ADD_TRANSACTION_FLOW_TARGETS_MAP', {
        [`${id}|${isOutput}|${index}`]: targetTransaction
      })
    }
  },
  async setAndGraphSubnetorkFlow(
    { commit }: { commit: Commit },
    {
      source,
      destination,
      network,
      trace
    }: {
      source: TxnTriplet;
      destination: TxnTriplet;
      network: string;
      trace?: AccountingMethod
    }
  ) {
    // clear these so an unintended flow isn't attempted
    commit('SET_SUBNETWORK_SENDER', undefined)
    commit('SET_SUBNETWORK_RECEIVER', undefined)
    
    const { id: sourceTxid, isOutput: sourceIsOutput, index: sourceIndex } = source
    const fullSourceTxn = await api.getTransactionLedger({
      network,
      id: sourceTxid,
      page: 1,
      perPage: 1,
      simple: true,
      isOutput: sourceIsOutput,
      index: sourceIndex,
      aggregated: true,
      groupBy: 'cluster'
    })
    if (failedResponse(fullSourceTxn)) {
      dispatch('updateSnackbar', {
        show: state.enableErrors,
        text: `Transaction data couldn't be fetched: ${JSON.stringify(fullSourceTxn.original)}`,
        timeout: -1
      })
      return
    }
    let id: string = ''
    let type: IdType = 'address'
    if (fullSourceTxn) {
      const source = fullSourceTxn.ledger[0] as AggregatedBitcoinTransaction
      dispatch('setSubnetworkTarget', { isSender: true, target: source, network, trace })
      if (state.target === undefined) {
        const { clusterAttribution, cluster, address } = source
        if (clusterAttribution) {
          id = clusterAttribution
          type = 'attribution'
        } else if (cluster) {
          id = cluster
          type = 'cluster'
        } else if (address) {
          id = address
        }
      }
    }

    const { id: destTxid, isOutput: destIsOutput, index: destIndex } = destination
    const fullDestTxn = await api.getTransactionLedger({
      network,
      id: destTxid,
      page: 1,
      perPage: 1,
      simple: true,
      isOutput: destIsOutput,
      index: destIndex,
      aggregated: true,
      groupBy: 'cluster'
    })
    if (failedResponse(fullDestTxn)) {
      dispatch('updateSnackbar', {
        show: state.enableErrors,
        text: `Transaction data couldn't be fetched: ${JSON.stringify(fullDestTxn.original)}`,
        timeout: -1
      })
      return
    }
    if (fullDestTxn) {
      const dest = fullDestTxn.ledger[0]
      await dispatch('setSubnetworkTarget', { isSender: false, target: dest, network, trace })
    }

    await dispatch('graphFlowSubnetwork')
    if (id) dispatch('setTarget', { id, network, type } as Target)
  },
  async getAggregateHierarchy(
    { commit }: { commit: Commit },
    { network, side, attribution, address, cluster, depth = 1, maxDepth = 2, trace }: HierarchicalAggregateFlowsRequest
  ) {
    const hierarchy = await api.getAggregateHierarchy({ network, side, attribution, address, cluster, depth, maxDepth, trace })
    if (failedResponse(hierarchy)) {
      dispatch('updateSnackbar', {
        show: state.enableErrors,
        text: `Flows summary failed to load: ${JSON.stringify(hierarchy.original)}`,
        timeout: 1500
      })
      commit('ATTRIBUTION_FLOWS_FAIL')
      return
    }
    track('getAggregateHierarchy', { network, side, attribution, address, cluster, depth, maxDepth, trace })
    commit('SET_HIERARCHY', { hierarchy })
  },
  clearAggregateHierarchy({ commit }: { commit: Commit }, side: string) {
    commit('SET_HIERARCHY', { hierarchy: undefined })
  },
  async getAttributionFlows(
    { commit }: { commit: Commit },
    {
      network,
      side,
      attribution,
      address,
      cluster,
      filters,
      trace,
      symbol
    }: {
      network: string
      side: SingleFlowDirection
      attribution?: string
      address?: string
      cluster?: string
      filters: Partial<AggregateFlowsRequestFilters>
      trace?: AccountingMethod
      symbol?: string
    }
  ) {
    const hierarchy = await api.getAggregateHierarchy({
      network,
      side,
      attribution,
      address,
      cluster,
      depth: 2,
      maxDepth: 3,
      filters,
      trace,
      symbol
    })
    const key = JSON.stringify(filters)
    if (failedResponse(hierarchy)) {
      dispatch('updateSnackbar', {
        show: state.enableErrors,
        text: `Flows summary failed to load: ${JSON.stringify(hierarchy.original)}`,
        timeout: 1500
      })
      commit('ATTRIBUTION_FLOWS_FAIL')
      return
    }
    commit('SET_ATTRIBUTION_FLOWS', { key, flows: hierarchy })
  },
  async getRawFlows({ commit }: { commit: Commit }) {
    if (state.flowTarget === undefined) {
      console.warn('attempted to get raw flows with no flow target defined')
      return
    }
    const { network, transaction } = state.flowTarget
    const { id: destinationId, minIndex: destinationIndex, isOutput: destinationIsOutput } = transaction
    const params: RawFlowsRequest = {
      network,
      destination: {
        destinationId,
        destinationIndex,
        destinationIsOutput
      }
      // TODO: add trace (balance vs. LIFO) here if applicable
    }
    const response = await api.getRawFlows(params)
    if (failedResponse(response)) {
      dispatch('updateSnackbar', {
        show: state.enableErrors,
        text: `Raw flows couldn't be fetched: ${response.message} ${JSON.stringify(response.original)}`,
        timeout: -1
      })
      return
    }
    track('getRawFlows')
    const { flows } = response
    commit('SET_RAW_FLOWS', { flows })
    // if using internal attributions, fetch them
    if (!state.useExternalApi || state.externalApiRequest === undefined) {
      const addressSet = new Set<string>()
      for (const flow of flows) {
        addressSet.add(flow.sourceAddress)
      }
      addressSet.add(flows[0].destinationAddress)
      await dispatch('getTargetClusters', { network, addresses: Array.from(addressSet) })
    }
    commit('SET_RAW_FLOWS_LOADED', { loaded: true })
  },
  async graphRawFlows({ commit }: { commit: Commit }) {
    if (state.flowTarget === undefined) {
      console.warn('attempted to graph raw flows with no flow target defined')
      return
    }
    track('graphRawFlows')
    const { network, transaction } = state.flowTarget
    const {
      id: targetId,
      address: targetAddress,
      cluster: targetCluster,
      clusterAttribution: targetAttribution,
      isOutput: targetIsOutput,
      minIndex: targetIndex,
      amount: targetAmount,
      symbol: targetSymbol,
      time: targetTime,
      externalResult: targetExternalResult
    } = transaction
    const targetTransaction: TripletCluster = {
      ...state.flowTarget!.transaction,
      cluster: targetCluster ?? '',
      address: targetAddress ?? '',
      index: targetIndex
    }
    const txns: VizTransaction[] = []
    let targetExternal = ''
    if (targetExternalResult) {
      const { data: attribution } = targetExternalResult as ExternalResult
      if (attribution) targetExternal = nodeTypeToIdString({ id: `ext-${attribution}`, type: 'attribution', network })
    }
    const triplets = state.rawFlows.map((f) => f.source)
    const response = await api.getBulkTransactionLedger({
      network,
      triplets,
      simple: false,
      aggregated: true,
      groupBy: 'cluster',
      externalAttributions: state.useExternalApi ? state.externalApiRequest : undefined
    })
    if (failedResponse(response)) {
      dispatch('updateSnackbar', {
        show: state.enableErrors,
        text: `Raw flows couldn't be graphed: ${response.message} ${JSON.stringify(response.original)}`,
        timeout: -1
      })
      return
    } else if (response === undefined) {
      dispatch('updateSnackbar', {
        show: state.enableErrors,
        text: `Raw flows couldn't be graphed: bulk transactions response was undefined`,
        timeout: -1
      })
      return
    }
    const tripletFlowMap: { [key: string]: RawFlow } = state.rawFlows.reduce((map, flow) => {
      const { id, isOutput, index } = flow.source
      const key = `${id}${isOutput}${index}`
      return {
        ...map,
        ...{ [key]: flow }
      }
    }, {})
    const nodeSet = new Set<string>()
    const txnSidesMap: IOMap = {}
    for (const transaction of response as AggregatedBitcoinTransaction[]) {
      const { id, cluster, clusterAttribution, amount, symbol, time, isOutput, minIndex: index, externalResult } = transaction
      const ghost = nodeTypeToIdString({ id, type: 'transaction', network })
      let entity = nodeTypeToIdString(
        clusterAttribution ? { id: clusterAttribution, type: 'attribution', network }
        : { id: cluster || '', type: 'cluster', network }
      )
      nodeSet.add(entity)
      const key = `${id}${isOutput}${index}`
      const { amount: flowAmount, shortestPath } = tripletFlowMap[key]
      if (state.useExternalApi) {
        if (externalResult) {
          const { data: attribution } = externalResult as ExternalResult
          if (attribution) {
            entity = nodeTypeToIdString({ id: `ext-${attribution}`, type: 'attribution', network })
            if (state.externalAttributionsCache.get(cluster!) == null) {
              // cluster can't be undefined because that's the agg level
              commit('ADD_EXTERNAL_ATTRIBUTION', { address: cluster, attribution })
            }
          }
        }
      }
      let sender, receiver
      if (isOutput) {
        sender = ghost
        receiver = entity
        if (!txnSidesMap[id]) {
          txnSidesMap[id] = { inputs: new Set(), outputs: new Set([entity]) }
        } else {
          txnSidesMap[id].outputs.add(entity)
        }
      } else {
        sender = entity
        receiver = ghost
        if (!txnSidesMap[id]) {
          txnSidesMap[id] = { inputs: new Set([entity]), outputs: new Set() }
        } else {
          txnSidesMap[id].inputs.add(entity)
        }
      }
      txns.push({
        id: `${sender}|${receiver}`,
        sender,
        receiver,
        amount,
        symbol,
        timestamp: time,
        flows: [
          {
            sending: false,
            targetTransaction,
            value: flowAmount,
            minHops: shortestPath
          }
        ]
      })
    }
    const transactionNode = nodeTypeToIdString({ id: targetId, type: 'transaction', network })
    let entity = nodeTypeToIdString(
      targetAttribution ? { id: targetAttribution, type: 'attribution', network }
      : targetCluster ? { id: targetCluster, type: 'cluster', network }
      : { id: targetAddress || '', type: 'address', network }
    )
    nodeSet.add(entity)
    if (state.useExternalApi && targetExternal !== '') {
      entity = targetExternal
    }
    let sender, receiver
    if (targetIsOutput) {
      sender = transactionNode
      receiver = entity
      if (!txnSidesMap[targetId]) {
        txnSidesMap[targetId] = { inputs: new Set(), outputs: new Set([entity]) }
      } else {
        txnSidesMap[targetId].outputs.add(entity)
      }
    } else {
      sender = entity
      receiver = transactionNode
      if (!txnSidesMap[targetId]) {
        txnSidesMap[targetId] = { inputs: new Set([entity]), outputs: new Set() }
      } else {
        txnSidesMap[targetId].inputs.add(entity)
      }
    }
    txns.push({
      id: `${sender}|${receiver}`,
      sender,
      receiver,
      amount: targetAmount,
      symbol: targetSymbol,
      timestamp: targetTime
    })

    await dispatch('addNewestLinksFromIOMap', { txnSidesMap })
    if (state.settings.autoLinksSwitch) {
      const nodes = Array.from(nodeSet).map(n => classifyNodeId(n))
      await dispatch('graphAllNodePairs', { nodes, allNew: false, endLoading: false })
    } else {
      await dispatch('graphNodePairsFromIOMap', { txnSidesMap, network, endLoading: false })
    }

    commit('ADD_GRAPH_TXNS', txns)
    const data = graphDataFromLedger(txns, state.linkColors)
    const transactionFlowTarget: TransactionFlowTarget = { targetTransaction, sending: false }
    dispatch('addTransactionFlowTarget', transactionFlowTarget)
    const forces: ForceControls = {
      ...state.forces,
      xEmbedTarget: transactionFlowTarget
    }
    commit('SET_FORCES', forces)
    dispatch('addToGraph', data)
    commit('SET_RAW_FLOWS_GRAPHED', { graphed: true })
    const transactions = response as AggregatedBitcoinTransaction[]
    transactions.push(transaction)
    commit('SET_RAW_FLOW_TXNS', { transactions })
  },
  selectFlowFilterNodes({ commit }: { commit: Commit }) {
    commit('SET_RAW_FLOW_FILTER_SELECT_MODE', { mode: true })
  },
  endRawFlowFilterSelectMode({ commit }: { commit: Commit }) {
    commit('SET_RAW_FLOW_FILTER_SELECT_MODE', { mode: false })
  },
  async filterRawFlows({ commit }: { commit: Commit }, { nodes }: { nodes: string[] }) {
    if (state.flowTarget === undefined) {
      console.warn('attempted to filter raw flows with no flow target defined')
      return
    }
    commit('SET_RAW_FLOWS_LOADED', { loaded: false })
    if (nodes.length > 0) {
      const addresses = new Set<string>()
      // create map of triplets to addresses
      const tripletAddressMap: { [key: string]: string } = state.rawFlows.reduce((map, flow) => {
        const { source, sourceAddress } = flow
        const { id, isOutput, index } = source
        const key = `${id}${isOutput}${index}`
        return {
          ...map,
          ...{ [key]: sourceAddress }
        }
      }, {})
      // add destination to map
      const { destination, destinationAddress } = state.rawFlows[0]
      const { id, isOutput, index } = destination
      const key = `${id}${isOutput}${index}`
      tripletAddressMap[key] = destinationAddress

      // create maps from high level entity to raw flows addresses and from triplets to high level entities
      const entityToAddressesMap: { [key: string]: Set<string> } = {}
      const tripletToEntityMap: { [key: string]: string } = {}
      for (const transaction of state.rawFlowTransactions) {
        const { cluster, clusterAttribution, externalResult, id, isOutput, minIndex: index } = transaction
        let entity = clusterAttribution ? clusterAttribution : cluster ?? '' // this will never be the empty string
        if (externalResult != null) {
          const { data, success } = externalResult as ExternalResult
          if (success) {
            entity = `ext-${data}`
          }
        }
        const key = `${id}${isOutput}${index}`
        const address = tripletAddressMap[key]
        if (!(entity in entityToAddressesMap)) {
          entityToAddressesMap[entity] = new Set([address])
        } else {
          entityToAddressesMap[entity].add(address)
        }
        tripletToEntityMap[key] = entity
      }

      // iterate through selected nodes and find matching address(es)
      for (const node of nodes) {
        const { id: nodeId, type } = classifyNodeId(node)
        let entity = nodeId
        if (type === 'address') {
          // must have extracted it
          const cluster = state.shared.clusterAddressCache.get(nodeId)
          if (cluster != null) {
            // this should always be true
            const { id, topAttribution } = cluster
            entity = topAttribution ? topAttribution : id // use ternary to account for empty string
          }
        }
        // look up the raw flows addresses associated with the given entity
        if (entity in entityToAddressesMap) {
          for (const address of entityToAddressesMap[entity]) {
            addresses.add(address)
          }
        }
      }
      if (addresses.size > 0) {
        const { network } = state.flowTarget
        const response = await api.getFilteredFlows({
          network,
          flows: state.rawFlows,
          addresses: Array.from(addresses)
          // TODO: add trace (balance vs. LIFO) here if applicable
        })
        if (failedResponse(response) && response.status !== 404) {
          // if it's 404, it just means the result set is empty, so treat it that way
          dispatch('updateSnackbar', {
            show: state.enableErrors,
            text: `Filtered flows couldn't be pulled: ${response.message} ${JSON.stringify(response.original)}`,
            timeout: -1
          })
        } else {
          let flows: RawFlow[] = [] // default to empty data in case of 404
          if (!failedResponse(response)) {
            // there must be non-empty data, so use that
            flows = response.flows
          }
          const previousFlows = structuredClone(state.rawFlows)
          commit('SET_RAW_FLOWS', { flows })
          // get difference between previous raw flows and new raw flows
          const originalTripletsMap = new Set<string>()
          for (const flow of previousFlows) {
            const { source } = flow
            originalTripletsMap.add(JSON.stringify(source))
          }
          const currentTripletsMap = new Set<string>()
          for (const flow of flows) {
            const { source } = flow
            currentTripletsMap.add(JSON.stringify(source))
          }
          // map triplets from the set difference to entities to set txns to remove
          const removedTxns = new Set<string>()
          for (const triplet of originalTripletsMap) {
            if (!currentTripletsMap.has(triplet)) {
              const { id: txid, isOutput, index } = JSON.parse(triplet) as TxnTriplet
              // TODO: this does not account for extracted addresses, not sure how it's possible to do that
              const entity = tripletToEntityMap[`${txid}${isOutput}${index}`]
              removedTxns.add(`${txid}${entity}${isOutput}`)
            }
          }
          // check ledger for removed txns and delete them
          const ledger: VizTransaction[] = JSON.parse(JSON.stringify(state.ledger))
          let ledgerIndex = 0
          while (ledgerIndex < ledger.length) {
            const { sender, receiver } = ledger[ledgerIndex]
            const { id: senderId, type: senderType } = classifyNodeId(sender)
            const { id: receiverId, type: receiverType } = classifyNodeId(receiver)
            let toRemove = false
            if (senderType === 'transaction') {
              if (removedTxns.has(`${senderId}${receiverId}${true}`)) {
                // needs to be removed
                toRemove = true
              }
            } else if (receiverType === 'transaction') {
              if (removedTxns.has(`${receiverId}${senderId}${false}`)) {
                // needs to be removed
                toRemove = true
              }
            }
            if (toRemove) {
              ledger.splice(ledgerIndex, 1)
              continue
            }
            ledgerIndex++
          }
          commit('SET_LEDGER', ledger)
          commit('SET_GRAPH_REDRAW', true)
          dispatch('createGraphFromLedger')
        }
      }
    }
    commit('SET_RAW_FLOWS_LOADED', { loaded: true })
  },
  highlightFlow(
    { commit }: { commit: Commit },
    { sourceFlow, destinationFlow }: { sourceFlow?: MinimalFlow; destinationFlow?: MinimalFlow }
  ) {
    const keys = []
    if (sourceFlow != null) {
      const { targetTransaction, sending, minHops } = sourceFlow
      const { id, isOutput, index } = targetTransaction
      keys.push(`${id}|${isOutput}|${index}|${sending}|${minHops}`)
    }
    if (destinationFlow != null) {
      const { targetTransaction, sending, minHops } = destinationFlow
      const { id, isOutput, index } = targetTransaction
      keys.push(`${id}|${isOutput}|${index}|${sending}|${minHops}`)
    }
    commit('SET_HIGHLIGHT_FLOW_KEYS', { keys })
  },
  async graphNodePairsFromIOMap(
    { commit }: { commit: Commit },
    { txnSidesMap, network, endLoading }: { txnSidesMap: IOMap; network: string; endLoading?: boolean }
  ) {
    const nodes = new Set<string>()
    const nodePairs = new Set<string>()
    const linksData: BasicLinkData[] = []
    const newestLinks: string[] = []
    for (const { inputs, outputs } of Object.values(txnSidesMap)) {
      if (inputs.size > 0 && outputs.size > 0) {
        for (const input of inputs) {
          nodes.add(input)
          for (const output of outputs) {
            nodes.add(output)
            const key = `${input}||${output}`
            if (!nodePairs.has(key)) {
              const { id: sender, type: senderType } = classifyNodeId(input)
              const { id: receiver, type: receiverType } = classifyNodeId(output)
              linksData.push({
                sender,
                senderType: senderType as EntityType,
                receiver,
                receiverType: receiverType as EntityType
              })
              newestLinks.push(key)
              nodePairs.add(key)
            }
          }
        }
      }
    }
    commit('ADD_NEWEST_NODES', nodes)
    commit('ADD_NEWEST_LINKS', newestLinks)
    await dispatch('graphNodePairs', { linksData, network, endLoading })
    await dispatch('traceIDs', { ids: Array.from(nodes).map(n => classifyNodeId(n)), endLoading })
  },
  async addNewestLinksFromIOMap({ commit }: { commit: Commit }, { txnSidesMap }: { txnSidesMap: IOMap }) {
    await Promise.all(
      filter(
        Object.keys(txnSidesMap),
        id => classifyInput(id).networkType !== 'bitcoin'
      )
      .map(id => dispatch('getReceipts', { network: classifyInput(id).networkType, id }))
    )
    const nodes = new Set<string>()
    const nodePairs = new Set<string>()
    const newestLinks: string[] = []
    for (const { inputs, outputs } of Object.values(txnSidesMap)) {
      if (inputs.size > 0 && outputs.size > 0) {
        for (const input of inputs) {
          nodes.add(input)
          for (const output of outputs) {
            nodes.add(output)
            const key = `${input}||${output}`
            if (!nodePairs.has(key)) {
              newestLinks.push(key)
              nodePairs.add(key)
            }
          }
        }
      }
    }
    commit('ADD_NEWEST_NODES', nodes)
    commit('ADD_NEWEST_LINKS', newestLinks)
  }
})
