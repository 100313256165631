
import { Blocklist, IPMetadata } from '@/types/ip';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'

@Component({})
export default class IPCard extends Vue {
  @Prop() ip!: IPMetadata
  public blocklists: Blocklist[] = []

  created() {
    this.blocklists = this.ip.threat.blocklists ?? []
  }

  public getCity(city: string | null | undefined): string {
    if (city == null || city === '') {
      return 'Unknown'
    }
    return city
  }

  public getLocation(): string {
    const city = this.ip.city
    const region = this.ip.region ?? this.ip.region_code
    const country = this.ip.country_name ?? this.ip.country_code
    const continent = this.ip.continent_name ?? this.ip.continent_code
    return [city, region, country, continent].filter(l => l != null).join(', ')
  }

  public formatDomain(domain?: string) {
    if (domain == null) {
      return 'Unkown'
    }
    if (!domain.startsWith('http')) {
      return `https://${domain}`
    }
    return domain
  }

  public formatDate(epoch: number) {
    return this.$store.state.formatDate(epoch, true, 1)
  }
}
