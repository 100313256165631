
import { Component, ProvideReactive, Vue, Watch } from 'vue-property-decorator'
import { ChartData } from '@/subcomponents/types/charts'
import AreaChart from '@/subcomponents/AreaChart.vue'
import { mapState } from 'vuex'
import { NetworkCounts } from '@/store/p2p'

export interface Chart {
  network: string
  interval: string
  dataType: string
  title: string
  data: ChartData[]
  lastUpdate: number
  unit: string
}

interface ChartHash {
  hash: string
  chart: Chart
}

export interface Charts {
  [key: string]: {
    peers?: ChartHash,
    users?: ChartHash,
    transactions?: ChartHash,
    wallets?: ChartHash,
    addresses?: ChartHash,
    watchlists?: ChartHash
  }
}

@Component({
  components: {
    AreaChart
  },
  computed: {
    ...mapState([
      // 'electrumPeerCounts',
      // 'electrumPeerCountsHash',
      'electrumUserCounts',
      'electrumUserCountsHash',
      'electrumTransactionCounts',
      'electrumTransactionCountsHash',
      'electrumWalletCounts',
      'electrumWalletCountsHash',
      'electrumAddressCounts',
      'electrumAddressCountsHash',
      'electrumWatchlistCounts',
      'electrumWatchlistCountsHash'
    ])
  }
})
export default class P2PStats extends Vue {
  // public chartsToDisplay: Chart[] = []
  @ProvideReactive() chartWidth: number = 300
  @ProvideReactive() chartHeight: number = 150
  // bindings
  // private electrumPeerCounts!: NetworkCounts
  // private electrumPeerCountsHash!: string
  private electrumUserCounts!: NetworkCounts
  private electrumUserCountsHash!: string
  private electrumTransactionCounts!: NetworkCounts
  private electrumTransactionCountsHash!: string
  private electrumWalletCounts!: NetworkCounts
  private electrumWalletCountsHash!: string
  private electrumAddressCounts!: NetworkCounts
  private electrumAddressCountsHash!: string
  private electrumWatchlistCounts!: NetworkCounts
  private electrumWatchlistCountsHash!: string

  private charts: Charts = {
    bitcoin: {},
    litecoin: {}
  }

  public loading: boolean = false
  private networks: string[] = ['bitcoin']

  private dataTypes = [
    // { type: 'peers', dispatch: 'getElectrumPeerCounts', interval: '1day', title: 'Servers per day' },
    { type: 'users', dispatch: 'getElectrumUserCounts', interval: '1day', title: 'Users per day' },
    { type: 'transactions', dispatch: 'getElectrumTransactionCounts', interval: '1day', title: 'Transactions per day' },
    { type: 'wallets', dispatch: 'getElectrumWalletCounts', interval: '1day', title: 'Wallets per day' },
    { type: 'addresses', dispatch: 'getElectrumAddressCounts', interval: '1day', title: 'Addresses per day' },
    { type: 'watchlists', dispatch: 'getElectrumWatchlistCounts', interval: '1day', title: 'Watchlists per day' }
  ]
  
  async mounted() {
    this.loading = true
    for (const network of this.networks) {
      this.charts[network] = {}
      for (const { type, dispatch, interval, title } of this.dataTypes) {
        await this.$store.dispatch(dispatch, { network })
        
      }
    }
  }
  
  chartKey(c: Chart): string {
    const { network, dataType, interval, lastUpdate } = c
    return `${network}-${dataType}-${interval}-${this.chartHeight}-${this.chartWidth}-${lastUpdate}}`
  }

  checkHaveData() {
    const { peers, users, transactions, wallets, addresses, watchlists } = this.charts['bitcoin']
    if (
      users != null
      && transactions != null
      && wallets != null
      && addresses != null
      && watchlists != null
    ) {
      this.loading = false
    } else {
      this.loading = true
    }
  }

  chartsToDisplay(): Chart[] {
    const network = 'bitcoin'
    const { peers, users, transactions, wallets, addresses, watchlists } = this.charts[network]
    if (
      users != null
      && transactions != null
      && wallets != null
      && addresses != null
      && watchlists != null
    ) {
      return [ users.chart, transactions.chart, wallets.chart, addresses.chart, watchlists.chart ]
    }
    return []
  }

  public formatDate(time: number) {
    return this.$store.state.formatDate(time, false, 1)
  }

  // @Watch('electrumPeerCountsHash', { deep: true })
  // private peerCountsUpdated() {
  //   for (const network of this.networks) {
  //     const dataType = 'peers'
  //     this.charts[network].peers = {
  //       hash: this.electrumPeerCountsHash,
  //       chart: {
  //         network,
  //         interval: '1day',
  //         dataType,
  //         title: 'Servers per day',
  //         data: this.electrumPeerCounts[network].map(({ start, end, count: value }) => ({
  //           date: new Date(start * 1000),
  //           value,
  //           tooltip: {
  //             items: [ { label: formatDate(start, false, true, true), value } ]
  //           }
  //         })),
  //         lastUpdate: Date.now(),
  //         unit: dataType
  //       }
  //     }
  //   }
  //   this.checkHaveData()
  // }

  @Watch('electrumUserCountsHash', { deep: true })
  private userCountsUpdated() {
    for (const network of this.networks) {
      const dataType = 'users'
      this.charts[network].users = {
        hash: this.electrumUserCountsHash,
        chart: {
          network,
          interval: '1day',
          dataType,
          title: 'IPs per day',
          data: this.electrumUserCounts[network].map(({ start, end, count: value }) => ({
            date: new Date(start * 1000),
            value,
            tooltip: {
              items: [{ label: this.formatDate(start), value }]
            }
          })),
          lastUpdate: Date.now(),
          unit: dataType
        }
      }
    }
    this.checkHaveData()
  }

  @Watch('electrumTransactionCountsHash', { deep: true })
  private txnCountsUpdated() {
    for (const network of this.networks) {
      const dataType = 'transactions'
      this.charts[network].transactions = {
        hash: this.electrumTransactionCountsHash,
        chart: {
          network,
          interval: '1day',
          dataType,
          title: 'Transactions per day',
          data: this.electrumTransactionCounts[network].map(({ start, end, count: value }) => ({
            date: new Date(start * 1000),
            value,
            tooltip: {
              items: [{ label: this.formatDate(start), value }]
            }
          })),
          lastUpdate: Date.now(),
          unit: dataType
        }
      }
    }
    this.checkHaveData()
  }

  @Watch('electrumWalletCountsHash', { deep: true })
  private walletCountsUpdated() {
    for (const network of this.networks) {
      const dataType = 'wallets'
      this.charts[network].wallets = {
        hash: this.electrumWalletCountsHash,
        chart: {
          network,
          interval: '1day',
          dataType,
          title: 'Wallets per day',
          data: this.electrumWalletCounts[network].map(({ start, end, count: value }) => ({
            date: new Date(start * 1000),
            value,
            tooltip: {
              items: [{ label: this.formatDate(start), value }]
            }
          })),
          lastUpdate: Date.now(),
          unit: dataType
        }
      }
    }
    this.checkHaveData()
  }

  @Watch('electrumAddressCountsHash', { deep: true })
  private addressCountsUpdated() {
    for (const network of this.networks) {
      const dataType = 'wallets'
      this.charts[network].addresses = {
        hash: this.electrumAddressCountsHash,
        chart: {
          network,
          interval: '1day',
          dataType,
          title: 'Addresses per day',
          data: this.electrumAddressCounts[network].map(({ start, end, count: value }) => ({
            date: new Date(start * 1000),
            value,
            tooltip: {
              items: [{ label: this.formatDate(start), value }]
            }
          })),
          lastUpdate: Date.now(),
          unit: dataType
        }
      }
    }
    this.checkHaveData()
  }

  @Watch('electrumWatchlistCountsHash', { deep: true })
  private watchlistCountsUpdated() {
    for (const network of this.networks) {
      const dataType = 'watchlists'
      this.charts[network].watchlists = {
        hash: this.electrumWatchlistCountsHash,
        chart: {
          network,
          interval: '1day',
          dataType,
          title: 'Watchlists per day',
          data: this.electrumWatchlistCounts[network].map(({ start, end, count: value }) => ({
            date: new Date(start * 1000),
            value,
            tooltip: {
              items: [{ label: this.formatDate(start), value }]
            }
          })),
          lastUpdate: Date.now(),
          unit: dataType
        }
      }
    }
    this.checkHaveData()
  }
}
