import Vue from 'vue'
import Vuex from 'vuex'
import { Api } from '../utils/api'
import { chainActions, chainMutations, chainState } from './chain'
import { authActions, authMutations, authState } from './auth'
import { accountActions, accountMutations, accountState } from './accounts'
import { investigationActions, investigationMutations, investigationState } from './investigations/investigations'
import { tabularActions, tabularMutations, tabularState } from './investigations/tabular'
import { flowsActions, flowsMutations, flowsState } from './investigations/flows'
import { vizActions, vizMutations, vizState } from './investigations/viz'
import { searchActions, searchMutations, searchState } from './search'
import { attributionActions, attributionMutations, attributionState } from './attributions'
import { generalActions, generalMutations, generalState } from './general'
import { clusterActions, clusterMutations, clusterState } from './clusters'
import { sharedActions, sharedMutations, sharedState } from './shared'
import { p2pActions, p2pMutations, p2pState } from './p2p'

const api = new Api()

Vue.use(Vuex)

export const state = {
  version: 1,
  shared: sharedState,
  ...authState,
  ...accountState,
  ...investigationState,
  ...tabularState,
  ...flowsState,
  ...vizState,
  ...attributionState,
  ...chainState,
  ...searchState,
  ...generalState,
  ...clusterState,
  ...p2pState
}

export type State = typeof state

const store = new Vuex.Store({
  state,
  mutations: {},
  actions: {},
  modules: {}
})

store.hotUpdate({
  mutations: {
    ...authMutations,
    ...accountMutations,
    ...chainMutations,
    ...searchMutations,
    ...investigationMutations,
    ...tabularMutations,
    ...flowsMutations,
    ...attributionMutations,
    ...vizMutations,
    ...generalMutations,
    ...clusterMutations,
    ...sharedMutations,
    ...p2pMutations
  },
  actions: {
    ...authActions(state, api),
    ...accountActions(state, api, store.dispatch),
    ...chainActions(state, api, store.dispatch),
    ...searchActions(state, api),
    ...investigationActions(state, api, store.dispatch),
    ...tabularActions(state, api, store.dispatch),
    ...flowsActions(state, api, store.dispatch),
    ...attributionActions(state, api, store.dispatch),
    ...vizActions(state, api, store.dispatch),
    ...generalActions(state, api, store.dispatch),
    ...clusterActions(state, api, store.dispatch),
    ...sharedActions(state, api, store.dispatch),
    ...p2pActions(state, api, store.dispatch)
  }
})
export default store
