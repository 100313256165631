
import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import ServerTable, { ColumnLabel } from '@/subcomponents/ServerTable.vue'
import { SortMap, LinkTransaction } from '@/utils/api'
import { FormattedSummaryLink } from '@/utils/graph-links'
import { ExtendedTx, isExtendedTx } from '@/types/bitcoin'
import { classifyInput } from '@/utils/validate'
import SortBar, { SortResponse } from './SortBar.vue'

@Component({
  components: {
    SortBar,
    ServerTable
  },
  computed: {
    ...mapState([
      'selectedLink',
      'fullTransactions',
      'transactionsLoading',
      'transactionsTotal',
      'summaryLinkDirection',
      'transaction',
      'targetNetwork'
    ])
  }
})
export default class EntityPairTransactions extends Vue {
  // from store
  public selectedLink!: FormattedSummaryLink
  public fullTransactions!: LinkTransaction[]
  public transactionsTotal!: number
  public transactionsLoading!: boolean
  public summaryLinkDirection!: 0 | 1
  public transaction?: ExtendedTx
  public targetNetwork!: string

  public itemsPerPage: number[] = [50, 100, 200, 400]
  public perPage: number = 100
  public page: number = 1
  public sortFields = [
    { label: 'time', key: 'cryptotime' },
    'amount'
  ]
  public sorts: SortMap = { cryptotime: 'desc' }

  public columnLabels: ColumnLabel[] = [
    {
      label: '',
      cols: 1,
      class: 'text-subtitle-2'
    },
    {
      label: 'TxID',
      cols: 5,
      class: 'text-subtitle-2'
    },
    {
      label: '',
      cols: 6,
      class: 'text-subtitle-2'
    }
  ]
  public nestedColumnLabels: ColumnLabel[] = [
    {
      label: 'To',
      cols: 8,
      class: 'pl-4 text-subtitle-2'
    },
    {
      label: 'Value',
      cols: 4,
      class: 'pl-1 text-subtitle-2'
    }
  ]

  public expanded: number[] = []

  public onPageUpdated(page: number) {
    this.page = page
    this.fetchPage()
  }

  public onItemsPerPageUpdated(count: number) {
    this.perPage = count
    this.fetchPage()
  }

  public onItemExpanded(update: { item: LinkTransaction; value: boolean }) {
    const { item, value } = update
    const network = classifyInput(item.txid).networkType
    if (value) {
      this.$store.dispatch('getCounterpartiesDetails', { network, txn: item, expansion: true })
    } else {
      this.$store.dispatch('getCounterpartiesDetails', { network, expansion: true })
    }
  }

  public onSort(selected: SortResponse) {
    this.sorts = {
      [selected.sortBy[0]]: selected.sortDesc[0] === 1 ? 'desc' : 'asc' // currently only one sort allowed
    }
    this.fetchFirstPage()
  }

  getNestedColumns() {
    return this.transaction && isExtendedTx(this.transaction) ? this.nestedColumnLabels : null
  }

  getNestedData() {
    return this.transaction && isExtendedTx(this.transaction) ? this.transaction.vout : []
  }

  mounted() {
    this.fetchPage()
  }

  @Watch('targetNetwork')
  @Watch('summaryLinkDirection')
  fetchFirstPage() {
    this.page = 1
    this.fetchPage()
  }

  @Watch('selectedLink')
  fetchPage() {
    this.expanded = []
    this.$store.dispatch('setTxnsPage', {
      sorts: this.sorts,
      page: this.page,
      perPage: this.perPage
    })
  }
}
