
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class DataIteratorSearch extends Vue {
  @Prop() color!: string
  @Prop() label!: string
  @Prop() input!: string
  @Prop() onChange!: (model: string) => void
  @Prop() onUserInput!: () => void
  public model: string = ''

  created() {
    if (this.input != null) {
      this.model = this.input
      this.onChange(this.model)
    }
  }

  public updateModel(event: any) {
    if (this.onUserInput != null) {
      this.onUserInput()
    }
    if (event.type === 'click') {
      this.model = ''
    }
    this.onChange(this.model)
  }
}
