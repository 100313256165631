
import { FormattedBlock } from '@/types/eth'
import { titleCase } from '@/utils/general'
import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class BlockEthereum extends Vue {
  private network: string = ''
  private blockId: string = ''
  private networkDisplayName: string = ''

  get block(): FormattedBlock {
    return this.$store.state.block
  }

  created() {
    this.network = this.$route.params.network.toLowerCase()
    this.blockId = this.$route.params.blockId
    this.networkDisplayName = titleCase(this.network)
  }
}
