
import { Component, Vue, Watch } from 'vue-property-decorator'

import { ForceControls, SettingsCollection, TransactionFlowTarget } from '@/store/investigations/viz'
import { mapState } from 'vuex'
import { cutEnd } from '@/utils/general'
import { AggregatedBitcoinTransaction } from '@/utils/api'
import { FormComponent } from '@/components/Dialog.vue'

@Component({
  computed: mapState([
    'nodeLabelTypes',
    'nodeSizeScales',
    'linkThicknessScales',
    'xEmbedScales',
    'transactionFlowTargets',
    'transactionFlowTargetsMap'
  ])
})
export default class Settings extends FormComponent { // extend FormComponent to inherit empty doAction and avoid error
  settings!: SettingsCollection
  public nodeLabelTypes!: string[]
  public nodeSizeScales!: string[]
  public linkThicknessScales!: string[]
  public xEmbedScales!: string[]
  public transactionFlowTargets!: TransactionFlowTarget[]
  public transactionFlowTargetsMap!: { [key: string]: AggregatedBitcoinTransaction }

  public showNodesAndLinks: boolean = false
  public showForces: boolean = false
  public forcesButtonColor: string = ''
  public forcesButtonText: string = ''
  public showGeneral: boolean = false
  public showLinkFlowTargets: boolean = false
  public showEmbedFlowTargets: boolean = false

  public forces!: ForceControls
  public chargeDistance!: number[]
  public transactionFlowTarget?: TransactionFlowTarget

  created() {
    this.settings = { ...this.$store.state.settings }
    this.updateLocalForces()
    const { min, max } = this.forces.chargeDistance
    this.chargeDistance = [min, max]
    this.forcesButtonColor = this.forces.playing ? 'red' : 'green'
    this.forcesButtonText = this.forces.playing ? 'Stop' : 'Start'
    this.showEmbedFlowTargets = this.forces.xEmbedRadio === 'hops'
  }

  get nodeLinkMenuIcon() {
    return this.showNodesAndLinks ? 'mdi-menu-up' : 'mdi-menu-down'
  }

  get forcesMenuIcon() {
    return this.showForces ? 'mdi-menu-up' : 'mdi-menu-down'
  }

  get generalMenuIcon() {
    return this.showGeneral ? 'mdi-menu-up' : 'mdi-menu-down'
  }

  formatFlowTarget(target: TransactionFlowTarget) {
    const { targetTransaction, sending } = target
    const { id, isOutput, index } = targetTransaction
    const key = `${id}|${isOutput}|${index}`
    const { address, cluster, clusterAttribution } = this.transactionFlowTargetsMap[key]
    return `${clusterAttribution ?? cutEnd(cluster ?? address ?? '')}, ${isOutput ? 'o' : 'i'}-${cutEnd(id)}: ${
      sending ? 'sending' : 'receiving'
    }`
  }

  freeze() {
    this.$store.dispatch('freezeNodes')
  }

  unfreeze() {
    this.$store.dispatch('unfreezeNodes')
  }

  handleForcesButton() {
    this.forces.playing = !this.forces.playing
    this.forcesButtonColor = this.forces.playing ? 'red' : 'green'
    this.forcesButtonText = this.forces.playing ? 'Stop' : 'Start'
    this.updateForces()
  }

  handleLinkThicknessRadioChange() {
    this.showLinkFlowTargets = this.settings.linkThicknessRadio === 'flow'
    if (this.settings.linkThicknessRadio === 'amount' || this.transactionFlowTarget) {
      this.updateSettings()
    }
  }

  updateTransactionFlowTarget() {
    this.$store.dispatch('setFlowScaleTarget', this.transactionFlowTarget)
    this.updateSettings()
  }

  handleChargeDistanceChange() {
    ;[this.forces.chargeDistance.min, this.forces.chargeDistance.max] = this.chargeDistance
    this.updateForces()
  }

  handleXEmbedRadioChange() {
    this.showEmbedFlowTargets = this.forces.xEmbedRadio === 'hops'
    if (this.forces.xEmbedRadio === 'appearance' || this.forces.xEmbedTarget) {
      this.updateForces()
    }
  }

  updateRounding() {
    if (this.settings.txnNodeSwitch) {
      this.settings.roundDecimalsSwitch = false
    } else {
      this.settings.roundDecimalsSwitch = true
    }
  }

  updateSettings() {
    this.$store.dispatch('setSettings', this.settings)
  }

  updateForces() {
    this.$store.dispatch('setForces', this.forces)
  }

  @Watch('$store.state.forces')
  updateLocalForces() {
    this.forces = { ...this.$store.state.forces }
    this.showEmbedFlowTargets = this.forces.xEmbedRadio === 'hops'
  }
}
