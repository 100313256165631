import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'

type PDFPageType = 'image' | 'table' | 'text'

export interface PDFImageConfigs {
  width: number
  height: number
  canvasURL: string
}

export interface PDFTableConfigs {
  headers: string[]
  body: string[][]
}

export interface PDFTextConfigs {
  text: string
  x: number
  y: number
}

export interface PDFPage {
  type: PDFPageType
  pageLayout: 'l' | 'p'
  body: PDFTextConfigs | PDFImageConfigs | PDFTableConfigs
}

export interface PDFData {
  pages: PDFPage[]
  name: string
}

const X_OFFSET = 20
const Y_OFFSET = 20

export async function toPDF(data: PDFData) {
  const pdf: jsPDF = new jsPDF('p', 'mm', 'a4', true)
  await createCoverPage(pdf, data.name, 40)
  data.pages.forEach((page) => {
    pdf.addPage('a4', page.pageLayout)
    if (page.type === 'image') {
      const image = getImage(page.body as PDFImageConfigs) // get image
      pdf.addImage(image.image, 'image/png', X_OFFSET / 2, Y_OFFSET / 2, image.width, image.height, undefined, 'FAST')
    } else if (page.type === 'table') {
      const rows = page.body as PDFTableConfigs
      const body = rows.body.map((row: any) => rows.headers.map((header: string) => row[header.toLowerCase()]))
      autoTable(pdf, {
        head: [rows.headers],
        body: body,
        tableWidth: 'wrap',
        styles: { cellPadding: 0.5, fontSize: 6 }
      })
    } else {
      const body = page.body as PDFTextConfigs
      pdf.text(body.text, body.x, body.y)
    }
  })
  pdf.save(`${data.name}.pdf`)
}

async function createCoverPage(pdf: jsPDF, name: string, fontSize: number) {
  pdf.setFontSize(fontSize)
  const pdfWidth = pdf.internal.pageSize.width
  const pdfHeight = pdf.internal.pageSize.height
  const wordArray = name.split(/[\s,]+/).map((word) => word.charAt(0).toUpperCase() + word.slice(1))
  const maxTextWidth = pdfWidth - X_OFFSET
  const textArray = getCorrectLines(wordArray, maxTextWidth, pdf)
  const { width, height } = await getImageSize('logo/heights-labs.png')
  const aspectRatio = width / height

  const newWidth = pdfWidth / 4
  const newHeight = newWidth / aspectRatio
  if (height !== 0 && width !== 0) {
    pdf.addImage('logo/heights-labs.png', 'png', 20, 10, newWidth, newHeight)
  }
  let y = pdfHeight / 2
  textArray.forEach((line) => {
    const x = (pdfWidth - pdf.getTextWidth(line)) / 2
    pdf.text(line, x, y)
    y += pdf.getTextDimensions(line).h + 2
  })
}

function getCorrectLines(wordArray: string[], maxTextWidth: number, pdf: jsPDF): string[] {
  const correctLines: string[] = []
  let currentLine = ''
  wordArray.forEach((word) => {
    if (pdf.getTextWidth(currentLine + ' ' + word) <= maxTextWidth) {
      currentLine = currentLine + ' ' + word
    } else {
      correctLines.push(currentLine)
      currentLine = word
    }
  })
  correctLines.push(currentLine)
  return correctLines
}

async function getImageSize(path: string) {
  const promiseArray = []
  let height = 0
  let width = 0
  promiseArray.push(
    new Promise<void>((resolve) => {
      const image = new Image()
      image.onload = function () {
        height = image.height
        width = image.width
        resolve()
      }
      image.src = path
    })
  )
  await Promise.all(promiseArray)
  return { width, height }
}

function getImage(configs: PDFImageConfigs) {
  const pdf = new jsPDF('p', 'mm', 'a4')
  const pdfWidth = pdf.internal.pageSize.getWidth() - X_OFFSET
  const pdfHeight = pdf.internal.pageSize.getHeight() - Y_OFFSET
  const { width: imageWidth, height: imageHeight } = configs
  const aspectRatio = imageWidth / imageHeight

  const newWidth = imageWidth > imageHeight ? pdfWidth : pdfHeight * aspectRatio
  const newHeight = imageWidth > imageHeight ? pdfWidth / aspectRatio : pdfHeight
  const image = document.createElement('img')
  image.src = configs.canvasURL
  return { image: image, width: newWidth, height: newHeight }
}
