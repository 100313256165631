
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { ExtendedCoinbase, ExtendedInput, ExtendedTx, isExtendedCoinbase, TxHeader } from '@/types/bitcoin'
import { decodeBtcHex, isDust } from '@/utils/bitcoin'
import { copyToClipboard, cutMiddle } from '@/utils/general'
import { ClusterMetadata, Attribution } from '@/utils/api'
import { mapState } from 'vuex'
import { FormattedLink, SettingsCollection } from '@/store/investigations/viz'
import { nodeTypeToIdString } from '@/utils/viz'
import { filter } from '@/utils/filters'

@Component({
  computed: mapState(['transaction'])
})
export default class BtcCounterpartyInputCard extends Vue {
  public isExtendedCoinbase = isExtendedCoinbase
  public cutMiddle = cutMiddle
  public decodeHex = decodeBtcHex
  public isDust = isDust
  public show = false
  public clusterId: string = ''
  public attribution = ''
  public heuristics = {
    haveHeader: false,
    rbf: false,
    locktime: 0,
    version: 0,
    witness: false,
    multisig: ''
  }

  private addressNode = ''
  private clusterNode = ''
  private graphAddressButton = {
    color: '',
    icon: '',
    text: ''
  }
  private graphClusterButton = {
    color: '',
    icon: '',
    text: ''
  }

  @Prop() item!: ExtendedInput | ExtendedCoinbase
  @Prop() selected!: boolean
  @Prop() decimalFormatter!: (n: number | string) => string

  public transaction!: ExtendedTx

  get humanize() {
    return this.$store.state.dateHumanizer
  }

  get isCoinbase() {
    const inputs = this.transaction.vin
    if (inputs && isExtendedCoinbase(inputs[0])) {
      return true
    }
    return false
  }

  get shortenedMultisig() {
    if (!isExtendedCoinbase(this.item)) {
      const { type } = this.item.spentOutput
      if (type.startsWith('ms')) {
        const [numerator, denominator] = type.split('/')
        return `${numerator.slice(-1)}/${denominator.slice(0, 1)}`
      }
    }
    return ''
  }

  created() {
    this.setAttribution()
    this.txnHeadersCacheUpdated()
    this.updateGraphed()
  }

  public cardClasses(hover: boolean): string {
    if (hover) {
      return 'hover'
    }
    return ''
  }

  public getAttributions(address: string): Attribution[] {
    const has = this.$store.state.shared.attributionsCache.has(address)
    return has ? this.$store.state.shared.attributionsCache.get(address) : []
  }

  public getAttribution(address: string): string {
    const attributions = this.getAttributions(address)
    if (attributions.length > 0) {
      const valueSet = new Set(attributions.flatMap((a) => a.attributions))
      if (valueSet.size > 0) {
        return `${Array.from(valueSet).join(', ')}`
      }
    }
    return ''
  }

  public getCluster(address: string): ClusterMetadata | null {
    const has = this.$store.state.shared.clusterAddressCache.has(address)
    return has ? this.$store.state.shared.clusterAddressCache.get(address) : null
  }

  public getClusterAttribution(address: string): { attribution?: string; clusterId?: string } {
    const cluster = this.getCluster(address)
    if (cluster != null) {
      const { id, topAttribution } = cluster
      this.clusterId = id
      if (topAttribution != null && topAttribution !== '') {
        this.clusterNode = nodeTypeToIdString({ id: topAttribution, type: 'attribution', network: 'bitcoin' })
        return {
          attribution: topAttribution,
          clusterId: id
        }
      } else {
        this.clusterNode = nodeTypeToIdString({ id, type: 'cluster', network: 'bitcoin' })
        return {
          clusterId: cluster.id
        }
      }
    }
    this.clusterId = ''
    return {}
  }

  public getLabel(attribution: string, cluster: { attribution?: string; clusterId?: string }): string {
    if (cluster.attribution != null && cluster.attribution !== '') {
      return cluster.attribution
    }
    if (attribution != '') {
      return attribution
    }
    if (cluster.clusterId != null && cluster.clusterId !== '') {
      return cutMiddle(cluster.clusterId, 9)
    }
    return ''
  }

  public setAttribution() {
    if (!isExtendedCoinbase(this.item)) {
      const { address } = this.item.spentOutput
      if (address != null && address !== '') {
        this.addressNode = nodeTypeToIdString({ id: address, type: 'address', network: 'bitcoin' })
        const clusterAttribution = this.getClusterAttribution(address)
        const attribution = this.getAttribution(address)
        this.attribution = this.getLabel(attribution, clusterAttribution)
      }
    }
  }

  public humanizeAge(input: ExtendedInput): string {
    const age = isExtendedCoinbase(input) ? 0 : input.time - input.spentOutput.time
    return this.humanize(age * 1000)
  }

  public copy(text: string) {
    copyToClipboard(text)
    this.$store.dispatch('updateSnackbar', { show: true, text: `Copied ${text} to clipboard.` })
  }

  private async toggleClusterGraphed() {
    const cp = this.clusterNode
    if (this.graphClusterButton.text.startsWith('Graph')) {
      this.$store.dispatch('graphCounterparty', { network: 'bitcoin', cp, cpIsOutput: false })
    } else {
      this.removeCp(cp)
    }
  }

  private toggleAddressGraphed() {
    const { value } = isExtendedCoinbase(this.item) ? this.item : this.item.spentOutput
    const cp = this.addressNode
    if (this.graphAddressButton.text.startsWith('Graph')) {
      this.$store.dispatch('graphCounterparty', { network: 'bitcoin', cp, value, cpIsOutput: false })
    } else {
      this.removeCp(cp)
    }
  }

  private async removeCp(cp: string) {
    const linkSelected = this.$store.state.selectedLink != null || this.$store.state.target.type === 'transaction'
    const txnNode = nodeTypeToIdString({ type: 'transaction', id: this.item.txid, network: 'bitcoin' })

    if (!linkSelected) this.$store.dispatch('removeNodes', { ids: [cp] })
    else this.$store.dispatch('removeLinks', { links: [`${cp}||${txnNode}`] })
  }

  @Watch('$store.state.txnHeadersCacheCount')
  private txnHeadersCacheUpdated() {
    if (!isExtendedCoinbase(this.item)) {
      if (this.$store.state.txnHeadersCache.has(this.item.previousOutput)) {
        const h: TxHeader = this.$store.state.txnHeadersCache.get(this.item.previousOutput)
        this.heuristics.locktime = h.locktime
        this.heuristics.version = h.version
        this.heuristics.witness = h.segwit
        this.heuristics.rbf = h.rbf
        this.heuristics.multisig = this.shortenedMultisig
        this.heuristics.haveHeader = true
      }
    }
  }

  @Watch('$store.state.formattedLinks')
  @Watch('$store.state.formattedSummaryLinks')
  @Watch('$store.state.settings', { deep: true })
  private updateGraphed() {
    const { txnNodeSwitch } = this.$store.state.settings as SettingsCollection
    const linkSelected = this.$store.state.selectedLink != null || this.$store.state.target.type === 'transaction'
    const links = linkSelected ?
      new Set(
        filter(this.$store.state.formattedLinks, (l: FormattedLink) => !!l.permanent)
        .map((l: FormattedLink) => `${l.source.id}${l.target.id}`)
      ) : txnNodeSwitch ?
      new Set(this.$store.state.formattedLinks.map((l: FormattedLink) => `${l.source.id}${l.target.id}`)) :
      new Set(this.$store.state.formattedSummaryLinks.map((l: FormattedLink) => `${l.source.id}${l.target.id}`))
    const opposingNode = (txnNodeSwitch || linkSelected) ?
      nodeTypeToIdString({ type: 'transaction', id: this.item.txid, network: 'bitcoin' }) :
      this.$store.state.target ? nodeTypeToIdString(this.$store.state.target) : ''

    if (this.clusterNode !== '' && (this.clusterNode === opposingNode || links.has(`${this.clusterNode}${opposingNode}`))) {
      this.graphClusterButton = {
        color: 'warning',
        icon: linkSelected ? 'mdi-vector-line' : 'mdi-vector-polyline-minus',
        text: `Ungraph Cluster${linkSelected ? ' Input' : ''}`
      }
    } else {
      this.graphClusterButton = {
        color: 'primary',
        icon: linkSelected ? 'mdi-vector-line' : 'mdi-vector-polyline-plus',
        text: `Graph Cluster${linkSelected ? ' Input' : ''}`
      }
    }

    if (this.addressNode !== '' && (this.addressNode === opposingNode || links.has(`${this.addressNode}${opposingNode}`))) {
      this.graphAddressButton = {
        color: 'warning',
        icon: linkSelected ? 'mdi-vector-line' : 'mdi-vector-polyline-minus',
        text: `Ungraph Address${linkSelected ? ' Input' : ''}`
      }
    } else {
      this.graphAddressButton = {
        color: 'gray darken-3',
        icon: linkSelected ? 'mdi-vector-line' : 'mdi-vector-polyline-plus',
        text: `Graph Address${linkSelected ? ' Input' : ''}`
      }
    }
  }
}
