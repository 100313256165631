
import { Component, Vue } from 'vue-property-decorator'
import { customAlphabet } from 'nanoid/async'
import { Filter, Header } from '@/subcomponents/types/genericTable'
import GenericTable from '@/subcomponents/GenericTable.vue'
import { ActiveUsers, RateLimits, UserSession } from '@/store/accounts'
import { cutMiddle } from '@/utils/general'
import { mapState } from 'vuex'

interface SignupCode {
  code: string
  used: boolean
  date: string
}

export interface GenParams {
  alphabet: string
  length: number
}

interface ApiKey {
  account: string
  active: boolean
  dateAdded: string
  key: string
  lastActive: string
  level: number
  username: string
  display: string
  scopes: string[]
}

interface User {
  username: string
  email: string
  active: boolean
  lastSeen: string
}

interface Session extends UserSession {
  ip: string
}

@Component({
  components: {
    GenericTable
  },
  computed: mapState(['apiKeys', 'signupCodes', 'users', 'activeUsers', 'rateLimits'])
})
export default class Accounts extends Vue {
  public hideKeys: boolean = true

  public apiKeyHeaders: Header[] = [
    { text: 'Key', value: 'display' },
    { text: 'Username', value: 'username' },
    { text: 'Added', value: 'dateAdded', isTimestamp: true },
    { text: 'Last Active', value: 'lastActive', isTimestamp: true },
    {
      text: 'Active',
      value: 'active',
      toggle: {
        click: this.toggleApiKey
      }
    },
    {
      text: 'Scopes',
      value: 'scopes',
      editable: {
        change: this.updateScopes
      }
    },
    {
      text: 'Delete',
      value: '',
      button: {
        click: this.deleteApiKey,
        icon: 'mdi-close-outline',
        color: 'red'
      }
    }
  ]
  public signupCodeHeaders: Header[] = [
    { text: 'Code', value: 'code' },
    { text: 'Used', value: 'used' },
    { text: 'Used By', value: 'usedBy' },
    { text: 'Date', value: 'date', isTimestamp: true }
  ]
  public userHeaders: Header[] = [
    { text: 'Username', value: 'username' },
    { text: 'Email', value: 'email' },
    { text: 'Last Seen', value: 'lastSeen', isTimestamp: true },
    {
      text: 'Active',
      value: 'active',
      toggle: {
        click: this.toggleUser
      }
    },
    {
      text: 'Delete',
      value: '',
      button: {
        click: this.deleteUser,
        icon: 'mdi-close-outline',
        color: 'red'
      }
    }
  ]
  public sessionHeaders: Header[] = [
    {
      text: 'IP',
      value: 'ip'
    },
    {
      text: 'User or ApiKey',
      value: 'type'
    },
    {
      text: 'Username/Key',
      value: 'label'
    },
    {
      text: 'Last Seen',
      value: 'time'
    },
    {
      text: 'Rate Limit',
      value: 'rateLimit'
    },
    {
      text: 'Rate Limit Resets',
      value: 'rateLimitReset',
      isTimestamp: true
    }
  ]
  public sessionFilters: Filter[] = [
    { text: 'IP', value: 'ip' },
    { text: 'Type', value: 'type' },
    { text: 'Value', value: 'value' }
  ]
  public apiKeyFilters: Filter[] = [
    { text: 'Key', value: 'key' },
    { text: 'Username', value: 'username' }
  ]
  public userFilters: Filter[] = [
    { text: 'Username', value: 'username' },
    { text: 'Email', value: 'email' }
  ]

  public showPass: boolean = false
  public rules = {
    required: (value: string) => !!value || 'Required',
    min: (value: string) => value.length >= 8 || 'Min 8 characters',
    email: (value: string) => {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return pattern.test(value) || 'Invalid e-mail.'
    }
  }
  public username: string = ''
  public password: string = ''
  public email: string = ''
  public signupCode: string = ''
  public userActive: boolean = true
  public emailUser: boolean = false
  public apiKeyUser: string = ''
  private pwdGenParams: GenParams = {
    alphabet: '1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ',
    length: 20
  }
  public sessionsListLoading = false

  private apiKeys!: ApiKey[]
  private signupCodes!: SignupCode[]
  private users!: User[]
  private activeUsers!: ActiveUsers
  private rateLimits!: RateLimits

  created() {
    this.getApiKeys()
    this.getSignupCodes()
    this.getUsers()
    this.getActiveUsers()
  }

  get apiKeysList() {
    return this.apiKeys.map((apiKey) => ({ ...apiKey, display: this.hideKeys ? cutMiddle(apiKey.key, 6) : apiKey.key }))
  }

  get signupCodesList() {
    if (this.signupCodes != null) {
      return this.signupCodes.sort((a: SignupCode, b: SignupCode) => (a === b ? 0 : a ? -1 : 1)) // false first
    }
    return []
  }

  get usersList() {
    return this.users
  }

  get sessionsList() {
    if (this.activeUsers != null && this.activeUsers.users != null) {
      const activeUsers = this.activeUsers.users
      const locations = Object.keys(activeUsers)
      const sessions: Session[] =
        locations.length === 0
          ? []
          : locations.flatMap((loc) =>
              activeUsers[loc].map(({ type, value, time }: UserSession) => {
                const rlRecord = this.rateLimits[`${value}`] == null ? undefined : this.rateLimits[`${value}`]
                const rateLimit = rlRecord != null ? `${rlRecord.remaining}/${rlRecord.limit}` : undefined
                const rateLimitReset = rlRecord != null ? rlRecord.reset : undefined
                return {
                  label: type === 'apikey' && this.hideKeys ? cutMiddle(value, 6) : value,
                  value,
                  type,
                  time,
                  ip: loc,
                  rateLimit,
                  rateLimitReset
                }
              })
            )
      return sessions
    }
    return []
  }

  public forceUpdate() {
    this.$forceUpdate()
  }

  public async getActiveUsers() {
    // this.sessionsListLoading = true
    await this.$store.dispatch('getActiveUsers')
    const ids = this.sessionsList.map(({ value }) => value)
    await this.$store.dispatch('getRateLimits', { ids })
    // this.sessionsListLoading = false
  }

  public getUsers() {
    this.$store.dispatch('getUsers')
  }

  public getApiKeys() {
    this.$store.dispatch('getApiKeys')
  }

  public async genApiKey() {
    if (this.apiKeyUser == null || this.apiKeyUser.length === 0) {
      this.$store.dispatch('updateSnackbar', {
        show: true,
        text: `must select a user to create an api key`
      })
      return
    }
    await this.$store.dispatch('genApiKey', { username: this.apiKeyUser })
    this.getApiKeys()
  }

  public getSignupCodes() {
    this.$store.dispatch('getSignupCodes')
  }

  public genSignupCodes() {
    this.$store.dispatch('genSignupCodes')
  }

  public async addUser() {
    await this.$store.dispatch('signUp', {
      username: this.username,
      password: this.password,
      email: this.email,
      code: this.signupCode,
      active: this.userActive,
      sendEmail: this.emailUser
    })
    this.getUsers()
  }

  public async genPassword() {
    const nanoid = customAlphabet(this.pwdGenParams.alphabet, this.pwdGenParams.length)
    const pwd = await nanoid()
    this.password = pwd
  }

  public async toggleApiKey(apiKey: ApiKey) {
    const { key, username } = apiKey
    await this.$store.dispatch('toggleApiKey', { username, key })
    this.getApiKeys()
  }

  public async updateScopes(apiKey: ApiKey, scopes: string[]) {
    const { key } = apiKey
    await this.$store.dispatch('editApiKeyScope', { key, scopes })
    this.getApiKeys()
  }

  public async deleteApiKey(apiKey: ApiKey) {
    const { key, username } = apiKey
    await this.$store.dispatch('deleteApiKey', { username, key })
    this.getApiKeys()
  }

  public async toggleUser(user: User) {
    const { username } = user
    await this.$store.dispatch('toggleUser', { username })
    this.getUsers()
  }

  public async deleteUser(user: User) {
    const { username } = user
    await this.$store.dispatch('deleteUser', { username })
    this.getUsers()
    this.getApiKeys()
  }
}
