
import { ComparisonConstraints, SortMap } from '@/utils/api'
import { stringDateToCryptoTime } from '@/utils/general'
import { Component, Prop, Vue } from 'vue-property-decorator'
import SortBar, { SortResponse } from '@/subcomponents/SortBar.vue'

@Component({
  components: {
    SortBar
  }
})
export default class FilterBar extends Vue {
  @Prop() onFilter!: ({
    amount,
    time,
    inputs,
    outputs
  } : {
    amount?: ComparisonConstraints
    time?: ComparisonConstraints
    inputs: boolean
    outputs: boolean
  }) => void
  @Prop() onUpdateSort!: (sort: SortMap) => void
  @Prop() disableSort!: boolean
  public startDateMenu: boolean = false
  public startDate: string = ''
  get formattedStartDate() {
    return this.formatDateLabel(this.startDate)
  }

  public endDateMenu: boolean = false
  public endDate: string = ''
  get formattedEndDate() {
    return this.formatDateLabel(this.endDate)
  }

  public amountLeast: number | null = null
  public amountMost: number | null = null
  public amountExact: number | null = null

  public inputs: boolean = true
  public outputs: boolean = true

  public sorts = [
    { label: 'time', key: 'time' },
    { label: 'value', key: 'amount' }
  ]
  public sort: SortMap = { time: 'desc' }

  public expand: boolean = true

  get sortDisabled() {
    if (!!this.disableSort) {
      return true
    }
    return false
  }

  public formatDateLabel(date: string) {
    if (!date) return ''
    const [year, month, day] = date.split('-')
    return `${month}/${day}/${year}`
  }

  public handleMenuInput(opened: boolean) {
    if (!opened) {
      // this.updateData()
    }
  }

  public updateSort(response: SortResponse) {
    this.sort = {
      [`${response.sortBy[0]}`]: response.sortDesc[0] === 1 ? 'desc' : 'asc'
    }
    if (this.onUpdateSort != null) {
      this.onUpdateSort(this.sort)
    }
  }

  public filter() {
    if (this.onFilter != null) {
      this.onFilter({
        amount: this.formatAmountConstraint(),
        time: this.formatTimeConstraint(),
        inputs: this.inputs,
        outputs: this.outputs
      })
    }
  }

  private formatAmountConstraint(): ComparisonConstraints | undefined {
    const constraints: ComparisonConstraints = {}
    let hasConstraints = false
    if (this.amountLeast != null && this.amountLeast > 0) {
      hasConstraints = true
      constraints.gte = parseFloat(this.amountLeast.toString())
    }
    if (this.amountMost != null && this.amountMost > 0) {
      hasConstraints = true
      constraints.lte = parseFloat(this.amountMost.toString())
    }
    if (this.amountExact != null && this.amountExact > 0) {
      hasConstraints = true
      constraints.eq = parseFloat(this.amountExact.toString())
    }
    return hasConstraints ? constraints : undefined
  }

  private formatTimeConstraint(): ComparisonConstraints | undefined {
    const constraints: ComparisonConstraints = {}
    let hasConstraints = false
    if (this.startDate !== '') {
      hasConstraints = true
      constraints.gte = stringDateToCryptoTime(this.startDate)
    }
    if (this.endDate !== '') {
      constraints.lte = stringDateToCryptoTime(this.endDate)
    }
    return hasConstraints ? constraints : undefined
  }
}
