import { Api } from "./api";
import { isBitcoinClassification, isEthereumClassification, isIPClassification, NetworkClassifications } from "./validate";
import { NodeType } from "./viz";

export interface BlockId {
  id: string
  type: 'block'
}

export interface IPId {
  id: string
  type: 'ip'
}

export async function setSearchTypes(
  id: string,
  input: NetworkClassifications,
  types: (NodeType | BlockId | IPId)[],
  typesData: string[],
  api: Api,
  explorer: boolean
) {
    const { networkType: network } = input
    if (isBitcoinClassification(input)) {
      if (input.isBitcoinTxid) {
        types.push({
          id,
          type: 'transaction',
          network
        })
        typesData.push('')
      } else if (
        input.isBitcoinAddress ||
        input.isBitcoinBech32Address ||
        input.isBitcoinBech32mAddress ||
        input.isBitcoinLegacyAddress
      ) {
        ({ types, typesData } = await attachAttributionAndCluster(id, network, types, typesData, api))
      } else if (input.isBitcoinBlock && explorer) {
        types.push({
          id,
          type: 'block'
        })
        typesData.push('')
      }
    } else if (isEthereumClassification(input)) {
      if (input.isEthBlockOrHash) {
        types.push({
          id,
          type: 'transaction',
          network
        })
        typesData.push('')

        if (explorer) {
          types.push({
            id,
            type: 'block'
          })
          typesData.push('')
        }
      } else if (input.isEthAddress) {
        ({ types, typesData } = await attachAttributionAndCluster(id, network, types, typesData, api))
      }
    } else if (isIPClassification(input) && explorer) {
      types.push({
        id,
        type: 'ip'
      })
      typesData.push('')
    }

    return { types, typesData }
  }

  async function attachAttributionAndCluster(
    id: string,
    network: string,
    types: (NodeType | BlockId | IPId)[],
    typesData: string[],
    api: Api
  ) {
    types.push({
      id,
      type: 'address',
      network
    })
    const attributionResponse = await api.getAttributions([id])
    if (attributionResponse != null && Object.keys(attributionResponse).length > 0) {
      typesData.push(attributionResponse[id][0].attributions[0])
    } else {
      typesData.push('')
    }
    const response = await api.getAddressCluster({ network: network, id })
    if (response && response.length) {
      const { id: clusterId, topAttribution, topCategory } = response[0]
      if (topAttribution && topCategory) {
        types.push({
          id: topAttribution,
          type: 'attribution',
          network
        })
        typesData.push(topCategory)
      } else {
        types.push({
          id: clusterId,
          type: 'cluster',
          network
        })
        typesData.push('')
      }
    }

    return { types, typesData }
  }