
import { TxnCounterpartiesMap } from '@/store/chain'
import { AggregatedTypedCounterparty } from '@/store/investigations/tabular'
import { FormattedTransaction, SimpleAggregatedEthereumTransaction } from '@/types/eth'
import { cutMiddle, timeToMilliseconds } from '@/utils/general'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'

@Component({
  computed: mapState([
    'entityLedgerFull',
    'txnCounterpartiesMap'
  ])
})
export default class EthItem extends Vue {
  @Prop() item!: SimpleAggregatedEthereumTransaction
  @Prop() index!: number

  public entityLedgerFull!: { [key: string]: FormattedTransaction }
  public txnCounterpartiesMap?: TxnCounterpartiesMap

  public cutMiddle = cutMiddle

  created() {}

  public formatValue(item: SimpleAggregatedEthereumTransaction): string {
    const { amount, isOutput, symbol } = item
    if (isOutput) {
      return `${amount} ${symbol}`
    }
    return `-${amount} ${symbol}`
  }

  public formatCounterPartyValue(value: number | string, item: SimpleAggregatedEthereumTransaction): string {
    const { isOutput, symbol } = item
    if (!isOutput) {
      return `${value} ${symbol}`
    }
    return `-${value} ${symbol}`
  }

  public valueColor(output: boolean): string {
    if (output) {
      return 'value-output'
    }
    return 'value-input'
  }

  public formatDate(time: number) {
    const epoch = timeToMilliseconds(time)
    return this.$store.state.formatDate(epoch, true)
  }

  public counterparties(item: SimpleAggregatedEthereumTransaction): AggregatedTypedCounterparty[] {
    if (this.txnCounterpartiesMap != null) {
      const { id, isOutput } = item
      return this.txnCounterpartiesMap[`${id}${isOutput}`]
    }
    return []
  }
}
