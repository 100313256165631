
import { Component, Prop, ProvideReactive, Vue } from 'vue-property-decorator'

@Component
export default class DataIteratorPagination extends Vue {
  @Prop() perPage!: number
  @Prop() itemCount!: number
  @Prop() onPageChange!: (page: number) => void
  @Prop() onItemsPerPageChange!: (number: number) => void
  @ProvideReactive() itemsPerPage = 4
  @ProvideReactive() page = 1
  public itemsPerPageOptions = [2, 4, 6, 10]
  public color = 'blue darken-3'

  @ProvideReactive() items() {
    return this.itemCount || this.itemsPerPage
  }

  get numberOfPages() {
    return Math.ceil(this.items() / this.itemsPerPage)
  }

  created() {
    this.itemsPerPage = this.perPage
  }

  prevPage() {
    if (this.page - 1 >= 1) {
      this.page -= 1
    }
    this.onPageChange(this.page)
  }

  nextPage() {
    if (this.page + 1 <= this.numberOfPages) {
      this.page += 1
    }
    this.onPageChange(this.page)
  }

  updateItemsPerPage(number: number) {
    this.itemsPerPage = number
    this.onItemsPerPageChange(number)
  }
}
