
import { Component, Prop, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'
import { LRUCache } from '@splunkdlt/cache'
import { ElectrumPeer, ElectrumUser } from '@/types/bitcoin'
import { titleCase } from '@/utils/general'

type ElectrumType = {
  type: 'server' | 'user'
} & ElectrumPeer & ElectrumUser 

@Component({
  computed: {
    ...mapState([
      'electrumPeer',
      'electrumUser'
    ])
  }
})
export default class ElectrumIP extends Vue {
  public electrumPeer!: LRUCache<string, ElectrumPeer>
  public electrumUser!: LRUCache<string, ElectrumUser>
  
  @Prop() network!: string
  @Prop() ip!: string
  public services: Array<ElectrumType> = []
  public titleCase = titleCase
  public loading: boolean = false

  get server(): ElectrumPeer | null {
    return this.electrumPeer.get(`${this.network}_${this.ip}`)
  }

  get user(): ElectrumUser | null {
    return this.electrumUser.get(`${this.network}_${this.ip}`)
  }

  getCity(city: string | null | undefined): string {
    if (city == null || city === '') {
      return 'Unknown'
    }
    return city
  }

  async mounted() {
    const { network, ip } = this
    this.loading = true
    await Promise.all([
      this.$store.dispatch('getElectrumPeer', { network, ip }),
      this.$store.dispatch('getElectrumUser', { network, ip })
    ])
    if (this.server != null) {
      this.services.push({
        type: 'server',
        ...this.server
      })
    }
    if (this.user != null) {
      this.services.push({
        type: 'user',
        ...this.user
      })
    }
    this.loading = false
  }

  public formatDate(epoch: number) {
    return this.$store.state.formatDate(epoch, false)
  }
}
