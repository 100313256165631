
import { titleCase } from '@/utils/general'
import { Component, Vue } from 'vue-property-decorator'
import BlockBitcoin from './BlockBitcoin.vue'
import BlockEthereum from './BlockEthereum.vue'

@Component({
  components: {
    BlockBitcoin,
    BlockEthereum
  }
})
export default class Block extends Vue {
  private network: string = ''
  private blockId: string = ''
  private networkDisplayName: string = ''
  public networkType: string = ''

  get supportedNetworks() {
    return this.$store.state.supportedNetworks
  }

  get bitcoinNetworks() {
    return this.$store.state.bitcoinNetworks
  }

  get ethereumNetworks() {
    return this.$store.state.ethereumNetworks
  }

  created() {
    this.network = this.$route.params.network.toLowerCase()
    this.blockId = this.$route.params.blockId
    this.networkDisplayName = titleCase(this.network)
    this.networkType = this.determineNetworkType()
    this.resetBlock()
    this.getBlock()
  }

  private determineNetworkType(): string {
    if (this.bitcoinNetworks.includes(this.network)) {
      return 'bitcoin'
    }
    if (this.ethereumNetworks.includes(this.network)) {
      return 'ethereum'
    }
    return ''
  }

  private resetBlock() {
    this.$store.dispatch('resetBlock')
  }

  private getBlock() {
    if (this.supportedNetworks.includes(this.network)) {
      this.$store.dispatch('getBlock', { network: this.network, id: this.blockId })
    }
  }
}
