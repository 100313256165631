import { humanizer } from 'humanize-duration'
import { Commit, Dispatch } from 'vuex'
import { state, State } from './index'
import { formatDate, parseServerEvent, ServerEvent } from '@/utils/general'
import { BrowserCache } from '@/utils/cache'
import { Api } from '@/utils/api'
import { track } from '@/utils/tracking'

export interface SnackbarProperties {
  show: boolean
  text: string
  timeout?: number
}

const DEFAULT_SNACKBAR_TIMEOUT = 5000

export const generalState = {
  section: '',
  useLocalTime: <boolean>false,
  formatDate: (epoch: number, ms: boolean, shortness?: number) => {
    return formatDate(epoch, ms, !state.useLocalTime, shortness)
  },
  snackbar: <SnackbarProperties>{ show: false, text: '', timeout: 5000 },
  dateHumanizer: humanizer({
    language: 'shortEn',
    languages: {
      shortEn: {
        y: () => 'y',
        mo: () => 'mo',
        w: () => 'w',
        d: () => 'd',
        h: () => 'h',
        m: () => 'm',
        s: () => 's',
        ms: () => 'ms'
      }
    }
  }),
  browserCache: <BrowserCache>new BrowserCache(),
  listeningToEvents: <boolean>false,
  serverEvents: <EventSource | null>null,
  serverEventsId: <string>'',
  eventStack: <ServerEvent[]>[],
  eventCount: <number>0,
  showLoadingDialog: <boolean>false,
  loadingValue: <number>-1,
  loadingDialogText: <string>''
}

export const generalMutations = {
  SET_SNACKBAR(state: State, { show, text, timeout }: SnackbarProperties) {
    state.snackbar = { show, text, timeout }
  },
  SET_LOCAL_TIME(state: State, value: boolean) {
    state.useLocalTime = value
  },
  BROWSER_CACHE_ADD(state: State, { key, value }: { key: string; value: string | object | number | boolean }) {
    state.browserCache.add(key, value)
  },
  SET_SECTION(state: State, section: string) {
    state.section = section
  },
  SET_SERVER_EVENTS(state: State, { serverEvents }: { serverEvents: EventSource }) {
    state.serverEvents = serverEvents
  },
  SET_EVENT_SUBSCRIPTION(state: State, { id }: { id: string }) {
    state.serverEventsId = id
    state.listeningToEvents = true
  },
  ADD_EVENT(state: State, { event }: { event: ServerEvent }) {
    state.eventStack.push(event)
    state.eventCount++
  },
  SET_LOADING_DIALOG(state: State, value: boolean) {
    state.showLoadingDialog = value
  },
  SET_LOADING_VALUE(state: State, value: number) {
    state.loadingValue = value
  },
  SET_LOADING_DIALOG_TEXT(state: State, text: string) {
    state.loadingDialogText = text
  }
}

export const generalActions = (state: State, api: Api, dispatch: Dispatch) => ({
  updateSnackbar({ commit }: { commit: Commit }, props: SnackbarProperties) {
    if (props.timeout == null) {
      props.timeout = DEFAULT_SNACKBAR_TIMEOUT
    }
    commit('SET_SNACKBAR', props)
  },
  setLocalTime({ commit }: { commit: Commit }, value: boolean) {
    commit('SET_LOCAL_TIME', value)
  },
  browserCacheAdd(
    { commit }: { commit: Commit },
    { key, value }: { key: string; value: string | object | number | boolean }
  ) {
    commit('BROWSER_CACHE_ADD', { key, value })
  },
  setSection({ commit }: { commit: Commit }, section: string) {
    track('setSection', { section })
    commit('SET_SECTION', section)
  },
  subscribeEvents({ commit }: { commit: Commit }) {
    if (!state.listeningToEvents) {
      const serverEvents = api.startEvents()
      serverEvents.onmessage = (message: MessageEvent) => {
        const event = parseServerEvent(message.data)
        if (event.success && !state.listeningToEvents) {
          commit('SET_EVENT_SUBSCRIPTION', { id: event.clientId })
        }
        if (event.success && state.listeningToEvents) {
          commit('ADD_EVENT', { event })
          if (event.requestedUrl.endsWith('addresses/count')) {
            dispatch('setAttributionSizeFromEvent', { event })
          }
        }
        if (!event.success) {
          console.warn(event)
        }
      }
      commit('SET_SERVER_EVENTS', { serverEvents })
    }
  },
  showLoadingDialog({ commit }: { commit: Commit }, { show, value, text }: { show: boolean; value?: number; text?: string }) {
    commit('SET_LOADING_VALUE', value ?? -1)
    commit('SET_LOADING_DIALOG_TEXT', text ?? '')
    commit('SET_LOADING_DIALOG', show)
  }
})
