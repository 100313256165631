
import { Component, ProvideReactive, Vue, Watch } from 'vue-property-decorator'
import DataIteratorPagination from '@/subcomponents/DataIteratorPagination.vue'
import DataIteratorSearch from '@/subcomponents/DataIteratorSearch.vue'
import BtcTxnMetadata from '@/subcomponents/BtcTxnMetadata.vue'
import BtcIOList from '@/subcomponents/BtcIOList.vue'
import { ExtendedCoinbase, ExtendedInput, ExtendedOutput, ExtendedTx, isExtendedCoinbase } from '@/types/bitcoin'
import { copyToClipboard, customSearch, NestedSearchKeys, NestedStringNumberMap, titleCase } from '@/utils/general'
import { TxnTriplet } from '@/utils/api'

@Component({
  components: {
    DataIteratorPagination,
    DataIteratorSearch,
    BtcTxnMetadata,
    BtcIOList
  }
})
export default class TransactionBitcoin extends Vue {
  public network: string = ''
  private txnId: string = ''
  public networkDisplayName: string = ''

  public color = 'primary'
  @ProvideReactive() itemsPerPageVin = 5
  @ProvideReactive() itemsPerPageVout = 5
  @ProvideReactive() pageVin = 1
  @ProvideReactive() pageVout = 1
  private keysVin = ['txid', 'vout', 'previousOutput', 'spentOutput.value', 'index']
  public searchVin = ''
  public searchVout = ''
  public itemsPerPageOptions = [5, 10, 20, 50]
  public inputSortFields: Array<string> = ['value', 'address', 'age']
  public outputSortFields: Array<string> = ['value', 'address']
  private inputSearchKeys: Array<string | NestedSearchKeys> = [
    'previousOutput',
    { spentOutput: ['address', 'value', 'height', 'type', 'hex', 'asm'] }
  ]
  private outputSearchKeys: Array<string | NestedSearchKeys> = [
    'value',
    { scriptPubKey: ['address', 'type', 'hex', 'asm'] }
  ]
  private queryStringInputs: string = ''
  private queryStringOutputs: string = ''

  get transaction(): ExtendedTx {
    return this.$store.state.transaction
  }

  created() {
    this.network = this.$route.params.network.toLowerCase()
    this.txnId = this.$route.params.txnId
    this.networkDisplayName = titleCase(this.network)
    const queryVin = this.$route.query.vin
    this.queryStringInputs = typeof queryVin === 'string' && queryVin.length > 0 ? `#${queryVin}` : ''
    const queryVout = this.$route.query.vout
    this.queryStringOutputs = typeof queryVout === 'string' && queryVout.length > 0 ? `#${queryVout}` : ''
    this.searchVin = this.queryStringInputs
    this.searchVout = this.queryStringOutputs
    this.$store.dispatch('resetInput')
    // get all input txids
    this.fetchAllInputTxns()
    // const inputAddresses = this.transaction.vin.map(i => (isExtendedCoinbase(i) ? null : i.spentOutput.address))
    // const outputAddresses = this.transaction.vout.map(o => o.scriptPubKey.address)
    // const addresses = [...inputAddresses.filter(a => a != null), ...outputAddresses]
    // this.$store.dispatch('getChainAttributions', { addresses })
  }

  // @Watch('$store.state.chainAttributions')
  // updateAttributions() {
  //   const addresses = this.$store.state.chainAttributions
  // }

  @Watch('$store.state.transaction')
  private fetchAllInputTxns() {
    const txn = this.$store.state.transaction
    if (txn != null && txn.vin != null) {
      const txns: TxnTriplet[] = txn.vin
        .map((i: ExtendedInput | ExtendedCoinbase) =>
          isExtendedCoinbase(i)
            ? null
            : {
                id: i.previousOutput,
                isOutput: true,
                index: i.vout
              }
        )
        .filter((i: any) => i != null)
      const unique = [...new Set(txns.map((i) => i.id))]
      // this.$store.dispatch('cacheAncestorHeuristics', { network: this.network, triplets: txns })
      this.$store.dispatch('cacheTxnHeaders', { network: this.network, txids: unique })
    }
  }

  public updatePageVin(page: number) {
    this.pageVin = page
  }

  public updatePageVout(page: number) {
    this.pageVout = page
  }

  public updateItemsPerPageVin(number: number) {
    this.itemsPerPageVin = number
  }

  public updateItemsPerPageVout(number: number) {
    this.itemsPerPageVout = number
  }

  public updateSearchModelVout(str: string) {
    this.searchVout = str
  }

  public updateSearchModelVin(str: string) {
    this.searchVin = str
  }

  customSearchInput<T, K extends NestedStringNumberMap & T>(items: K[], search: string): T[] {
    return customSearch(items, search, this.inputSearchKeys, 'inputs')
  }

  customSearchOutput<T, K extends NestedStringNumberMap & T>(items: K[], search: string): T[] {
    return customSearch(items, search, this.outputSearchKeys, 'outputs')
  }

  copy(text: string) {
    copyToClipboard(text)
    this.$store.dispatch('updateSnackbar', { show: true, text: `Copied ${text} to clipboard.` })
  }
}
