
import { ClusterMetadata } from '@/utils/api'
import { timeToMilliseconds } from '@/utils/general'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class BtcAddress extends Vue {
  @Prop() item!: ClusterMetadata
  public network: string = ''

  public formatDate(time: number) {
    const epoch = timeToMilliseconds(time)
    return this.$store.state.formatDate(epoch, true)
  }

  created() {
    this.network = this.$route.params.network
  }
}
