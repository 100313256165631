import eip55 from 'eip55'
import { validate as validateBitcoin, getAddressInfo as getAddressInfoBitcoin } from 'bitcoin-address-validation'

type BitcoinNetworkType = 'bitcoin'
type EthereumNetworkType = 'ethereum'
type IPNetworkType = 'ip'
export type NetworkTypes = BitcoinNetworkType | EthereumNetworkType | IPNetworkType | ''

export interface NetworkClassification {
  networkType: NetworkTypes
}

export interface NoNetworkClassification {
  networkType: ''
}

export type NetworkClassifications =
  | BitcoinClassification
  | EthereumClassification
  | IPClassification
  | NoNetworkClassification

// bitcoin start
export interface BitcoinClassification extends BitcoinClassifications {
  networkType: BitcoinNetworkType
}

export interface BitcoinClassifications {
  isBitcoinBlock: boolean
  isBitcoinTxid: boolean
  isBitcoinLegacyAddress: boolean
  isBitcoinBech32Address: boolean
  isBitcoinBech32mAddress: boolean
  isBitcoinAddress: boolean
}

export function isBitcoinClassification(classification: UntypedNetwork | unknown): classification is BitcoinClassification {
  return (
    (classification as BitcoinClassification).isBitcoinBlock ||
    (classification as BitcoinClassification).isBitcoinTxid ||
    (classification as BitcoinClassification).isBitcoinLegacyAddress ||
    (classification as BitcoinClassification).isBitcoinBech32Address ||
    (classification as BitcoinClassification).isBitcoinBech32mAddress
  )
}
// bitcoin end

// ethereum start
export interface EthereumClassification extends EthereumClassifications {
  networkType: EthereumNetworkType
}

export interface EthereumClassifications {
  isEthBlockOrHash: boolean
  isEthAddress: boolean
}

export function isEthereumClassification(classification: UntypedNetwork | unknown): classification is EthereumClassification {
  return (
    (classification as EthereumClassification).isEthAddress ||
    (classification as EthereumClassification).isEthBlockOrHash
  )
}
// ethereum end

// ip start
export interface IPClassification extends IPClassifications {
  networkType: IPNetworkType
}

export interface IPClassifications {
  isIPv4: boolean
}

export function isIPClassification(classification: UntypedNetwork | unknown): classification is IPClassification {
  return (classification as IPClassification).isIPv4
}
// ip end

export type UntypedNetwork = (BitcoinClassifications | EthereumClassifications | IPClassifications) & NetworkClassification

/*
  regex notes
  ethereum block hash (same as tx)
  0x[a-fA-F0-9]{64}

  ethereum tx hash (same as block)
  0x[a-fA-F0-9]{64}

  ethereum address
  0x[a-fA-F0-9]{40}

  bitcoin block hash
  000000[a-f0-9]{58}

  bitcoin txid
  [a-f0-9]{64}

  bitcoin addresses
  legacy - [13][a-km-zA-HJ-NP-Z1-9]{27,34}
  bech32 - bc1[a-zA-HJ-NP-Z1-9]{39,62}
*/

const ETH_BLOCK_TX_HASH_REGEX = /0x[a-fA-F0-9]{64}/
const ETH_ADDRESS_REGEX = /0x[a-fA-F0-9]{40}/
const BITCOIN_BLOCK_HASH_REGEX = /000000[a-f0-9]{58}/
const BITCOIN_TXID_REGEX = /[a-f0-9]{64}/
const BITCOIN_LEGACY_ADDRESS_REGEX = /[13][a-km-zA-HJ-NP-Z1-9]{25,35}/
const BITCOIN_BECH32_ADDRESS_REGEX = /bc1[qpzry9x8gf2tvdw0s3jn54khce6mua7l]{36,59}/
const IP_ADDRESS_REGEX = /((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}/

const ethClassifications = (input: string) => ({
  isEthBlockOrHash: ETH_BLOCK_TX_HASH_REGEX.test(input) && input.length === 66,
  isEthAddress: ETH_ADDRESS_REGEX.test(input) && input.length === 42
})

const bitcoinClassifications = (input: string) => {
  const results = {
    isBitcoinBlock: BITCOIN_BLOCK_HASH_REGEX.test(input) && input.length === 64,
    isBitcoinTxid: BITCOIN_TXID_REGEX.test(input) && input.length === 64 && !input.startsWith('000000'),
    isBitcoinLegacyAddress:
      BITCOIN_LEGACY_ADDRESS_REGEX.test(input) && !BITCOIN_BECH32_ADDRESS_REGEX.test(input) && input.length <= 36,
    isBitcoinBech32Address: BITCOIN_BECH32_ADDRESS_REGEX.test(input),
    isBitcoinBech32mAddress: BITCOIN_BECH32_ADDRESS_REGEX.test(input) && input.length > 42
  }
  return {
    isBitcoinAddress: results.isBitcoinLegacyAddress || results.isBitcoinBech32Address || results.isBitcoinBech32mAddress,
    ...results
  }
}

const ipClassifications = (input: string) => ({
  isIPv4: IP_ADDRESS_REGEX.test(input)
})

export function classifyInput(input: string): NetworkClassifications {
  let networkType: NetworkTypes = ''
  
  const ethereumResults = ethClassifications(input)
  if (isEthereumClassification({ networkType, ...ethereumResults })) {
    return {
      networkType: 'ethereum',
      ...ethereumResults
    }
  }

  const bitcoinResults = bitcoinClassifications(input)
  if (isBitcoinClassification({ networkType, ...bitcoinResults })) {
    return {
      networkType: 'bitcoin',
      ...bitcoinResults
    }
  }

  const ipResults = ipClassifications(input)
  if (isIPClassification({ networkType, ...ipResults })) {
    return {
      networkType: 'ip',
      ...ipResults
    }
  }

  return {
    networkType
  }
}



export function validateEthAddress(input: string): boolean {
  try {
    return eip55.verify(input)
  } catch (e) {
    console.log(e)
    return false
  }
}

export function formatEthAddress(input: string): string {
  try {
    return eip55.encode(input)
  } catch (e) {
    console.log(e)
    return ''
  }
}

export function validateBitcoinAddress(input: string): boolean {
  try {
    return validateBitcoin(input)
  } catch (e) {
    console.log(e)
    return false
  }
}

export function getBitcoinAddressInfo(input: string) {
  try {
    return getAddressInfoBitcoin(input)
  } catch (e) {
    return null
  }
}
