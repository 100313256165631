import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueObserveVisibility from 'vue-observe-visibility'
import Component from 'vue-class-component'
import { register } from 'register-service-worker'

Component.registerHooks(['setup', 'beforeRouteUpdate', 'beforeRouteEnter', 'beforeRouteLeave'])

Vue.config.productionTip = false

Vue.use(VueObserveVisibility)

// ended up not needing this, but potentially useful for the future.
// edge case where computed value was not re-computing when data was sorted.
// solution was to "bubble-down" a counter and @Watch that in the component.

// Vue.prototype.$forceCompute = function (computedName: string, forceUpdate: boolean = true) {
//   if (this._computedWatchers[computedName]) {
//     this._computedWatchers[computedName].run()
//     if (forceUpdate) this.$forceUpdate()
//   }
// }

// use like: this.$forceCompute('variableNameHere')

// service worker
register('/service-worker.js', {
  ready() {
    // console.log('service worker ready')
  },
  registered() {
    // console.log('service worker registered')
  },
  cached() {
    // console.log('service worker cached')
  },
  error(err) {
    console.error('service worker error', err)
  }
})

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app')

