
import { Component, Prop, Vue } from 'vue-property-decorator'

import Dialog, { DialogConfig, FormComponent } from '@/components/Dialog.vue'

@Component({
  components: {
    Dialog
  }
})
export default class Menu extends Vue {
  @Prop({ required: true }) dialogs!: DialogConfig<FormComponent>[]
  @Prop() callback: (() => void) | undefined

  handleExit() {
    if (this.callback != null) {
      this.callback()
    }
    this.$store.dispatch('exitInvestigation')
  }
}
