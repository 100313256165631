import { FlowTarget, FormattedLink, FormattedLinkBase, FormattedNode, GraphLink, isNetworkDupesLink, isNoteLink, NetworkDupesLink, NoteLink, SummaryLink } from "@/store/investigations/viz"
import { SDFRenderer } from "./sdf-text"
import { Graphics, Polygon } from "pixi.js"
import { classifyNodeId, dateRange, nodeTypeToIdString, summaryLinkTimeLabel } from "./viz"
import { timeToMilliseconds } from "./general"
import { COLOR_BLACK, COLOR_BLUE, COLOR_GOLD, COLOR_GREEN, COLOR_LIGHT_GRAY, COLOR_NEON_RED, COLOR_ORANGE, COLOR_VERY_LIGHT_GRAY, convertColorToNumeric } from "./colors"
import { filter } from "./filters"
import { FlowResponse } from "./api"

export interface FormattedSummaryLink extends Omit<SummaryLink, 'source' | 'target'>, FormattedLinkBase<FormattedSummaryLink> {}

export interface ArrowHead {
  headAngle: number
  headLength: number
  pointAngle: number
  pointLength: number
  lineAngle: number
  lineLength: number
  labelAngle: number
  labelLength: number
  thickness: number
  color: number
}

export const LINK_MIN_TRANSACTIONS = 1
export const LINK_MAX_TRANSACTIONS = 10000

export const MIN_THICKNESS = 2
export const MAX_THICKNESS = 7

export const LINK_LABEL_SIZE = 10
export const LINK_LABEL_MARGIN = 10

export const BIDIRECTIONAL_SPACE_THICKNESS = 1

export const LINK_HEAD_LENGTH = 6
export const HALF_PI = Math.PI / 2
export const PI_OVER_HEAD = Math.PI / LINK_HEAD_LENGTH
export const ARROWHEAD_THICKNESS = 1
export const ARROWHEAD_MULTIPLIER = 1.5

export function isTransactionLevel(link: GraphLink): link is FormattedLink {
  return (link as FormattedLink).amount != null
}

export async function labelLink(
  link: FormattedLinkBase<any>,
  decimalFormatter: (n: number | string) => string,
  formatDate: (epoch: number, ms: boolean, shortness?: number) => string,
  sdf: SDFRenderer,
  linkLabels: boolean,
  linkDateLabels: boolean
) {
  if (link.gfx != null) {
    const labelsText = getLinkLabelsText(link as FormattedLink | FormattedSummaryLink, decimalFormatter, formatDate)
    await setUpSDFLinkLabel(link, labelsText, sdf)
    link.gfx.removeChildren()
    if (linkLabels && link.amountLabel != null) {
      link.gfx.addChild(link.amountLabel)
      if (linkDateLabels && link.timeLabel != null) {
        link.gfx.addChild(link.timeLabel)
      }
    }
  }
}

function getLinkLabelsText(
  link: FormattedLink | FormattedSummaryLink,
  decimalFormatter: (n: number | string) => string,
  formatDate: (epoch: number, ms: boolean, shortness?: number) => string
): { amount: string, time: string } {
  let amount, time
  if (isTransactionLevel(link)) {
    const { type: sourceType } = classifyNodeId(link.source.id)
    const currency = link.amount.symbol
    const change = link.reversedLink && sourceType === 'transaction' && link.reversedLink.amount.symbol === currency ?
      ' (change)' : ''
    amount = `${decimalFormatter(link.amount.amount)} ${currency}${change}`
    time = dateRange([link.amount], formatDate)
  } else { // it's a summary link
    amount = ''
    let firstFirst = Infinity
    let lastLast = 0
    const btcIndex = link.linkSummaries.findIndex(summary => summary.symbol === 'BTC')
    const nonBtcSummaries = filter(link.linkSummaries, summary => summary.symbol !== 'BTC')
    let amountSet = false
    if (btcIndex !== -1) {
      const { amount: summaryAmount, symbol } = link.linkSummaries[btcIndex]
      amount = `${decimalFormatter(summaryAmount)} ${symbol}`

      if (nonBtcSummaries.length > 1) {
        amount += `${amount.length > 0 ? ', ' : ''}eth network`
      } else {
        for (const linkSummary of nonBtcSummaries) {
          const { amount: summaryAmount, symbol } = linkSummary
          amount += `${amount.length > 0 ? ', ' : ''}${decimalFormatter(summaryAmount)} ${symbol}`
        }
      }
      amountSet = true
    }
    if (!amountSet && nonBtcSummaries.length > 2) {
      const ethSummary = nonBtcSummaries.find(s => s.symbol === 'ETH')
      if (ethSummary != null) {
        amount = `${decimalFormatter(ethSummary.amount)} ETH, + tokens`
      } else {
        amount = 'tokens'
      }
      amountSet = true
    }
    for (const linkSummary of link.linkSummaries) {
      const { amount: summaryAmount, first, last, symbol } = linkSummary
      if (summaryAmount === -1) {
        // the summary link is manufactured because there's no data but it should be there
        return { amount: 'unavailable', time: 'unavailable' }
      }
      if (!amountSet) {
        amount += `${amount.length > 0 ? ', ' : ''}${decimalFormatter(summaryAmount)} ${symbol}`
      }
      const firstMillis = timeToMilliseconds(first)
      const lastMillis = timeToMilliseconds(last)
      if (firstMillis < firstFirst) {
        firstFirst = firstMillis
      }
      if (lastMillis > lastLast) {
        lastLast = lastMillis
      }
    }
    time = summaryLinkTimeLabel(formatDate, firstFirst, lastLast)
  }
  return { amount, time }
}

async function setUpSDFLinkLabel(link: FormattedLinkBase<any>, texts: { amount: string, time: string }, sdf: SDFRenderer) {
  const { amount, time } = texts

  const amountWidth = amount.length
  link.amountLabel = sdf.createMesh(amount, 1, amountWidth)
  link.amountLabel.width = (amountWidth * LINK_LABEL_SIZE) / 2
  link.amountLabel.height = LINK_LABEL_SIZE

  const timeWidth = time.length
  link.timeLabel = sdf.createMesh(time, 1, timeWidth)
  link.timeLabel.width = (timeWidth * LINK_LABEL_SIZE) / 2
  link.timeLabel.height = LINK_LABEL_SIZE
}

export async function getTotalThicknessForLink(
  link: FormattedLink | FormattedSummaryLink,
  scaleLinkThickness: (l: FormattedLink | FormattedSummaryLink) => number
) {
  const reversedLink = link.reversedLink
  const rawSourceToTargetThickness = scaleLinkThickness(link)
  const rawTargetToSourceThickness = reversedLink ? scaleLinkThickness(reversedLink) : 0

  const largerThickness =
    rawSourceToTargetThickness > rawTargetToSourceThickness ? rawSourceToTargetThickness : rawTargetToSourceThickness
  const totalThicknessWithoutSpacing = largerThickness - BIDIRECTIONAL_SPACE_THICKNESS
  const totalThicknessWithSpacing = largerThickness + BIDIRECTIONAL_SPACE_THICKNESS

  const proportion = rawSourceToTargetThickness + rawTargetToSourceThickness

  const sourceProportion = rawSourceToTargetThickness / proportion
  const targetProportion = rawTargetToSourceThickness / proportion

  const totalThickness =
    largerThickness > BIDIRECTIONAL_SPACE_THICKNESS ? totalThicknessWithoutSpacing : totalThicknessWithSpacing

  const sourceToTargetThickness = sourceProportion * totalThickness
  const targetToSourceThickness = targetProportion * totalThickness

  link.totalThickness = totalThickness
  link.forwardThickness = sourceToTargetThickness
  if (link.reversedLink) link.reversedLink.forwardThickness = targetToSourceThickness
}

export function drawLink(
  link: FormattedLink | FormattedSummaryLink,
  thickness: number,
  nodeSizeScale: d3.ScaleLinear<number, number, never> | d3.ScaleSymLog<number, number, never>,
  networksFilter: string[],
  activeLinksRemoved: GraphLink[],
  newestLinksCopy: Set<string>,
  highlightFlowKeys: string[],
  selectedLinks: Set<GraphLink>,
  highlightLink: string,
  selectedLink?: GraphLink,
  subnetworkFlow?: FlowResponse,
  subnetworkSender?: FlowTarget,
  subnetworkReceiver?: FlowTarget,
  hitBoxOnly?: boolean
) {
  const { source, target, gfx, amountLabel, timeLabel } = link
  if (gfx == null || amountLabel == null || timeLabel == null) return

  const color = getColorForLink(
    link,
    networksFilter,
    activeLinksRemoved,
    newestLinksCopy,
    highlightFlowKeys,
    selectedLinks,
    highlightLink,
    selectedLink,
    subnetworkFlow,
    subnetworkSender,
    subnetworkReceiver
  )
  gfx.clear()
  gfx.lineStyle(thickness, color)

  // Find the widths of the target and source nodes
  const sourceWidth = nodeSizeScale(source.size)
  const targetWidth = nodeSizeScale(target.size)

  const sourceX = source.x as number
  const sourceY = source.y as number
  const targetX = target.x as number
  const targetY = target.y as number

  // Start the link by pointing our cursor at the midpoint of the line
  const centerX = (targetX + sourceX) / 2
  const centerY = (targetY + sourceY) / 2

  const canonicalTargetX = targetX - centerX
  const canonicalTargetY = targetY - centerY
  const canonicalSourceX = sourceX - centerX
  const canonicalSourceY = sourceY - centerY

  gfx.x = centerX
  gfx.y = centerY

  // Angle between source and target node
  let textAngle = Math.atan2(canonicalTargetY - canonicalSourceY, canonicalTargetX - canonicalSourceX)
  let angle: number = textAngle

  // We need to adjust where the line goes so that it doesn't go to the center of the node and rather goes to the perimeter.
  const sourceIsSquare = classifyNodeId(source.id).type === 'transaction'
  const targetIsSquare = classifyNodeId(target.id).type === 'transaction'
  const sourceAdjustments = adjustToNodePerimeter(angle, sourceWidth, sourceIsSquare)
  const targetAdjustments = adjustToNodePerimeter(angle, targetWidth, targetIsSquare, false, thickness)

  const sourcePerimeterX = canonicalSourceX + sourceAdjustments.adjustmentX
  const sourcePerimeterY = canonicalSourceY + sourceAdjustments.adjustmentY
  const targetPerimeterX = canonicalTargetX - targetAdjustments.adjustmentX
  const targetPerimeterY = canonicalTargetY - targetAdjustments.adjustmentY

  gfx.moveTo(sourcePerimeterX, sourcePerimeterY)

  if (hitBoxOnly || color === COLOR_VERY_LIGHT_GRAY) {
    amountLabel.visible = false
    timeLabel.visible = false
  } else {
    amountLabel.visible = true
    timeLabel.visible = true
  }

  // adjust label
  if (textAngle > HALF_PI || textAngle < -HALF_PI) {
    textAngle += Math.PI //ensure all text is right-side up
  }
  amountLabel.rotation = textAngle
  timeLabel.rotation = textAngle

  // We need to adjust where the line goes if the node requires arrows (i.e. its a circle)
  // If its a square it should go to the center. Otherwise, it should go to the bottom of the arrowhead.
  const arrowHeadAdjustmentX = targetIsSquare ? 0 : LINK_HEAD_LENGTH * Math.cos(angle)
  const arrowHeadAdjustmentY = targetIsSquare ? 0 : LINK_HEAD_LENGTH * Math.sin(angle)

  if (!hitBoxOnly) {
    gfx.lineTo(targetPerimeterX - arrowHeadAdjustmentX, targetPerimeterY - arrowHeadAdjustmentY)
  }

  const textMoveAngle = textAngle + HALF_PI
  const expandedThickness = thickness + LINK_LABEL_MARGIN
  const amountLabelHalfWidth = amountLabel.width / 2
  amountLabel.x = expandedThickness * Math.cos(textMoveAngle) - amountLabelHalfWidth * Math.cos(textAngle)
  amountLabel.y = expandedThickness * Math.sin(textMoveAngle) - amountLabelHalfWidth * Math.sin(textAngle)

  // for some reason, width and height are sometimes NaN even when Mesh exists, resolve to 0
  const extraLabelDistance = expandedThickness + (timeLabel.height || 0) + 2
  const timeLabelHalfWidth = (timeLabel.width || 0) / 2
  timeLabel.x = extraLabelDistance * Math.cos(textMoveAngle) - timeLabelHalfWidth * Math.cos(textAngle)
  timeLabel.y = extraLabelDistance * Math.sin(textMoveAngle) - timeLabelHalfWidth * Math.sin(textAngle)

  // define hit area
  gfx.hitArea = new Polygon([canonicalSourceX, canonicalSourceY, -timeLabel.x, -timeLabel.y, canonicalTargetX, canonicalTargetY, timeLabel.x, timeLabel.y])
  amountLabel.hitArea = new Polygon([amountLabel.x, amountLabel.y]) // need to do this to avoid errors
  timeLabel.hitArea = new Polygon([timeLabel.x, timeLabel.y])

  // draw the arrows by finding the tip of the node and making a triangle from it
  if (!targetIsSquare && !hitBoxOnly) {
    // This makes it so the end of the arrow head is located at tox, toy
    gfx.beginFill(color)
    gfx.moveTo(targetPerimeterX, targetPerimeterY)

    const adjustedAngle1 = angle - PI_OVER_HEAD
    const adjustedAngle2 = angle + PI_OVER_HEAD

    // starting a new path from the tip to one of the side points
    gfx.lineTo(
      targetPerimeterX - LINK_HEAD_LENGTH * Math.cos(adjustedAngle1),
      targetPerimeterY - LINK_HEAD_LENGTH * Math.sin(adjustedAngle1)
    )

    // path from side point to the other side point
    gfx.lineTo(
      targetPerimeterX - LINK_HEAD_LENGTH * Math.cos(adjustedAngle2),
      targetPerimeterY - LINK_HEAD_LENGTH * Math.sin(adjustedAngle2)
    )

    // path from the side point back to the tip of the arrow
    gfx.lineTo(targetPerimeterX, targetPerimeterY)
    gfx.closePath() // without this, PIXI triangles have a weird notch at the tip
    gfx.endFill()
  }
}

export function drawNetworkDupesLink(
  link: NetworkDupesLink,
  thickness: number,
  nodeSizeScale: d3.ScaleLinear<number, number, never> | d3.ScaleSymLog<number, number, never>,
  networksFilter: string[],
  activeLinksRemoved: GraphLink[],
  newestLinksCopy: Set<string>,
  highlightFlowKeys: string[],
  selectedLinks: Set<GraphLink>,
  highlightLink: string,
  selectedLink?: GraphLink,
  subnetworkFlow?: FlowResponse,
  subnetworkSender?: FlowTarget,
  subnetworkReceiver?: FlowTarget
) {
  const { source, target, gfx } = link
  const color = getColorForLink(
    link,
    networksFilter,
    activeLinksRemoved,
    newestLinksCopy,
    highlightFlowKeys,
    selectedLinks,
    highlightLink,
    selectedLink,
    subnetworkFlow,
    subnetworkSender,
    subnetworkReceiver
  )
  gfx.clear()
  gfx.lineStyle(thickness, color)

  // Find the widths of the target and source nodes
  const sourceWidth = nodeSizeScale(source.size)
  const targetWidth = nodeSizeScale(target.size)

  const sourceX = source.x as number
  const sourceY = source.y as number
  const targetX = target.x as number
  const targetY = target.y as number

  // Start the link by pointing our cursor at the midpoint of the line
  const centerX = (targetX + sourceX) / 2
  const centerY = (targetY + sourceY) / 2

  const canonicalTargetX = targetX - centerX
  const canonicalTargetY = targetY - centerY
  const canonicalSourceX = sourceX - centerX
  const canonicalSourceY = sourceY - centerY

  gfx.x = centerX
  gfx.y = centerY

  // Angle between source and target node
  let textAngle = Math.atan2(canonicalTargetY - canonicalSourceY, canonicalTargetX - canonicalSourceX)
  let angle: number = textAngle

  // We need to adjust where the line goes so that it doesn't go to the center of the node and rather goes to the perimeter.
  const sourceAdjustments = adjustToNodePerimeter(angle, sourceWidth, false)
  const targetAdjustments = adjustToNodePerimeter(angle, targetWidth, false)

  const sourcePerimeterX = canonicalSourceX + sourceAdjustments.adjustmentX
  const sourcePerimeterY = canonicalSourceY + sourceAdjustments.adjustmentY
  const targetPerimeterX = canonicalTargetX - targetAdjustments.adjustmentX
  const targetPerimeterY = canonicalTargetY - targetAdjustments.adjustmentY

  gfx.moveTo(sourcePerimeterX, sourcePerimeterY)
  gfx.lineTo(targetPerimeterX, targetPerimeterY)
}

export function drawNoteLink(
  link: NoteLink,
  nodeSizeScale: d3.ScaleLinear<number, number, never> | d3.ScaleSymLog<number, number, never>,
  networksFilter: string[],
  activeLinksRemoved: GraphLink[],
  newestLinksCopy: Set<string>,
  highlightFlowKeys: string[],
  selectedLinks: Set<GraphLink>,
  highlightLink: string,
  selectedLink?: GraphLink,
  subnetworkFlow?: FlowResponse,
  subnetworkSender?: FlowTarget,
  subnetworkReceiver?: FlowTarget
) {
  const { source, target, gfx } = link
  const color = getColorForLink(
    link,
    networksFilter,
    activeLinksRemoved,
    newestLinksCopy,
    highlightFlowKeys,
    selectedLinks,
    highlightLink,
    selectedLink,
    subnetworkFlow,
    subnetworkSender,
    subnetworkReceiver
  )
  gfx.clear()
  gfx.lineStyle(1, color)

  // Find the widths of the target and source nodes
  const sourceWidth = source.input.width
  const targetWidth = nodeSizeScale(target.size)

  const sourceX = source.x as number + sourceWidth / 2
  const sourceY = source.y as number + source.input.height / 2
  const targetX = target.x as number
  const targetY = target.y as number

  // Start the link by pointing our cursor at the midpoint of the line
  const centerX = (targetX + sourceX) / 2
  const centerY = (targetY + sourceY) / 2

  const canonicalTargetX = targetX - centerX
  const canonicalTargetY = targetY - centerY
  const canonicalSourceX = sourceX - centerX
  const canonicalSourceY = sourceY - centerY

  gfx.x = centerX
  gfx.y = centerY

  // Angle between source and target node
  let textAngle = Math.atan2(canonicalTargetY - canonicalSourceY, canonicalTargetX - canonicalSourceX)
  let angle: number = textAngle

  // We need to adjust where the line goes so that it doesn't go to the center of the node and rather goes to the perimeter.
  const sourceIsSquare = true
  const targetIsSquare = classifyNodeId(target.id).type === 'transaction'
  const sourceAdjustments = adjustToNodePerimeter(angle, sourceWidth, sourceIsSquare)
  const targetAdjustments = adjustToNodePerimeter(angle, targetWidth, targetIsSquare, false, 1)

  const sourcePerimeterX = canonicalSourceX + sourceAdjustments.adjustmentX
  const sourcePerimeterY = canonicalSourceY + sourceAdjustments.adjustmentY
  const targetPerimeterX = canonicalTargetX - targetAdjustments.adjustmentX
  const targetPerimeterY = canonicalTargetY - targetAdjustments.adjustmentY

  gfx.moveTo(sourcePerimeterX, sourcePerimeterY)

  // adjust label
  if (textAngle > HALF_PI || textAngle < -HALF_PI) {
    textAngle += Math.PI //ensure all text is right-side up
  }

  // We need to adjust where the line goes if the node requires arrows (i.e. its a circle)
  // If its a square it should go to the center. Otherwise, it should go to the bottom of the arrowhead.
  const arrowHeadAdjustmentX = targetIsSquare ? 0 : LINK_HEAD_LENGTH * Math.cos(angle)
  const arrowHeadAdjustmentY = targetIsSquare ? 0 : LINK_HEAD_LENGTH * Math.sin(angle)

  gfx.lineTo(targetPerimeterX - arrowHeadAdjustmentX, targetPerimeterY - arrowHeadAdjustmentY)

  // draw the arrows by finding the tip of the node and making a triangle from it
  if (!targetIsSquare) {
    // This makes it so the end of the arrow head is located at tox, toy
    gfx.beginFill(color)
    gfx.moveTo(targetPerimeterX, targetPerimeterY)

    const adjustedAngle1 = angle - PI_OVER_HEAD
    const adjustedAngle2 = angle + PI_OVER_HEAD

    // starting a new path from the tip to one of the side points
    gfx.lineTo(
      targetPerimeterX - LINK_HEAD_LENGTH * Math.cos(adjustedAngle1),
      targetPerimeterY - LINK_HEAD_LENGTH * Math.sin(adjustedAngle1)
    )

    // path from side point to the other side point
    gfx.lineTo(
      targetPerimeterX - LINK_HEAD_LENGTH * Math.cos(adjustedAngle2),
      targetPerimeterY - LINK_HEAD_LENGTH * Math.sin(adjustedAngle2)
    )

    // path from the side point back to the tip of the arrow
    gfx.lineTo(targetPerimeterX, targetPerimeterY)
    gfx.closePath() // without this, PIXI triangles have a weird notch at the tip
    gfx.endFill()
  }
}

function getColorForLink(
  link: GraphLink,
  networksFilter: string[],
  activeLinksRemoved: GraphLink[],
  newestLinksCopy: Set<string>,
  highlightFlowKeys: string[],
  selectedLinks: Set<GraphLink>,
  highlightLink: string,
  selectedLink?: GraphLink,
  subnetworkFlow?: FlowResponse,
  subnetworkSender?: FlowTarget,
  subnetworkReceiver?: FlowTarget
): number {
  // if there's a networks filter, check if link is not included first
  if (networksFilter.length > 0) {
    const { network } = classifyNodeId(link.target.id)
    if (!networksFilter.includes(network)) {
      return COLOR_VERY_LIGHT_GRAY
    }
  }

  // note links and dupe links have specific colors
  if (isNoteLink(link)) {
    return COLOR_BLACK
  } else if (isNetworkDupesLink(link)) {
    return COLOR_LIGHT_GRAY
  }

  const linkString = JSON.stringify([link.source.id, link.target.id])
  const reversedLinkString = JSON.stringify([link.target.id, link.source.id])
  
  const deletedLinks = new Set(activeLinksRemoved.map(l => JSON.stringify([l.source.id, l.target.id])))
  if (deletedLinks.has(linkString) || deletedLinks.has(reversedLinkString)) {
    return COLOR_LIGHT_GRAY
  }

  let color = link.color ? convertColorToNumeric(link.color) : COLOR_BLACK

  if (newestLinksCopy.has(`${link.source.id}||${link.target.id}`)) {
    color = COLOR_GREEN
  }

  if (highlightFlowKeys.length > 0) {
    const keys: string[] = []
    const hops: number[] = []
    for (const key of highlightFlowKeys) {
      const split = key.split('|')
      hops.push(parseInt(split.pop() as string, 10))
      keys.push(split.join('|'))
    }
    if (isTransactionLevel(link)) {
      const amount = link.amount
      if (amount.flows != null) {
        const found = filter(amount.flows, flow => {
          const { targetTransaction, sending, minHops } = flow
          const { id, isOutput, index } = targetTransaction
          const key = `${id}|${isOutput}|${index}|${sending}`
          for (let i=0; i < keys.length; i++) {
            if (key === keys[i] && minHops <= hops[i]) {
              return true
            }
          }
          return false
        })
        if (found.length >= keys.length) {
          color = COLOR_NEON_RED
        }
      }
    } else {
      const amount = link.transactions
      let totalMatches = 0
      for (const a of amount) {
        if (a.flows != null) {
          const found = filter(a.flows, flow => {
            const { targetTransaction, sending, minHops } = flow
            const { id, isOutput, index } = targetTransaction
            const key = `${id}|${isOutput}|${index}|${sending}`
            for (let i=0; i < keys.length; i++) {
              if (key === keys[i] && minHops <= hops[i]) {
                return true
              }
            }
            return false
          })
          totalMatches += found.length
        }
        if (totalMatches >= keys.length) {
          color = COLOR_NEON_RED
        }
      }
    }
  }

  if (selectedLinks.has(link)) {
    color = COLOR_ORANGE
  } else if (selectedLink != null && (selectedLink === link || selectedLink.reversedLink === link)) {
    color = COLOR_BLUE
  } else if (highlightLink === linkString || highlightLink === reversedLinkString) {
    color = COLOR_ORANGE
  } else if (subnetworkFlow) {
    let found = false
    if (subnetworkSender) {
      const { network, transaction } = subnetworkSender
      const { address, cluster, clusterAttribution, id, isOutput } = transaction
      const entities = new Set([address, cluster, clusterAttribution])
      const txnNodeId = nodeTypeToIdString({ id, type: 'transaction', network })
      if (!isOutput) {
        if (entities.has(link.source.id) && link.target.id === txnNodeId) {
          color = COLOR_GOLD
          found = true
        }
      } else {
        if (link.source.id === txnNodeId && entities.has(link.target.id)) {
          color = COLOR_GOLD
          found = true
        }
      }
    }
    if (!found && subnetworkReceiver) {
      const { network, transaction } = subnetworkReceiver
      const { address, cluster, clusterAttribution, id, isOutput } = transaction
      const entities = new Set([address, cluster, clusterAttribution])
      const txnNodeId = nodeTypeToIdString({ id, type: 'transaction', network })
      if (!isOutput) {
        if (entities.has(link.source.id) && link.target.id === txnNodeId) {
          color = COLOR_GOLD
        }
      } else {
        if (link.source.id === txnNodeId && entities.has(link.target.id)) {
          color = COLOR_GOLD
        }
      }
    }
  }
  return color
}

// get the adjustment so that lines go to the tip and not the center of the node
function adjustToNodePerimeter(
  angle: number,
  width: number,
  isSquare: boolean,
  bidirectional: boolean = false,
  thickness: number = 0
): { adjustmentX: number; adjustmentY: number } {
  // Need to adjust slightly by the thickness
  const thicknessOffsetX = bidirectional ? 0 : Math.cos(angle) * thickness
  const thicknessOffsetY = bidirectional ? 0 : Math.sin(angle) * thickness
  if (isSquare) {
    // DON'T ADJUST ANYTHING IF IT'S A SQUARE! IT LOOKS WEIRD.
    if (!bidirectional) {
      return {
        adjustmentX: 0,
        adjustmentY: 0
      }
    } else {
      const halfWidth = width / 2
      return {
        adjustmentX: Math.cos(angle) * halfWidth,
        adjustmentY: Math.sin(angle) * halfWidth
      }
    }
  } else {
    return {
      adjustmentX: Math.cos(angle) * width + thicknessOffsetX,
      adjustmentY: Math.sin(angle) * width + thicknessOffsetY
    }
  }
}

// BIDIRECTIONAL

export function combineBidirectionalLinks(links: Array<FormattedLinkBase<any>>) {
  const ends = links.map((l) => JSON.stringify([(l.source as FormattedNode).id, (l.target as FormattedNode).id]))
  links.forEach((l) => {
    const index = ends.indexOf(JSON.stringify([(l.target as FormattedNode).id, (l.source as FormattedNode).id]))
    if (index !== -1) {
      l.reversedLink = links[index]
    }
  })
}

export function drawSingleBidirectionalLink(
  link: FormattedLink | FormattedSummaryLink,
  nodeSizeScale: d3.ScaleLinear<number, number, never> | d3.ScaleSymLog<number, number, never>,
  networksFilter: string[],
  activeLinksRemoved: GraphLink[],
  newestLinksCopy: Set<string>,
  highlightFlowKeys: string[],
  selectedLinks: Set<GraphLink>,
  highlightLink: string,
  selectedLink?: GraphLink,
  subnetworkFlow?: FlowResponse,
  subnetworkSender?: FlowTarget,
  subnetworkReceiver?: FlowTarget,
  hitBoxOnly?: boolean
) {
  const { gfx, totalThickness, forwardThickness: linkThickness } = link
  if (gfx == null || !totalThickness || !linkThickness) return
  
  const color = getColorForLink(
    link,
    networksFilter,
    activeLinksRemoved,
    newestLinksCopy,
    highlightFlowKeys,
    selectedLinks,
    highlightLink,
    selectedLink,
    subnetworkFlow,
    subnetworkSender,
    subnetworkReceiver
  )
  gfx.clear()

  const source = link.source
  const target = link.target

  const sourceX = source.x as number
  const sourceY = source.y as number
  const targetX = target.x as number
  const targetY = target.y as number

  // set origin at center between source and target
  const centerX = (targetX + sourceX) / 2
  const centerY = (targetY + sourceY) / 2
  gfx.x = centerX
  gfx.y = centerY

  // in world space, get our X, Y of source and target
  const canonicalTargetX = targetX - centerX
  const canonicalTargetY = targetY - centerY
  const canonicalSourceX = sourceX - centerX
  const canonicalSourceY = sourceY - centerY

  // Find the widths of the target and source nodes
  const targetWidth = nodeSizeScale(target.size)
  const sourceWidth = nodeSizeScale(source.size)
  const sourceIsSquare = (classifyNodeId(source.id).type === 'transaction')
  const targetIsSquare = (classifyNodeId(target.id).type === 'transaction')
  const sourceRadius = sourceIsSquare ? sourceWidth / 2 : sourceWidth
  const targetRadius = targetIsSquare ? targetWidth / 2 : targetWidth

  // for arrows
  const targetTotalAngle = Math.atan2(canonicalTargetY - canonicalSourceY, canonicalTargetX - canonicalSourceX)
  const sourceTotalAngle = Math.atan2(canonicalSourceY - canonicalTargetY, canonicalSourceX - canonicalTargetX)
  const yOffset = linkThickness > LINK_HEAD_LENGTH ? linkThickness : LINK_HEAD_LENGTH

  const origin = getBidirectionalLine(totalThickness, sourceRadius)
  const terminal = getBidirectionalLine(totalThickness, targetRadius, yOffset)

  const line = {
    originX: canonicalSourceX - origin.length * Math.cos(sourceTotalAngle + origin.angle),
    originY: canonicalSourceY - origin.length * Math.sin(sourceTotalAngle + origin.angle),
    terminalX: canonicalTargetX - terminal.length * Math.cos(targetTotalAngle - terminal.angle),
    terminalY: canonicalTargetY - terminal.length * Math.sin(targetTotalAngle - terminal.angle),
    thickness: linkThickness,
    color
  }

  if (!hitBoxOnly) {
    // source to target line
    gfx.lineStyle(line.thickness, line.color)
    gfx.moveTo(line.originX, line.originY)
    gfx.lineTo(line.terminalX, line.terminalY)

    const arrowheadThickness = line.thickness > ARROWHEAD_THICKNESS ? ARROWHEAD_THICKNESS : line.thickness
    const arrowHead = getArrowHeadAngles(
      totalThickness,
      targetRadius,
      targetTotalAngle,
      line.thickness,
      arrowheadThickness,
      targetIsSquare,
      yOffset,
      line.color
    )
    drawArrowHead(canonicalTargetX, canonicalTargetY, arrowHead, gfx)
  }

  // adjust label
  const { amountLabel, timeLabel } = link
  if (amountLabel != null && timeLabel != null) {
    if (hitBoxOnly || color === COLOR_VERY_LIGHT_GRAY) {
      amountLabel.visible = false
      timeLabel.visible = false
    } else {
      amountLabel.visible = true
      timeLabel.visible = true
    }

    let textAngle = targetTotalAngle
    const textMoveAngle = textAngle + HALF_PI
    if (textAngle > HALF_PI || textAngle < -HALF_PI) {
      textAngle += Math.PI // ensure all text is right-side up
    }
    amountLabel.rotation = textAngle
    timeLabel.rotation = textAngle

    const expandedThickness = line.thickness + LINK_LABEL_MARGIN
    const amountLabelHalfWidth = amountLabel.width / 2
    amountLabel.x = expandedThickness * Math.cos(textMoveAngle) - amountLabelHalfWidth * Math.cos(textAngle)
    amountLabel.y = expandedThickness * Math.sin(textMoveAngle) - amountLabelHalfWidth * Math.sin(textAngle)

    const extraLabelDistance = expandedThickness + timeLabel.height + 2
    const timeLabelHalfWidth = timeLabel.width / 2
    timeLabel.x = extraLabelDistance * Math.cos(textMoveAngle) - timeLabelHalfWidth * Math.cos(textAngle)
    timeLabel.y = extraLabelDistance * Math.sin(textMoveAngle) - timeLabelHalfWidth * Math.sin(textAngle)

    // define hit area
    gfx.hitArea = new Polygon([canonicalSourceX, canonicalSourceY, canonicalTargetX, canonicalTargetY, timeLabel.x, timeLabel.y])
    amountLabel.hitArea = new Polygon([amountLabel.x, amountLabel.y]) // need to do this to avoid errors
    timeLabel.hitArea = new Polygon([timeLabel.x, timeLabel.y])
  }
}

function drawArrowHead(canonicalX: number, canonicalY: number, arrowHead: ArrowHead, gfx: Graphics) {
  const points = {
    originX: canonicalX - arrowHead.headLength * Math.cos(arrowHead.headAngle),
    originY: canonicalY - arrowHead.headLength * Math.sin(arrowHead.headAngle),
    tipX: canonicalX - arrowHead.pointLength * Math.cos(arrowHead.pointAngle),
    tipY: canonicalY - arrowHead.pointLength * Math.sin(arrowHead.pointAngle),
    lineX: canonicalX - arrowHead.lineLength * Math.cos(arrowHead.lineAngle),
    lineY: canonicalY - arrowHead.lineLength * Math.sin(arrowHead.lineAngle)
  }

  gfx.lineStyle(arrowHead.thickness, arrowHead.color)
  gfx.beginFill(arrowHead.color)
  gfx.moveTo(points.originX, points.originY)
  gfx.lineTo(points.lineX, points.lineY)
  gfx.lineTo(points.tipX, points.tipY)
  gfx.lineTo(points.originX, points.originY)
  gfx.endFill()
}

function getArrowHeadAngles(
  totalThickness: number,
  radius: number,
  totalAngle: number,
  linkThickness: number,
  arrowheadThickness: number,
  isSquare: boolean,
  yOffset: number,
  color: number
): ArrowHead {
  const deltaX = totalThickness / 2 - linkThickness / 2 + arrowheadThickness / 2 // end of the link
  const arrowHeadDeltaY = isSquare ? radius : Math.sqrt(radius * radius - deltaX * deltaX) // get y
  const arrowHeadAngleDiff = Math.atan(deltaX / arrowHeadDeltaY)
  const arrowHeadAngle = totalAngle - arrowHeadAngleDiff

  const multiplier = totalThickness / linkThickness
  const pointAngleOffset = multiplier > 0.5 ? Math.PI / 20 : Math.PI / 30

  const deltaY = radius + yOffset + arrowheadThickness
  const pointAngle = arrowHeadAngleDiff + pointAngleOffset
  const arrowHeadLength = deltaY / Math.cos(pointAngle)
  const lineAngle = totalAngle - Math.atan(deltaX / deltaY)
  const arrowHeadLinePoint = Math.sqrt(deltaX * deltaX + deltaY * deltaY)

  const labelDeltaY = deltaY + LINK_HEAD_LENGTH
  const labelAngle = pointAngle
  const labelLength = labelDeltaY / Math.cos(labelAngle)
  return {
    headAngle: arrowHeadAngle,
    headLength: radius + 1,
    pointAngle: totalAngle - pointAngle,
    pointLength: arrowHeadLength,
    lineAngle: lineAngle,
    lineLength: arrowHeadLinePoint,
    labelAngle: totalAngle + labelAngle,
    labelLength: labelLength,
    thickness: arrowheadThickness,
    color: color
  }
}

function getBidirectionalLine(totalThickness: number, width: number, yOffset: number = 0) {
  const deltaX = totalThickness / 2
  const deltaY = width + yOffset
  const angle = Math.atan(deltaX / deltaY)
  const length = Math.sqrt(deltaX * deltaX + deltaY * deltaY)
  return {
    angle: angle,
    length: length
  }
}