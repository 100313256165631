
import { SimpleAddress } from '@/types/bitcoin'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class BtcAddress extends Vue {
  @Prop() item!: SimpleAddress
  public network: string = ''

  created() {
    this.network = this.$route.params.network
  }
}
