
import { Component, Prop, Vue } from 'vue-property-decorator'
import { ExtendedTx, isExtendedCoinbase } from '@/types/bitcoin'
import { copyToClipboard } from '@/utils/general'
import { calcFee, calcInputAmount, calcOutputAmount, rbfEnabled, svb } from '@/utils/bitcoin'

@Component
export default class BtcTxnMetadata extends Vue {
  @Prop() transaction!: ExtendedTx
  public show = false

  get humanize() {
    return this.$store.state.dateHumanizer
  }

  get fee() {
    const inputs = this.transaction.vin
    if (inputs && isExtendedCoinbase(inputs[0])) {
      return 0
    }
    return calcFee(this.inputAmount, this.outputAmount)
  }

  get inputAmount(): string {
    return calcInputAmount(this.transaction.vin)
  }

  get outputAmount() {
    return calcOutputAmount(this.transaction.vout)
  }

  get feeByte() {
    if (!this.fee || !this.transaction) {
      return 0
    }
    const sats = parseFloat(this.fee) * 10 ** 8
    return Math.round(sats / this.transaction.size)
  }

  get svb() {
    if (!this.fee || !this.transaction) {
      return 0
    }
    return svb(this.fee, this.transaction.vsize)
  }

  get feeWU() {
    if (!this.fee || !this.transaction) {
      return 0
    }
    const sats = parseFloat(this.fee) * 10 ** 8
    return Math.round(sats / this.transaction.weight)
  }

  get avgInputAge() {
    const ageSum = this.transaction.vin
      .map((i) => (isExtendedCoinbase(i) ? 0 : this.transaction.time - i.spentOutput.time))
      .reduce((sum, value) => sum + value)
    return this.humanize((ageSum * 1000) / this.transaction.vin.length)
  }

  get segwit() {
    for (const i of this.transaction.vin) {
      if (!isExtendedCoinbase(i)) {
        if (i.txinwitness && i.txinwitness.length > 0) {
          return true
        }
      }
    }
    return false
  }

  get rbf() {
    return rbfEnabled(this.transaction)
  }

  copy(text: string) {
    copyToClipboard(text)
    this.$store.dispatch('updateSnackbar', { show: true, text: `Copied ${text} to clipboard.` })
  }

  public formatDate(epoch: number) {
    return this.$store.state.formatDate(epoch, false)
  }
}
