
import { Component, Prop, ProvideReactive, Vue } from 'vue-property-decorator'
import { DirectiveOptions } from 'vue'
import * as d3 from 'd3'
import * as PIXI from 'pixi.js'
import { Viewport } from 'pixi-viewport'
import { ChartData, TooltipItems } from './types/charts'

const xAxisDirective: DirectiveOptions = {
  inserted(el, binding) {
    const axis = binding.value
    // @ts-ignore
    d3.select(el).call(axis).selectAll('text').attr('transform', 'rotate(315)').style('text-anchor', 'end')
  }
}

const yAxisDirective: DirectiveOptions = {
  inserted(el, binding) {
    const axis = binding.value
    // @ts-ignore
    d3.select(el).call(axis)
  },
  componentUpdated(el, binding) {
    const axis = binding.value
    // @ts-ignore
    d3.select(el).transition().call(axis)
  }
}

@Component({
  directives: {
    xAxisDirective,
    yAxisDirective
  }
})
export default class AreaChart extends Vue {
  @Prop() graphData!: ChartData[]
  @Prop() chartTitle!: string
  @Prop() height!: number
  @Prop() width!: number
  @Prop() axisPadding!: number
  @Prop() fillColor!: number
  @Prop() lineColor!: number
  @Prop() yScaleType!: string
  @Prop() hideScales!: boolean
  @Prop() hideCloseBtn!: boolean
  @Prop() showAvg!: boolean
  public topPadding: number = 35
  private chartHeight: number = 0
  private chartWidth: number = 0
  public scaleToggle: string = ''
  private viewport!: any
  private xScale!: d3.ScaleTime<number, number, never>
  private yScaleLinear!: d3.ScaleLinear<number, number, never>
  private yScaleLog!: d3.ScaleLogarithmic<number, number, never>
  private yScale!: d3.ScaleLinear<number, number, never> | d3.ScaleLogarithmic<number, number, never>
  private yDomain: any
  private mousevline!: Element
  public nearestX = 0
  public nearestY = 0
  @ProvideReactive() public axisLeft: any = null
  public axisBottom: any = null
  private area!: any
  private line!: any
  private areaGraphics!: PIXI.Graphics
  private lineGraphics!: PIXI.Graphics
  @ProvideReactive() tooltipTitle = ''
  @ProvideReactive() tooltipItems: TooltipItems[] = []
  public mouseInChart: boolean = false

  mounted() {
    this.chartHeight = this.height - this.axisPadding
    this.chartWidth = this.width - this.axisPadding

    const xDomain: any = d3.extent(this.graphData, (d) => d.date)
    this.xScale = d3.scaleTime().range([0, this.chartWidth]).domain(xDomain)

    this.yDomain = d3.extent(this.graphData, (d) => d.value)
    if (this.yScaleType === 'log') {
      this.scaleToggle = this.yScaleType
      this.yScale = d3.scaleLog().range([this.chartHeight, 0]).domain(this.yDomain)
    } else {
      // linear is default
      this.scaleToggle = 'linear'
      this.yScale = d3.scaleLinear().range([this.chartHeight, 0]).domain(this.yDomain)
    }

    this.axisLeft = d3.axisLeft(this.yScale).ticks(5, '~s')

    const timeRangeDays = (xDomain[1] - xDomain[0]) / 86400000.0

    var formatDatetime: (date: Date) => string
    if (timeRangeDays < 1.5) formatDatetime = d3.timeFormat('%-I:%M %p')
    else if (timeRangeDays < 5) formatDatetime = d3.timeFormat('%-m/%-d %-I %p')
    else formatDatetime = d3.timeFormat('%x')

    this.axisBottom = d3
      .axisBottom(this.xScale)
      // @ts-ignore
      .tickFormat(formatDatetime)
      .ticks(Math.min(this.graphData.length, 10))

    this.drawChart(this.$refs.graphContainer as Element, this.graphData)
    this.initSvg()
  }

  scaleToggleChange(value: string) {
    if (value === 'log') {
      this.yScale = d3.scaleLog().range([this.chartHeight, 0]).domain(this.yDomain)
    } else {
      // linear is default
      this.yScale = d3.scaleLinear().range([this.chartHeight, 0]).domain(this.yDomain)
    }
    this.axisLeft = d3.axisLeft(this.yScale).ticks(5, '~s')

    this.updateChart(this.graphData)
  }

  initSvg() {
    const svg = this.$refs.graphSVG as Element
    this.mousevline = this.$refs.mousevline as Element

    svg.addEventListener('mousemove', (e: any) => {
      const mousePoint = d3.pointer(e)
      const lastMouseX = mousePoint[0]
      this.adjustTooltip(lastMouseX)
    })
  }

  adjustTooltip(lastMouseX: number) {
    const closestItem = this.getClosestItem(lastMouseX)
    const closestPoint = this.getItemCoords(closestItem)
    this.tooltipTitle = closestItem.tooltip.title ?? ''
    this.tooltipItems = closestItem.tooltip.items
    this.nearestX = closestPoint.x
    this.nearestY = closestPoint.y
  }

  getItemCoords(input: ChartData): { x: number; y: number } {
    const x = this.xScale(input.date)
    const y = this.yScale(input.value)
    return { x, y }
  }

  getClosestItem(lastMouseX: number) {
    const bisectDate = d3.bisector((d: ChartData) => d.date).center
    const mouseDate = this.xScale.invert(lastMouseX)
    let index = bisectDate(this.graphData, mouseDate)
    return this.graphData[index]
  }

  mousevlineStyle() {
    return `translate(${this.nearestX},10)`
  }

  addCanvas(container: any): Viewport {
    const app = new PIXI.Application({
      width: this.chartWidth,
      height: this.chartHeight,
      antialias: true,
      backgroundAlpha: 0,
      resolution: 1,
      view: (this.$refs.graphContainer as Element).getElementsByTagName('canvas')[0]
      // forceCanvas: true
    })
    container.appendChild(app.view)

    this.viewport = new Viewport({
      screenWidth: this.chartWidth,
      screenHeight: this.chartHeight,
      events: app.renderer.events
    })
    app.stage.addChild(this.viewport)

    const ticker = PIXI.Ticker.shared
    ticker.autoStart = false
    ticker.stop()
    // console.log(PIXI.utils.isWebGLSupported())
    return this.viewport
  }

  setLine() {
    this.line = d3
      .line()
      .x((d: any) => {
        return this.xScale(d.date)
      })
      .y((d: any) => {
        return this.yScale(d.value)
      })
      .curve(d3.curveMonotoneX)
  }

  setArea() {
    this.area = d3
      .area()
      .x((d: any) => {
        return this.xScale(d.date)
      })
      .y1((d: any) => {
        return this.yScale(d.value)
      })
      .y0(this.chartHeight)
      .curve(d3.curveMonotoneX)
  }

  drawChart(container: any, data: ChartData[]) {
    this.addCanvas(container)
    // fill area
    this.setArea()
    this.addArea(data)
    // draw line on top
    this.setLine()
    this.addLine(data)
  }

  addArea(data: ChartData[]) {
    const areaShape = new PIXI.Graphics()
    this.areaGraphics = areaShape
    this.viewport.addChild(areaShape)
    areaShape.beginFill(this.fillColor)
    this.area.context(areaShape)(data)
    areaShape.endFill()
  }

  addLine(data: ChartData[]) {
    const line = new PIXI.Graphics()
    this.lineGraphics = line
    this.viewport.addChild(line)
    line.lineStyle(2, this.lineColor)
    this.line.context(line)(data)
  }

  updateArea(data: ChartData[]) {
    const areaShape = this.areaGraphics
    areaShape.clear()
    areaShape.beginFill(this.fillColor)
    this.area.context(areaShape)(data)
    areaShape.endFill()
  }

  updateLine(data: ChartData[]) {
    const line = this.lineGraphics
    line.clear()
    line.lineStyle(2, this.lineColor)
    this.line.context(line)(data)
  }

  updateChart(data: ChartData[]) {
    this.setArea()
    this.updateArea(data)

    this.setLine()
    this.updateLine(data)
  }

  getAvg() {
    const total = this.graphData.reduce((sum, d) => sum + d.value, 0)
    return parseFloat((total / this.graphData.length).toFixed(1)).toLocaleString()
  }
}
