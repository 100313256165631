import { BigNumber } from 'bignumber.js'

export function stringToNumber(value: string | number): number {
  return new BigNumber(value).toNumber()
}

export function avgValue(sum: string | number, count: number): string {
  return new BigNumber(sum).div(count).toFixed(3)
}

export function sumValuesBigNum(values: Array<number | string>, decimals: number, divide: boolean = true): string {
  if (decimals == null) {
    throw new Error(`Decimals must be defined.`)
  }
  return values.reduce((sum: string, value: string | number) => {
    const clonedBn = BigNumber.clone({ DECIMAL_PLACES: decimals })
    const valueBn = divide ? new clonedBn(value as string).div(10 ** decimals) : new clonedBn(value as string)
    return new clonedBn(sum).plus(valueBn).toFixed(decimals)
  }, '0') as string
}

export function formatNumber(value: number | string, decimals: number): string {
  const clonedBn = BigNumber.clone({ DECIMAL_PLACES: decimals })
  return new clonedBn(value as string).div(10 ** decimals).toFixed(decimals)
}

export function formatDecimal(decimals: number): (n: number | string) => string {
  return (n: number | string) => new BigNumber(n).decimalPlaces(decimals).toString()
}

export function addBigNum(...values: (string | number)[]): string {
  return BigNumber.sum(...values).toFixed()
}

export function sum(amounts: Array<number | string>, decimals: number = 8, parse: boolean = true): number | string {
  const result = BigNumber.sum(...amounts).toFixed(decimals)
  if (parse) {
    if (result.includes('.')) {
      return tryParseFloat(result)
    }
    return tryParseInt(result)
  }
  return result
}

export function subtractBigNum(values: Array<string | number>, decimals: number): string {
  const clonedBn = BigNumber.clone({ DECIMAL_PLACES: decimals })
  return new clonedBn(values[0] as string).minus(values[1]).toFixed(decimals)
}

export function divideBigNum(dividend: number | string, divisor: number | string, decimals?: number): string {
  const num = new BigNumber(dividend).dividedBy(divisor)
  return decimals != null ? num.toFixed(decimals) : num.toFixed()
}

export function trimZeros(value: string): string {
  while (value.endsWith('0')) {
    value = value.substring(0, value.length - 1)
  }
  if (value.endsWith('.')) {
    value = `${value}0`
  }
  return value
}

export function multDiv(mult1: number | string, mult2: number | string, div: number | string): string {
  return new BigNumber(mult1).times(new BigNumber(mult2)).div(new BigNumber(div)).toFixed(8)
}

export function medianBigNum(numbers: (number | string)[]): string {
  const bigNums = numbers.map((n) => new BigNumber(n))
  bigNums.sort((a, b) => a.minus(b).toNumber())
  const middle = Math.floor(bigNums.length / 2)

  if (bigNums.length % 2 === 0) {
    return bigNums[middle - 1].plus(bigNums[middle]).div(2).toFixed()
  }

  return bigNums[middle].toFixed()
}

export function floatToInt(float: number | string, decimals: number): string {
  return new BigNumber(float).times(new BigNumber(10).pow(decimals)).toString()
}

export function multiply(
  a: string | number,
  b: string | number,
  decimals: number = 8,
  parse: boolean = true
): number | string {
  const result = new BigNumber(a).multipliedBy(b).toFixed(decimals)
  if (parse) {
    if (result.includes('.')) {
      return tryParseFloat(result)
    }
    return tryParseInt(result)
  }
  return result
}

export function subtract(values: Array<string | number>, decimals: number): number | string {
  const result = new BigNumber(values[0] as string).minus(values[1]).toFixed(decimals)
  return tryParseFloat(result)
}

export function greaterThan(a: string | number, b: string | number): boolean {
  return new BigNumber(a).isGreaterThan(b)
}

export function bignumParse(value: string | number, decimals: number): number {
  const bn = new BigNumber(value).toFixed(decimals)
  return parseFloat(bn)
}

export function tryParseFloat(num: string): number | string {
  try {
    return parseFloat(num)
  } catch (e) {
    return num
  }
}

export function tryParseInt(num: string): number | string {
  try {
    return parseInt(num, 10)
  } catch (e) {
    return num
  }
}

export function greatestIndex(nums: Array<string | number>): number {
  const bigNums = nums.map((n) => new BigNumber(n))
  const max = BigNumber.max(...bigNums)
  return bigNums.findIndex((n) => n.toFixed() === max.toFixed())
}
