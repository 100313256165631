
import { Component, Vue, Watch } from 'vue-property-decorator'

import Metadata from './sidepanel-tabs/Metadata.vue'
import Summary from './sidepanel-tabs/Summary.vue'
import Notes from './sidepanel-tabs/Notes.vue'
// import Flows from './sidepanel-tabs/Flows.vue'
// import Intersection from './sidepanel-tabs/Intersection.vue'
import Transactions from './sidepanel-tabs/Transactions.vue'
// import OSINT from './sidepanel-tabs/OSINT.vue'
// import History from './sidepanel-tabs/History.vue'
// import Settings from './sidepanel-tabs/Settings.vue'
// import Coaddresses from './sidepanel-tabs/Coaddresses.vue'
import Report from './sidepanel-tabs/Report.vue'
import CopyBtn from '@/subcomponents/CopyBtn.vue'
import { mapState } from 'vuex'
import { FormattedLink, Target } from '@/store/investigations/viz'
import { FormattedSummaryLink } from '@/utils/graph-links'
import { Attribution, ClusterMetadata } from '@/utils/api'
import { State } from '@/store'
import { LRUCache } from '@splunkdlt/cache'
import { networkSymbol } from '@/utils/viz'
import { categoryColor } from '@/utils/colors'

interface SidepanelItem {
  tab: string
  comp: string
}

@Component({
  components: {
    Metadata,
    Summary,
    Notes,
    // Flows,
    // Intersection,
    Transactions,
    // OSINT,
    // History,
    // Settings,
    // Coaddresses,
    Report,
    CopyBtn
  },
  computed: {
    ...mapState(['addressCluster', 'attributionClusters', 'sidepanelTab', 'target', 'selectedLink', 'categoryScores']),
    ...mapState({
      attributionsCache: (state) => (state as State).shared.attributionsCache
    })
  }
})
export default class Sidepanel extends Vue {
  public tab: number = 0
  public items: SidepanelItem[] = [
    { tab: 'Summary', comp: 'Summary' },
    { tab: 'Txns', comp: 'Transactions' },
    // { tab: 'Flows', comp: 'Flows' },
    // { tab: 'Intersxn', comp: 'Intersection' },
    // { tab: 'OSINT', comp: 'OSINT' },
    { tab: 'Notes', comp: 'Notes' },
    // { tab: 'History', comp: 'History' },
    // { tab: 'Settings', comp: 'Settings' },
    // { tab: 'Addresses', comp: 'Coaddresses' },
    { tab: 'Report', comp: 'Report' }
  ]
  public addressCluster!: ClusterMetadata
  public attributionClusters!: ClusterMetadata[]
  public sidepanelTab!: string
  public target!: Target | undefined
  public selectedLink!: FormattedLink | FormattedSummaryLink | undefined
  public categoryScores!: { [category: string]: number }
  public attributionsCache!: LRUCache<string, Attribution[]>

  public editingName: boolean = false
  private nameEditValue: string = ''
  public clusterMetadataLoaded: boolean = false
  
  public networkSymbol = networkSymbol

  get targetType() {
    if (this.target) {
      return this.target.type !== 'transaction' ? this.target.type : ''
    }
    return ''
  }

  get showEntity() {
    return this.targetType !== ''
  }

  get name(): string {
    if (this.target == null) return ''
    return this.editingName ? this.nameEditValue :
      this.target.name ? this.target.name :
      this.attribution ? this.attribution :
      this.addressCluster ? this.addressCluster.id : ''
  }

  set name(value: string | InputEvent) { // for some reason this is getting double triggered with the data and the event
    if (typeof value === 'string') {
      this.nameEditValue = value
    }
  }

  get attribution(): string {
    if (this.targetType === 'attribution') { // target must exist
      return this.target!.id
    } else {
      if (this.targetType === 'address') {
        const attribution = this.addressAttribution
        if (attribution != null) {
          return attribution.attributions[0]
        }
      }
      if (this.clusterMetadataLoaded) {
        return this.addressCluster.topAttribution ?? ''
      }
    }
    return ''
  }

  get addressAttribution(): Attribution | null {
    if (this.target == null) return null
    const attribution = this.attributionsCache.get(this.target.id)
    if (attribution != null && attribution.length > 0) {
      return attribution[0]
    }
    return null
  }

  get attributionCategory(): string {
    if (this.targetType !== 'attribution') {
      if (this.targetType === 'address') {
        const attribution = this.addressAttribution
        if (attribution != null) {
          return attribution.categories[0]
        }
      }
      if (this.addressCluster != null) {
        const { topCategory } = this.addressCluster
        return topCategory ? topCategory : 'Unknown'
      }
    } else if (this.attributionClusters != null && this.attributionClusters.length) {
      const { topCategory } = this.attributionClusters[0]
      return topCategory ? topCategory : 'Unknown'
    }
    return 'Unknown'
  }

  get categoryColor() {
    return categoryColor(this.attributionCategory, this.categoryScores, true, true)
  }

  private handleStartEditing() {
    this.nameEditValue = this.name
    this.editingName = true
    const nameField = this.$refs['name']
    if (nameField != null) (<any>nameField).focus()
  }

  private updateName() {
    this.editingName = false
    if (this.nameEditValue !== this.name) {
      this.$store.dispatch('renameTargetNode', { name: this.nameEditValue })
    }
    this.nameEditValue = ''
  }

  private dispatchTabChange(tab: number) {
    this.$store.dispatch('setSidepanelTab', this.items[tab].tab)
  }

  @Watch('addressCluster')
  @Watch('attributionClusters')
  private clusterUpdated() {
    if (this.addressCluster != null || this.attributionClusters != null) {
      this.clusterMetadataLoaded = true
    }
  }

  @Watch('sidepanelTab')
  private tabChanged() {
    if (this.sidepanelTab != null && this.sidepanelTab !== '') {
      const tabIndex = this.items.findIndex(i => i.tab === this.sidepanelTab)
      if (tabIndex > -1 && this.tab !== tabIndex) {
        this.tab = tabIndex
      }
    }
  }

  @Watch('target')
  @Watch('selectedLink')
  changeTabsList() {
    if (this.showEntity) {
      this.clusterMetadataLoaded = false
      this.items = [
        { tab: 'Summary', comp: 'Summary' },
        { tab: 'Txns', comp: 'Transactions' },
        { tab: 'Notes', comp: 'Notes' },
        { tab: 'Report', comp: 'Report' }
      ]
    } else {
      this.items = [
        { tab: 'Summary', comp: 'Summary' },
        { tab: 'Notes', comp: 'Notes' },
        { tab: 'Report', comp: 'Report' }
      ]
    }
  }
  
  created() {
    this.changeTabsList()
  }

  public destroyTreemap() {
    try {
      if (this.$refs.tabs != null && Array.isArray(this.$refs.tabs)) {
        ;(this.$refs.tabs[0] as Vue & { destroyTreemap: () => void }).destroyTreemap()
      }
    } catch (e) {}
  }
}
