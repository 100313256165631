
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Splitpanes, Pane } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'
import Graph from './Graph.vue'
import Sidepanel from './Sidepanel.vue'
import { mapState } from 'vuex'
import { Investigation } from '@/store/investigations/investigations'
import { FormattedLink, Target } from '@/store/investigations/viz'

interface PaneSize {
  min: number
  max: number
  size: number
}

@Component({
  components: {
    Splitpanes,
    Pane,
    Graph,
    Sidepanel
  },
  computed: mapState(['currentInvestigation', 'target', 'selectedLink'])
})
export default class Dashboard extends Vue {
  private graphPaneSize: number = 100
  private dashWidth: number = 0

  public currentInvestigation!: Investigation
  public target!: Target | undefined
  public selectedLink!: FormattedLink | undefined

  get graphPaneWidth(): number {
    return (this.graphPaneSize / 100) * this.dashWidth
  }

  contextMenuListenerFunc(e: any) {
    e.preventDefault()
    e.stopPropagation()
  }

  addContextMenuListener(container: Element) {
    window.addEventListener('resize', this.setDashWidth)
    container.addEventListener('contextmenu', this.contextMenuListenerFunc)
  }

  removeContextMenuListener(container: Element) {
    window.removeEventListener('resize', this.setDashWidth)
    container.removeEventListener('contextmenu', this.contextMenuListenerFunc)
  }

  setDashWidth() {
    if (this.$refs.dash) {
      this.dashWidth = (this.$refs.dash as Element).clientWidth
    }
  }

  mounted() {
    this.addContextMenuListener(this.$refs.dash as Element)
    this.setDashWidth()
  }

  created() {
    this.$store.dispatch('setSection', 'investigations')
  }

  unmounted() {
    this.removeContextMenuListener(this.$refs.dash as Element)
  }

  onResize(sizes: PaneSize[]) {
    this.graphPaneSize = sizes[0].size
  }

  onDestroy() {
    try {
      ;(this.$refs.sidepanel as Vue & { destroyTreemap: () => void }).destroyTreemap()
    } catch (e) {}
  }

  @Watch('target')
  @Watch('selectedLink')
  toggleSidepanel() {
    if ((this.target != null || this.selectedLink != null) && this.graphPaneSize === 100) {
      this.graphPaneSize = 63
    } else if (this.target == null && this.selectedLink == null) {
      this.graphPaneSize = 100
    }
  }
}
