import { Flow, ReportTransaction, TransactionFlowTarget } from '@/store/investigations/viz'
import { saveAs } from 'file-saver'
import JSZip from 'jszip'
import { jsonToCSV, cutEnd, formatDate, timeToMilliseconds } from '@/utils/general'
import { PDFData, PDFImageConfigs, PDFPage, PDFTableConfigs, toPDF } from './pdf'
import { AggregatedBitcoinTransaction } from './api'

export interface TargetFlowData {
  hops: number | string
  amount: number | string
}

const headers = [
  'Transaction',
  'Entity',
  'Address',
  'Attribution (Cluster)',
  // 'Attribution Address',
  'Address Balance',
  'Cluster Balance',
  'Amount',
  'Exchange Rate',
  'Time',
  'Flow Hops',
  'Flow Amount'
]

export async function handleDownload(
  report: ReportTransaction[],
  flowsToDownload: TransactionFlowTarget[],
  downloadType: string,
  name: string,
  graphPdfConfigs: PDFImageConfigs,
  notesPdfConfigs?: PDFImageConfigs
) {
  const formatted = flowsToDownload.map((flow) => {
    return transformReportsForCSV(report, flow)
  })
  if (downloadType === 'CSV') {
    const zip = new JSZip()
    formatted.forEach((format, index) => {
      const reportName: string = `report-${name.replaceAll(/\W/g, '-')}`
      const csv = jsonToCSV(format)
      const blob = new Blob([csv], { type: 'text/csv' })
      zip.file(`${reportName}-${Date.now()}-${index}.csv`, blob)
    })
    await zip.generateAsync({ type: 'blob' }).then(function (content) {
      saveAs(content, 'reports.zip')
    })
  } else if (downloadType === 'PDF' && graphPdfConfigs) {
    const pages: PDFPage[] = []
    // insert graph image page
    pages.push({ type: 'image', pageLayout: 'p', body: graphPdfConfigs })
    // insert notes page
    if (notesPdfConfigs) pages.push({ type: 'image', pageLayout: 'p', body: notesPdfConfigs })
    // insert report table pages
    formatted.forEach((format: any) => {
      const config: PDFTableConfigs = { headers, body: format }
      pages.push({ type: 'table', pageLayout: 'l', body: config })
    })
    const pdfData: PDFData = { pages, name: name }
    await toPDF(pdfData)
  }
}

export function transformReportsForCSV(report: ReportTransaction[], targetFlowTransaction?: TransactionFlowTarget) {
  const mapped = report.map((rep) => {
    const flowData = getTargetFlowData(targetFlowTransaction, rep.flows)
    return {
      transaction: rep.transaction,
      entity: rep.type === 'clusterAttribution' ? 'cluster' : rep.type,
      address: rep.root,
      'attribution (cluster)': rep.type === 'clusterAttribution' ? rep.entity : rep.clusterAttribution,
      // 'address attribution': rep.addressAttribution,
      'address balance': rep.addressBalanceBTC, // TODO: deal with other symbols if relevant
      'cluster balance': rep.clusterBalanceBTC,
      amount: `${rep.isOutput ? '+' : '-'} ${rep.amount}`,
      time: formatDate(timeToMilliseconds(rep.time), true, true),
      'flow hops': flowData.hops,
      'flow amount': flowData.amount
    }
  })
  return mapped
}

export function getTargetFlowData(transactionFlowTarget?: TransactionFlowTarget, flows?: Flow[]): TargetFlowData {
  if (transactionFlowTarget) {
    const targetTransaction = transactionFlowTarget.targetTransaction
    const targetKey = `${targetTransaction.id}|${targetTransaction.isOutput}|${targetTransaction.index}|${transactionFlowTarget?.sending}`
    if (flows) {
      for (const flow of flows) {
        const flowKey = `${flow.targetTransaction.id}|${flow.targetTransaction.isOutput}|${flow.targetTransaction.index}|${flow.sending}`
        if (flowKey === targetKey) {
          return {
            hops: flow.minHops ? flow.minHops : 'no hops',
            amount: flow.value
          }
        }
      }
    }
  }
  return {
    hops: 'no flows',
    amount: 'no flows'
  }
}

export function formatFlowTarget(
  target: TransactionFlowTarget,
  transactionFlowTargetsMap: { [key: string]: AggregatedBitcoinTransaction }
) {
  const { targetTransaction, sending } = target
  const { id, isOutput, index } = targetTransaction
  const key: string = `${id}|${isOutput}|${index}`
  const { address, cluster, clusterAttribution } = transactionFlowTargetsMap[key]
  return `${clusterAttribution ?? cutEnd(cluster ?? address ?? '')}, ${isOutput ? 'o' : 'i'}-${cutEnd(id)}: ${
    sending ? 'sending' : 'receiving'
  }`
}
