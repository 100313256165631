
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'

interface DialogProps {
  title: string
  action: string
  actionClick?: () => void
}

interface LinkProps {
  label: string
  path: string
}

interface ButtonProps {
  icon: string
  hide?: boolean
  tooltip?: string
}

type ComponentProps = { [prop: string]: any }

type UpdateHandler = (v: any) => void

export type DialogConfig<ContentType> = {
  button: ButtonProps
  dialog: DialogProps
  content: ContentType
  link?: LinkProps
  props?: ComponentProps
  onValueUpdate?: UpdateHandler
  show?: boolean
}

@Component
export class FormComponent extends Vue {
  public doAction() {}
}

@Component({
  computed: {
    ...mapState(['showLoadingDialog'])
  }
})
export default class Dialog extends Vue {
  @Prop() name!: string
  @Prop() button!: ButtonProps
  @Prop() dialog!: DialogProps
  @Prop() link?: LinkProps
  @Prop() contentComponent!: any
  @Prop() componentProps?: ComponentProps
  @Prop() onValueUpdate?: UpdateHandler
  @Prop() manualShow?: boolean

  public show: boolean = false

  public showLoadingDialog!: boolean

  get getComponentProps() {
    return this.componentProps ?? {}
  }

  get loading() {
    return this.name === 'loading'
  }

  public click() {
    if (this.dialog.actionClick != null) {
      this.dialog.actionClick()
    }
    (this.$refs.childComponent as FormComponent).doAction()
  }

  public componentValueUpdated(v: any) {
    if (this.onValueUpdate != null) {
      this.onValueUpdate(v)
    }
  }

  @Watch('show')
  private showDialogChanged(value: boolean) {
    if (this.manualShow != null && value !== this.manualShow) {
      this.$emit('showChanged', this.show)
    }
  }

  @Watch('manualShow')
  private manualShowDialogChanged(value: boolean) {
    if (value) { // needs to exist and be true
      this.show = value
    }
  }

  @Watch('showLoadingDialog')
  private showLoadingDialogChanged(value: boolean) {
    if (this.loading) {
      this.show = value
    }
  }
}
