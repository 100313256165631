import { render, staticRenderFns } from "./CRO.vue?vue&type=template&id=588f2c65&scoped=true&"
var script = {}
import style0 from "./CRO.vue?vue&type=style&index=0&id=588f2c65&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "588f2c65",
  null
  
)

export default component.exports