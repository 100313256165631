
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class SignInForm extends Vue {
  public username: string = ''
  public password: string = ''
  public showPass: boolean = false
  public rules = {
    required: (value: string) => !!value || 'Required',
    min: (value: string) => value.length >= 8 || 'Min 8 characters'
  }

  public doAction() {
    this.$store.dispatch('signIn', { username: this.username, password: this.password })
  }
}
