import { Attribution } from '@/utils/api'
import { IPMetadata } from './ip'

export function isStrippedBlock(data: any): data is StrippedBlock {
  if (!data) {
    return false
  }
  return (data as StrippedBlock).height != null
}

export function isBlockSummary(data: any): data is BlockSummary {
  const strippedBlock = isStrippedBlock(data)
  return strippedBlock && (data as BlockSummary).totalFees != null
}

export function isExtendedTx(data: any): data is ExtendedTx {
  if (!data) {
    return false
  }
  return (data as ExtendedTx).txid != null
}

export function isExtendedTxns(data: any[]): data is ExtendedTx[] {
  try {
    return (data[0] as ExtendedTx).txid != null || data.length === 0
  } catch (e) {
    return false
  }
}

export function isExtendedCoinbase(
  input: Input | ExtendedInput | Coinbase | ExtendedCoinbase
): input is ExtendedCoinbase {
  return (input as ExtendedCoinbase).coinbase != null && (input as ExtendedCoinbase).value != null
}

interface ScriptSig {
  asm: string
  hex: string
}

export interface Input {
  scriptSig: ScriptSig
  sequence: number
  txinwitness: string[] | undefined
  txid: string
  vout: number
}

export interface SpentOutput {
  _id?: string // because this comes from mongodb and then we delete it
  time: number
  height: number
  blockhash: string
  address?: string | undefined
  attributions?: Attribution[]
  asm: string
  hex: string
  value: number
  type: string
}

export interface VerboseRawTx {
  hex: string
  blockhash: string
  confirmations: number
  time: number
  blocktime: number
}

export interface Coinbase {
  coinbase: string
  sequence: number
}

interface ScriptPubKey extends ScriptSig {
  address?: string
  attributions?: Attribution[]
  reqSigs?: number
  type: string
}

export interface Output {
  n: number
  scriptPubKey: ScriptPubKey
  value: number
}

export function isOutput(io: ExtendedInput | ExtendedCoinbase | Output): io is Output {
  return (<Output>io).scriptPubKey != null
}

interface TxBase {
  hash: string
  locktime: number
  size: number
  txid: string
  version: number
  vin: Array<ExtendedCoinbase | ExtendedInput>
  vout: Array<Output>
  vsize: number
  weight: number
}

export interface Tx extends TxBase {
  hex: string
}

interface BlockBase {
  bits: string
  chainwork: string
  confirmations: number
  difficulty: number
  hash: string
  height: number
  mediantime: number
  merkleroot: string
  nTx: number
  nextblockhash?: string
  nonce: number
  previousblockhash: string
  size: number
  strippedsize: number
  time: number
  version: number
  versionHex: string
  weight: number
}

export interface Block extends BlockBase {
  tx: Array<ExtendedTx>
}

interface Blockhash {
  blockhash: string
}

interface Txid {
  txid: string
}

interface Height {
  height: number
}

interface Time {
  time: number
}

export interface StrippedBlock extends BlockBase {
  tx: Array<string>
}

export interface BlockSummary extends StrippedBlock {
  totalValueSent: number | string
  totalFees: number | string
  reward: number
  miner: Array<string>
}

export interface ExtendedTx extends Tx, Blockhash, Height, Time {
  index: number
  fee?: number
  valueIn?: number
  valueOut?: number
}

export type TxHeader = {
  segwit: boolean
  rbf: boolean
} & Omit<TxBase, 'vin' | 'vout' | 'hash'> &
  Blockhash &
  Height &
  Time

export interface ExtendedInput extends Input, Blockhash, Height, Time {
  index: number
  spentOutput: SpentOutput
  previousOutput: string
}

export interface ExtendedCoinbase extends Coinbase, Txid, Blockhash, Height, Time {
  index: number
  value: number
}

export type ExtendedInputs = ExtendedInput | ExtendedCoinbase

export interface ExtendedOutput extends Output, Txid, Blockhash, Height, Time {}

export interface UtxoCache {
  time: number
  height: number
  blockhash: string
  txid: string
  n: number
  address: string | undefined
  reqSigs: number | undefined
  type: string
  hex: string
  asm: string
  value: number
}

// mempool

export interface MempoolTx {
  fees: {
    base: number
    modified: number
    ancestor: number
    descendant: number
  }
  vsize: number
  weight: number
  fee: number // deprecated, but still in the response
  modifiedfee: number // deprecated, but still in the response
  time: number
  height: number
  descendantcount: number
  descendantsize: number
  descendantfees: number // deprecated, but still in the response
  ancestorcount: number
  ancestorsize: number
  ancestorfees: number // deprecated, but still in the response
  wtxid: string
  depends: Array<string>
  spentby: Array<string>
  'bip125-replaceable': boolean
}

export interface ExtendedMempoolTx extends MempoolTx {
  hash: string
}

export interface Mempool {
  [key: string]: MempoolTx
}

// address

export interface MultisigComponents {
  pubkeys: Array<string>
  needed: number
}

export interface Seen {
  time: number
  blockhash: string
  txid: string
  input: boolean
  output: boolean
  index: number
}

export interface Address {
  pubkey: string | null
  pubkeyhash: string | null
  scripthash: string | null
  address: string
  aliases: string[]
  type: string
  components: MultisigComponents | null
  sentCount: number
  sentValue: number
  receivedCount: number
  receivedValue: number
  balance: number
  firstSeen: Seen
  lastSeen: Seen
}

export interface AddressTx {
  time: number
  blockhash: string
  txid: string
  address: string
  components: MultisigComponents | null
  value: number
  hex: string
  type: string | null
  input: boolean
  output: boolean
  index: number
}

// info

export interface Network {
  version: number
  subversion: string
  protocolversion: number
  localservices: string
  localservicenames: Array<string>
  localrelay: boolean
  timeoffset: number
  networkactive: boolean
  connections: number
  networks: Array<any>
  relayfee: number
  incrementalfee: number
  localaddresses: Array<any>
  warnings: string
}

export interface Peer {
  id: number
  addr: string
  addrlocal: string
  addrbind: string
  services: string
  servicesnames: Array<string>
  relaytxes: boolean
  lastsend: number
  lastrecv: number
  bytesrecv: number
  conntime: number
  timeoffset: number
  pingtime: number
  minping: number
  version: number
  subver: string
  inbound: boolean
  addnode: boolean
  startingheight: number
  banscore: number
  synced_headers: number
  synced_blocks: number
  inflight: Array<any>
  whitelisted: boolean
  permissions: Array<any>
  minfeefilter: number
  bytessent_per_msg: {
    [key: string]: number
  }
  bytesrecv_per_msg: {
    [key: string]: number
  }
}

export interface BlockchainInfo {
  chain: string
  blocks: number
  headers: number
  bestblockhash: string
  difficulty: number
  mediantime: number
  verificationprogress: number
  initialblockdownload: boolean
  chainwork: string
  size_on_disk: number
  pruned: boolean
  softforks: {
    [key: string]: {
      type: string
      active: boolean
      height?: number
      bip9?: {
        status: string
        start_time: number
        timeout: number
        since: number
        min_activation_height: number
      }
    }
  }
  warnings: string
}

export interface CounterParty {
  address: string
  value: number | string
}

export interface AggregatedTransaction {
  id: string
  isOutput: boolean
  index?: number
  minIndex?: number
  maxIndex?: number
  amount: number
  symbol: string
  time: number
  address?: string
  cluster?: string
  clusterAttribution?: string
  count?: number
}

export interface SimpleAddress {
  address: string
}

export interface SimpleAggregatedTransaction extends AggregatedTransaction {
  version: number
  locktime: number
  svb?: number
  fee?: number
  rbf?: boolean
  totalInputValue: number | string
  totalOutputValue: number | string
  counterparties: CounterParty[]
}

// electrum types

export interface Geo {
  country: string | null
  region: string | null
  city: string | null
  latitude: number | null
  longitude: number | null
}

export interface IP {
  ip: string
}

export interface Existing {
  network: string
  versions: Array<string>
  firstSeen: number
  lastSeen: number
}

export interface ElectrumUser extends IP, Existing, Geo {}

export interface ElectrumPeer extends ElectrumUser {
  hostname?: string
}

export interface ElectrumUserTxn extends IP {
  txid: string
  port: number
  timestamp: number
}

export interface NetworkItemConfig {
  enabled: boolean
  preload: boolean
  display: boolean
}

export interface NetworkItemsConfig {
  name: string
  nodes: NetworkItemConfig
  electrumServers: NetworkItemConfig
  electrumUsers: NetworkItemConfig
  electrumTxns: NetworkItemConfig
  electrumWallets: NetworkItemConfig
  electrumWatchlists: NetworkItemConfig
}

export interface GeoIP extends IP, Geo {
  timestamp: number
}

export interface ElectrumWalletBase {
  id: string
  hash: string
  network: string
  ips: Array<IPMetadata>
  firstSeen: number
  lastSeen: number
  watchlist: boolean
}

export interface ElectrumWalletFull extends ElectrumWalletBase {
  oldHashes: Array<string>
  addresses: Array<string>
  scripthashes: Array<string>
}

export interface ElectrumWallet extends Omit<ElectrumWalletFull, 'oldHashes' | 'scripthashes'> {}

export interface ElectrumWalletSummary extends ElectrumWalletBase {
  scripthashCount: number
  addressCount: number
}

export interface ElectrumWalletSingleIP extends Omit<ElectrumWalletSummary, 'ips'>, IPMetadata {}