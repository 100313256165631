
import { Component, Vue } from 'vue-property-decorator'
import P2PStats from '@/subcomponents/P2PStats.vue'

@Component({
  components: {
    P2PStats
  }
})
export default class Stats extends Vue {

}
