
import { Component, Prop, Vue } from 'vue-property-decorator'
import { copyToClipboard } from '@/utils/general'

@Component
export default class CopyBtn extends Vue {
  @Prop() value!: string

  public copy() {
    copyToClipboard(this.value)
    this.$store.dispatch('updateSnackbar', { show: true, text: `Copied ${this.value} to clipboard.` })
  }
}
