
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import CopyBtn from '@/subcomponents/CopyBtn.vue'
import {
  Header,
  HeaderLink,
  MenuButton,
  SingleValueStringTransform,
  TableClick,
  TableStringTransform,
  TableTransform
} from './types/genericTable'

@Component({
  components: {
    CopyBtn
  }
})
export default class ServerTableDefaultRow extends Vue {
  @Prop() item!: any
  @Prop() headers!: Header[]
  @Prop() itemClass!: string
  @Prop() clicked?: (item: any, index?: number) => Promise<void> | void
  @Prop() mouseover?: (item: any, index?: number) => void
  @Prop() mouseout?: () => void
  @Prop() renderValue!: ({
    item,
    value,
    transform
  }: {
    item: any
    value: number | string | string[]
    transform?: string | TableTransform
  }) => string | number
  @Prop() renderItemClass!: ({
    item,
    value,
    itemClass
  }: {
    item?: any
    value?: any
    itemClass?: string | TableStringTransform
  }) => string
  @Prop() applyClipping!: ({
    item,
    header,
    transform
  }: {
    item: any
    header: Header
    transform: string | SingleValueStringTransform
  }) => string | number
  @Prop() renderSubtext!: ({ item, header }: { item: any; header: Header }) => string | number
  @Prop() formatDate!: (timestamp: number | string, cryptotime?: boolean) => any
  @Prop() expandClick!: (item: any) => void
  @Prop() parentItem!: any
  @Prop() actionsDisabled!: boolean

  public selected: boolean[] = []
  public checkboxKey = 0
  public editingField = false
  public editField = ''


  public timeValue(header: Header): boolean {
    const { value, isTimestamp } = header
    if (isTimestamp !== undefined) {
      return isTimestamp
    }
    if (!Array.isArray(value) && (value === 'time' || value === 'timestamp' || value === 'cryptotime')) {
      return true
    }
    return false
  }

  public formatStyle(header: Header): string {
    const { align, width } = header
    return `${align ? `text-align: ${align};`: ''}${width ? `min-width: ${width}; max-width: ${width}` : ''}`
  }

  public buttonClick(header: Header, item: any) {
    if (header.value === 'expand') {
      this.expandClick(item)
    } else {
      const button = header.button
      if (button != null) {
        button.click(item, this.parentItem)
      }
    }
  }

  public async handleClick(item: any, index?: number) {
    if (this.clicked != null) {
      await this.clicked(item, index)
      this.updateSelected()
    }
  }

  public handleMouseover(item: any, index?: number) {
    if (this.mouseover != null) {
      this.mouseover(item, index)
    }
  }

  public handleMouseout() {
    if (this.mouseout != null) {
      this.mouseout()
    }
  }

  public async selectClick(header: Header, item: any, parent: any) {
    if (header.select != null) {
      if (header.select.click != null) {
        await header.select.click(item, parent)
        this.updateSelected()
      }
    }
  }

  public startEditing(item: any, field: string) {
    this.editField = JSON.stringify(item[field])
    this.editingField = true
  }

  public blurEditField() {
    const el = document.getElementById('editField')
    if (el != null) {
      el.blur()
    }
  }

  public async endEditing(item: any, action: TableClick) {
    await action(item, JSON.parse(this.editField))
    this.editingField = false
  }

  public checkMenuButtonCondition(button: MenuButton, item: any, parent?: any, grandparent?: any) {
    const { condition } = button
    if (condition == null) return true
    return condition(item, parent, grandparent)
  }

  public menuButtonClick(button: MenuButton, item: any, parent?: any, grandparent?: any) {
    return button.click(item, parent, grandparent)
  }

  public menuButtonIcon(button: MenuButton, item: any, parent?: any, grandparent?: any) {
    const { icon } = button
    if (typeof icon === 'string') {
      return icon
    } else if (icon) {
      return icon(item, parent, grandparent)
    }
  }

  public menuButtonTooltip(button: MenuButton, item: any, parent?: any, grandparent?: any) {
    const { tooltip } = button
    if (typeof tooltip === 'string') {
      return tooltip
    } else if (tooltip) {
      return tooltip(item, parent, grandparent)
    }
  }

  public expandIcon(isExpanded: boolean) {
    return isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down'
  }

  public getLinkTarget(link: HeaderLink) {
    return link.target ?? '_self'
  }

  public menuCondition(header: Header, item: any) {
    const { menu } = header
    if (menu) {
      const { condition } = menu
      if (condition !== undefined) {
        return condition(item)
      }
      return true
    }
    return false
  }

  created() {
    this.updateSelected()
  }

  @Watch('item') // sorting causes the item to change
  updateSelected() {
    const priorSelected = Array.from(this.selected)
    this.selected = this.headers.map((h) => (h.select ? h.select.selected(this.item, this.parentItem) : false))
    if (priorSelected.length === this.selected.length) {
      for (let i = 0; i < this.selected.length; i++) {
        if (priorSelected[i] !== this.selected[i]) {
          this.checkboxKey++
          return
        }
      }
    }
  }
}
