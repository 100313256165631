import { Commit, Dispatch } from 'vuex'
import { Api, Category } from '@/utils/api'
import { filter } from '@/utils/filters'
import { State } from './index'
import { classifyNodeId } from '@/utils/viz'
import { track } from '@/utils/tracking'
import { Node } from './investigations/viz'
import { LRUCache } from '@splunkdlt/cache'
import { sleep } from '@/utils/general'

export const attributionState = {
  categoryScores: <{ [category: string]: number }>{},
  categories: <Category[]>[],
  externalAttributionsCache: new LRUCache<string, string>({ maxSize: 2000 })
}

export const attributionMutations = {
  SET_ATTRIBUTION_CATEGORIES(state: State, categories: Category[]) {
    state.categories = categories
  },
  SET_ATTRIBUTION_CATEGORY_SCORES(state: State, categoryScores: { [category: string]: number }) {
    state.categoryScores = categoryScores
  },
  ADD_EXTERNAL_ATTRIBUTION(state: State, { address, attribution }: { address: string; attribution: string }) {
    state.externalAttributionsCache.set(address, attribution)
  }
}

export const attributionActions = (state: State, api: Api, dispatch: Dispatch) => ({
  async getCategories({ commit }: { commit: Commit }) {
    const categories = await api.getCategories()
    const categoryScores: { [category: string]: number } = {}
    for (const category of categories) {
      categoryScores[category.name] = category.score
    }
    categoryScores['Crypto Bridge'] = 0 // patch until hbase is up to data with mongo
    commit('SET_ATTRIBUTION_CATEGORIES', categories)
    commit('SET_ATTRIBUTION_CATEGORY_SCORES', categoryScores)
  },
  async getInvestigationAttributions({ commit }: { commit: Commit }, { nodes }: { nodes: Node[] }) {
    const addresses = filter(
      nodes.map((n) => {
        const { type, id } = classifyNodeId(n.id)
        if (type === 'address') return id
        return null
      }),
      (a) => a != null
    ) as string[] // have to caste, typescript doesn't detect null filter

    track('getInvestigationAttributions', { nodes })
    // need to use get instead of has to ensure that item doesn't get evicted when more are added
    const unattributed = filter(addresses, (a) => state.shared.attributionsCache.get(a) == null)
    if (unattributed.length > 0) {
      const attributions = await api.getAttributions(unattributed as string[])
      if (attributions) {
        commit('ATTRIBUTIONS_CACHE_ADD', { attributions })
      }
    }
  },
  async getInvestigationClusters(
    { commit }: { commit: Commit },
    { nodes, network }: { nodes: Node[]; network: string }
  ) {
    const addresses: string[] = []
    const attributions: string[] = []
    for (const node of nodes) {
      const { id, type } = classifyNodeId(node.id)
      if (type === 'attribution') attributions.push(id)
      else if (type !== 'transaction') addresses.push(id)
    }

    track('getInvestigationClusters', { nodes, network })
    const unclustered = filter(addresses, (a) => state.shared.clusterAddressCache.get(a) == null)
    if (unclustered.length > 0) {
      const clusters = await api.getClustersForAddresses({ network, ids: unclustered })
      if (clusters) {
        for (const address of Object.keys(clusters)) {
          const cluster = clusters[address]
          // TODO: include network here to distinguish same cluster on different networks
          commit('CLUSTER_CACHE_ADD', { address, cluster })
        }
      }
    }

    await dispatch('getAttributionsClusters', { network, attributions })
  },
  async getAttributionsClusters(
    { commit }: { commit: Commit },
    { network, attributions }: { network: string; attributions: string[]; }
  ) {
    const unknownAttributions = filter(attributions, (a) => {
      const networkClusters = state.shared.attributionClustersCache.get(a)
      return networkClusters == null || networkClusters[network] == null
    })
    await Promise.all(
      unknownAttributions.map((id) => {
        dispatch('getAddressesInAttributionCount', { network, id, stream: true })
        return api.attributionClusters({ network, id, page: 1, perPage: 1 })
      })
    ).then((clustersList) => {
      for (let i = 0; i < unknownAttributions.length; i++) {
        const attribution = unknownAttributions[i]
        const clusters = clustersList[i]
        if (clusters != null) {
          commit('ATTRIBUTION_CLUSTER_CACHE_ADD', { attribution, network, cluster: clusters[0] })
        }
      }
    })
  },
  async getAttributionClusters(
    { commit }: { commit: Commit },
    { network, id, page, perPage }: { network: string; id: string; page: number; perPage: number }
  ) {
    const response = await api.attributionClusters({ network, id, page, perPage })
    if (response != undefined) {
      commit('SET_ATTRIBUTION_CLUSTERS', { clusters: response })
    }
  },
  async getAttributionClustersCount(
    { commit }: { commit: Commit },
    { network, id, stream }: { network: string; id: string; stream: boolean }
  ) {
    if (stream) {
      const streamTo = state.serverEventsId
      api.attributionClustersCount({ network, id, streamTo })
    } else {
      await api.attributionClustersCount({ network, id })
    }
  },
  async getAddressesInAttributionCount(
    { commit }: { commit: Commit },
    { network, id, stream = true }: { network: string; id: string; stream?: boolean }
  ) {
    if (stream) {
      const streamTo = state.serverEventsId
      api.attributedClusterAddressesCount({ network, id, streamTo })
      await sleep(10000) // if don't have a size after 10 secs, consider it "not loaded"
      if (!state.shared.attributionSizeCache.has(id)) {
        commit('ATTRIBUTION_SIZE_NOT_LOADED', { attribution: id, network })
        dispatch('updateSnackbar', {
          show: state.enableErrors,
          text: `Failed to load size of ${id} after 10 seconds. Will keep attempting.`
        })
      }
    } else {
      await api.attributedClusterAddressesCount({ network, id })
    }
  },
  async getTargetClusters(
    { commit }: { commit: Commit },
    { network, addresses }: { network: string; addresses: Iterable<string> }
  ) {
    let ids = []
    for (const address of addresses) {
      if (state.shared.clusterAddressCache.get(address) == null) {
        ids.push(address)
      }
    }
    if (ids.length > 0) {
      const clusters = await api.getClustersForAddresses({ network, ids })
      if (clusters != null) {
        track('getTargetClusters', { network })
        for (const [address, cluster] of Object.entries(clusters)) {
          commit('CLUSTER_CACHE_ADD', { address, cluster })
        }
      }
    }
  },
  async getTargetAttributions(
    { commit }: { commit: Commit },
    { network, addresses }: { network: string; addresses: Iterable<string> }
  ) {
    let ids = []
    for (const address of addresses) {
      if (state.shared.attributionsCache.get(address) == null) {
        ids.push(address)
      }
    }
    if (ids.length > 0) {
      const attributions = await api.getAttributions(ids)
      if (attributions != null) {
        track('getTargetAttributions', { network })
        commit('ATTRIBUTIONS_CACHE_ADD', { attributions })
      }
    }
  }
})
