import { Api } from '@/utils/api'
import { Commit } from 'vuex'
import { State } from './index'
import { analytics, identify, track } from '@/utils/tracking'

export interface UserSignupRequest {
  username: string
  password: string
  email: string
  code: string
  active: boolean
}

export interface UserInfo {
  username: string
  email: string
  account: string
  isAdmin?: boolean
}

export const authState = {
  username: '',
  internalUser: false,
  signedIn: false,
  isAdmin: false,
  registered: <null | boolean>null,
  saReported: false,
  resetRequested: <null | boolean>null,
  resetSuccess: <null | boolean>null
}

export const authMutations = {
  // auth
  SET_USERNAME(state: State, { username }: { username: string }) {
    state.username = username
  },
  SET_INTERNAL_USER(state: State, { internal }: { internal: boolean }) {
    state.internalUser = internal
  },
  SET_ADMIN(state: State, { isAdmin }: { isAdmin: boolean }) {
    state.isAdmin = isAdmin
  },
  SET_SIGNED_IN(state: State, { signedIn }: { signedIn: boolean }) {
    state.signedIn = signedIn
  },
  SET_REGISTERED(state: State, { success }: { success: boolean }) {
    state.registered = success
  },
  SET_SA_REPORTED(state: State, { reported }: { reported: boolean }) {
    state.saReported = reported
  },
  SET_PW_RESET_REQUEST(state: State, { success }: { success: boolean }) {
    state.resetRequested = success
  },
  SET_PW_RESET(state: State, { reset }: { reset: boolean }) {
    state.resetSuccess = reset
  }
}

export const authActions = (state: State, api: Api) => ({
  // sign in / sign out
  async signIn({ commit }: { commit: Commit }, { username, password }: { username: string; password: string }) {
    const response = await api.signIn(username, password)
    if (response.success) {
      await identify(username)
      track('signIn')
      commit('SET_USERNAME', { username })
      commit('SET_SIGNED_IN', { signedIn: true })
      api.userSeen(username)
      const userResponse = await api.getUser(username)
      if (userResponse.success && userResponse.data != null) {
        commit('SET_INTERNAL_USER', { internal: userResponse.data.email.endsWith('heightslabs.com') })
        if (userResponse.data.isAdmin) {
          commit('SET_ADMIN', { isAdmin: true })
        }
      }
    }
  },
  async checkAuth({ commit }: { commit: Commit }) {
    const response = await api.checkAuth()
    if (response.success) {
      commit('SET_USERNAME', { username: response.username })
      commit('SET_SIGNED_IN', { signedIn: true })
      await identify(state.username)
      track('resumeSession')
      // set isAdmin
      const userResponse = await api.getUser(state.username)
      if (userResponse.success && userResponse.data != null) {
        commit('SET_INTERNAL_USER', { internal: userResponse.data.email.endsWith('heightslabs.com') })
        if (userResponse.data.isAdmin) {
          commit('SET_ADMIN', { isAdmin: true })
        }
      }
    }
  },
  signOut({ commit }: { commit: Commit }) {
    commit('SET_USERNAME', { username: '' })
    commit('SET_SIGNED_IN', { signedIn: false })
    api.signOut()
    track('signOut')
  },
  async signUp({ commit }: { commit: Commit }, { username, password, email, code, active = true }: UserSignupRequest) {
    const response = await api.signUp(username, password, email, code, active)
    const { success } = response
    if (success) {
      await identify(state.username, { email })
      track('signUp', { email })
      commit('SET_REGISTERED', { success })
    }
  },
  // unauthenticated account related routes
  async securityAlert({ commit }: { commit: Commit }, username: string) {
    const response = await api.securityAlert(username)
    const { success } = response
    if (success) {
      await identify(state.username)
      track('securityAlert')
      commit('SET_SA_REPORTED', { reported: success })
    }
  },
  async requestPwReset({ commit }: { commit: Commit }, { username, email }: { username: string; email: string }) {
    const response = await api.requestPwReset({ username, email })
    const { success } = response
    await identify(state.username, { email })
    track('requestPwReset', { email })
    commit('SET_PW_RESET_REQUEST', { success })
  },
  async resetPassword({ commit }: { commit: Commit }, { password, code }: { password: string; code: string }) {
    const response = await api.resetPassword(password, code)
    const { success } = response
    commit('SET_PW_RESET', { reset: success })
  }
})
