
import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import ServerTable from '@/subcomponents/ServerTable.vue'
import { Filter, Header, ServerFilter } from './types/genericTable'
import { ElectrumWalletSummaries } from '@/store/p2p'
import { ElectrumWallet, ElectrumWalletSummary } from '@/types/bitcoin'
import { IPMetadata } from '@/types/ip'

function filterIP(wallet: ElectrumWalletSummary) {
  return wallet.ips.map(ip => ip == null ? null : ip.ip).filter(ip => ip != null)
}

function filterContinent(wallet: ElectrumWalletSummary) {
  return wallet.ips.map(ip => ip == null ? null : ip.continent_name).filter(c => c != null && c !== '')
}

function filterCountry(wallet: ElectrumWalletSummary) {
  return wallet.ips.map(ip => ip == null ? null : ip.country_name).filter(c => c != null && c !== '')
}

function filterRegion(wallet: ElectrumWalletSummary) {
  return wallet.ips.map(ip => ip == null ? null : ip.region).filter(r => r != null && r !== '')
}

function filterCity(wallet: ElectrumWalletSummary) {
  return wallet.ips.map(ip => ip == null ? null : ip.city).filter(c => c != null && c !== '')
}

function filterASN(wallet: ElectrumWalletSummary) {
  return wallet.ips.map(ip => ip == null ? null : (ip.asn != null) ? ip.asn.name ?? ip.asn.asn ?? null : null).filter(a => a != null && a !== '')
}

function filterThreat(wallet: ElectrumWalletSummary) {
  return wallet.ips.map(ip => ip == null ? null : ip.threat.is_threat).filter(t => t != null)
}

function filterCarrier(wallet: ElectrumWalletSummary) {
  return wallet.ips.map(ip => ip == null ? null : (ip.carrier != null) ? ip.carrier.name ?? null : null).filter(c => c != null && c !== '')
}

function hasThreat(ips: IPMetadata[]) {
  for (const ip of ips) {
    if (ip != null && ip.threat.is_threat) {
      return true
    }
  }
  return false
}

function filterTor(wallet: ElectrumWalletSummary) {
  return wallet.ips.map(ip => ip == null ? null : ip.threat.is_tor).filter(t => t != null)
}

function hasTor(ips: IPMetadata[]) {
  for (const ip of ips) {
    if (ip.threat.is_tor) {
      return true
    }
  }
  return false
}

function continentTransform(ips: IPMetadata[]) {
  return Array.from(new Set(ips
    .map(ip => ip.continent_code)
    .filter(c => c != null && c != ''))
    )
    .join(', ')
}

function countryTransform(ips: IPMetadata[]) {
  return Array.from(new Set(ips
    .map(ip => ip.country_name)
    .filter(c => c != null && c != '')))
    .join(', ')
}


@Component({
  components: {
    ServerTable
  },
  computed: mapState(['electrumWalletSummaries', 'walletsLastUpdated'])
})
export default class P2PTable extends Vue {
  public show: boolean = false
  public loading: boolean = false
  public selectedNetwork: string = 'bitcoin'

  private electrumWalletSummaries!: ElectrumWalletSummaries
  private walletsLastUpdated!: number

  public electrumWallets: Array<ElectrumWalletSummary> = []

  public headers: Header[] = [
    { text: 'ID', value: 'id', width: '150px', sortable: false, link: {
        label: (id: string) => id,
        url: (id: string) => `explorer/wallet/${this.selectedNetwork}/${id}`,
        target: '_blank',
        field: 'id'
      } },
    { text: 'Total Addresses', value: 'scripthashCount', width: '140px', tooltip: 'Addresses in the wallet, including unused addresses.', sortable: true },
    { text: 'Known Addresses', value: 'addressCount', width: '145px', tooltip: 'Addresses seen on the blockchain', sortable: true },
    { text: 'Continent', value: 'ips', transform: continentTransform, width: '110px' },
    { text: 'Country', value: 'ips', transform: countryTransform },
    { text: 'IPs', value: 'ips', transform: (ips: IPMetadata[]) => ips.length, width: '100px' },
    { text: 'Watchlist', value: 'watchlist', width: '110px', tooltip: 'Collection of addresses is a watchlist instead of wallet.' },
    { text: 'Threat', value: 'ips', transform: hasThreat, width: '100px' },
    { text: 'Tor', value: 'ips', transform: hasTor, width: '100px' },
    { text: 'First Seen', value: 'firstSeen', isTimestamp: true, width: '200px' },
    { text: 'Last Seen', value: 'lastSeen', isTimestamp: true, width: '200px' }
  ]

  public filters: Filter[] = [
    { text: 'IP', value: 'ips', nestedProp: 'ip', transform: filterIP },
    { text: 'Continent', value: 'ips', nestedProp: 'continent_name', transform: filterContinent },
    { text: 'Country', value: 'ips', nestedProp: 'country_name', transform: filterCountry },
    { text: 'Region', value: 'ips', nestedProp:'region', transform: filterRegion },
    { text: 'City', value: 'ips', nestedProp: 'city', transform: filterCity },
    { text: 'ISP/ASN', value: 'ips', nestedProp: 'asn.name', transform: filterASN },
    { text: 'Mobile Carrier', value: 'ips', nestedProp: 'carrier.name', transform: filterCarrier },
    { text: 'Threat', value: 'ips', nestedProp: 'threat.is_threat', transform: filterThreat },
    { text: 'Tor', value: 'ips', nestedProp: 'threat.is_tor', transform: filterTor }
  ]

  public serverFilters: ServerFilter[] = [
    { 
      text: 'Address',
      value: 'id',
      dispatch: 'getElectrumWalletByAddress',
      dispatchTransform: (address: string) => ({ network: this.selectedNetwork, address }),
      responseTransform: (r: ElectrumWalletSummary[] | ElectrumWallet) => {
        return Array.isArray(r) ? r.map(w => w.id) : [r.id]
      }
    }
  ]

  @Watch('walletsLastUpdated')
  private walletsUpdated() {
    if (this.electrumWalletSummaries[this.selectedNetwork] == null) {
      this.electrumWallets = []
      this.loading = true
    } else {
      this.electrumWallets = this.electrumWalletSummaries[this.selectedNetwork].values()
      this.loading = false
    }
  }

  public setFilteredData(wallets: ElectrumWalletSummary[]) {
    this.$store.dispatch('setElectrumWalletsFiltered', { network: 'bitcoin', wallets})
  }

  public goToWallet(wallet: ElectrumWalletSummary) {
    const network = this.selectedNetwork
    const { id } = wallet
    this.$router.push(`explorer/wallet/${network}/${id}`)
  }
}
