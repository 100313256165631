
import { Investigation, InvestigationState } from '@/store/investigations/investigations'
import FileUpload from '@/subcomponents/FileUpload.vue'
import { CustomFile, getCsvOrExcelData } from '@/utils/general'
import { filter } from '@/utils/filters'
import { DSVRowArray } from 'd3'
import { Component, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'
import { classifyNodeId } from '@/utils/viz'

export interface DropdownItem {
  text: string
  icon: string
}

@Component({
  components: {
    FileUpload
  },
  computed: mapState(['investigations'])
})
export default class CaseManagement extends Vue {
  public investigations!: Investigation[]
  public show: boolean = false
  public showShare: boolean = false
  private toShare?: Investigation
  public newName: string = ''
  public showErrorMessage: boolean = false
  private editingNameIndex: number = -1
  private editingName: string = ''
  public shareUsername: string = ''
  public addItems: DropdownItem[] = [
    { text: 'Create New', icon: 'mdi-pencil' },
    { text: 'Upload File', icon: 'mdi-file-upload' }
  ]
  public selectedItem: number = -1
  public fileData?: DSVRowArray<string>[]

  get sortedInvestigations() {
    return this.investigations.sort((a, b) => new Date(b.userUpdated).getTime() - new Date(a.userUpdated).getTime())
  }

  async handleFilesUploaded(files: CustomFile[]) {
    const fileData = await getCsvOrExcelData(files)
    if (fileData != null) {
      const { data, name } = fileData
      this.fileData = data
      this.newName = name
    }
  }

  addInvestigation(name: string) {
    this.$store.dispatch('addInvestigation', { name })
  }

  addInvestigationFromFile() {
    if (this.fileData != null) {
      this.$store.dispatch('addInvestigationFromFile', { name: this.newName, data: this.fileData })
    }
  }

  startEditingName(index: number) {
    this.editingNameIndex = index
    this.editingName = this.investigations[index].name
  }

  rename(index: number) {
    this.$store.dispatch('renameInvestigation', { index, name: this.editingName })
    this.editingNameIndex = -1
    this.editingName = ''
  }

  enterInvestigation(index: number) {
    this.$store.dispatch('enterInvestigation', { index })
  }

  deleteInvestigation(index: number) {
    this.$store.dispatch('deleteInvestigation', { index })
  }

  setUpShare(investigation: Investigation) {
    this.showShare = true
    this.toShare = investigation
  }

  async shareInvestigation(username: string) {
    const successfullyShared = await this.$store.dispatch('shareInvestigation', { investigation: this.toShare, username })
    if (successfullyShared) {
      this.shareUsername = ''
      this.showErrorMessage = false
      this.showShare = false
    } else {
      this.showErrorMessage = true
    }
  }

  formatDateString(date: string) {
    if (date != null) return this.$store.state.formatDate(new Date(date).getTime(), true, 1)
    return 'no date (error)'
  }

  getImage(state?: InvestigationState) {
    if (state != null) {
      return URL.createObjectURL(state.snapshot)
    }
    return '' //TODO: find placeholder
  }

  nodesShownText(state?: InvestigationState) {
    let count = 0
    if (state != null) {
      count = filter(state.nodes, n => !(classifyNodeId(n.id).type === 'transaction' || n.deleted)).length
    }
    return `${count} ${count === 1 ? 'entity' : 'entities'}`
  }

  created() {
    this.$store.dispatch('getInvestigations', { username: this.$store.state.username })
  }
}
