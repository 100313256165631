import { render, staticRenderFns } from "./Sidepanel.vue?vue&type=template&id=e066d000&scoped=true&"
import script from "./Sidepanel.vue?vue&type=script&lang=ts&"
export * from "./Sidepanel.vue?vue&type=script&lang=ts&"
import style0 from "./Sidepanel.vue?vue&type=style&index=0&id=e066d000&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e066d000",
  null
  
)

export default component.exports