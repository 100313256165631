
import { Component, Vue } from 'vue-property-decorator'

import {
  TiptapVuetify,
  History,
  Heading,
  Bold,
  Italic,
  Underline,
  BulletList,
  OrderedList,
  ListItem,
  // Link,
  // Blockquote,
  HardBreak,
  // HorizontalRule
} from 'tiptap-vuetify'

@Component({
  components: {
    TiptapVuetify
  }
})
export default class Notes extends Vue {
  private debouncedContent: string = ''
  private timeout?: number

  get content() {
    return this.debouncedContent
  }

  set content(value: string) {
    if (this.timeout) clearTimeout(this.timeout)
    this.timeout = window.setTimeout(() => {
      this.debouncedContent = value
      this.save()
    }, 750)
  }

  public extensions = [
    History,
    Bold,
    Underline,
    Italic,
    [
      Heading,
      {
        options: {
          levels: [1, 2, 3]
        }
      }
    ],
    ListItem,
    BulletList,
    OrderedList,
    // Blockquote,
    // Link,
    // HorizontalRule,
    HardBreak
  ]

  save() {
    this.$store.dispatch('saveNotes', { notes: this.content })
  }

  created() {
    this.content = JSON.parse(JSON.stringify(this.$store.state.notes))
  }
}
