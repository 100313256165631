
import { Component, Vue } from 'vue-property-decorator'
import P2PMap, { NetworksConfig } from '@/subcomponents/P2PMap.vue'
import P2PTable from '@/subcomponents/P2PTable.vue'
import { Expression } from 'mapbox-gl'
import { MAP_ELECTRUM_SERVER_COLOR_BTC, MAP_ELECTRUM_SERVER_COLOR_LTC, MAP_ELECTRUM_TXN_COLOR_BTC, MAP_ELECTRUM_TXN_COLOR_LTC, MAP_ELECTRUM_USER_COLOR_LTC, MAP_ELECTRUM_WALLET_COLOR_BTC, MAP_ELECTRUM_WALLET_COLOR_LTC, MAP_ELECTRUM_WATCHLIST_COLOR_BTC, MAP_ELECTRUM_WATCHLIST_COLOR_LTC, MAP_NODE_COLOR_BTC, MAP_NODE_COLOR_LTC } from '@/utils/colors'

// icon size expressions
const nodeSize: Expression = ['interpolate', ['linear'], ['zoom'], 1.4, 0.1, 20, 1]
const electrumServerSize: Expression = ['interpolate', ['linear'], ['zoom'], 1.4, 0.15, 20, 1]
const electrumUserSize: Expression = ['interpolate', ['linear'], ['zoom'], 1.4, 0.05, 20, 1]
const electrumUserTxnsSize: Expression = ['interpolate', ['linear'], ['zoom'], 1.4, 0.2, 20, 1]
const electrumWalletSize: Expression = ['interpolate', ['linear'], ['zoom'], 1.4, 0.1, 20, 1]
const electrumWatchlistSize: Expression = ['interpolate', ['linear'], ['zoom'], 1.4, 0.05, 20, 1]

const bitcoinIcon = 'bitcoin-icon'
const bitcoinIconElectrum = 'bitcoin-electrum'
const litecoinIcon = 'litecoin-icon'
const litecoinIconElectrum = 'litecoin-electrum'

@Component({
  components: {
    P2PMap,
    P2PTable
  }
})
export default class Network extends Vue {
  public networksConfig: NetworksConfig = {
    display: {
      bitcoin: {
        nodes: { enabled: true, preload: false, display: false },
        electrumServers: { enabled: true, preload: false, display: false },
        electrumUsers: { enabled: true, preload: false, display: false },
        electrumTxns: { enabled: true, preload: true, display: true },
        electrumWallets: { enabled: true, preload: true, display: true },
        electrumWatchlists: { enabled: true, preload: false, display: false }
      },
      litecoin: {
        nodes: { enabled: false, preload: false, display: false },
        electrumServers: { enabled: true, preload: false, display: false },
        electrumUsers: { enabled: true, preload: false, display: false },
        electrumTxns: { enabled: true, preload: false, display: false },
        electrumWallets: { enabled: false, preload: false, display: false },
        electrumWatchlists: { enabled: false, preload: false, display: false }
      }
    },
    icons: {
      bitcoin: {
        nodes: { path: '/icons/bitcoin.png', name: 'bitcoin-icon' },
        electrum: { path: '/icons/electrum.png', name: 'bitcoin-electrum' }
      },
      litecoin: {
        nodes: { path: '/icons/litecoin.png', name: 'litecoin-icon' },
        electrum: { path: '/icons/electrum-litecoin.png', name: 'litecoin-electrum' }
      }
    },
    layers: {
      bitcoin: {
        nodes: {
          icons: {
            name: bitcoinIcon,
            color: MAP_NODE_COLOR_BTC,
            size: nodeSize
          }
        },
        electrumServers: {
          icons: {
            name: bitcoinIconElectrum,
            color: MAP_ELECTRUM_SERVER_COLOR_BTC,
            size: electrumServerSize,
            halo: true
          }
        },
        electrumUsers: {
          icons: {
            name: bitcoinIconElectrum,
            color: MAP_ELECTRUM_SERVER_COLOR_BTC,
            size: electrumUserSize
          }
        },
        electrumUserTxns: {
          icons: {
            name: bitcoinIconElectrum,
            color: MAP_ELECTRUM_TXN_COLOR_BTC,
            size: electrumUserTxnsSize
          }
        },
        electrumWallets: {
          icons: {
            name: bitcoinIconElectrum,
            color: MAP_ELECTRUM_WALLET_COLOR_BTC,
            size: electrumWalletSize
          }
        },
        electrumWatchlists: {
          icons: {
            name: bitcoinIconElectrum,
            color: MAP_ELECTRUM_WATCHLIST_COLOR_BTC,
            size: electrumWatchlistSize
          }
        }
      },
      litecoin: {
        nodes: {
          icons: {
            name: litecoinIcon,
            color: MAP_NODE_COLOR_LTC,
            size: nodeSize
          }
        },
        electrumServers: {
          icons: {
            name: litecoinIconElectrum,
            color: MAP_ELECTRUM_SERVER_COLOR_LTC,
            size: electrumServerSize,
            halo: true
          }
        },
        electrumUsers: {
          icons: {
            name: litecoinIconElectrum,
            color: MAP_ELECTRUM_USER_COLOR_LTC,
            size: electrumUserSize
          }
        },
        electrumUserTxns: {
          icons: {
            name: litecoinIconElectrum,
            color: MAP_ELECTRUM_TXN_COLOR_LTC,
            size: electrumUserTxnsSize
          }
        },
        electrumWallets: {
          icons: {
            name: litecoinIconElectrum,
            color: MAP_ELECTRUM_WALLET_COLOR_LTC,
            size: electrumWalletSize,
          }
        },
        electrumWatchlists: {
          icons: {
            name: litecoinIconElectrum,
            color: MAP_ELECTRUM_WATCHLIST_COLOR_LTC,
            size: electrumWatchlistSize
          }
        }
      }
    }
  }

  created() {
    this.init()
  }

  init() {

  }
}
