
import { Header } from '@/subcomponents/types/genericTable'
import { ElectrumUserTxn, ElectrumWalletSummary, ExtendedInput, ExtendedOutput, ExtendedTx, isExtendedCoinbase } from '@/types/bitcoin'
import { sumValuesBigNum, trimZeros } from '@/utils/bignum'
import { cutMiddle, titleCase } from '@/utils/general'
import ServerTable from '@/subcomponents/ServerTable.vue'
import ElectrumIP from '@/subcomponents/ElectrumIP.vue'
import MapCard from '@/subcomponents/MapCard.vue'
import IPCard from '@/subcomponents/IPCard.vue'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import { CompressedLRUCache } from '@/utils/compressed-cache'
import { IPMetadata } from '@/types/ip'
import { LRUCache } from '@splunkdlt/cache'
import { ElectrumWalletSummaries, TimeIDs } from '@/store/p2p'

@Component({
  components: {
    ServerTable,
    ElectrumIP,
    MapCard,
    IPCard
  },
  computed: mapState([
    'ips',
    'ipTransactions',
    'transactionsPage',
    'electrumWalletIPs',
    'electrumWalletSummaries',
    'electrumWalletIPsUpdated',
    'electrumTransactionsForIp',
    'electrumTransactionsForIpMap'
  ])
})
export default class IP extends Vue {
  public network: string = ''
  public networkDisplayName: string = ''
  public ipAddress: string = ''
  public txnsLoading: boolean = false
  public ipLoading: boolean = false
  public electrumLoading: boolean = false
  public ips!: CompressedLRUCache<IPMetadata>
  public ip?: IPMetadata
  public latitude: number = 0
  public longitude: number = 0
  private ipTransactions!: string[]
  public transactionsPage!: ExtendedTx[]
  public electrumTransactionsForIp!: Array<ElectrumUserTxn>
  public electrumWalletSummaries!: ElectrumWalletSummaries
  public electrumWalletIPs!: LRUCache<string, TimeIDs>
  public electrumWalletIPsUpdated!: number
  public electrumTransactionsForIpMap!: Map<string, Array<ElectrumUserTxn>>
  public showWallets: boolean = false
  public transactionsFound: boolean = false

  public txnHeaders: Header[] = [
    { text: 'TxId', value: 'txid', transform: cutMiddle, link: {
        label: (txid: string) => txid,
        url: (txid: string) => `/explorer/transaction/${this.$route.params.network}/${txid}`,
        target: '_blank',
        field: 'txid'
      } },
    { text: 'Block', value: 'height' },
    { text: 'Time', value: 'time', isTimestamp: true },
    { text: 'Version', value: 'version' },
    { text: 'Inputs', value: 'vin', transform: (v: ExtendedInput[]) => v.length },
    { text: 'Outputs', value: 'vout', transform: (v: ExtendedOutput[]) => v.length },
    {
      text: 'Value In',
      value: 'vin',
      transform: (v: ExtendedInput[]) =>
        trimZeros(
          sumValuesBigNum(
            v.map((i) => (isExtendedCoinbase(i) ? i.value : i.spentOutput.value)),
            8,
            false
          )
        )
    },
    {
      text: 'Value Out',
      value: 'vout',
      transform: (v: ExtendedOutput[]) =>
        trimZeros(
          sumValuesBigNum(
            v.map((o) => o.value),
            8,
            false
          )
        )
    }
  ]
  public headersLitecoin: Header[] = [
    { text: 'TxId', value: 'txid' },
    { text: 'Time', value: 'timestamp', isTimestamp: true }
  ]
  public walletHeaders: Header[] = [
    {
      text: 'ID', value: 'id', link: {
        label: (id: string) => id,
        url: (id: string) => `/explorer/wallet/${this.$route.params.network}/${id}`,
        target: '_blank',
        field: 'id'
      }
    },
    { text: 'Network', value: 'network' },
    { text: 'Last Seen', value: 'lastSeen', isTimestamp: true },
    { text: 'IPs', value: 'ips', transform: (i: IPMetadata[]) => i.length },
    { text: 'Addresses', value: 'addressCount' }
  ]

  @Watch('electrumWalletIPsUpdated')
  walletIPsUpdated() {
    this.showWallets = true
  }

  get wallets(): Array<ElectrumWalletSummary> {
    if (this.ip != null) {
      const walletIDs = this.electrumWalletIPs.get(this.ip.ip)
      if (walletIDs != null) {
        const wallets: ElectrumWalletSummary[] = []
        for (const id of walletIDs.ids) {
          const wallet = this.electrumWalletSummaries[this.network].get(id)
          if (wallet != null) {
            wallets.push(wallet)
          }
        }
        return wallets
      }
    }
    return []
  }

  get transactions(): Array<ElectrumUserTxn> | undefined {
    return this.electrumTransactionsForIpMap.get(`${this.network}_${this.ip}`)
  }

  async created() {
    this.ipAddress = this.$route.params.ip
    this.network = this.$route.params.network
    this.networkDisplayName = titleCase(this.network)
    this.getIP()
    this.getTransactions()
    this.getElectrumData()
  }

  async getElectrumData() {
    const { network } = this
    this.electrumLoading = true
    await this.$store.dispatch('getElectrumWalletsByIP', { network, ip: this.ipAddress })
    this.electrumLoading = false
  }

  async getIP() {
    this.ipLoading = true
    await this.$store.dispatch('getIP', { ip: this.ipAddress })
    this.ip = this.ips.get(this.ipAddress)
    if (this.ip != null) {
      this.latitude = this.ip.latitude
      this.longitude = this.ip.longitude
    }
    this.ipLoading = false
  }

  async getTransactions() {
    this.txnsLoading = true
    await Promise.all([
      this.$store.dispatch('getElectrumTransactionsForIp', { network: this.network, ip: this.ipAddress }),
      this.$store.dispatch('getTxnsByIp', { network: this.network, ip: this.ipAddress })
    ])
    const count = 50
    const list = this.ipTransactions.concat((this.electrumTransactionsForIp ?? []).map(txn => txn.txid)).slice(0, count)
    await this.$store.dispatch('getTransactionsListPage', { network: this.network, list })
    this.txnsLoading = false
  }
}
