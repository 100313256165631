
import { StringMap } from '@/utils/api'
import { BITCOIN_START_MILLIS, currentCryptotime } from '@/utils/bitcoin'
import { timeToMilliseconds } from '@/utils/general'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import { DotOption } from 'vue-slider-component/typings/typings'

interface Marks {
  [key: string]: string | MarkOption
}

interface MarkOption {
  label: string
  style?: Styles
  activeStyle?: Styles
  labelStyle?: Styles
  labelActiveStyle?: Styles
}

type Styles = StringMap

const STEPS = 8

@Component({
  components: {
    VueSlider
  }
})
export default class BtcDateRange extends Vue {
  @Prop() dateRange!: number[]
  @Prop() loading!: boolean
  @Prop() hover!: boolean
  private start!: number
  private time!: number
  private dates!: number[]
  private dotOptions!: DotOption[]

  created() {
    this.start = BITCOIN_START_MILLIS
    this.time = timeToMilliseconds(Math.floor(currentCryptotime()))
    this.handleUpdate()
    this.dotOptions = [
      {
        disabled: true,
        tooltipStyle: {'font-size': '12px'}
      },
      {
        disabled: true,
        tooltipStyle: {'font-size': '12px'}
      }
    ]
  }

  get marks(): Marks {
    const labelStyle = {
      'font-size': '12px',
      'margin-top': '2px'
    }
    const marks: Marks = {
      [`${this.start}`]: {
        label: `${this.formatDate(this.start)}`,
        labelStyle
      },
      [`${this.time}`]: {
        label: `${this.formatDate(this.time)}`,
        labelStyle
      }
    }
    const step = this.time / STEPS
    for (let mark = step; mark < this.time; mark += step) {
      marks[`${mark}`] = ''
    }
    return marks
  }

  public formatDate(timestamp: number): string {
    const epoch = timeToMilliseconds(timestamp)
    return this.$store.state.formatDate(epoch, true, 3)
  }

  @Watch('dateRange', { deep: true })
  private handleUpdate() {
    this.dates = this.dateRange.map(timestamp => timeToMilliseconds(timestamp))
  }
}
