import { Commit, Dispatch } from 'vuex'
import { Api, IntervalCount, StringMap, StringArrayMap, failedResponse } from '../utils/api'
import { State } from './index'
import { ElectrumPeer, ElectrumUser, ElectrumUserTxn, ElectrumWallet, ElectrumWalletSummary } from '@/types/bitcoin'
import { LRUCache } from '@splunkdlt/cache'
import { CompressedLRUCache } from '@/utils/compressed-cache'
import { CompressedMap } from '@/utils/compressed-map'
import { md5 } from '@/utils/general'
import { ASN, City, Country, IPMetadata, Region } from '@/types/ip'

export interface TimeIDs {
  time: number
  ids: string[]
}

export interface TimeID {
  time: number
  id: string
}

export interface TimeWallet {
  time: number
  wallet: ElectrumWallet
}

export interface NetworkCounts {
  [key: string]: IntervalCount[]
}

export interface ElectrumWallets {
  [key: string]: CompressedLRUCache<TimeWallet>
}

export interface GeoItem {
  name: string
  code: string
}

function compressedTimeWalletCache() {
  return new CompressedLRUCache<TimeWallet>({ maxSize: 100, hashKeys: false, parseObjects: true })
}

export interface ElectrumWalletSummaries {
  [key: string]: CompressedMap<ElectrumWalletSummary>
}

function compressedWalletSummaryMap() {
  return new CompressedMap<ElectrumWalletSummary>()
}

const WALLET_STATS_START = 1696809600 // 2023-10-09 00:00:00
const BITCOIN_STATS_START = 1691539200 // 2023-08-09 00:00:0
const ONE_DAY = 86400

export const p2pState = {
  mapBoxToken: 'pk.eyJ1IjoiaGVpZ2h0c2xhYnMiLCJhIjoiY2xqdm84cTV0MG5mcTN1bWo3eHQwNTlqNSJ9.fOLvIli6FCpk-bh_xKkSiw',
  allP2PNodes: <Array<Node>>[],
  ipTransactions: <Array<string>>[],
  electrumPeersMap: <Map<string, ElectrumPeer[]>>new Map<string, ElectrumPeer[]>(),
  electrumPeer: <LRUCache<string, ElectrumPeer>>new LRUCache<string, ElectrumPeer>({ maxSize: 100 }),
  electrumUsersMap: <Map<string, Map<string, ElectrumUser>>>new Map<string, Map<string, ElectrumUser>>(),
  electrumUser: <LRUCache<string, ElectrumUser>>new LRUCache<string, ElectrumUser>({ maxSize: 100 }),
  electrumTransactionsMap: <Map<string, StringArrayMap>>new Map<string, StringArrayMap>(),
  electrumTransactionsForIpMap: <Map<string, Array<ElectrumUserTxn>>>new Map<string, Array<ElectrumUserTxn>>(),
  electrumTransactionsForIp: <Array<ElectrumUserTxn>>[],
  electrumUsersWithTransactionsMap: <Map<string, Array<ElectrumUser>>>new Map<string, Array<ElectrumUser>>(),
  walletCacheTTL: 1000 * 60 * 60, // 1 hour
  walletsLastUpdated: 0,
  filteredWalletsLastUpdated: <string>'',
  watchlistsLastUpdated: 0,
  electrumWalletSummaries: <ElectrumWalletSummaries>{},
  electrumWalletSummariesFiltered: <ElectrumWalletSummaries>{},
  electrumWatchlistSummaries: <ElectrumWalletSummaries>{},
  electrumWallets: <ElectrumWallets>{},
  electrumWalletUpdated: 0,
  electrumWalletIPs: <LRUCache<string, TimeIDs>>new LRUCache<string, TimeIDs>({ maxSize: 1_000 }),
  electrumWalletIPsUpdated: 0,
  electrumWalletAddresses: <LRUCache<string, TimeID>>new LRUCache<string, TimeID>({ maxSize: 10_000 }),
  electrumPeerCounts: <NetworkCounts>{},
  electrumPeerCountsHash: <string>'',
  electrumUserCounts: <NetworkCounts>{},
  electrumUserCountsHash: <string>'',
  electrumTransactionCounts: <NetworkCounts>{},
  electrumTransactionCountsHash: <string>'',
  electrumWalletCounts: <NetworkCounts>{},
  electrumWalletCountsHash: <string>'',
  electrumWatchlistCounts: <NetworkCounts>{},
  electrumWatchlistCountsHash: <string>'',
  electrumAddressCounts: <NetworkCounts>{},
  electrumAddressCountsHash: <string>'',
  statsCacheTTL: 1000 * 60 * 15, // 15 minutes
  geoList: <GeoItem[]>[],
  ips: <CompressedLRUCache<IPMetadata>>new CompressedLRUCache<IPMetadata>({ maxSize: 100_000, hashKeys: false }),
  ipsInCountry: <CompressedMap<IPMetadata>>new CompressedMap<IPMetadata>(),
  asns: <CompressedLRUCache<ASN>>new CompressedLRUCache<ASN>({ maxSize: 10_000, hashKeys: false }),
  carriers: <string[]>[],
  countries: <Country[]>[],
  regions: <Region[]>[],
  cities: <City[]>[]
}

export const p2pMutations = {
  SET_ALL_NODES(state: State, { nodes }: { nodes: Array<Node> }) {
    state.allP2PNodes = nodes
  },
  SET_TXNS_FOR_IP(state: State, { txns }: { txns: Array<string> }) {
    state.ipTransactions = txns.filter((txn) => !txn.startsWith('000000'))
  },
  SET_ELECTRUM_PEERS(state: State, { network, peers }: { network: string; peers: Array<ElectrumPeer> }) {
    state.electrumPeersMap.set(network, peers)
  },
  SET_ELECTRUM_PEER(state: State, { network, ip, peer }: { network: string; ip: string; peer: ElectrumPeer }) {
    state.electrumPeer.set(`${network}_${ip}`, peer)
  },
  SET_ELECTRUM_USERS(state: State, { network, users }: { network: string; users: ElectrumUser[] }) {
    for (const user of users) {
      const networkUsers = state.electrumUsersMap.get(network) ?? new Map<string, ElectrumUser>()
      networkUsers.set(user.ip, user)
      state.electrumUsersMap.set(network, networkUsers)
    }
  },
  SET_ELECTRUM_USER(state: State, { network, user }: { network: string; user: ElectrumUser }) {
    state.electrumUser.set(`${network}_${user.ip}`, user)
  },
  SET_ELECTRUM_TRANSACTIONS(
    state: State,
    { network, transactions }: { network: string; transactions: StringArrayMap }
  ) {
    state.electrumTransactionsMap.set(network, transactions)
  },
  SET_ELECTRUM_TRANSACTIONS_FOR_IP(
    state: State,
    { network, ip, transactions }: { network: string; ip: string; transactions: Array<ElectrumUserTxn> }
  ) {
    state.electrumTransactionsForIpMap.set(`${network}_${ip}`, transactions)
    state.electrumTransactionsForIp = transactions
  },
  SET_ELECTRUM_USERS_WITH_TXNS(state: State, { network, users }: { network: string; users: Array<ElectrumUser> }) {
    state.electrumUsersWithTransactionsMap.set(network, users)
  },
  CLEAR_ELECTRUM_IP_TRANSACTIONS(state: State) {
    state.electrumTransactionsForIp = []
  },
  SET_ELECTRUM_WALLETS(state: State, { network, wallets }: { network: string; wallets: ElectrumWalletSummary[] }) {
    if (state.electrumWalletSummaries[network] == null) {
      state.electrumWalletSummaries[network] = compressedWalletSummaryMap()
    }
    if (state.electrumWalletSummariesFiltered[network] == null) {
      state.electrumWalletSummariesFiltered[network] = compressedWalletSummaryMap()
    }
    for (const wallet of wallets) {
      state.electrumWalletSummaries[network].set(wallet.id, wallet)
      state.electrumWalletSummariesFiltered[network].set(wallet.id, wallet)
    }
    state.walletsLastUpdated = Date.now()
  },
  SET_ELECTRUM_WALLETS_FILTERED(state: State, { network, wallets }: { network: string; wallets: ElectrumWalletSummary[] }) {
    state.electrumWalletSummariesFiltered[network].clear()
    for (const wallet of wallets) {
      state.electrumWalletSummariesFiltered[network].set(wallet.id, wallet)
    }
    state.filteredWalletsLastUpdated = `${network}_${Date.now()}`
  },
  SET_ELECTRUM_WATCHLISTS(state: State, { network, wallets }: { network: string; wallets: ElectrumWalletSummary[] }) {
    if (state.electrumWatchlistSummaries[network] == null) {
      state.electrumWatchlistSummaries[network] = compressedWalletSummaryMap()
    }
    for (const wallet of wallets) {
      state.electrumWatchlistSummaries[network].set(wallet.id, wallet)
    }
    state.watchlistsLastUpdated = Date.now()
  },
  SET_ELECTRUM_WALLET_ADDRESSES(state: State, { network, wallet }: { network: string; wallet: ElectrumWallet }) {
    const { id, addresses } = wallet
    const time = Date.now()
    if (state.electrumWallets[network] == null) {
      state.electrumWallets[network] = compressedTimeWalletCache()
    }
    state.electrumWallets[network].set(id, { time, wallet })
    for (const address of addresses) {
      state.electrumWalletAddresses.set(address, { time, id })
    }
    state.electrumWalletUpdated = time
  },
  SET_ELECTRUM_WALLETS_BY_IP(
    state: State,
    { network, ip, wallets }: { network: string; ip: string; wallets: ElectrumWalletSummary[] }
  ) {
    const time = Date.now()
    if (state.electrumWalletSummaries[network] == null) {
      state.electrumWalletSummaries[network] = compressedWalletSummaryMap()
    }
    state.electrumWalletIPs.set(ip, { time, ids: wallets.map(({ id }) => id) })
    if (time - state.walletsLastUpdated > state.walletCacheTTL) {
      for (const wallet of wallets) {
        state.electrumWalletSummaries[network].set(wallet.id, wallet)
      }
    }
    state.electrumWalletIPsUpdated = time
  },
  SET_ELECTRUM_WALLET_UPDATED(state: State) {
    state.electrumWalletUpdated = Date.now()
  },
  SET_ELECTRUM_PEER_COUNTS(state: State, { counts, network }: { counts: IntervalCount[]; network: string }) {
    state.electrumPeerCounts[network] = counts
    state.electrumPeerCountsHash = md5(state.electrumPeerCounts)
  },
  SET_ELECTRUM_USER_COUNTS(state: State, { counts, network }: { counts: IntervalCount[]; network: string }) {
    state.electrumUserCounts[network] = counts
    state.electrumUserCountsHash = md5(state.electrumUserCounts)
  },
  SET_ELECTRUM_TRANSACTION_COUNTS(state: State, { counts, network }: { counts: IntervalCount[]; network: string }) {
    state.electrumTransactionCounts[network] = counts
    state.electrumTransactionCountsHash = md5(state.electrumTransactionCounts)
  },
  SET_ELECTRUM_WALLET_COUNTS(state: State, { counts, network }: { counts: IntervalCount[]; network: string }) {
    state.electrumWalletCounts[network] = counts
    state.electrumWalletCountsHash = md5(state.electrumWalletCounts)
  },
  SET_ELECTRUM_WATCHLIST_COUNTS(state: State, { counts, network }: { counts: IntervalCount[]; network: string }) {
    state.electrumWatchlistCounts[network] = counts
    state.electrumWatchlistCountsHash = md5(state.electrumWatchlistCounts)
  },
  SET_ELECTRUM_ADDRESS_COUNTS(state: State, { counts, network }: { counts: IntervalCount[]; network: string }) {
    state.electrumAddressCounts[network] = counts
    state.electrumAddressCountsHash = md5(state.electrumAddressCounts)
  },
  SET_GEO_LIST(state: State, { list }: { list: GeoItem[] }) {
    state.geoList = list
  },
  SET_IPS(state: State, { ips }: { ips: IPMetadata[] }) {
    for (const ip of ips) {
      state.ips.set(ip.ip, ip)
    }
  },
  SET_IPS_IN_COUNTRY(state: State, { ips }: { ips: IPMetadata[] }) {
    for (const ip of ips) {
      state.ipsInCountry.set(ip.ip, ip)
    }
  },
  CLEAR_IPS_IN_COUNTRY(state: State) {
    state.ipsInCountry.clear()
  },
  SET_ASNS(state: State, { asns }: { asns: ASN[] }) {
    for (const asn of asns) {
      state.asns.set(asn.asn, asn)
    }
  },
  SET_CARRIERS(state: State, { carriers }: { carriers: string[] }) {
    state.carriers = carriers
  },
  SET_COUNTRIES(state: State, { countries }: { countries: Country[] }) {
    state.countries = countries
  },
  SET_REGIONS(state: State, { regions }: { regions: Region[] }) {
    state.regions = regions
  },
  SET_CITIES(state: State, { cities }: { cities: City[] }) {
    state.cities = cities
  }
}

export const p2pActions = (state: State, api: Api, dispatch: Dispatch) => ({
  async getAllNodes({ commit }: { commit: Commit }, { network }: { network: string }) {
    const response = await api.getAllNodes({ network })
    if (Array.isArray(response)) {
      commit('SET_ALL_NODES', { nodes: response })
    }
  },
  async getTxnsByIp({ commit }: { commit: Commit }, { network, ip }: { network: string; ip: string }) {
    const response = await api.getTxnsByIp({ network, ip })
    if (Array.isArray(response)) {
      commit('SET_TXNS_FOR_IP', { txns: response })
    }
  },
  async getElectrumPeers({ commit }: { commit: Commit }, { network }: { network: string }) {
    const response = await api.getElectrumPeers({ network })
    if (!failedResponse(response) && Array.isArray(response)) {
      commit('SET_ELECTRUM_PEERS', { network, peers: response })
    }
  },
  async getElectrumPeer({ commit }: { commit: Commit }, { network, ip }: { network: string; ip: string }) {
    const response = await api.getElectrumPeer({ network, ip })
    if (!failedResponse(response)) {
      commit('SET_ELECTRUM_PEER', { network, ip, peer: response })
    }
  },
  // async getElectrumUsers(
  //   { commit }: { commit: Commit },
  //   { network, page, limit }: { network: string; page: number; limit: number }
  // ) {
  //   const response = await api.getElectrumUsers({ network, page, limit })
  //   if (!failedResponse(response)) {
  //     commit('SET_ELECTRUM_USERS', { network, users: response.users })
  //   }
  // },
  async getElectrumUsers({ commit }: { commit: Commit }, { network }: { network: string }) {
    const response = await api.getElectrumUsers({ network })
    if (!failedResponse(response)) {
      commit('SET_ELECTRUM_USERS', { network, users: response })
    }
  },
  async getElectrumUser({ commit }: { commit: Commit }, { network, ip }: { network: string; ip: string }) {
    const response = await api.getElectrumUser({ network, ip })
    if (!failedResponse(response)) {
      commit('SET_ELECTRUM_USER', { network, ip, user: response })
    }
  },
  async getElectrumTransactions({ commit }: { commit: Commit }, { network }: { network: string }) {
    const response = await api.getElectrumTransactions({ network })
    if (!failedResponse(response)) {
      commit('SET_ELECTRUM_TRANSACTIONS', { network, transactions: response })
    }
  },
  async getElectrumTransactionsForIp({ commit }: { commit: Commit }, { network, ip }: { network: string; ip: string }) {
    commit('CLEAR_ELECTRUM_IP_TRANSACTIONS')
    const response = await api.getElectrumTransactionsForIp({ network, ip })
    if (!failedResponse(response) && Array.isArray(response)) {
      commit('SET_ELECTRUM_TRANSACTIONS_FOR_IP', { network, ip, transactions: response })
    }
  },
  async getElectrumUsersWithTransactions({ commit }: { commit: Commit }, { network }: { network: string }) {
    const response = await api.getElectrumUsersWithTransactions({ network })
    if (!failedResponse(response) && Array.isArray(response)) {
      commit('SET_ELECTRUM_USERS_WITH_TXNS', { network, users: response })
    }
  },
  async getElectrumWallets({ commit }: { commit: Commit }, { network }: { network: string }) {
    const response = await api.getElectrumWallets({ network })
    if (!failedResponse(response) && Array.isArray(response)) {
      commit('SET_ELECTRUM_WALLETS', { network, wallets: response })
    }
  },
  async setElectrumWalletsFiltered(
    { commit }: { commit: Commit },
    { network, wallets }: { network: string; wallets: ElectrumWalletSummary[] }
  ) {
    commit('SET_ELECTRUM_WALLETS_FILTERED', { network, wallets })
  },
  async getElectrumWatchlists({ commit }: { commit: Commit }, { network }: { network: string }) {
    const response = await api.getElectrumWatchlists({ network })
    if (!failedResponse(response) && Array.isArray(response)) {
      commit('SET_ELECTRUM_WATCHLISTS', { network, wallets: response })
    }
  },
  async getElectrumWalletAddresses({ commit }: { commit: Commit }, { network, id }: { network: string; id: string }) {
    const response = await api.getElectrumWalletAddresses({ network, id })
    if (!failedResponse(response)) {
      commit('SET_ELECTRUM_WALLET_ADDRESSES', { network, wallet: response })
    }
  },
  async getElectrumWalletsByIP({ commit }: { commit: Commit }, { network, ip }: { network: string; ip: string }) {
    const response = await api.getElectrumWalletsByIP({ network, ip })
    if (!failedResponse(response)) {
      commit('SET_ELECTRUM_WALLETS_BY_IP', { network, wallets: response, ip })
    }
  },
  async getElectrumWalletByAddress(
    { commit }: { commit: Commit },
    { network, address }: { network: string; address: string }
  ) {
    // try cache first
    const idFromCache = state.electrumWalletAddresses.get(address)
    let haveWallet = false
    if (idFromCache != null) {
      const now = Date.now()
      const { time, id } = idFromCache
      if (now - time < state.walletCacheTTL) {
        // check wallet cache, if not request full wallet from api
        if (state.electrumWallets[network] != null) {
          const fromCache = state.electrumWallets[network].get(id)
          if (fromCache != null) {
            if (now - fromCache.time < state.walletCacheTTL) {
              haveWallet = true
              commit('SET_ELECTRUM_WALLET_UPDATED')
              return fromCache.wallet
            }
          }
        }
      }
      if (!haveWallet) {
        const response = await api.getElectrumWalletAddresses({ network, id })
        if (!failedResponse(response)) {
          commit('SET_ELECTRUM_WALLET_ADDRESSES', { wallet: response })
          haveWallet = true
          return response
        }
      }
    }
    if (!haveWallet) {
      const summaryResponse = await api.getElectrumWalletByAddress({ network, address })
      if (!failedResponse(summaryResponse) && Array.isArray(summaryResponse) && summaryResponse.length > 0) {
        commit('SET_ELECTRUM_WALLETS', { network, wallets: summaryResponse })
        const response = await api.getElectrumWalletAddresses({ network, id: summaryResponse[0].id })
        if (!failedResponse(response)) {
          commit('SET_ELECTRUM_WALLET_ADDRESSES', { wallet: response })
        }
        return summaryResponse
      }
    }
    return undefined
  },
  async getElectrumPeerCounts({ commit }: { commit: Commit }, { network }: { network: string }) {
    const start = BITCOIN_STATS_START
    const end = Math.floor(Date.now() / 1000)
    const response = await api.getElectrumPeerCounts({ network, start, end, interval: ONE_DAY })
    if (!failedResponse(response)) {
      commit('SET_ELECTRUM_PEER_COUNTS', { network, counts: response })
    }
  },
  async getElectrumUserCounts({ commit }: { commit: Commit }, { network }: { network: string }) {
    const start = BITCOIN_STATS_START
    const end = Math.floor(Date.now() / 1000)
    const response = await api.getElectrumUserCounts({ network, start, end, interval: ONE_DAY })
    if (!failedResponse(response)) {
      commit('SET_ELECTRUM_USER_COUNTS', { network, counts: response })
    }
  },
  async getElectrumTransactionCounts({ commit }: { commit: Commit }, { network }: { network: string }) {
    const start = BITCOIN_STATS_START
    const end = Math.floor(Date.now() / 1000)
    const response = await api.getElectrumTransactionCounts({ network, start, end, interval: ONE_DAY })
    if (!failedResponse(response)) {
      commit('SET_ELECTRUM_TRANSACTION_COUNTS', { network, counts: response })
    }
  },
  async getElectrumWalletCounts({ commit }: { commit: Commit }, { network }: { network: string }) {
    const start = WALLET_STATS_START
    const end = Math.floor(Date.now() / 1000)
    const response = await api.getElectrumWalletCounts({ network, start, end, interval: ONE_DAY })
    if (!failedResponse(response)) {
      commit('SET_ELECTRUM_WALLET_COUNTS', { network, counts: response })
    }
  },
  async getElectrumWatchlistCounts({ commit }: { commit: Commit }, { network }: { network: string }) {
    const start = WALLET_STATS_START
    const end = Math.floor(Date.now() / 1000)
    const response = await api.getElectrumWatchlistCounts({ network, start, end, interval: ONE_DAY })
    if (!failedResponse(response)) {
      commit('SET_ELECTRUM_WATCHLIST_COUNTS', { network, counts: response })
    }
  },
  async getElectrumAddressCounts({ commit }: { commit: Commit }, { network }: { network: string }) {
    const start = WALLET_STATS_START
    const end = Math.floor(Date.now() / 1000)
    const response = await api.getElectrumAddressCounts({ network, start, end, interval: ONE_DAY })
    if (!failedResponse(response)) {
      commit('SET_ELECTRUM_ADDRESS_COUNTS', { network, counts: response })
    }
  },
  async getGeoList({ commit }: { commit: Commit }) {
    const response = await api.getGeoList()
  },
  async getIP({ commit }: { commit: Commit }, { ip }: { ip: string }) {
    const response = await api.getIP({ ip })
    if (!failedResponse(response)) {
      commit('SET_IPS', { ips: [response] })
    } else {
      dispatch('updateSnackbar', { show: state.enableErrors, text: `Could not get IP ${ip}.` })
    }
  },
  async getIPs({ commit }: { commit: Commit }, { ips }: { ips: string[] }) {
    const response = await api.getIPs({ ips })
  },
  async getIPsWithinCountry({ commit }: { commit: Commit }, { code, lastSeen }: { code: string; lastSeen?: number }) {
    const response = await api.getIPsWithinCountry({ code, lastSeen })
  },
  async getASNs({ commit }: { commit: Commit }) {
    const response = await api.getASNs()
  },
  async getCarriers({ commit }: { commit: Commit }) {
    const response = await api.getCarriers()
  },
  async getCountries({ commit }: { commit: Commit }) {
    const response = await api.getCountries()
  },
  async getRegions({ commit }: { commit: Commit }) {
    const response = await api.getRegions()
  },
  async getCities({ commit }: { commit: Commit }) {
    const response = await api.getCities()
  }
})