
import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class Snackbar extends Vue {
  get snackbar() {
    return this.$store.state.snackbar.show
  }

  get timeout() {
    return this.$store.state.snackbar.timeout
  }

  set snackbar(show: boolean) {
    this.$store.dispatch('updateSnackbar', { show, text: '' })
  }

  get text() {
    return this.$store.state.snackbar.text
  }

  public close() {
    this.$store.dispatch('updateSnackbar', { show: false, text: '' })
  }
}
