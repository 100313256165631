
import { TxnCounterpartiesMap } from '@/store/chain'
import { AggregatedTypedCounterparty } from '@/store/investigations/tabular'
import { ExtendedTx, SimpleAggregatedTransaction, TxHeader } from '@/types/bitcoin'
import { divideBigNum, floatToInt } from '@/utils/bignum'
import { calcFee, rbfEnabled } from '@/utils/bitcoin'
import { cutMiddle, timeToMilliseconds } from '@/utils/general'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'

@Component({
  computed: mapState([
    'entityLedgerFull',
    'txnCounterpartiesMap'
  ])
})
export default class BtcItem extends Vue {
  @Prop() item!: SimpleAggregatedTransaction
  @Prop() index!: number
  @Prop() txnHeadersMap!: { [txid: string]: TxHeader }

  public entityLedgerFull!: { [key: string]: ExtendedTx }
  public txnCounterpartiesMap?: TxnCounterpartiesMap

  public cutMiddle = cutMiddle

  created() {}

  public getRbf(txid: string): boolean {
    const txn: ExtendedTx | undefined = this.entityLedgerFull[txid]
    if (txn != null) {
      return rbfEnabled(txn)
    }
    return false
  }

  public getFee(simpleTxn: SimpleAggregatedTransaction): string {
    return calcFee(simpleTxn.totalInputValue, simpleTxn.totalOutputValue)
  }

  public getSvb(simpleTxn: SimpleAggregatedTransaction): string {
    const txn: ExtendedTx | undefined = this.entityLedgerFull[simpleTxn.id]
    if (txn != null) {
      const fee = calcFee(simpleTxn.totalInputValue, simpleTxn.totalOutputValue)
      const feeSats = floatToInt(fee, 8)
      return divideBigNum(feeSats, txn.vsize, 1)
    }
    return ''
  }

  public getWitness(txid: string): boolean {
    const header = this.txnHeadersMap[txid]
    if (header != null) {
      return header.segwit
    }
    return false
  }

  public formatValue(item: SimpleAggregatedTransaction): string {
    const value = item.amount
    if (item.isOutput) {
      return `${value}`
    }
    return `-${value}`
  }

  public formatCounterPartyValue(value: number | string, isOutput: boolean): string {
    if (isOutput) {
      return `${value}`
    }
    return `-${value}`
  }

  public valueColor(output: boolean): string {
    if (output) {
      return 'value-output'
    }
    return 'value-input'
  }

  public formatDate(time: number) {
    const epoch = timeToMilliseconds(time)
    return this.$store.state.formatDate(epoch, true)
  }

  public counterparties(item: SimpleAggregatedTransaction): AggregatedTypedCounterparty[] {
    if (this.txnCounterpartiesMap != null) {
      const { id, isOutput } = item
      return this.txnCounterpartiesMap[`${id}${isOutput}`]
    }
    return []
  }
}
