
import { Component, Vue, Watch } from 'vue-property-decorator'
import { titleCase, sleep, ServerEvent } from '@/utils/general'
import { ExtendedTx, SimpleAddress, SimpleAggregatedTransaction } from '@/types/bitcoin'
import FilterBar from '@/subcomponents/FilterBar.vue'
import ServerTable from '@/subcomponents/ServerTable.vue'
import { ClusterMetadata, ComparisonConstraints, EntitySummary, SortMap } from '@/utils/api'
import { mapState } from 'vuex'
import { prettyRoundedNumber } from '@/utils/general'
import BtcDateRange from '@/subcomponents/BtcDateRange.vue'
import { networkSymbol } from '@/utils/viz'
import { filter } from '@/utils/filters'

@Component({
  components: {
    FilterBar,
    ServerTable,
    BtcDateRange
  },
  computed: mapState(['entitySummary', 'attributionClusters', 'entityLedgerSimple', 'entityLedgerFull', 'listeningToEvents'])
})
export default class Attribution extends Vue {
  public attributionName: string = ''
  public network: string = ''
  public networkDisplayName: string = ''
  public symbol: string = ''
  public perPage: number = 100
  public page: number = 1
  public perPageOptions = [10, 25, 50, 100]
  public loading = true
  public pageView: 'Transaction' | 'Address' | 'Cluster' = 'Transaction'
  public type = ''
  public currentData: SimpleAggregatedTransaction[] | SimpleAddress[] | ClusterMetadata[] = []

  public sorts = [
    { label: 'time', key: 'time' },
    { label: 'value', key: 'amount' }
  ]
  public sort: SortMap = { time: 'desc' }

  private amountConstraint?: ComparisonConstraints
  private timeConstraint?: ComparisonConstraints
  private inputs: boolean = true
  private outputs: boolean = true

  private category = ''
  private size = ''

  public entitySummary!: EntitySummary
  public attributionClusters!: ClusterMetadata[] | undefined
  public entityLedgerSimple!: SimpleAggregatedTransaction[]
  public entityLedgerFull!: ExtendedTx[]
  public listeningToEvents!: boolean

  private sliderHover = false
  public dateRange = [0, 0]

  public prettyRoundedNumber = prettyRoundedNumber
  public networkSymbol = networkSymbol

  private countFromStream: number = 0

  get symbols() {
    return this.entitySummary != null ? 
      filter(
        Object.keys(this.entitySummary.symbols),
        key => {
          if (this.entitySummary != null) {
            return this.entitySummary.symbols[key].currentCredits !== 0 ||
              this.entitySummary.symbols[key].currentDeductions !== 0
          }
          return false // this will never happen
        }
      )
      : []
  }

  get totalCount(): number {
    if (this.countFromStream > 0) {
      return this.countFromStream
    } else if (this.pageView === 'Transaction') {
      return this.$store.state.entityLedgerCount || 0
    }
    return 0
  }

  get pageViewTitle() {
    switch (this.pageView) {
      case 'Transaction':
        return 'Transactions'
      case 'Address':
        return 'Addresses'
      case 'Cluster':
        return 'Clusters'
    }
  }

  get nonnegativeBalance(): string {
    if (this.entitySummary != null) {
      const { currentBalance } = this.entitySummary.symbols[this.symbol]
      if (currentBalance >= 0) {
        return prettyRoundedNumber(currentBalance)
      }
    }
    return '0'
  }

  private async getAddresses() {
    const network = this.network
    const id = this.attributionName
    await this.$store.dispatch('getAddressesInCluster', {
      network,
      id,
      page: this.page,
      perPage: this.perPage,
      isAttributed: true
    })
  }

  private async getClusters() {
    const network = this.network
    const id = this.attributionName
    await this.$store.dispatch('getAttributionClusters', {
      network,
      id,
      page: this.page,
      perPage: this.perPage
    })
    if (!this.listeningToEvents) {
      await sleep(1000)
    }
    this.$store.dispatch('getAttributionClustersCount', { network, id, stream: true })
  }

  private async getClusterTxnsById() {
    const network = this.network
    const id = this.attributionName
    const params = {
      network,
      id,
      page: this.page,
      perPage: this.perPage,
      sorts: this.sort,
      amountConstraint: this.amountConstraint,
      timeConstraint: this.timeConstraint,
      inputs: this.inputs,
      outputs: this.outputs,
      type: 'attribution'
    }
    const countParams = {
      network,
      id,
      amountConstraint: this.amountConstraint,
      timeConstraint: this.timeConstraint,
      stream: true,
      type: 'attribution'
    }
    this.countFromStream = 0
    await this.$store.dispatch('getEntity', params)
    if (!this.listeningToEvents) {
      await sleep(1000)
    }
    this.$store.dispatch('getEntityLedgerCount', countParams)
  }

  public onFilter({
    amount,
    time,
    inputs,
    outputs
  } : {
    amount?: ComparisonConstraints
    time?: ComparisonConstraints
    inputs: boolean
    outputs: boolean
  }) {
    this.amountConstraint = amount
    this.timeConstraint = time
    this.inputs = inputs
    this.outputs = outputs
    this.refreshPageView()
  }

  private async refreshData() {
    this.updateAttributionCategory() // in case cluster is already cached
    this.updateAttributionSize() // in case size is already cached
    this.loading = true
    this.$store.dispatch('getClusterForAttribution', { network: this.network, attribution: this.attributionName })
    if (this.pageView === 'Transaction') {
      await this.getClusterTxnsById()
    } else if (this.pageView === 'Address') {
      await this.getAddresses()
    } else {
      await this.getClusters()
    }
    this.loading = false
  }

  public async refreshPageView() {
    await this.refreshData()
    if (this.pageView === 'Address') {
      this.type = 'bitcoinAddress'
      const addressList = this.$store.state.addresses
      this.currentData = addressList.map((address: string) => ({ address }))
    } else if (this.pageView === 'Cluster') {
      this.type = 'bitcoinCluster'
      this.currentData = this.attributionClusters ?? []
    } else {
      this.type = `${this.network}Item`
      this.currentData = this.entityLedgerSimple
    }
  }

  async created() {
    this.attributionName = this.$route.params.attribution
    this.network = this.$route.params.network
    this.networkDisplayName = titleCase(this.network)
    await this.refreshPageView()
  }

  public onPageUpdated(page: number) {
    this.page = page
    this.refreshPageView()
  }

  public onItemsPerPageUpdated(count: number) {
    this.perPage = count
    this.refreshPageView()
  }

  public onUpdateSort(sort: SortMap) {
    this.sort = sort
    this.refreshPageView()
  }

  @Watch('entitySummary')
  private updateDateRange() {
    if (this.entitySummary != null) {
      const { firstTransactionTime, lastTransactionTime } = this.entitySummary
      this.dateRange = [firstTransactionTime, lastTransactionTime]
      this.symbol = this.symbols.length ? this.symbols[0] : ''
    }
  }

  @Watch('$store.state.shared.attributionClustersUpdated')
  updateAttributionCategory() {
    const clustersMetadata: ClusterMetadata | null = this.$store.state.shared.attributionClustersCache.get(this.attributionName)
    if (clustersMetadata != null) {
      this.category = clustersMetadata.topCategory ?? 'Unknown'
    } else {
      this.category = 'Loading...'
    }
  }

  @Watch('$store.state.shared.attributionSizesUpdated')
  @Watch('$store.state.shared.attributionSizesNotLoaded', { deep: true })
  updateAttributionSize() {
    const networkSizeMap: { [network: string]: number } | null = this.$store.state.shared.attributionSizeCache.get(this.attributionName)
    if (networkSizeMap != null && networkSizeMap[this.network] != null) {
      this.size = prettyRoundedNumber(networkSizeMap[this.network])
    } else {
      this.size = this.$store.state.shared.attributionSizesNotLoaded.has(`${this.attributionName}||${this.network}`) ?
        'Failed to load' : 'Loading...'
    }
  }

  @Watch('$store.state.eventCount')
  private newEvent() {
    const eventsLength = this.$store.state.eventStack.length
    if (eventsLength > 0) {
      const event: ServerEvent = this.$store.state.eventStack[eventsLength - 1]
      if (event.type === 'count') {
        this.countFromStream = event.data as number
      }
    }
  }
}
