
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { TxHeader } from '@/types/bitcoin'
import { cutMiddle, timeToMilliseconds } from '@/utils/general'
import { LinkTransaction } from '@/utils/api'
import { classifyInput } from '@/utils/validate'
import CopyBtn from './CopyBtn.vue'
import { FormattedSummaryLink } from '@/utils/graph-links'
import { LinkTransactionWithCounterparties } from '@/store/investigations/tabular'
import { FormattedLink, SettingsCollection } from '@/store/investigations/viz'
import { nodeTypeToIdString } from '@/utils/viz'
import { filter } from '@/utils/filters'
import { LRUCache } from '@splunkdlt/cache'
import { ContractInfo, FormattedLogEvent, isTokenProps } from '@/types/eth'

@Component({
  components: {
    CopyBtn
  }
})
export default class EntityTransaction extends Vue {
  public expanded = false
  public cutMiddle = cutMiddle
  public heuristics = {
    haveHeader: false,
    rbf: false,
    locktime: 0,
    version: 0,
    witness: false
  }
  public contract = {
    present: false,
    name: '',
    event: ''
  }
  private graphedCounterparties: string[] = []
  private ungraphed = false
  private showExpandTooltip = false

  @Prop() item!: LinkTransactionWithCounterparties
  @Prop() expandClick!: (item: LinkTransaction) => void
  @Prop() decimalFormatter!: (n: number | string) => string

  get network() {
    return classifyInput(this.item.txid).networkType
  }

  get expandText() {
    return this.expanded ? 'Collapse' : 'Expand'
  }

  created() {
    this.setExpanded()
    this.txnHeadersCacheUpdated()
    this.receiptsCacheUpdated()
    this.updateGraphed()
  }

  private async graphTransaction() {
    this.$store.dispatch('graphTransaction', {
      txn: this.item,
      counterparties: this.item.counterparties,
      network: this.network,
      permanent: true
    })
  }

  private ungraphTransaction() {
    const ids = [] // Array.from(this.graphedCounterparties)
    ids.push(nodeTypeToIdString({ type: 'transaction', id: this.item.txid, network: this.network }))
    this.$store.dispatch('removeNodes', { ids })
  }

  private handleExpand() {
    if (this.network === 'bitcoin') {
      this.expandClick(this.item)
    }
  }

  public formatDate(time: number) {
    const epoch = timeToMilliseconds(time)
    return this.$store.state.formatDate(epoch, true)
  }

  @Watch('$store.state.expandedTransaction')
  private setExpanded() {
    const expandedTransaction = <LinkTransaction | undefined>this.$store.state.expandedTransaction
    this.expanded = expandedTransaction != null && expandedTransaction.txid === this.item.txid
  }

  @Watch('$store.state.txnHeadersCacheCount')
  private txnHeadersCacheUpdated() {
    if (this.$store.state.txnHeadersCache.has(this.item.txid)) {
      const h: TxHeader = this.$store.state.txnHeadersCache.get(this.item.txid)
      this.heuristics.locktime = h.locktime
      this.heuristics.version = h.version
      this.heuristics.witness = h.segwit
      this.heuristics.rbf = h.rbf
      this.heuristics.haveHeader = true
    }
  }

  @Watch('$store.state.receiptsCacheCount')
  private receiptsCacheUpdated() {
    const receipt = (<LRUCache<string, FormattedLogEvent>>this.$store.state.receiptsCache).get(this.item.txid)
    if (receipt != null) {
      const { addressInfo, event } = receipt
      const eventName = event != null ? event.name : ''
      let contract = ''
      if (addressInfo != null && addressInfo.isContract) {
        const { contractName, properties } = addressInfo as ContractInfo
        if (isTokenProps(properties)) {
          contract = properties.name
        } else { 
          contract = contractName ?? ''
        }
      }
      this.contract = {
        present: eventName !== '',
        name: contract,
        event: eventName
      }
    }
  }

  @Watch('$store.state.formattedLinks')
  @Watch('$store.state.formattedSummaryLinks')
  @Watch('$store.state.settings', { deep: true })
  private async updateGraphed() {
    const { id: entityNode } = (this.$store.state.selectedLink as FormattedSummaryLink).source
    const { counterparties } = this.item

    // for IO of txn and each counterparty, check if graphed; if all graphed, transaction is graphed
    const links = new Set(
      filter(
        this.$store.state.formattedLinks,
        (l: FormattedLink) => (<SettingsCollection>this.$store.state.settings).txnNodeSwitch || !!l.permanent)
      .map((l: FormattedLink) => `${l.source.id}${l.target.id}`)
    )
    const opposingNode = nodeTypeToIdString({ type: 'transaction', id: this.item.txid, network: this.network })
    let allGraphed = links.has(`${entityNode}${opposingNode}`)
    const graphedCounterparties: string[] = []

    if (counterparties != null) {
      for (const { entity, type, attribution } of counterparties) {
        let cpNode
        if (attribution != null) {
          cpNode = nodeTypeToIdString({ type: 'attribution', id: attribution, network: this.network })
        } else {
          cpNode = nodeTypeToIdString({ type, id: entity, network: this.network })
        }
        const counterpartyGraphed = links.has(`${opposingNode}${cpNode}`)
        allGraphed = allGraphed && counterpartyGraphed
        if (counterpartyGraphed) {
          graphedCounterparties.push(cpNode)
        }
      }
    }

    this.ungraphed = !allGraphed
    this.graphedCounterparties = graphedCounterparties
  }
}
