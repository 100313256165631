import {
  ExtendedCoinbase,
  ExtendedInput,
  ExtendedTx,
  isExtendedCoinbase,
  Output
} from '@/types/bitcoin'
import { subtractBigNum, sumValuesBigNum, trimZeros } from './bignum'
import { hex2ascii } from './general'

export const BITCOIN_START = 1231006505
export const BITCOIN_START_MILLIS = BITCOIN_START * 1000
const MAX_SEQUENCE = 0xffffffff
const BIP_125_TIMESTAMP = 1448625601 // 11/27/2015 - BIP 125 merged

export function isDust(value: string | number | undefined) {
  if (!value) return false
  const val = typeof value === 'string' ? parseFloat(value) : value
  if (val.toString() !== value.toString()) {
    return false
  }
  if (val * 10 ** 8 === 546) {
    return true
  }
  return false
}

export function decodeBtcHex(asm: string) {
  const asmStack = asm.split(' ')
  const decodedStack: string[] = []

  for (const item of asmStack) {
    if (!item.startsWith('OP_')) {
      decodedStack.push(hex2ascii(item))
    }
  }
  return decodedStack.join(' ')
}

export function rbfEnabled(txn: ExtendedTx): boolean {
  if (txn.time < BIP_125_TIMESTAMP) {
    return false
  }
  const lowestSequence = (<ExtendedInput[]>txn.vin)
    .map((i) => i.sequence)
    .reduce((lowest, i) => {
      if (i < lowest) {
        lowest = i
      }
      return lowest
    }, MAX_SEQUENCE)
  if (lowestSequence === MAX_SEQUENCE || (lowestSequence === MAX_SEQUENCE - 1 && txn.locktime > 0)) {
    return false
  }
  if (lowestSequence < MAX_SEQUENCE - 1 || (lowestSequence === MAX_SEQUENCE - 1 && txn.locktime === 0)) {
    return true
  }
  return false
}

export function calcFee(inputValue: string | number, outputValue: string | number): string {
  return trimZeros(subtractBigNum([inputValue, outputValue], 8))
}

export function calcOutputAmount(outputs: Output[]): string {
  return trimZeros(
    sumValuesBigNum(
      outputs.map((o) => o.value),
      8,
      false
    )
  )
}

export function calcInputAmount(inputs: Array<ExtendedInput | ExtendedCoinbase>): string {
  return trimZeros(
    sumValuesBigNum(
      inputs.map((i) => (isExtendedCoinbase(i) ? i.value : i.spentOutput.value)),
      8,
      false
    )
  )
}

export function svb(fee: string | number, vsize: number): number {
  if (typeof fee === 'string') {
    fee = parseFloat(fee)
  }
  const sats = fee * 10 ** 8
  return Math.round(sats / vsize)
}

export function currentCryptotime(): number {
  return Date.now() / 1000 - BITCOIN_START
}
